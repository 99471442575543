// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as User_bs from "../types/user_bs.bs.js";
import * as User_types from "../types/user_types.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function make(param) {
  return User_types.default_user(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);
}

function id(p) {
  return Belt_Option.getExn(p.id);
}

function withId(p, id) {
  return {
          id: id,
          display_name: p.display_name,
          email: p.email,
          provider_id: p.provider_id,
          distance_unit: p.distance_unit,
          speed_unit: p.speed_unit,
          pressure_unit: p.pressure_unit,
          temperature_unit: p.temperature_unit,
          is_anonymous: p.is_anonymous,
          unix_timestamp: p.unix_timestamp
        };
}

function prepareForDb(p) {
  return {
          id: undefined,
          display_name: p.display_name,
          email: p.email,
          provider_id: p.provider_id,
          distance_unit: p.distance_unit,
          speed_unit: p.speed_unit,
          pressure_unit: p.pressure_unit,
          temperature_unit: p.temperature_unit,
          is_anonymous: p.is_anonymous,
          unix_timestamp: p.unix_timestamp
        };
}

var encode = User_bs.encode_user;

var decode = User_bs.decode_user;

export {
  make ,
  encode ,
  decode ,
  id ,
  withId ,
  prepareForDb ,
  
}
/* User_bs Not a pure module */
