// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as FormLabel from "@material-ui/core/FormLabel";

function StringFormLabel(Props) {
  var $staropt$star = Props.text;
  var text = $staropt$star !== undefined ? $staropt$star : "";
  return React.createElement(FormLabel.default, {
              children: text
            });
}

var Mui = /* alias */0;

var make = StringFormLabel;

export {
  Mui ,
  make ,
  
}
/* react Not a pure module */
