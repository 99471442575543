// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Boat_bs from "../types/boat_bs.bs.js";
import * as Boat_types from "../types/boat_types.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function make(param) {
  return Boat_types.default_boat(undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);
}

function id(p) {
  return Belt_Option.getExn(p.id);
}

function withId(p, id) {
  return {
          id: id,
          name: p.name,
          builder: p.builder,
          model: p.model,
          year: p.year,
          loa: p.loa
        };
}

function prepareForDb(p) {
  return {
          id: undefined,
          name: p.name,
          builder: p.builder,
          model: p.model,
          year: p.year,
          loa: p.loa
        };
}

var encode = Boat_bs.encode_boat;

var decode = Boat_bs.decode_boat;

export {
  make ,
  encode ,
  decode ,
  id ,
  withId ,
  prepareForDb ,
  
}
/* Boat_bs Not a pure module */
