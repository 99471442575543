// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Distance from "./Distance.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Passage_types from "../types/passage_types.bs.js";
import * as Belt_HashSetInt from "bs-platform/lib/es6/belt_HashSetInt.js";

function revChronoPassageLogs(l, r) {
  return r.unix_timestamp - l.unix_timestamp | 0;
}

function passageType(p) {
  var match = p.retroactive_data;
  if (match !== undefined) {
    return /* Retroactive */0;
  } else {
    return /* Live */1;
  }
}

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function fieldRank(param) {
  switch (param) {
    case /* Leg */0 :
        return 0;
    case /* Sog */1 :
        return 4;
    case /* Cog */2 :
        return 5;
    case /* Distance */3 :
        return 7;
    case /* Chart_course */4 :
        return 6;
    case /* Wind_speed */5 :
        return 9;
    case /* Wind_direction */6 :
        return 8;
    case /* Sea_state_description */7 :
        return 10;
    case /* Barometric_pressure */8 :
        return 11;
    case /* Air_temperature */9 :
        return 13;
    case /* Sky */10 :
        return 12;
    case /* Sea_temperature */11 :
        return 14;
    case /* Remarks */12 :
        return 3;
    case /* Geo_location */13 :
        return 1;
    case /* Location_name */14 :
        return 2;
    
  }
}

function fieldCmp(l, r) {
  return fieldRank(l) - fieldRank(r) | 0;
}

function makeEnabledFieldSet(fields) {
  var set = Belt_HashSetInt.make(20);
  Belt_List.forEachU(fields, (function (field) {
          return Belt_HashSetInt.add(set, fieldRank(field));
        }));
  return set;
}

function makeIsFieldEnabledFun(fields) {
  var set = makeEnabledFieldSet(fields);
  return (function (field) {
      return Belt_HashSetInt.has(set, fieldRank(field));
    });
}

function makeIsAtLeastOneFieldEnabledFun(fields) {
  var set = makeEnabledFieldSet(fields);
  return (function (fields) {
      return Belt_List.someU(fields, (function (field) {
                    return Belt_HashSetInt.has(set, fieldRank(field));
                  }));
    });
}

var noFieldGroupEnabled = {
  leg: false,
  position: false,
  navigation: false,
  weather: false,
  system: false,
  remarks: false
};

function defaultFieldEnabled(param) {
  return true;
}

function makeFieldGroupEnabled(fields) {
  return Belt_List.reduce(fields, noFieldGroupEnabled, (function (ret, param) {
                var exit = 0;
                switch (param) {
                  case /* Leg */0 :
                      return {
                              leg: true,
                              position: ret.position,
                              navigation: ret.navigation,
                              weather: ret.weather,
                              system: ret.system,
                              remarks: ret.remarks
                            };
                  case /* Sog */1 :
                  case /* Cog */2 :
                  case /* Distance */3 :
                  case /* Chart_course */4 :
                      exit = 2;
                      break;
                  case /* Wind_speed */5 :
                  case /* Wind_direction */6 :
                  case /* Sea_state_description */7 :
                  case /* Barometric_pressure */8 :
                  case /* Air_temperature */9 :
                  case /* Sky */10 :
                  case /* Sea_temperature */11 :
                      exit = 3;
                      break;
                  case /* Remarks */12 :
                      return {
                              leg: ret.leg,
                              position: ret.position,
                              navigation: ret.navigation,
                              weather: ret.weather,
                              system: ret.system,
                              remarks: true
                            };
                  case /* Geo_location */13 :
                  case /* Location_name */14 :
                      exit = 1;
                      break;
                  
                }
                switch (exit) {
                  case 1 :
                      return {
                              leg: ret.leg,
                              position: true,
                              navigation: ret.navigation,
                              weather: ret.weather,
                              system: ret.system,
                              remarks: ret.remarks
                            };
                  case 2 :
                      return {
                              leg: ret.leg,
                              position: ret.position,
                              navigation: true,
                              weather: ret.weather,
                              system: ret.system,
                              remarks: ret.remarks
                            };
                  case 3 :
                      return {
                              leg: ret.leg,
                              position: ret.position,
                              navigation: ret.navigation,
                              weather: true,
                              system: ret.system,
                              remarks: ret.remarks
                            };
                  
                }
              }));
}

function fieldToString(param) {
  switch (param) {
    case /* Leg */0 :
        return "Leg";
    case /* Sog */1 :
        return "Speed Over Ground";
    case /* Cog */2 :
        return "Course Over Ground";
    case /* Distance */3 :
        return "Distance";
    case /* Chart_course */4 :
        return "Chart Course";
    case /* Wind_speed */5 :
        return "Wind Speed";
    case /* Wind_direction */6 :
        return "Wind Direction";
    case /* Sea_state_description */7 :
        return "Sea State";
    case /* Barometric_pressure */8 :
        return "Barometric Pressure";
    case /* Air_temperature */9 :
        return "Air Temperature";
    case /* Sky */10 :
        return "Sky";
    case /* Sea_temperature */11 :
        return "Sea Temperature";
    case /* Remarks */12 :
        return "Remarks";
    case /* Geo_location */13 :
        return "GPS Coordinates";
    case /* Location_name */14 :
        return "Location Name";
    
  }
}

function fieldToShortString(param) {
  switch (param) {
    case /* Leg */0 :
        return "Leg";
    case /* Sog */1 :
        return "SOG";
    case /* Cog */2 :
        return "COG";
    case /* Distance */3 :
        return "Dist";
    case /* Chart_course */4 :
        return "Chart C.";
    case /* Wind_speed */5 :
        return "Wind Sp";
    case /* Wind_direction */6 :
        return "Wind Dir";
    case /* Sea_state_description */7 :
        return "Sea State";
    case /* Barometric_pressure */8 :
        return "Baro";
    case /* Air_temperature */9 :
        return "Air T";
    case /* Sky */10 :
        return "Sky";
    case /* Sea_temperature */11 :
        return "Sea T";
    case /* Remarks */12 :
        return "Remarks";
    case /* Geo_location */13 :
        return "Lat/Lon";
    case /* Location_name */14 :
        return "Location";
    
  }
}

function enginesHoursToDisplayString(param) {
  if (param !== undefined) {
    return param.toFixed(1).concat(" hr");
  } else {
    return "";
  }
}

function cardinalDirectionToString(param) {
  switch (param) {
    case /* None */0 :
        return "NONE";
    case /* N */1 :
        return "N";
    case /* Nne */2 :
        return "NNE";
    case /* Ne */3 :
        return "NE";
    case /* Ene */4 :
        return "ENE";
    case /* E */5 :
        return "E";
    case /* Ese */6 :
        return "ESE";
    case /* Se */7 :
        return "SE";
    case /* Sse */8 :
        return "SSE";
    case /* S */9 :
        return "S";
    case /* Ssw */10 :
        return "SSW";
    case /* Sw */11 :
        return "SW";
    case /* Wsw */12 :
        return "WSW";
    case /* W */13 :
        return "W";
    case /* Wnw */14 :
        return "WNW";
    case /* Nw */15 :
        return "NW";
    case /* Nnw */16 :
        return "NNW";
    
  }
}

var zeroStats = Passage_types.default_stats(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);

function addChronoLogEntry(stats, logEntry) {
  var type_ = logEntry.type_;
  var geo_location = logEntry.geo_location;
  var unix_timestamp = logEntry.unix_timestamp;
  var match = stats.start_unix_timestamp;
  var start = match !== undefined ? (
      unix_timestamp > 0.0 && unix_timestamp < match ? unix_timestamp : stats.start_unix_timestamp
    ) : (
      unix_timestamp > 0.0 ? unix_timestamp : stats.start_unix_timestamp
    );
  var match$1 = stats.end_unix_timestamp;
  var end_ = match$1 !== undefined ? (
      unix_timestamp > 0.0 && unix_timestamp > match$1 ? unix_timestamp : stats.end_unix_timestamp
    ) : (
      unix_timestamp > 0.0 ? unix_timestamp : stats.end_unix_timestamp
    );
  var match$2 = stats.latest_engine_on_unix_timestamp;
  var latest_engine_on_unix_timestamp;
  if (match$2 !== undefined) {
    if (type_ !== undefined) {
      switch (type_.tag | 0) {
        case /* Motor_off */2 :
            latest_engine_on_unix_timestamp = undefined;
            break;
        case /* Position_data */0 :
        case /* Motor_on */1 :
        case /* Sail_change */3 :
            latest_engine_on_unix_timestamp = stats.latest_engine_on_unix_timestamp;
            break;
        
      }
    } else {
      latest_engine_on_unix_timestamp = stats.latest_engine_on_unix_timestamp;
    }
  } else if (type_ !== undefined) {
    switch (type_.tag | 0) {
      case /* Motor_on */1 :
          latest_engine_on_unix_timestamp = unix_timestamp;
          break;
      case /* Motor_off */2 :
          latest_engine_on_unix_timestamp = undefined;
          break;
      case /* Position_data */0 :
      case /* Sail_change */3 :
          latest_engine_on_unix_timestamp = stats.latest_engine_on_unix_timestamp;
          break;
      
    }
  } else {
    latest_engine_on_unix_timestamp = stats.latest_engine_on_unix_timestamp;
  }
  var latest_engine_hours_counter;
  if (type_ !== undefined) {
    var match$3 = type_;
    var exit = 0;
    switch (match$3.tag | 0) {
      case /* Motor_on */1 :
      case /* Motor_off */2 :
          exit = 1;
          break;
      case /* Position_data */0 :
      case /* Sail_change */3 :
          latest_engine_hours_counter = stats.latest_engine_hours_counter;
          break;
      
    }
    if (exit === 1) {
      var match$4 = match$3[0].engine_hours_counter;
      latest_engine_hours_counter = match$4 !== undefined ? match$4 : stats.latest_engine_hours_counter;
    }
    
  } else {
    latest_engine_hours_counter = stats.latest_engine_hours_counter;
  }
  var current_leg;
  if (type_ !== undefined) {
    var match$5 = type_;
    if (match$5.tag) {
      current_leg = stats.current_leg;
    } else {
      var leg = match$5[0].leg;
      current_leg = leg > stats.current_leg ? leg : stats.current_leg;
    }
  } else {
    current_leg = stats.current_leg;
  }
  var match$6 = stats.latest_engine_on_unix_timestamp;
  var tmp;
  if (match$6 !== undefined) {
    if (type_ !== undefined) {
      var match$7 = type_;
      if (match$7.tag === /* Motor_off */2) {
        var eH = match$7[0].engine_hours_counter;
        var prevT = match$6;
        var match$8 = stats.latest_engine_hours_counter;
        tmp = match$8 !== undefined ? (
            eH !== undefined ? eH - match$8 : (unix_timestamp - prevT) / 3600.0
          ) : (unix_timestamp - prevT) / 3600.0;
      } else {
        tmp = 0.0;
      }
    } else {
      tmp = 0.0;
    }
  } else {
    tmp = 0.0;
  }
  var total_engine_hours = stats.total_engine_hours + tmp;
  var match$9 = stats.latest_geo_location;
  var ret;
  if (match$9 !== undefined) {
    if (geo_location !== undefined) {
      var current = geo_location;
      ret = /* tuple */[
        current,
        stats.total_distance + Distance.distance(match$9, current)
      ];
    } else {
      ret = /* tuple */[
        stats.latest_geo_location,
        stats.total_distance
      ];
    }
  } else {
    ret = geo_location !== undefined ? /* tuple */[
        geo_location,
        stats.total_distance
      ] : /* tuple */[
        undefined,
        stats.total_distance
      ];
  }
  return {
          start_unix_timestamp: start,
          end_unix_timestamp: end_,
          total_engine_hours: total_engine_hours,
          latest_engine_hours_counter: latest_engine_hours_counter,
          latest_engine_on_unix_timestamp: latest_engine_on_unix_timestamp,
          current_leg: current_leg,
          total_distance: ret[1],
          latest_geo_location: ret[0]
        };
}

function computePassageStats(passage) {
  var match = passage.retroactive_data;
  if (match !== undefined) {
    var rd = match;
    return /* tuple */[
            {
              start_unix_timestamp: rd.start_unix_timestamp,
              end_unix_timestamp: rd.end_unix_timestamp,
              total_engine_hours: rd.total_engine_hours,
              latest_engine_hours_counter: undefined,
              latest_engine_on_unix_timestamp: undefined,
              current_leg: 0,
              total_distance: rd.total_distance,
              latest_geo_location: undefined
            },
            false
          ];
  } else {
    var match$1 = passage.logs_stats;
    var match$2 = passage.logs;
    if (match$1 !== undefined) {
      var prevStats = match$1;
      if (match$2) {
        var stats = Belt_List.reduceReverse(passage.logs, zeroStats, addChronoLogEntry);
        return /* tuple */[
                stats,
                Caml_obj.caml_notequal(prevStats, stats)
              ];
      } else {
        return /* tuple */[
                prevStats,
                false
              ];
      }
    } else {
      var stats$1 = Belt_List.reduceReverse(passage.logs, zeroStats, addChronoLogEntry);
      return /* tuple */[
              stats$1,
              true
            ];
    }
  }
}

function computeHoursSince(t, passageStatsInRevChrono) {
  var _eh = 0.0;
  var _param = passageStatsInRevChrono;
  while(true) {
    var param = _param;
    var eh = _eh;
    if (param) {
      var tl = param[1];
      var hd = param[0];
      var match = hd.end_unix_timestamp;
      if (match !== undefined) {
        if (match < t) {
          return eh;
        } else {
          _param = tl;
          _eh = eh + hd.total_engine_hours;
          continue ;
        }
      } else {
        _param = tl;
        continue ;
      }
    } else {
      return eh;
    }
  };
}

function computeDistanceSince(t, passageStatsInRevChrono) {
  var _d = 0.0;
  var _param = passageStatsInRevChrono;
  while(true) {
    var param = _param;
    var d = _d;
    if (param) {
      var tl = param[1];
      var hd = param[0];
      var match = hd.end_unix_timestamp;
      if (match !== undefined) {
        if (match < t) {
          return d;
        } else {
          _param = tl;
          _d = d + hd.total_distance;
          continue ;
        }
      } else {
        _param = tl;
        continue ;
      }
    } else {
      return d;
    }
  };
}

var Pa = /* alias */0;

var B = /* alias */0;

var S = /* alias */0;

var CD = /* alias */0;

var navigationFields = /* :: */[
  /* Leg */0,
  /* :: */[
    /* Geo_location */13,
    /* :: */[
      /* Location_name */14,
      /* :: */[
        /* Sog */1,
        /* :: */[
          /* Cog */2,
          /* :: */[
            /* Distance */3,
            /* :: */[
              /* Chart_course */4,
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ]
];

var weatherFields = /* :: */[
  /* Wind_speed */5,
  /* :: */[
    /* Wind_direction */6,
    /* :: */[
      /* Sea_state_description */7,
      /* :: */[
        /* Barometric_pressure */8,
        /* :: */[
          /* Air_temperature */9,
          /* :: */[
            /* Sky */10,
            /* :: */[
              /* Sea_temperature */11,
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ]
];

var otherFields = /* :: */[
  /* Remarks */12,
  /* [] */0
];

var allFieldGroupEnabled = {
  leg: true,
  position: true,
  navigation: true,
  weather: true,
  system: true,
  remarks: true
};

export {
  Pa ,
  B ,
  S ,
  CD ,
  revChronoPassageLogs ,
  passageType ,
  concat ,
  navigationFields ,
  weatherFields ,
  otherFields ,
  fieldRank ,
  fieldCmp ,
  makeEnabledFieldSet ,
  makeIsFieldEnabledFun ,
  makeIsAtLeastOneFieldEnabledFun ,
  allFieldGroupEnabled ,
  noFieldGroupEnabled ,
  defaultFieldEnabled ,
  makeFieldGroupEnabled ,
  fieldToString ,
  fieldToShortString ,
  enginesHoursToDisplayString ,
  cardinalDirectionToString ,
  zeroStats ,
  addChronoLogEntry ,
  computePassageStats ,
  computeHoursSince ,
  computeDistanceSince ,
  
}
/* zeroStats Not a pure module */
