// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Ignore from "../../types/Ignore.bs.js";
import * as UnixTimestamp from "../../util/UnixTimestamp.bs.js";
import * as DateFns from "@date-io/date-fns";
import * as Grid from "@material-ui/core/Grid";
import * as DatePicker from "@material-ui/pickers/DatePicker";
import * as TimePicker from "@material-ui/pickers/TimePicker";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";
import * as MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";

function useHook(param) {
  var match = React.useState((function () {
          return /* tuple */[
                  new Date(),
                  new Date()
                ];
        }));
  var setDateTime = match[1];
  var match$1 = match[0];
  var resetToNow = function (param) {
    return Curry._1(setDateTime, (function (param) {
                  return /* tuple */[
                          new Date(),
                          new Date()
                        ];
                }));
  };
  var update = function (what, d) {
    if (what !== 758940238) {
      if (what !== 936769581 || (d == null)) {
        return /* () */0;
      } else {
        return Curry._1(setDateTime, (function (param) {
                      return /* tuple */[
                              param[0],
                              d
                            ];
                    }));
      }
    } else if (d == null) {
      return /* () */0;
    } else {
      return Curry._1(setDateTime, (function (param) {
                    return /* tuple */[
                            d,
                            param[1]
                          ];
                  }));
    }
  };
  return /* tuple */[
          match$1[0],
          match$1[1],
          update,
          resetToNow
        ];
}

var useStyles = MakeStyles.default({
      date: {
        width: "100%"
      }
    });

function makeNullable(prim) {
  return prim;
}

function label(includeLabels, what) {
  if (includeLabels) {
    if (what >= 936769581) {
      return "Time";
    } else {
      return "Date";
    }
  } else {
    return "";
  }
}

function toNullDate(x) {
  if (x !== 0.0) {
    return UnixTimestamp.toDate(x);
  } else {
    return null;
  }
}

function DatetimePickers(Props) {
  var $staropt$star = Props.unixTimestamp;
  var $staropt$star$1 = Props.onChange;
  var $staropt$star$2 = Props.includeLabels;
  var $staropt$star$3 = Props.disabled;
  var $staropt$star$4 = Props.disableFuture;
  var unixTimestamp = $staropt$star !== undefined ? $staropt$star : 0.0;
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : Ignore.v1;
  var includeLabels = $staropt$star$2 !== undefined ? $staropt$star$2 : true;
  var disabled = $staropt$star$3 !== undefined ? $staropt$star$3 : false;
  var disableFuture = $staropt$star$4 !== undefined ? $staropt$star$4 : true;
  var classes = useStyles();
  var date = toNullDate(unixTimestamp);
  var time = toNullDate(unixTimestamp);
  var onChange$1 = function (what, v) {
    var date = UnixTimestamp.toDate(unixTimestamp);
    if (what !== 758940238) {
      if (what !== 936769581 || (v == null)) {
        return /* () */0;
      } else {
        date.setHours(v.getHours());
        date.setMinutes(v.getMinutes());
        return Curry._1(onChange, UnixTimestamp.fromDate(date));
      }
    } else if (v == null) {
      return /* () */0;
    } else {
      return Curry._1(onChange, UnixTimestamp.fromDate(v));
    }
  };
  var onTimeChange = function (dateOrError, displayedString) {
    if ((dateOrError == null) || (displayedString == null)) {
      return /* () */0;
    } else {
      var f = dateOrError.getTime();
      if (!isNaN(f) && f > 1.0) {
        onChange$1(/* Time */936769581, dateOrError);
      }
      return /* () */0;
    }
  };
  return React.createElement(Grid.default, {
              container: true,
              spacing: 2,
              children: React.createElement(MuiPickersUtilsProvider.MuiPickersUtilsProvider, {
                    utils: DateFns.default,
                    children: null
                  }, React.createElement(Grid.default, {
                        item: true,
                        sm: 6,
                        xs: 6,
                        children: React.createElement(DatePicker.DatePicker, {
                              className: classes.date,
                              inputVariant: "outlined",
                              label: label(includeLabels, /* Date */758940238),
                              disabled: disabled,
                              disableFuture: disableFuture,
                              value: date,
                              onChange: (function (param) {
                                  return onChange$1(/* Date */758940238, param);
                                }),
                              format: "MMM do u"
                            })
                      }), React.createElement(Grid.default, {
                        item: true,
                        sm: 6,
                        xs: 6,
                        children: React.createElement(TimePicker.KeyboardTimePicker, {
                              className: classes.date,
                              inputVariant: "outlined",
                              value: time,
                              onChange: onTimeChange,
                              label: label(includeLabels, /* Time */936769581),
                              disabled: disabled,
                              placeholder: "__:__ AM",
                              mask: "__:__ _M"
                            })
                      }))
            });
}

var Mui = /* alias */0;

var make = DatetimePickers;

export {
  Mui ,
  useHook ,
  useStyles ,
  makeNullable ,
  label ,
  toNullDate ,
  make ,
  
}
/* useStyles Not a pure module */
