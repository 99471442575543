// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as System_bs from "../types/system_bs.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as System_types from "../types/system_types.bs.js";

function make(param) {
  return System_types.default_system(undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);
}

function id(p) {
  return Belt_Option.getExn(p.id);
}

function withId(p, id) {
  return {
          id: id,
          identifier: p.identifier,
          category: p.category,
          other_category: p.other_category,
          brand: p.brand,
          model: p.model,
          maintenance_items: p.maintenance_items
        };
}

function prepareForDb(p) {
  return {
          id: undefined,
          identifier: p.identifier,
          category: p.category,
          other_category: p.other_category,
          brand: p.brand,
          model: p.model,
          maintenance_items: /* [] */0
        };
}

var encode = System_bs.encode_system;

var decode = System_bs.decode_system;

export {
  make ,
  encode ,
  decode ,
  id ,
  withId ,
  prepareForDb ,
  
}
/* System_bs Not a pure module */
