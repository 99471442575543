// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Boat from "../util/Boat.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Input from "./core/Input.bs.js";
import * as React from "react";
import * as Belt_Int from "bs-platform/lib/es6/belt_Int.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as EntryDialog from "./core/EntryDialog.bs.js";
import * as LengthEntry from "./LengthEntry.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as AddBoatConfirmation from "./AddBoatConfirmation.bs.js";
import * as Grid from "@material-ui/core/Grid";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function titleOfMode(param) {
  if (param) {
    var name = param[0].data.name;
    return EntryDialog.makeTitle(undefined, "Edit boat ".concat(name));
  } else {
    return EntryDialog.makeTitle(undefined, "Add a new boat");
  }
}

function actionOfMode(onAction, param) {
  if (param) {
    return EntryDialog.makeUpdate(onAction, "boat");
  } else {
    return EntryDialog.makeAdd(onAction, "boat");
  }
}

function screenOfMode(param) {
  if (param) {
    return "BoatEntry-Edit";
  } else {
    return "BoatEntry-Add";
  }
}

function ignoreOnClose(param) {
  return /* () */0;
}

function BoatEntry(Props) {
  var $staropt$star = Props.mode;
  var $staropt$star$1 = Props.onClose;
  var mode = $staropt$star !== undefined ? $staropt$star : /* Add */0;
  var onClose = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnClose;
  AnalyticsEffect.useCurrentScreen(screenOfMode(mode));
  var match = React.useState((function () {
          return Boat.$$default(undefined, /* () */0);
        }));
  var setBoat = match[1];
  var boat = match[0];
  React.useEffect((function () {
          if (mode) {
            var boat = mode[0];
            Curry._1(setBoat, (function (param) {
                    return boat;
                  }));
            return ;
          }
          
        }), [mode]);
  var match$1 = React.useState((function () {
          return /* Hidden */19559306;
        }));
  var setConfirmationPopup = match$1[1];
  var updateBoatData = function (f) {
    return Curry._1(setBoat, (function (b) {
                  var data = Curry._1(f, b.data);
                  return {
                          data: data,
                          systems: b.systems,
                          passages: b.passages
                        };
                }));
  };
  var updateName = function (e) {
    var name = e.target.value;
    return updateBoatData((function (boat) {
                  return {
                          id: boat.id,
                          name: name,
                          builder: boat.builder,
                          model: boat.model,
                          year: boat.year,
                          loa: boat.loa
                        };
                }));
  };
  var updateBuilder = function (e) {
    var builder = e.target.value;
    return updateBoatData((function (boat) {
                  return {
                          id: boat.id,
                          name: boat.name,
                          builder: builder,
                          model: boat.model,
                          year: boat.year,
                          loa: boat.loa
                        };
                }));
  };
  var updateModel = function (e) {
    var model = e.target.value;
    return updateBoatData((function (boat) {
                  return {
                          id: boat.id,
                          name: boat.name,
                          builder: boat.builder,
                          model: model,
                          year: boat.year,
                          loa: boat.loa
                        };
                }));
  };
  var updateYear = function (e) {
    var year = e.target.value;
    var match = Belt_Int.fromString(year);
    var year$1 = year === "" || match === undefined ? 0 : match;
    return updateBoatData((function (boat) {
                  return {
                          id: boat.id,
                          name: boat.name,
                          builder: boat.builder,
                          model: boat.model,
                          year: year$1,
                          loa: boat.loa
                        };
                }));
  };
  var updateLoa = function (applyLength) {
    return updateBoatData((function (boat) {
                  var loa = Curry._1(applyLength, boat.loa);
                  return {
                          id: boat.id,
                          name: boat.name,
                          builder: boat.builder,
                          model: boat.model,
                          year: boat.year,
                          loa: loa
                        };
                }));
  };
  var match$2 = React.useState((function () {
          return ;
        }));
  var setNameError = match$2[1];
  var match$3 = boat.data;
  var name = match$3.name;
  var year = Boat.stringOfYear(match$3.year);
  var match$4 = React.useContext(AppContext.ctx);
  var addErrorAlert = match$4.addErrorAlert;
  var user = match$4.user;
  var firestore = match$4.firestore;
  var addBoat = function (param) {
    if (name === "") {
      return Curry._1(setNameError, (function (param) {
                    return "Name required";
                  }));
    } else {
      Curry._1(setNameError, (function (param) {
              return ;
            }));
      if (typeof user === "number") {
        return Curry._1(onClose, /* () */0);
      } else {
        var match = user[0].id;
        if (match !== undefined) {
          var userId = match;
          if (mode) {
            Db.updateBoat(firestore, boat.data, (function (param) {
                    if (typeof param === "number") {
                      return Analytics.data(Events_types.default_data(/* Boat */1, /* Updated */5, /* Success */1, undefined, userId.firestore_path, /* () */0));
                    } else {
                      Analytics.except("DB Edit - Boat", param[1]);
                      Analytics.data(Events_types.default_data(/* Boat */1, /* Updated */5, /* Failure */2, undefined, userId.firestore_path, /* () */0));
                      return Curry._1(addErrorAlert, "Error saving boat data, please retry");
                    }
                  }));
            return Curry._1(onClose, /* () */0);
          } else {
            Curry._1(setConfirmationPopup, (function (param) {
                    return /* Saving */-674467366;
                  }));
            return Db.addBoat(userId, firestore, boat.data, (function (param) {
                          if (param[0] >= 106380200) {
                            Analytics.except("DB Add - Boat", param[1]);
                            Analytics.data(Events_types.default_data(/* Boat */1, /* Added */4, /* Failure */2, undefined, undefined, /* () */0));
                            Curry._1(addErrorAlert, "Internal error adding boat. App will reset");
                            return Curry._1(onClose, /* () */0);
                          } else {
                            Analytics.data(Events_types.default_data(/* Boat */1, /* Added */4, /* Success */1, undefined, param[1].firestore_path, /* () */0));
                            var boatName = boat.data.name;
                            return Curry._1(setConfirmationPopup, (function (param) {
                                          return /* `Done */[
                                                  759635106,
                                                  boatName
                                                ];
                                        }));
                          }
                        }));
          }
        } else {
          return Curry._1(onClose, /* () */0);
        }
      }
    }
  };
  var action = actionOfMode(addBoat, mode);
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(EntryDialog.make, {
                  title: titleOfMode(mode),
                  action: action,
                  onClose: onClose,
                  children: React.createElement(Grid.default, {
                        container: true,
                        justify: "space-between",
                        alignItems: "center",
                        spacing: 4,
                        children: null
                      }, React.createElement(Grid.default, {
                            item: true,
                            xs: 12,
                            children: React.createElement(Input.make, {
                                  error: match$2[0],
                                  label: "Name",
                                  value: name,
                                  onChange: updateName
                                })
                          }), React.createElement(Grid.default, {
                            item: true,
                            sm: 6,
                            xs: 12,
                            children: React.createElement(Input.make, {
                                  label: "Builder",
                                  value: match$3.builder,
                                  onChange: updateBuilder
                                })
                          }), React.createElement(Grid.default, {
                            item: true,
                            sm: 6,
                            xs: 12,
                            children: React.createElement(Input.make, {
                                  label: "Model",
                                  value: match$3.model,
                                  onChange: updateModel
                                })
                          }), React.createElement(Grid.default, {
                            item: true,
                            sm: 6,
                            xs: 12,
                            children: React.createElement(Input.make, {
                                  label: "Model Year",
                                  value: year,
                                  onChange: updateYear
                                })
                          }), React.createElement(Grid.default, {
                            item: true,
                            sm: 6,
                            xs: 12,
                            children: React.createElement(LengthEntry.make, {
                                  length: match$3.loa,
                                  onChange: updateLoa
                                })
                          }))
                }), React.createElement(AddBoatConfirmation.make, {
                  onClose: onClose,
                  mode: match$1[0]
                }));
}

var Mui = /* alias */0;

var B = /* alias */0;

var U = /* alias */0;

var I = /* alias */0;

var E = /* alias */0;

var defaultMode = /* Add */0;

var make = BoatEntry;

export {
  Mui ,
  B ,
  U ,
  I ,
  E ,
  concat ,
  titleOfMode ,
  actionOfMode ,
  screenOfMode ,
  defaultMode ,
  ignoreOnClose ,
  make ,
  
}
/* Db Not a pure module */
