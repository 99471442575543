// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Box from "@material-ui/core/Box";
import * as TableCell from "@material-ui/core/TableCell";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      tableHead: {
        fontWeight: 800
      },
      content: (function (props) {
          return {
                  minWidth: props.minWidth
                };
        })
    });

function StringHeadTableCell(Props) {
  var $staropt$star = Props.value;
  var $staropt$star$1 = Props.colSpan;
  var $staropt$star$2 = Props.minWidth;
  var $staropt$star$3 = Props.children;
  var value = $staropt$star !== undefined ? $staropt$star : "";
  var colSpan = $staropt$star$1 !== undefined ? $staropt$star$1 : 1;
  var minWidth = $staropt$star$2 !== undefined ? $staropt$star$2 : 0;
  var children = $staropt$star$3 !== undefined ? Caml_option.valFromOption($staropt$star$3) : null;
  var classes = useStyles({
        minWidth: minWidth
      });
  return React.createElement(TableCell.default, {
              className: classes.tableHead,
              colSpan: colSpan,
              children: React.createElement(Box.default, {
                    className: classes.content,
                    children: null
                  }, value, children)
            });
}

var Mui = /* alias */0;

var make = StringHeadTableCell;

export {
  Mui ,
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
