// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as About from "./About.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as MenuHook from "./util/MenuHook.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as LoginButton from "./LoginButton.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Menu from "@material-ui/core/Menu";
import * as Menu$1 from "@material-ui/icons/Menu";
import * as Hidden from "@material-ui/core/Hidden";
import * as Divider from "@material-ui/core/Divider";
import * as Toolbar from "@material-ui/core/Toolbar";
import * as MenuItem from "@material-ui/core/MenuItem";
import * as IconButton from "@material-ui/core/IconButton";
import * as Typography from "@material-ui/core/Typography";
import * as AccountCircle from "@material-ui/icons/AccountCircle";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      loginButton: {
        margin: "auto 0px auto auto"
      }
    });

var signIn = "Sign In";

function makeLoginOrOutMenuItem(onLoginClick, hide, user) {
  var match;
  if (typeof user === "number") {
    match = user !== 0 ? /* tuple */[
        signIn,
        false,
        (function (e) {
            Curry._1(hide, e);
            return Curry._1(onLoginClick, /* Login */203508041);
          })
      ] : /* tuple */[
        signIn,
        true,
        (function (param) {
            return /* () */0;
          })
      ];
  } else {
    var match$1 = user[0];
    if (match$1.is_anonymous) {
      match = /* tuple */[
        signIn,
        false,
        (function (e) {
            Curry._1(hide, e);
            return Curry._1(onLoginClick, /* Login */203508041);
          })
      ];
    } else {
      var text = Curry._1(Printf.sprintf(/* Format */[
                /* String_literal */Block.__(11, [
                    "Logout (",
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* Char_literal */Block.__(12, [
                            /* ")" */41,
                            /* End_of_format */0
                          ])
                      ])
                  ]),
                "Logout (%s)"
              ]), match$1.display_name);
      match = /* tuple */[
        text,
        false,
        (function (e) {
            Curry._1(hide, e);
            return Curry._1(onLoginClick, /* Logout */285436586);
          })
      ];
    }
  }
  var text$1 = match[0];
  if (typeof user === "number" && user === 0) {
    return null;
  }
  return React.createElement(MenuItem.default, {
              key: text$1,
              onClick: match[2],
              disabled: match[1],
              children: text$1
            });
}

function makeSimpleMenuItem(onClick, hide, text) {
  var onClick$1 = function (e) {
    Curry._1(onClick, /* () */0);
    return Curry._1(hide, e);
  };
  return React.createElement(MenuItem.default, {
              key: text,
              onClick: onClick$1,
              children: text
            });
}

var loadingData = "Loading Data...";

var loggingIn = "Logging In...";

var initializing = "Initializing";

function infoText(user, isDataComplete) {
  var match = process.env.NODE_ENV;
  if (typeof user === "number") {
    if (user !== 0) {
      return null;
    } else if (match === "development") {
      return loggingIn;
    } else {
      return initializing;
    }
  } else if (isDataComplete) {
    return null;
  } else if (match === "development") {
    return loadingData;
  } else {
    return initializing;
  }
}

function AppBar(Props) {
  var title = Props.title;
  var user = Props.user;
  var onLoginClick = Props.onLoginClick;
  var onMenuClick = Props.onMenuClick;
  var isDataComplete = Props.isDataComplete;
  var $staropt$star = Props.disabled;
  var disabled = $staropt$star !== undefined ? $staropt$star : false;
  var classes = useStyles();
  var match = MenuHook.useMenu(/* () */0);
  var toggle = match[2];
  var showPrivacyPolicy = function (param) {
    Analytics.nav(Events_types.default_navigation(/* App_bar_user_menu_privacy_policy */10, undefined, /* () */0));
    window.open("https://boat-health.net/privacy.html", "_blank");
    return /* () */0;
  };
  var showTerms = function (param) {
    Analytics.nav(Events_types.default_navigation(/* App_bar_user_menu_terms_and_conditions */11, undefined, /* () */0));
    window.open("https://boat-health.net/terms.html", "_blank");
    return /* () */0;
  };
  var match$1 = React.useState((function () {
          return false;
        }));
  var setIsAboutShown = match$1[1];
  var showAbout = function (param) {
    Analytics.nav(Events_types.default_navigation(/* App_bar_user_menu_about */12, undefined, /* () */0));
    return Curry._1(setIsAboutShown, (function (param) {
                  return true;
                }));
  };
  var hideAbout = function (param) {
    return Curry._1(setIsAboutShown, (function (param) {
                  return false;
                }));
  };
  var handleOnIconMenuClicked = function (param) {
    Analytics.nav(Events_types.default_navigation(/* App_bar_menu */5, undefined, /* () */0));
    return Curry._1(onMenuClick, /* () */0);
  };
  var onAppBarLogin = function (which) {
    Analytics.nav(Events_types.default_navigation(/* App_bar_login */6, undefined, /* () */0));
    return Curry._1(onLoginClick, which);
  };
  var handleUserMenuLoginClicked = function (which) {
    var type_ = which >= 285436586 ? /* App_bar_user_menu_logout */9 : /* App_bar_user_menu_login */8;
    Analytics.nav(Events_types.default_navigation(type_, undefined, /* () */0));
    return Curry._1(onLoginClick, which);
  };
  var handleUserMenuClicked = function (e) {
    Analytics.nav(Events_types.default_navigation(/* App_bar_user_menu */7, undefined, /* () */0));
    return Curry._2(toggle, /* Show */925630749, e);
  };
  return React.createElement(Toolbar.default, {
              color: "primary",
              children: null
            }, React.createElement(Hidden.default, {
                  mdUp: true,
                  xsDown: true,
                  children: React.createElement(IconButton.default, {
                        color: "inherit",
                        edge: "start",
                        disabled: disabled,
                        onClick: handleOnIconMenuClicked,
                        children: React.createElement(Menu$1.default, { })
                      })
                }), React.createElement(Typography.default, {
                  variant: "h6",
                  noWrap: true,
                  children: title
                }), React.createElement(Box.default, {
                  className: classes.loginButton,
                  children: React.createElement(LoginButton.make, {
                        user: user,
                        onClick: onAppBarLogin,
                        isDataComplete: isDataComplete
                      })
                }), infoText(user, isDataComplete), React.createElement(Box.default, {
                  children: null
                }, React.createElement(IconButton.default, {
                      color: "inherit",
                      edge: "end",
                      disabled: disabled,
                      onClick: handleUserMenuClicked,
                      children: React.createElement(AccountCircle.default, { })
                    }), React.createElement(Menu.default, {
                      open: match[1],
                      anchorEl: match[0],
                      keepMounted: true,
                      onClose: Curry._1(toggle, /* Hide */803692770),
                      children: null
                    }, makeLoginOrOutMenuItem(handleUserMenuLoginClicked, Curry._1(toggle, /* Hide */803692770), user), React.createElement(Divider.default, { }), makeSimpleMenuItem(showPrivacyPolicy, Curry._1(toggle, /* Hide */803692770), "Privacy Policy"), makeSimpleMenuItem(showTerms, Curry._1(toggle, /* Hide */803692770), "Terms & Conditions"), makeSimpleMenuItem(showAbout, Curry._1(toggle, /* Hide */803692770), "About"))), React.createElement(About.make, {
                  open: match$1[0],
                  onClose: hideAbout
                }));
}

var Mui = /* alias */0;

var U = /* alias */0;

var E = /* alias */0;

var make = AppBar;

export {
  Mui ,
  U ,
  E ,
  useStyles ,
  signIn ,
  makeLoginOrOutMenuItem ,
  makeSimpleMenuItem ,
  loadingData ,
  loggingIn ,
  initializing ,
  infoText ,
  make ,
  
}
/* useStyles Not a pure module */
