// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as State from "../util/State.bs.js";
import * as React from "react";
import * as AppBar from "./AppBar.bs.js";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as AppMenu from "./AppMenu.bs.js";
import * as AppAlert from "./AppAlert.bs.js";
import * as AppTitle from "./AppTitle.bs.js";
import * as UserHook from "./util/UserHook.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as UserState from "../util/UserState.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as AuthDialog from "./AuthDialog.bs.js";
import * as Collection from "../util/Collection.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as BoatSection from "./BoatSection.bs.js";
import * as HomeSection from "./HomeSection.bs.js";
import * as PassageSection from "./PassageSection.bs.js";
import * as LogoutConfirmation from "./LogoutConfirmation.bs.js";
import * as MaintenanceSection from "./MaintenanceSection.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Alert from "@material-ui/lab/Alert";
import * as Paper from "@material-ui/core/Paper";
import * as AppBar$1 from "@material-ui/core/AppBar";
import * as Hidden from "@material-ui/core/Hidden";
import * as CssBaseline from "@material-ui/core/CssBaseline";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var appBarWidth = "calc(100% - ".concat(String(AppMenu.drawerWidth)).concat("px)");

function makeStylesRaw (theme){return {
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - 240px)`,
      marginLeft: 240,
    },
  },
  subContent: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '100px',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 240,
      marginTop: '85px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '85px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '70px',
    },
  },
  bottomMenu: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  bottomMsg: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '450px',
      marginLeft: 'auto',
      right: '0px',
    },
    position: 'fixed',
    bottom: 70,
  },
}};

var useStyles = MakeStyles.default(makeStylesRaw);

var successDuration = 2000;

function App(Props) {
  var classes = useStyles();
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setStateNotifications = match[1];
  var stateNotifications = match[0];
  var appendNotification = function (notification) {
    return Curry._1(setStateNotifications, (function (stateNotifications) {
                  return /* :: */[
                          notification,
                          stateNotifications
                        ];
                }));
  };
  var match$1 = React.useReducer((function (param, param$1) {
          return State.reduce(appendNotification, param, param$1);
        }), State.makeDefault(/* () */0));
  var stateDispatch = match$1[1];
  var state = match$1[0];
  var match$2 = React.useState((function () {
          return /* LoggingIn */0;
        }));
  var setUser = match$2[1];
  var user = match$2[0];
  var match$3 = React.useState((function () {
          return ;
        }));
  var setBackdrop = match$3[1];
  var match$4 = AppMenu.useControl(/* () */0);
  var appMenuDispatch = match$4[1];
  var appMenu = match$4[0];
  var onAuthChange = function (userState) {
    if (typeof userState === "number") {
      if (userState !== 0) {
        Curry._1(stateDispatch, /* Reset */-101336657);
        Curry._1(stateDispatch, /* `AddSuccessAlert */[
              880431674,
              /* tuple */[
                "Logged Out Successfully",
                successDuration
              ]
            ]);
        Curry._1(appMenuDispatch, /* `Select */[
              516394780,
              /* Home */0
            ]);
        Curry._1(setBackdrop, (function (param) {
                return ;
              }));
        return Curry._1(setUser, (function (param) {
                      return /* LoggedOut */1;
                    }));
      } else {
        return Curry._1(setUser, (function (param) {
                      return /* LoggingIn */0;
                    }));
      }
    } else {
      var user = userState[0];
      Curry._1(stateDispatch, /* Reset */-101336657);
      Curry._1(setUser, (function (prevUserState) {
              var wasLoggedIn = UserState.isLoggedIn(prevUserState);
              var wasAnonymous = UserState.isLoggedInAnonymous(prevUserState);
              var isAnonymous = UserState.isLoggedInAnonymous(userState);
              if (wasLoggedIn) {
                if (wasAnonymous) {
                  if (isAnonymous) {
                    
                  } else {
                    Curry._1(stateDispatch, /* `AddSuccessAlert */[
                          880431674,
                          /* tuple */[
                            "Signed In Successfully",
                            successDuration
                          ]
                        ]);
                  }
                } else if (isAnonymous) {
                  Curry._1(stateDispatch, /* `AddSuccessAlert */[
                        880431674,
                        /* tuple */[
                          "Logged Out Successfully",
                          successDuration
                        ]
                      ]);
                }
                
              } else if (!isAnonymous) {
                var msg = Curry._1(Printf.sprintf(/* Format */[
                          /* String_literal */Block.__(11, [
                              "Signed In as ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ]),
                          "Signed In as %s"
                        ]), user.display_name);
                Curry._1(stateDispatch, /* `AddSuccessAlert */[
                      880431674,
                      /* tuple */[
                        msg,
                        successDuration
                      ]
                    ]);
              }
              return userState;
            }));
      Curry._1(setBackdrop, (function (param) {
              return ;
            }));
      var id = Belt_Option.getExn(user.id);
      Db.loadBoats(id, AppContext.firestore, stateDispatch);
      return /* () */0;
    }
  };
  var onUserUpgraded = function (param) {
    if (typeof param === "number") {
      return /* () */0;
    } else {
      var user = param[0];
      return Curry._1(setUser, (function (param) {
                    return /* LoggedIn */[user];
                  }));
    }
  };
  var onSignInSuccess = function (param) {
    return Curry._1(stateDispatch, /* `AddSuccessAlert */[
                880431674,
                /* tuple */[
                  "Account successfully created",
                  successDuration
                ]
              ]);
  };
  UserHook.useAuth(onAuthChange);
  var setUser$1 = function (f) {
    return Curry._1(setUser, (function (userState) {
                  if (typeof userState === "number") {
                    return userState;
                  } else {
                    var user = Curry._1(f, userState[0]);
                    Db.updateUser(AppContext.firestore, user, (function (param) {
                            if (typeof param === "number") {
                              return /* () */0;
                            } else {
                              console.log("Error updating user data");
                              return /* () */0;
                            }
                          }));
                    return /* LoggedIn */[user];
                  }
                }));
  };
  var clearAlert = function (param) {
    return Curry._1(stateDispatch, /* ClearAlert */-313943377);
  };
  var addErrorAlert = function (msg) {
    return Curry._1(stateDispatch, /* `AddErrorAlert */[
                -926162987,
                msg
              ]);
  };
  var addSuccessAlert = function (msg) {
    return Curry._1(stateDispatch, /* `AddSuccessAlert */[
                880431674,
                /* tuple */[
                  msg,
                  undefined
                ]
              ]);
  };
  var appContext_setUser = setUser$1;
  var appContext = {
    firestore: AppContext.firestore,
    auth: AppContext.auth,
    user: user,
    setUser: appContext_setUser,
    addErrorAlert: addErrorAlert,
    addSuccessAlert: addSuccessAlert,
    analytics: AppContext.analytics
  };
  var match$5 = AuthDialog.useControl(/* () */0);
  var toggleAuthDialog = match$5[1];
  var match$6 = React.useState((function () {
          return false;
        }));
  var setLogoutConfirmationVisible = match$6[1];
  var logout = function (param) {
    AppContext.auth.signOut();
    return Curry._1(setLogoutConfirmationVisible, (function (param) {
                  return false;
                }));
  };
  var onLoginClick = function (param) {
    if (param >= 285436586) {
      return Curry._1(setLogoutConfirmationVisible, (function (param) {
                    return true;
                  }));
    } else {
      return Curry._1(toggleAuthDialog, /* Show */925630749);
    }
  };
  var onMenuClick = function (param) {
    return Curry._1(appMenuDispatch, /* Open */881668074);
  };
  React.useEffect((function () {
          var isDataComplete = State.isComplete(state);
          var isLoggedIn = UserState.isLoggedIn(user);
          var appReady = isDataComplete && isLoggedIn;
          Curry._1(setBackdrop, (function (backdrop) {
                  if (backdrop !== undefined) {
                    if (backdrop && appReady) {
                      return false;
                    } else {
                      return backdrop;
                    }
                  } else if (appReady) {
                    return ;
                  } else {
                    return true;
                  }
                }));
          return ;
        }), /* tuple */[
        state,
        user
      ]);
  React.useEffect((function () {
          Belt_List.forEach(stateNotifications, (function (param) {
                  return Db.updatePassage(AppContext.firestore, param[0], (function (param) {
                                return /* () */0;
                              }));
                }));
          Curry._1(setStateNotifications, (function (param) {
                  return /* [] */0;
                }));
          return ;
        }), [stateNotifications]);
  var isDataComplete = State.isComplete(state);
  var onStart = function (param) {
    return Curry._1(appMenuDispatch, /* `Select */[
                516394780,
                /* Boat */1
              ]);
  };
  var isAnonymous = UserState.isLoggedInAnonymous(user);
  var hasDataSaved = !Collection.isEmpty(state.boats);
  var isHome = appMenu.selected === /* Home */0;
  var showAnonymousAlert = isAnonymous && hasDataSaved && !isHome;
  var match$7 = appMenu.selected;
  var tmp;
  switch (match$7) {
    case /* Home */0 :
        tmp = React.createElement(HomeSection.make, {
              onStart: onStart,
              state: state
            });
        break;
    case /* Boat */1 :
        tmp = React.createElement(BoatSection.make, {
              state: state,
              stateDispatch: stateDispatch
            });
        break;
    case /* Maintenance */2 :
        tmp = React.createElement(MaintenanceSection.make, {
              state: state,
              stateDispatch: stateDispatch
            });
        break;
    case /* Passage */3 :
        tmp = React.createElement(PassageSection.make, {
              state: state,
              stateDispatch: stateDispatch
            });
        break;
    
  }
  return React.createElement(Box.default, {
              children: React.createElement(AppContext.make, {
                    value: appContext,
                    children: null
                  }, React.createElement(CssBaseline.default, { }), React.createElement(AppBar$1.default, {
                        className: classes.appBar,
                        color: "primary",
                        position: "fixed",
                        children: React.createElement(AppBar.make, {
                              title: React.createElement(AppTitle.make, {
                                    appMenu: appMenu,
                                    state: state
                                  }),
                              user: user,
                              onLoginClick: onLoginClick,
                              onMenuClick: onMenuClick,
                              isDataComplete: isDataComplete,
                              disabled: false
                            })
                      }), React.createElement(Hidden.default, {
                        mdUp: true,
                        xsDown: true,
                        children: React.createElement(AppMenu.Drawer.make, {
                              appMenu: appMenu,
                              appMenuDispatch: appMenuDispatch,
                              variant: "temporary",
                              disabled: false
                            })
                      }), React.createElement(Hidden.default, {
                        smDown: true,
                        children: React.createElement(AppMenu.Drawer.make, {
                              appMenu: appMenu,
                              appMenuDispatch: appMenuDispatch,
                              variant: "permanent",
                              disabled: false
                            })
                      }), React.createElement("main", undefined, React.createElement(Box.default, {
                            className: classes.content,
                            children: React.createElement(Box.default, {
                                  className: classes.subContent,
                                  children: tmp
                                })
                          }), match$5[0] ? React.createElement(AuthDialog.make, {
                              onClose: (function (param) {
                                  return Curry._1(toggleAuthDialog, /* Hide */803692770);
                                }),
                              onUserUpgraded: onUserUpgraded,
                              onSuccess: onSignInSuccess
                            }) : null, match$6[0] ? React.createElement(LogoutConfirmation.make, {
                              onClose: (function (param) {
                                  return Curry._1(setLogoutConfirmationVisible, (function (param) {
                                                return false;
                                              }));
                                }),
                              onOk: logout
                            }) : null), React.createElement(AppAlert.make, {
                        state: state,
                        onClose: clearAlert
                      }), showAnonymousAlert ? React.createElement(Alert.default, {
                          className: classes.bottomMsg,
                          severity: "info",
                          variant: "filled",
                          children: "Sign In for your changes to be permanently saved"
                        }) : null, React.createElement(Hidden.default, {
                        smUp: true,
                        children: React.createElement(Paper.default, {
                              className: classes.bottomMenu,
                              square: true,
                              children: React.createElement(AppMenu.Tab.make, {
                                    appMenu: appMenu,
                                    appMenuDispatch: appMenuDispatch,
                                    disabled: false
                                  })
                            })
                      }))
            });
}

var Mui = /* alias */0;

var U = /* alias */0;

var Pa = /* alias */0;

var drawerWidth = AppMenu.drawerWidth;

var make = App;

export {
  Mui ,
  U ,
  Pa ,
  concat ,
  drawerWidth ,
  appBarWidth ,
  makeStylesRaw ,
  useStyles ,
  successDuration ,
  make ,
  
}
/* appBarWidth Not a pure module */
