// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Box from "@material-ui/core/Box";
import * as Add from "@material-ui/icons/Add";
import * as Paper from "@material-ui/core/Paper";
import * as Slide from "@material-ui/core/Slide";
import * as AppBar from "@material-ui/core/AppBar";
import * as Button from "@material-ui/core/Button";
import * as Dialog from "@material-ui/core/Dialog";
import * as Hidden from "@material-ui/core/Hidden";
import * as Close from "@material-ui/icons/Close";
import * as Toolbar from "@material-ui/core/Toolbar";
import * as IconButton from "@material-ui/core/IconButton";
import * as Typography from "@material-ui/core/Typography";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      subContent: (function (props) {
          return {
                  margin: 10,
                  maxWidth: props.maxWidth
                };
        }),
      content: {
        marginTop: 80,
        marginRight: "auto",
        marginLeft: "auto",
        width: "95%"
      },
      appBar: {
        position: "fixed"
      },
      title: {
        marginLeft: 8,
        flex: 1
      }
    });

function ignoreOnClose(param) {
  return /* () */0;
}

function ignoreOnClick(param) {
  return /* () */0;
}

var defaultActionIcon = null;

function makeAdd(onAction, what) {
  var largeText = "Add ".concat(what);
  var icon = Caml_option.some(React.createElement(Add.default, { }));
  return {
          largeText: largeText,
          smallText: "Add",
          icon: icon,
          onAction: onAction
        };
}

function makeUpdate(onAction, what) {
  var largeText = "Update ".concat(what);
  var icon = Caml_option.some(null);
  return {
          largeText: largeText,
          smallText: "Update",
          icon: icon,
          onAction: onAction
        };
}

function makeTitle(small, large) {
  return {
          largeText: large,
          smallText: small
        };
}

function EntryDialog$ActionButton(Props) {
  var action = Props.action;
  var $staropt$star = Props.disabled;
  var disabled = $staropt$star !== undefined ? $staropt$star : false;
  var onAction = action.onAction;
  var largeText = action.largeText;
  var onClick = function (param) {
    return Curry._1(onAction, /* () */0);
  };
  return React.createElement(Button.default, {
              color: "secondary",
              startIcon: Belt_Option.getWithDefault(action.icon, null),
              variant: "contained",
              disabled: disabled,
              onClick: onClick,
              children: null
            }, React.createElement(Hidden.default, {
                  smDown: true,
                  children: largeText
                }), React.createElement(Hidden.default, {
                  mdUp: true,
                  children: Belt_Option.getWithDefault(action.smallText, largeText)
                }));
}

var ActionButton = {
  make: EntryDialog$ActionButton
};

function EntryDialog(Props) {
  var title = Props.title;
  var action = Props.action;
  var $staropt$star = Props.onClose;
  var $staropt$star$1 = Props.usePaperForContent;
  var $staropt$star$2 = Props.subContentWidth;
  var $staropt$star$3 = Props.disabled;
  var children = Props.children;
  var onClose = $staropt$star !== undefined ? $staropt$star : ignoreOnClose;
  var usePaperForContent = $staropt$star$1 !== undefined ? $staropt$star$1 : true;
  var subContentWidth = $staropt$star$2 !== undefined ? $staropt$star$2 : "720px";
  var disabled = $staropt$star$3 !== undefined ? $staropt$star$3 : false;
  var classes = useStyles({
        maxWidth: subContentWidth
      });
  var handleButtonClose = function (param) {
    return Curry._1(onClose, /* () */0);
  };
  var handleDialogClose = function (param, param$1) {
    return Curry._1(onClose, /* () */0);
  };
  var match;
  if (title !== undefined) {
    var match$1 = title;
    var largeText = match$1.largeText;
    match = /* tuple */[
      largeText,
      Belt_Option.getWithDefault(match$1.smallText, largeText)
    ];
  } else {
    match = /* tuple */[
      "",
      ""
    ];
  }
  return React.createElement(Dialog.default, {
              open: true,
              fullScreen: true,
              onClose: handleDialogClose,
              TransitionComponent: Slide.default,
              TransitionProps: {
                direction: "up",
                timeout: 400
              },
              children: null
            }, React.createElement(AppBar.default, {
                  className: classes.appBar,
                  children: React.createElement(Toolbar.default, {
                        children: null
                      }, React.createElement(IconButton.default, {
                            color: "inherit",
                            edge: "start",
                            onClick: handleButtonClose,
                            children: React.createElement(Close.default, { })
                          }), React.createElement(Typography.default, {
                            className: classes.title,
                            variant: "h6",
                            children: null
                          }, React.createElement(Hidden.default, {
                                smDown: true,
                                children: match[0]
                              }), React.createElement(Hidden.default, {
                                mdUp: true,
                                children: match[1]
                              })), action !== undefined ? React.createElement(EntryDialog$ActionButton, {
                              action: action,
                              disabled: disabled
                            }) : null)
                }), usePaperForContent ? React.createElement(Paper.default, {
                    className: classes.content,
                    children: React.createElement(Box.default, {
                          className: classes.subContent,
                          children: children
                        })
                  }) : React.createElement(Box.default, {
                    className: classes.content,
                    children: React.createElement(Box.default, {
                          className: classes.subContent,
                          children: children
                        })
                  }));
}

var Mui = /* alias */0;

var make = EntryDialog;

export {
  Mui ,
  useStyles ,
  ignoreOnClose ,
  ignoreOnClick ,
  defaultActionIcon ,
  makeAdd ,
  makeUpdate ,
  makeTitle ,
  ActionButton ,
  make ,
  
}
/* useStyles Not a pure module */
