// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "@material-ui/core/Button";

var signIn = "Sign In";

function ignoreClick(param) {
  return /* () */0;
}

function LoginButton(Props) {
  var user = Props.user;
  var $staropt$star = Props.onClick;
  var $staropt$star$1 = Props.isDataComplete;
  var onClick = $staropt$star !== undefined ? $staropt$star : ignoreClick;
  var isDataComplete = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  if (isDataComplete) {
    if (typeof user === "number") {
      if (user === 0) {
        return null;
      }
      
    } else if (!user[0].is_anonymous) {
      return null;
    }
    
  } else {
    return null;
  }
  return React.createElement(Button.default, {
              color: "secondary",
              variant: "contained",
              onClick: (function (param) {
                  return Curry._1(onClick, /* Login */203508041);
                }),
              children: signIn
            });
}

var Mui = /* alias */0;

var make = LoginButton;

export {
  Mui ,
  signIn ,
  ignoreClick ,
  make ,
  
}
/* react Not a pure module */
