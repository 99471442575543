// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Speed_unit_types from "./speed_unit_types.bs.js";
import * as Distance_unit_types from "./distance_unit_types.bs.js";
import * as Pressure_unit_types from "./pressure_unit_types.bs.js";
import * as Temperature_unit_types from "./temperature_unit_types.bs.js";

function default_user($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, $staropt$star$4, $staropt$star$5, $staropt$star$6, $staropt$star$7, $staropt$star$8, $staropt$star$9, param) {
  var id = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var display_name = $staropt$star$1 !== undefined ? $staropt$star$1 : "";
  var email = $staropt$star$2 !== undefined ? $staropt$star$2 : "";
  var provider_id = $staropt$star$3 !== undefined ? $staropt$star$3 : "";
  var distance_unit = $staropt$star$4 !== undefined ? $staropt$star$4 : Distance_unit_types.default_distance_unit(/* () */0);
  var speed_unit = $staropt$star$5 !== undefined ? $staropt$star$5 : Speed_unit_types.default_speed_unit(/* () */0);
  var pressure_unit = $staropt$star$6 !== undefined ? $staropt$star$6 : Pressure_unit_types.default_pressure_unit(/* () */0);
  var temperature_unit = $staropt$star$7 !== undefined ? $staropt$star$7 : Temperature_unit_types.default_temperature_unit(/* () */0);
  var is_anonymous = $staropt$star$8 !== undefined ? $staropt$star$8 : false;
  var unix_timestamp = $staropt$star$9 !== undefined ? $staropt$star$9 : 0;
  return {
          id: id,
          display_name: display_name,
          email: email,
          provider_id: provider_id,
          distance_unit: distance_unit,
          speed_unit: speed_unit,
          pressure_unit: pressure_unit,
          temperature_unit: temperature_unit,
          is_anonymous: is_anonymous,
          unix_timestamp: unix_timestamp
        };
}

export {
  default_user ,
  
}
/* No side effect */
