// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box from "@material-ui/core/Box";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      root: {
        marginRight: "auto",
        marginLeft: "auto",
        width: "95%"
      }
    });

function SectionWithMargin(Props) {
  var children = Props.children;
  var classes = useStyles();
  return React.createElement(Box.default, {
              className: classes.root,
              children: children
            });
}

var Mui = /* alias */0;

var make = SectionWithMargin;

export {
  Mui ,
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
