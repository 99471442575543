// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as System from "../util/System.bs.js";
import * as MenuHook from "./util/MenuHook.bs.js";
import * as Planning from "../util/Planning.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Frequency from "../util/Frequency.bs.js";
import * as UserState from "../util/UserState.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Maintenance from "../util/Maintenance.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as RowMenuButton from "./RowMenuButton.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as StringBodyTableCell from "./core/StringBodyTableCell.bs.js";
import * as StringHeadTableCell from "./core/StringHeadTableCell.bs.js";
import * as SimpleTableHeaderMenu from "./SimpleTableHeaderMenu.bs.js";
import * as Menu from "@material-ui/core/Menu";
import * as Hidden from "@material-ui/core/Hidden";
import * as Launch from "@material-ui/icons/Launch";
import * as MenuItem from "@material-ui/core/MenuItem";
import * as TableRow from "@material-ui/core/TableRow";
import * as PostAdd from "@material-ui/icons/PostAdd";
import * as TableCell from "@material-ui/core/TableCell";
import * as IconButton from "@material-ui/core/IconButton";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function makeStylesRaw (theme){return {
  rowMenu: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '70px'
    },
    textAlign: 'right',
  },
}};

var useStyles = MakeStyles.default(makeStylesRaw);

function ignoreOnClick(param) {
  return /* () */0;
}

function dataConfigToString(param) {
  switch (param) {
    case /* Frequency */0 :
        return "Frequency";
    case /* Next */1 :
        return "Next";
    case /* Latest */2 :
        return "Latest";
    
  }
}

var allDataConfigs = /* :: */[
  /* Next */1,
  /* :: */[
    /* Frequency */0,
    /* :: */[
      /* Latest */2,
      /* [] */0
    ]
  ]
];

function useTableConfig(param) {
  return React.useState((function () {
                return /* Next */1;
              }));
}

function MaintenanceItemRow$DataMenu(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(SimpleTableHeaderMenu.make, {
              config: tableConfig,
              values: allDataConfigs,
              toString: dataConfigToString,
              navType: /* Maintenance_item_header_data */20
            });
}

var DataMenu = {
  navType: /* Maintenance_item_header_data */20,
  make: MaintenanceItemRow$DataMenu
};

function MaintenanceItemRow$SingleDataFieldHead(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(React.Fragment, {
              children: React.createElement(StringHeadTableCell.make, {
                    value: dataConfigToString(tableConfig[0]),
                    colSpan: 2,
                    minWidth: 96,
                    children: React.createElement(MaintenanceItemRow$DataMenu, {
                          tableConfig: tableConfig
                        })
                  })
            });
}

var SingleDataFieldHead = {
  make: MaintenanceItemRow$SingleDataFieldHead
};

function MaintenanceItemRow$AllFieldHead(Props) {
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(StringHeadTableCell.make, {
                  value: "Frequency"
                }), React.createElement(StringHeadTableCell.make, {
                  value: "Next"
                }), React.createElement(StringHeadTableCell.make, {
                  value: "Latest"
                }));
}

var AllFieldHead = {
  make: MaintenanceItemRow$AllFieldHead
};

function MaintenanceItemRow$Head(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringHeadTableCell.make, {
                  value: "System"
                }), React.createElement(StringHeadTableCell.make, {
                  value: "Description"
                }), React.createElement(Hidden.default, {
                  xsDown: true,
                  children: null
                }, React.createElement(MaintenanceItemRow$AllFieldHead, { }), React.createElement(StringHeadTableCell.make, {
                      value: ""
                    })), React.createElement(Hidden.default, {
                  smUp: true,
                  children: React.createElement(MaintenanceItemRow$SingleDataFieldHead, {
                        tableConfig: tableConfig
                      })
                }));
}

var Head = {
  make: MaintenanceItemRow$Head
};

function key(param) {
  var id = param.id;
  if (id !== undefined) {
    return id.firestore_id;
  } else {
    return Maintenance.typeToString(param.type_).concat(":").concat(param.short_description);
  }
}

function next(distanceUnit, item, passagesStats) {
  return Planning.nextMaintenanceToString(distanceUnit, Planning.computeNextMaintenance(item, passagesStats));
}

function latest(item) {
  var match = Maintenance.latestLog(item);
  if (match !== undefined) {
    var duration = UnixTimestamp.now(/* () */0) - match.unix_timestamp;
    return Frequency.secondsToApproximatePeriodString(duration).concat(" ago");
  } else {
    return "";
  }
}

function frequency(distanceUnit, item) {
  return Belt_Option.mapWithDefault(item.frequency, "", (function (param) {
                return Frequency.stringOfFrequency(distanceUnit, param);
              }));
}

function MaintenanceItemRow$SingleFieldBody(Props) {
  var tableConfig = Props.tableConfig;
  var passagesStats = Props.passagesStats;
  var item = Props.item;
  var onClick = Props.onClick;
  var match = React.useContext(AppContext.ctx);
  var distanceUnit = UserState.mapWhenLoggedIn(match.user, (function (user) {
          return user.distance_unit;
        }));
  var value;
  switch (tableConfig[0]) {
    case /* Frequency */0 :
        value = frequency(distanceUnit, item);
        break;
    case /* Next */1 :
        value = next(distanceUnit, item, passagesStats);
        break;
    case /* Latest */2 :
        value = latest(item);
        break;
    
  }
  return React.createElement(StringBodyTableCell.make, {
              value: value,
              onClick: onClick
            });
}

var SingleFieldBody = {
  make: MaintenanceItemRow$SingleFieldBody
};

function MaintenanceItemRow$AllFieldBody(Props) {
  var item = Props.item;
  var passagesStats = Props.passagesStats;
  var onClick = Props.onClick;
  var match = React.useContext(AppContext.ctx);
  var distanceUnit = UserState.mapWhenLoggedIn(match.user, (function (user) {
          return user.distance_unit;
        }));
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(StringBodyTableCell.make, {
                  value: frequency(distanceUnit, item),
                  onClick: onClick
                }), React.createElement(StringBodyTableCell.make, {
                  value: next(distanceUnit, item, passagesStats),
                  onClick: onClick
                }), React.createElement(StringBodyTableCell.make, {
                  value: latest(item),
                  onClick: onClick
                }));
}

var AllFieldBody = {
  make: MaintenanceItemRow$AllFieldBody
};

function ignoreOnAction(param) {
  return /* () */0;
}

function MaintenanceItemRow$Body(Props) {
  var tableConfig = Props.tableConfig;
  var system = Props.system;
  var item = Props.item;
  var $staropt$star = Props.onAction;
  var $staropt$star$1 = Props.passagesStats;
  var $staropt$star$2 = Props.showLogsDisabled;
  var onAction = $staropt$star !== undefined ? $staropt$star : ignoreOnAction;
  var passagesStats = $staropt$star$1 !== undefined ? $staropt$star$1 : /* [] */0;
  var showLogsDisabled = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var classes = useStyles();
  var match = MenuHook.useMenu(/* () */0);
  var toggle = match[2];
  var handleOnAction = function (action, e) {
    if (action.tag === /* ShowInfo */4) {
      Analytics.nav(Events_types.default_navigation(/* Maintenance_item_row_more_menu_show_info */19, undefined, /* () */0));
    }
    Curry._2(toggle, /* Hide */803692770, e);
    return Curry._1(onAction, action);
  };
  var showLogs = function (param) {
    Analytics.nav(Events_types.default_navigation(/* Maintenance_item_row_expand */17, undefined, /* () */0));
    return Curry._1(onAction, /* ShowLogs */Block.__(3, [
                  system,
                  item
                ]));
  };
  var handleMoreMenuClicked = function (e) {
    Curry._2(toggle, /* Show */925630749, e);
    return Analytics.nav(Events_types.default_navigation(/* Maintenance_item_row_more_menu */18, undefined, /* () */0));
  };
  var handleTableCellClicked = function (param) {
    Analytics.nav(Events_types.default_navigation(/* Maintenance_item_row */16, undefined, /* () */0));
    return Curry._1(onAction, /* ShowLogs */Block.__(3, [
                  system,
                  item
                ]));
  };
  var partial_arg = /* AddLog */Block.__(2, [
      system,
      item
    ]);
  var partial_arg$1 = /* Edit */Block.__(0, [
      system,
      item
    ]);
  var partial_arg$2 = /* Delete */Block.__(1, [item]);
  var partial_arg$3 = /* AddLog */Block.__(2, [
      system,
      item
    ]);
  var partial_arg$4 = /* ShowInfo */Block.__(4, [
      system,
      item
    ]);
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringBodyTableCell.make, {
                  value: System.displayName(system),
                  onClick: handleTableCellClicked
                }), React.createElement(StringBodyTableCell.make, {
                  value: Maintenance.shortDescription(item),
                  onClick: handleTableCellClicked
                }), React.createElement(Hidden.default, {
                  xsDown: true,
                  children: React.createElement(MaintenanceItemRow$AllFieldBody, {
                        item: item,
                        passagesStats: passagesStats,
                        onClick: handleTableCellClicked
                      })
                }), React.createElement(Hidden.default, {
                  smUp: true,
                  children: React.createElement(MaintenanceItemRow$SingleFieldBody, {
                        tableConfig: tableConfig,
                        passagesStats: passagesStats,
                        item: item,
                        onClick: handleTableCellClicked
                      })
                }), React.createElement(TableCell.default, {
                  className: classes.rowMenu,
                  children: null
                }, React.createElement(IconButton.default, {
                      color: "secondary",
                      size: "small",
                      edge: "end",
                      disabled: showLogsDisabled,
                      onClick: showLogs,
                      children: React.createElement(Launch.default, {
                            fontSize: "small"
                          })
                    }), React.createElement(Hidden.default, {
                      xsDown: true,
                      children: null
                    }, "  ", React.createElement(IconButton.default, {
                          color: "secondary",
                          size: "small",
                          edge: "end",
                          onClick: (function (param) {
                              return handleOnAction(partial_arg, param);
                            }),
                          children: React.createElement(PostAdd.default, {
                                fontSize: "small"
                              })
                        })), React.createElement(RowMenuButton.make, {
                      onClick: handleMoreMenuClicked,
                      color: /* secondary */-499495052
                    }), React.createElement(Menu.default, {
                      open: match[1],
                      anchorEl: match[0],
                      keepMounted: true,
                      onClose: Curry._1(toggle, /* Hide */803692770),
                      children: null
                    }, React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg$1, param);
                            }),
                          children: "Edit"
                        }), React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg$2, param);
                            }),
                          children: "Delete"
                        }), React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg$3, param);
                            }),
                          children: "Add log"
                        }), React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg$4, param);
                            }),
                          children: "Show Info"
                        }))));
}

var Body = {
  ignoreOnAction: ignoreOnAction,
  defaultPassagesStats: /* [] */0,
  make: MaintenanceItemRow$Body
};

var Mui = /* alias */0;

var M = /* alias */0;

var S = /* alias */0;

var U = /* alias */0;

var E = /* alias */0;

var Pa = /* alias */0;

export {
  Mui ,
  M ,
  S ,
  U ,
  E ,
  Pa ,
  concat ,
  makeStylesRaw ,
  useStyles ,
  ignoreOnClick ,
  dataConfigToString ,
  allDataConfigs ,
  useTableConfig ,
  DataMenu ,
  SingleDataFieldHead ,
  AllFieldHead ,
  Head ,
  key ,
  next ,
  latest ,
  frequency ,
  SingleFieldBody ,
  AllFieldBody ,
  Body ,
  
}
/* useStyles Not a pure module */
