// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Selector from "./core/Selector.bs.js";
import * as NumberInput from "./core/NumberInput.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as FormLabel from "@material-ui/core/FormLabel";

function LogValueEntry$CheckValue(Props) {
  var $staropt$star = Props.value;
  var onChange = Props.onChange;
  var value = $staropt$star !== undefined ? $staropt$star : false;
  var toString = function (param) {
    if (param) {
      return "Yes";
    } else {
      return "No";
    }
  };
  var onChange$1 = function (v) {
    return Curry._1(onChange, /* Check_value */Block.__(0, [v]));
  };
  return React.createElement(Grid.default, {
              container: true,
              alignItems: "center",
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  xs: 4,
                  children: React.createElement(FormLabel.default, {
                        children: "Check OK?"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 8,
                  children: React.createElement(Selector.make, {
                        value: value,
                        values: /* :: */[
                          true,
                          /* :: */[
                            false,
                            /* [] */0
                          ]
                        ],
                        toString: toString,
                        onChange: onChange$1
                      })
                }));
}

var CheckValue = {
  make: LogValueEntry$CheckValue
};

function LogValueEntry$RecordValue(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var onChange$1 = function (f) {
    return Curry._1(onChange, /* Record_value */Block.__(1, [f]));
  };
  return React.createElement(Grid.default, {
              container: true,
              alignItems: "center",
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  xs: 4,
                  children: React.createElement(FormLabel.default, {
                        children: "Recorded value"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 8,
                  children: React.createElement(NumberInput.Float.make, {
                        value: value,
                        onChange: onChange$1
                      })
                }));
}

var RecordValue = {
  make: LogValueEntry$RecordValue
};

function LogValueEntry(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  if (value.tag) {
    return React.createElement(LogValueEntry$RecordValue, {
                value: value[0],
                onChange: onChange
              });
  } else {
    return React.createElement(LogValueEntry$CheckValue, {
                value: value[0],
                onChange: onChange
              });
  }
}

var Mui = /* alias */0;

var M = /* alias */0;

var defaultLogValue = /* Check_value */Block.__(0, [false]);

var make = LogValueEntry;

export {
  Mui ,
  M ,
  CheckValue ,
  RecordValue ,
  defaultLogValue ,
  make ,
  
}
/* react Not a pure module */
