// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as State from "../util/State.bs.js";
import * as React from "react";
import * as AppMenu from "./AppMenu.bs.js";
import * as BoatInfo from "./BoatInfo.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as BoatEntry from "./BoatEntry.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as BoatSelector from "./BoatSelector.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as SectionWithMargin from "./SectionWithMargin.bs.js";
import * as MaintenanceItemLogs from "./MaintenanceItemLogs.bs.js";
import * as NoDataInGridMessage from "./NoDataInGridMessage.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Fab from "@material-ui/core/Fab";
import * as Add from "@material-ui/icons/Add";
import * as MaintenanceItemListTab from "./MaintenanceItemListTab.bs.js";
import * as Paper from "@material-ui/core/Paper";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      boatSelectorPaper: {
        padding: "10px"
      },
      dataPaper: {
        padding: "0px",
        marginTop: "15px"
      },
      addButton: {
        width: "100%",
        textAlign: "center",
        marginBottom: 5,
        paddingTop: 15
      }
    });

var noBoatMsg = "Click 'Add Boat' to start configuring your boat";

function BoatSection(Props) {
  var state = Props.state;
  var stateDispatch = Props.stateDispatch;
  var classes = useStyles();
  AnalyticsEffect.useCurrentScreen(AppMenu.boatString);
  var match = React.useState((function () {
          return false;
        }));
  var setShowInfo = match[1];
  var match$1 = React.useState((function () {
          return ;
        }));
  var setShowBoatEntry = match$1[1];
  var showBoatEntry = match$1[0];
  var showAddBoatEntry = function (param) {
    Analytics.data(Events_types.default_data(/* Boat */1, /* Open_dialog_entry_add */1, undefined, undefined, undefined, /* () */0));
    return Curry._1(setShowBoatEntry, (function (param) {
                  return /* Add */0;
                }));
  };
  var hideBoatEntry = function (param) {
    return Curry._1(setShowBoatEntry, (function (param) {
                  return ;
                }));
  };
  var boat = State.getSelectedBoat(state);
  var match$2 = React.useContext(AppContext.ctx);
  var user = match$2.user;
  var onBack = function (param) {
    Curry._1(setShowInfo, (function (param) {
            return false;
          }));
    return Curry._1(stateDispatch, /* `SelectMaintenanceItem */[
                377983242,
                undefined
              ]);
  };
  var doShowInfo = function (param) {
    return Curry._1(setShowInfo, (function (param) {
                  return true;
                }));
  };
  var item = State.getSelectedItem(state);
  var isDataComplete = State.isComplete(state);
  var passagesStats = React.useMemo((function () {
          return State.getPassageStatsForBoat(state, boat);
        }), [boat]);
  var display;
  var exit = 0;
  if (typeof user === "number" && user !== 0) {
    display = /* LoginInfoMessage */188047408;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (boat !== undefined) {
      if (match[0]) {
        display = /* ShowBoatInfo */611024587;
      } else if (item !== undefined) {
        var match$3 = item;
        display = /* `ShowMaintenanceItemLogs */[
          -494527432,
          /* tuple */[
            match$3[0],
            match$3[1]
          ]
        ];
      } else {
        display = /* ShowMaintenanceItemList */-494823129;
      }
    } else {
      display = /* AddFirstBoat */1062232591;
    }
  }
  var tmp;
  var exit$1 = 0;
  if (typeof display === "number") {
    if (display !== 611024587) {
      exit$1 = 1;
    } else {
      tmp = null;
    }
  } else if (display[0] !== -494527432) {
    exit$1 = 1;
  } else {
    tmp = null;
  }
  if (exit$1 === 1) {
    tmp = React.createElement(Paper.default, {
          className: classes.boatSelectorPaper,
          children: React.createElement(BoatSelector.make, {
                state: state,
                stateDispatch: stateDispatch,
                onShow: doShowInfo,
                includeSettings: true
              })
        });
  }
  var tmp$1;
  if (typeof display === "number") {
    tmp$1 = display >= 611024587 ? (
        display >= 1062232591 ? React.createElement(React.Fragment, {
                children: null
              }, React.createElement(Box.default, {
                    className: classes.addButton,
                    children: React.createElement(Fab.default, {
                          color: "primary",
                          size: "medium",
                          variant: "extended",
                          onClick: showAddBoatEntry,
                          children: null
                        }, React.createElement(Add.default, { }), " Add Boat")
                  }), React.createElement(NoDataInGridMessage.make, {
                    msg: /* NoData */Block.__(0, [noBoatMsg])
                  })) : React.createElement(BoatInfo.make, {
                boat: boat,
                onBack: onBack
              })
      ) : (
        display >= 188047408 ? React.createElement(NoDataInGridMessage.make, {
                msg: /* NoData */Block.__(0, ["You must be logged in to manage your boat configuration"])
              }) : React.createElement(MaintenanceItemListTab.make, {
                boat: boat,
                passagesStats: passagesStats,
                isDataComplete: isDataComplete,
                stateDispatch: stateDispatch
              })
      );
  } else {
    var match$4 = display[1];
    tmp$1 = React.createElement(MaintenanceItemLogs.make, {
          systemIdentifier: match$4[0],
          item: match$4[1],
          onBack: onBack,
          isDataComplete: isDataComplete
        });
  }
  return React.createElement(SectionWithMargin.make, {
              children: null
            }, tmp, React.createElement(Paper.default, {
                  className: classes.dataPaper,
                  children: tmp$1
                }), showBoatEntry !== undefined ? React.createElement(BoatEntry.make, {
                    mode: showBoatEntry,
                    onClose: hideBoatEntry
                  }) : null);
}

var B = /* alias */0;

var Mui = /* alias */0;

var make = BoatSection;

export {
  B ,
  Mui ,
  useStyles ,
  noBoatMsg ,
  make ,
  
}
/* useStyles Not a pure module */
