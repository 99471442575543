// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function default_login($staropt$star, param) {
  var method_ = $staropt$star !== undefined ? $staropt$star : "";
  return {
          method_: method_
        };
}

function default_exception_($staropt$star, $staropt$star$1, param) {
  var description = $staropt$star !== undefined ? $staropt$star : "";
  var fatal = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  return {
          description: description,
          fatal: fatal
        };
}

function default_data_type(param) {
  return /* No_data_type */0;
}

function default_data_action(param) {
  return /* No_action */0;
}

function default_data_status(param) {
  return /* No_status */0;
}

function default_data_passage_log_type(param) {
  return /* No_passage_log_type */0;
}

function default_data($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, $staropt$star$4, param) {
  var type_ = $staropt$star !== undefined ? $staropt$star : /* No_data_type */0;
  var action = $staropt$star$1 !== undefined ? $staropt$star$1 : /* No_action */0;
  var status = $staropt$star$2 !== undefined ? $staropt$star$2 : /* No_status */0;
  var passage_log_type = $staropt$star$3 !== undefined ? $staropt$star$3 : /* No_passage_log_type */0;
  var firestore_path = $staropt$star$4 !== undefined ? $staropt$star$4 : "";
  return {
          type_: type_,
          action: action,
          status: status,
          passage_log_type: passage_log_type,
          firestore_path: firestore_path
        };
}

function default_navigation_type(param) {
  return /* No_navigation_type */0;
}

function default_navigation_action(param) {
  return /* Clicked */0;
}

function default_navigation($staropt$star, $staropt$star$1, param) {
  var type_ = $staropt$star !== undefined ? $staropt$star : /* No_navigation_type */0;
  var action = $staropt$star$1 !== undefined ? $staropt$star$1 : /* Clicked */0;
  return {
          type_: type_,
          action: action
        };
}

export {
  default_login ,
  default_exception_ ,
  default_data_type ,
  default_data_action ,
  default_data_status ,
  default_data_passage_log_type ,
  default_data ,
  default_navigation_type ,
  default_navigation_action ,
  default_navigation ,
  
}
/* No side effect */
