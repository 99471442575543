// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function useMenu(param) {
  var match = React.useState((function () {
          return null;
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var isOpen = Belt_Option.isSome(anchorEl === null ? undefined : Caml_option.some(anchorEl));
  var toggle = function (which, e) {
    if (which >= 925630749) {
      var target = e.currentTarget;
      return Curry._1(setAnchorEl, (function (param) {
                    return target;
                  }));
    } else {
      return Curry._1(setAnchorEl, (function (param) {
                    return null;
                  }));
    }
  };
  return /* tuple */[
          anchorEl,
          isOpen,
          toggle
        ];
}

export {
  useMenu ,
  
}
/* react Not a pure module */
