// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Boat_types from "../types/boat_types.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as NumberInput from "./core/NumberInput.bs.js";
import * as LengthUnitSelector from "./LengthUnitSelector.bs.js";
import * as Grid from "@material-ui/core/Grid";

var defaultLength = Boat_types.default_length(undefined, undefined, /* () */0);

function ignoreOnChange(param) {
  return /* () */0;
}

function LengthEntry(Props) {
  var $staropt$star = Props.length;
  var $staropt$star$1 = Props.onChange;
  var length = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnChange;
  var onLengthChange = function (l) {
    return Curry._1(onChange, (function (param) {
                  var v = l;
                  var length = param;
                  var length$1 = Belt_Option.getWithDefault(length, defaultLength);
                  return {
                          length: v,
                          unit_: length$1.unit_
                        };
                }));
  };
  var onUnitChange = function (u) {
    return Curry._1(onChange, (function (param) {
                  var u$1 = u;
                  var length = param;
                  var length$1 = Belt_Option.getWithDefault(length, defaultLength);
                  return {
                          length: length$1.length,
                          unit_: u$1
                        };
                }));
  };
  var match = Belt_Option.getWithDefault(length, defaultLength);
  return React.createElement(Grid.default, {
              container: true,
              justify: "space-between",
              alignItems: "center",
              spacing: 1,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  xs: 8,
                  children: React.createElement(NumberInput.Float.make, {
                        value: match.length,
                        onChange: onLengthChange
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 4,
                  children: React.createElement(LengthUnitSelector.make, {
                        value: match.unit_,
                        onChange: onUnitChange
                      })
                }));
}

var Mui = /* alias */0;

var B = /* alias */0;

var make = LengthEntry;

export {
  Mui ,
  B ,
  defaultLength ,
  ignoreOnChange ,
  make ,
  
}
/* defaultLength Not a pure module */
