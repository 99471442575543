// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Ignore from "../types/Ignore.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Button from "@material-ui/core/Button";
import * as Dialog from "@material-ui/core/Dialog";
import * as DialogTitle from "@material-ui/core/DialogTitle";
import * as DialogActions from "@material-ui/core/DialogActions";
import * as DialogContent from "@material-ui/core/DialogContent";

function appVersionString(param) {
  var version = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(process.env.REACT_APP_VERSION), "undefined");
  return "App Version: ".concat(version);
}

var infoString1 = "\nBoat Health website is currently in Beta version and developped by I (\n  Maxime Ransan) in my spare time. I am a weekend sailor who regularly goes \n  out in the water with my family but also spends significant amount of \n  time maintaining his boat. \n";

var infoString2 = "\nKeep in mind that Boat Health is free to use at the moment, and that while \n  I will do my best to improve the website I need to balance my time with \n  my family, sailing and dreaming of a new boat on yachtworld.\n";

var contactString = "\nIf you have any enquiry please contact Boat Health at contact@boat-health.net\n  and we will be happy to help you. \n";

function About(Props) {
  var $staropt$star = Props.open;
  var $staropt$star$1 = Props.onClose;
  var _open = $staropt$star !== undefined ? $staropt$star : false;
  var onClose = $staropt$star$1 !== undefined ? $staropt$star$1 : Ignore.v0;
  return React.createElement(Dialog.default, {
              open: _open,
              fullWidth: true,
              maxWidth: "xs",
              onClose: (function (param, param$1) {
                  return Curry._1(onClose, /* () */0);
                }),
              children: null
            }, React.createElement(DialogTitle.default, {
                  children: "About"
                }), React.createElement(DialogContent.default, {
                  children: null
                }, infoString1, React.createElement("br", undefined), React.createElement("br", undefined), infoString2, React.createElement("br", undefined), React.createElement("br", undefined), contactString, React.createElement("br", undefined), React.createElement("br", undefined), appVersionString(/* () */0)), React.createElement(DialogActions.default, {
                  children: React.createElement(Button.default, {
                        color: "primary",
                        variant: "outlined",
                        onClick: (function (param) {
                            return Curry._1(onClose, /* () */0);
                          }),
                        children: "close"
                      })
                }));
}

var Mui = /* alias */0;

var make = About;

export {
  Mui ,
  appVersionString ,
  infoString1 ,
  infoString2 ,
  contactString ,
  make ,
  
}
/* react Not a pure module */
