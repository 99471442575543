// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function default_distance_unit(param) {
  return /* Nautical_miles */0;
}

export {
  default_distance_unit ,
  
}
/* No side effect */
