// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as P from "../../util/P.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as App from "firebase/app";

require("firebase/firestore");
;

require("firebase/auth");
;

require("firebase/analytics");
;

var app = App.initializeApp({
      apiKey: "AIzaSyCZIUMFjL2-RL6eaV9BVJbhMAZJ_OUb38g",
      authDomain: "boat-health.firebaseapp.com",
      projectId: "boat-health",
      appId: "1:101637532430:web:6aafbe2921d467db1e855f",
      measurementId: "G-VBKQPHC6RP"
    });

function enablePersistence(firestore) {
  P.mapCatch((function (e) {
          console.log("Persistence enabled... Failed: ", e);
          return /* () */0;
        }), P.mapThen((function (param) {
              console.log("Persistence enabled... Ok");
              return /* () */0;
            }), firestore.enablePersistence({
                synchronizeTabs: true
              })));
  return /* () */0;
}

function resetFirestore(firestore, onUpdate) {
  P.mapCatch((function (param) {
          console.log("Error terminating previous firestore");
          var firestore = app.firestore();
          enablePersistence(firestore);
          return Curry._1(onUpdate, firestore);
        }), P.mapThen((function (param) {
              console.log("Previous firestore terminated with success");
              var firestore = app.firestore();
              enablePersistence(firestore);
              return Curry._1(onUpdate, firestore);
            }), firestore.terminate()));
  return /* () */0;
}

var firestore = app.firestore();

enablePersistence(firestore);

var auth = app.auth();

var analytics = app.analytics();

analytics.setUserProperties({
      app_version: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(process.env.REACT_APP_VERSION), "undefined")
    });

function addErrorAlert(param) {
  return /* () */0;
}

function addSuccessAlert(param) {
  return /* () */0;
}

var defaultCtx = {
  firestore: firestore,
  auth: auth,
  user: /* LoggingIn */0,
  setUser: undefined,
  addErrorAlert: addErrorAlert,
  addSuccessAlert: addSuccessAlert,
  analytics: analytics
};

var ctx = React.createContext(defaultCtx);

var make = ctx.Provider;

var t0 = Date.now();

var Pr = /* alias */0;

var user = /* LoggingIn */0;

var setUser = undefined;

export {
  Pr ,
  app ,
  enablePersistence ,
  resetFirestore ,
  firestore ,
  auth ,
  analytics ,
  user ,
  setUser ,
  addErrorAlert ,
  addSuccessAlert ,
  defaultCtx ,
  ctx ,
  make ,
  t0 ,
  
}
/*  Not a pure module */
