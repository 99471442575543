// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function v0(param) {
  return /* () */0;
}

function v1(param) {
  return /* () */0;
}

function v2(param, param$1) {
  return /* () */0;
}

export {
  v0 ,
  v1 ,
  v2 ,
  
}
/* No side effect */
