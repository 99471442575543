// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$Error from "./util/Error.bs.js";
import * as React from "react";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as System from "../util/System.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Collection from "../util/Collection.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as SearchInput from "./core/SearchInput.bs.js";
import * as Belt_MutableQueue from "bs-platform/lib/es6/belt_MutableQueue.js";
import * as Belt_HashMapString from "bs-platform/lib/es6/belt_HashMapString.js";

function identifierU(system) {
  return system.identifier;
}

function ignoreOnSelected(param) {
  return /* () */0;
}

var newGroup = "New";

var existingGroup = "Existing";

function computeOptions(systems) {
  var index = Belt_HashMapString.make(128);
  var options = Belt_MutableQueue.make(/* () */0);
  Collection.forEachValues(systems, (function (s) {
          var v = Curry._1(Printf.sprintf(/* Format */[
                    /* String_literal */Block.__(11, [
                        "* ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* End_of_format */0
                          ])
                      ]),
                    "* %s"
                  ]), System.displayName(s));
          Belt_HashMapString.set(index, v, /* Existing */Block.__(1, [s]));
          return Belt_MutableQueue.add(options, v);
        }));
  Belt_Array.forEachU(System.allCategoriesAsString, (function (c) {
          Belt_HashMapString.set(index, c, /* NewKnownCategory */Block.__(0, [c]));
          return Belt_MutableQueue.add(options, c);
        }));
  var options$1 = Belt_MutableQueue.toArray(options);
  var groupBy = function (s) {
    var match = Belt_HashMapString.get(index, s);
    if (match !== undefined) {
      switch (match.tag | 0) {
        case /* Existing */1 :
            return existingGroup;
        case /* NewKnownCategory */0 :
        case /* NewOtherCategory */2 :
            return newGroup;
        
      }
    } else {
      return newGroup;
    }
  };
  var getType = function (s) {
    var match = Belt_HashMapString.get(index, s);
    if (match !== undefined) {
      return match;
    } else {
      return /* NewOtherCategory */Block.__(2, [s]);
    }
  };
  return /* tuple */[
          options$1,
          groupBy,
          getType
        ];
}

function SystemSelector(Props) {
  var boat = Props.boat;
  var onChange = Props.onChange;
  var $staropt$star = Props.error;
  var $staropt$star$1 = Props.disabled;
  var error = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : $$Error.noError;
  var disabled = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var match = React.useMemo((function () {
          return computeOptions(boat.systems);
        }), [boat.systems]);
  var getType = match[2];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setValue = match$1[1];
  var onChange$1 = function (s) {
    Curry._1(setValue, (function (param) {
            return s;
          }));
    return Curry._1(onChange, Curry._1(getType, s));
  };
  return React.createElement(SearchInput.UnControlled.make, {
              value: match$1[0],
              options: match[0],
              onChange: onChange$1,
              label: "Part",
              error: error,
              disabled: disabled,
              allowArbitraryInput: true,
              groupBy: match[1]
            });
}

var S = /* alias */0;

var B = /* alias */0;

var defaultValue = undefined;

var make = SystemSelector;

export {
  S ,
  B ,
  identifierU ,
  defaultValue ,
  ignoreOnSelected ,
  newGroup ,
  existingGroup ,
  computeOptions ,
  make ,
  
}
/* react Not a pure module */
