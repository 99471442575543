// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Id from "../util/Id.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Ignore from "../types/Ignore.bs.js";
import * as Passage from "../util/Passage.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as EntryDialog from "./core/EntryDialog.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as Passage_types from "../types/passage_types.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as PassageCrewEntry from "./PassageCrewEntry.bs.js";
import * as PassageDataEntry from "./PassageDataEntry.bs.js";
import * as PassageTypeSelection from "./PassageTypeSelection.bs.js";
import * as AddPassageConfirmation from "./AddPassageConfirmation.bs.js";

function defaultPassage(param) {
  return Passage_types.default_passage(undefined, undefined, undefined, undefined, undefined, PassageCrewEntry.defaultCrew(/* () */0), undefined, /* :: */[
              /* Geo_location */13,
              /* :: */[
                /* Location_name */14,
                /* :: */[
                  /* Remarks */12,
                  /* [] */0
                ]
              ]
            ], undefined, undefined, /* () */0);
}

function passageOfMode(param) {
  if (param.tag) {
    return param[0];
  } else {
    return defaultPassage(/* () */0);
  }
}

function titleOfMode(param) {
  if (param.tag) {
    return "Edit passage";
  } else {
    return "Add new passage";
  }
}

function typeOfMode(param) {
  if (param.tag) {
    return Passage.passageType(param[0]);
  }
  
}

function screenOfMode(param) {
  if (param.tag) {
    return "Edit";
  } else {
    return "Add";
  }
}

function PassageEntry(Props) {
  var $staropt$star = Props.onClose;
  var mode = Props.mode;
  var onClose = $staropt$star !== undefined ? $staropt$star : Ignore.v1;
  AnalyticsEffect.useCurrentScreen("PassageEntry-".concat(screenOfMode(mode)));
  var match = React.useState((function () {
          return passageOfMode(mode);
        }));
  var setPassage = match[1];
  var match$1 = React.useState((function () {
          return typeOfMode(mode);
        }));
  var setType = match$1[1];
  var type_ = match$1[0];
  var match$2 = React.useState((function () {
          return /* Hidden */19559306;
        }));
  var setConfirmationPopup = match$2[1];
  var showDoneConfirmation = function (type_, passage) {
    return Curry._1(setConfirmationPopup, (function (param) {
                  return /* `Done */[
                          759635106,
                          /* tuple */[
                            type_,
                            passage
                          ]
                        ];
                }));
  };
  React.useEffect((function () {
          Curry._1(setPassage, (function (param) {
                  return passageOfMode(mode);
                }));
          Curry._1(setType, (function (param) {
                  return typeOfMode(mode);
                }));
          return ;
        }), /* tuple */[
        mode,
        setPassage
      ]);
  var local = PassageDataEntry.useLocal(/* () */0);
  var match$3 = React.useContext(AppContext.ctx);
  var addErrorAlert = match$3.addErrorAlert;
  var firestore = match$3.firestore;
  var updateType = function (type_) {
    if (!type_) {
      Curry._1(setPassage, (function (p) {
              var retroactive_data = Passage_types.default_retroactive_data(UnixTimestamp.now(/* () */0), UnixTimestamp.now(/* () */0), undefined, undefined, /* () */0);
              return {
                      id: p.id,
                      name: p.name,
                      from: p.from,
                      to_: p.to_,
                      notes: p.notes,
                      crew: p.crew,
                      logs: p.logs,
                      fields: p.fields,
                      retroactive_data: retroactive_data,
                      logs_stats: p.logs_stats
                    };
            }));
    }
    return Curry._1(setType, (function (param) {
                  return type_;
                }));
  };
  var addPassage = function (param) {
    return Curry._1(setPassage, (function (p) {
                  var isValid = PassageDataEntry.validate(local, p);
                  if (isValid) {
                    var logData = function (action, status, firestore_path) {
                      return Analytics.data(Events_types.default_data(/* Passage */5, action, status, undefined, firestore_path, /* () */0));
                    };
                    Curry._1(setConfirmationPopup, (function (param) {
                            return /* Saving */-674467366;
                          }));
                    if (mode.tag) {
                      Db.updatePassage(firestore, p, (function (param) {
                              if (typeof param === "number") {
                                logData(/* Updated */5, /* Success */1, Id.firestorePath(p.id));
                                return showDoneConfirmation(/* Updated */-350164453, p);
                              } else {
                                Analytics.except("DB Edit - Passage", param[1]);
                                logData(/* Updated */5, /* Failure */2, Id.firestorePath(p.id));
                                Curry._1(addErrorAlert, "Error updating passage details");
                                return Curry._1(onClose, /* NoFurtherAction */0);
                              }
                            }));
                    } else {
                      Db.addPassage(mode[0], firestore, p, (function (param) {
                              if (param[0] >= 106380200) {
                                Analytics.except("DB Add - Passage", param[1]);
                                logData(/* Added */4, /* Failure */2, "");
                                Curry._1(addErrorAlert, "Internal error saving passage. App will reset");
                                return Curry._1(onClose, /* NoFurtherAction */0);
                              } else {
                                var id = param[1];
                                logData(/* Added */4, /* Success */1, id.firestore_path);
                                return showDoneConfirmation(/* Added */795952288, {
                                            id: id,
                                            name: p.name,
                                            from: p.from,
                                            to_: p.to_,
                                            notes: p.notes,
                                            crew: p.crew,
                                            logs: p.logs,
                                            fields: p.fields,
                                            retroactive_data: p.retroactive_data,
                                            logs_stats: p.logs_stats
                                          });
                              }
                            }));
                    }
                    return p;
                  } else {
                    return p;
                  }
                }));
  };
  var action;
  action = mode.tag ? EntryDialog.makeUpdate(addPassage, "passage") : EntryDialog.makeAdd(addPassage, "passage");
  var title = type_ !== undefined ? EntryDialog.makeTitle(undefined, titleOfMode(mode)) : EntryDialog.makeTitle(undefined, "Select passage type");
  var closeWithNoFurtherAction = function (param) {
    return Curry._1(onClose, /* NoFurtherAction */0);
  };
  return React.createElement(React.Fragment, {
              children: null
            }, type_ !== undefined ? React.createElement(EntryDialog.make, {
                    title: title,
                    action: action,
                    onClose: closeWithNoFurtherAction,
                    children: React.createElement(PassageDataEntry.make, {
                          passage: match[0],
                          setPassage: setPassage,
                          local: local,
                          type_: type_
                        })
                  }) : React.createElement(EntryDialog.make, {
                    title: title,
                    onClose: closeWithNoFurtherAction,
                    usePaperForContent: false,
                    subContentWidth: "unset",
                    children: React.createElement(PassageTypeSelection.make, {
                          onSelected: updateType
                        })
                  }), React.createElement(AddPassageConfirmation.make, {
                  mode: match$2[0],
                  onClose: closeWithNoFurtherAction,
                  onStartNow: (function (p) {
                      return Curry._1(onClose, /* StartNow */[p.id]);
                    })
                }));
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var E = /* alias */0;

var I = /* alias */0;

var make = PassageEntry;

export {
  Mui ,
  Pa ,
  E ,
  I ,
  defaultPassage ,
  passageOfMode ,
  titleOfMode ,
  typeOfMode ,
  screenOfMode ,
  make ,
  
}
/* Db Not a pure module */
