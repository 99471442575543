// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Belt_Result from "bs-platform/lib/es6/belt_Result.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Geolocation from "../external/Geolocation.bs.js";
import * as NumberInput from "./core/NumberInput.bs.js";
import * as Passage_types from "../types/passage_types.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as Sync from "@material-ui/icons/Sync";
import * as Refresh from "@material-ui/icons/Refresh";
import * as IconButton from "@material-ui/core/IconButton";

function latitude(position) {
  return Belt_Option.mapWithDefaultU(position, 0.0, (function (p) {
                return p.latitude;
              }));
}

function longitude(position) {
  return Belt_Option.mapWithDefaultU(position, 0.0, (function (p) {
                return p.longitude;
              }));
}

function getCurrentGeoLocation(f) {
  return Geolocation.getCurrentGeoLocation((function (res) {
                return Curry._1(f, Belt_Result.mapU(res, (function (position) {
                                  var latitude = position.coords.latitude;
                                  var longitude = position.coords.longitude;
                                  return {
                                          longitude: longitude,
                                          latitude: latitude
                                        };
                                })));
              }));
}

function ignoreOnChange(param) {
  return /* () */0;
}

function GeoLocationEntry(Props) {
  var $staropt$star = Props.position;
  var $staropt$star$1 = Props.onChange;
  var position = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnChange;
  var match = React.useState((function () {
          return false;
        }));
  var setDisabled = match[1];
  var disabled = match[0];
  var updateLocation = function (param) {
    Curry._1(setDisabled, (function (param) {
            return true;
          }));
    return getCurrentGeoLocation((function (res) {
                  Curry._1(setDisabled, (function (param) {
                          return false;
                        }));
                  if (res.tag) {
                    console.log("Error retrieving loc: ", res[0]);
                    return /* () */0;
                  } else {
                    return Curry._1(onChange, res[0]);
                  }
                }));
  };
  React.useEffect((function () {
          if (position === undefined) {
            updateLocation(/* () */0);
          }
          return ;
        }), [position]);
  var refreshLocation = function (param) {
    return updateLocation(/* () */0);
  };
  var update = function (what, v) {
    var gp = Belt_Option.getWithDefault(position, Passage_types.default_geo_location(undefined, undefined, /* () */0));
    var gp$1 = what >= 1000251663 ? ({
          longitude: v,
          latitude: gp.latitude
        }) : ({
          longitude: gp.longitude,
          latitude: v
        });
    return Curry._1(onChange, gp$1);
  };
  return React.createElement(Grid.default, {
              container: true,
              alignItems: "center",
              spacing: 2,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  xs: 5,
                  children: React.createElement(NumberInput.Float.make, {
                        value: latitude(position),
                        onChange: (function (param) {
                            return update(/* Latitude */-535133300, param);
                          }),
                        label: "Latitude",
                        disabled: disabled,
                        digits: 6
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 5,
                  children: React.createElement(NumberInput.Float.make, {
                        value: longitude(position),
                        onChange: (function (param) {
                            return update(/* Longitude */1000251663, param);
                          }),
                        label: "Longitude",
                        disabled: disabled,
                        digits: 6
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 2,
                  children: React.createElement(IconButton.default, {
                        color: "secondary",
                        size: "small",
                        edge: "start",
                        disabled: disabled,
                        onClick: refreshLocation,
                        children: disabled ? React.createElement(Sync.default, { }) : React.createElement(Refresh.default, { })
                      })
                }));
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var defaultPosition = undefined;

var digits = 6;

var make = GeoLocationEntry;

export {
  Mui ,
  Pa ,
  latitude ,
  longitude ,
  getCurrentGeoLocation ,
  defaultPosition ,
  ignoreOnChange ,
  digits ,
  make ,
  
}
/* react Not a pure module */
