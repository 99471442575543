// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Notes from "./Notes.bs.js";
import * as React from "react";
import * as System from "../util/System.bs.js";
import * as Maintenance from "../util/Maintenance.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as Button from "@material-ui/core/Button";
import * as Dialog from "@material-ui/core/Dialog";
import * as DialogTitle from "@material-ui/core/DialogTitle";
import * as DialogActions from "@material-ui/core/DialogActions";
import * as DialogContent from "@material-ui/core/DialogContent";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function MaintenanceNoteDialog(Props) {
  var system = Props.system;
  var item = Props.item;
  var onClose = Props.onClose;
  AnalyticsEffect.useCurrentScreen("MaintenanceItemNotesDialog");
  var handleOnDialogClose = function (param, param$1) {
    return Curry._1(onClose, /* () */0);
  };
  var handleOnClose = function (param) {
    return Curry._1(onClose, /* () */0);
  };
  var description = Maintenance.shortDescription(item);
  var title = description.concat(" for ").concat(System.displayName(system));
  return React.createElement(Dialog.default, {
              open: true,
              maxWidth: "md",
              onClose: handleOnDialogClose,
              children: null
            }, React.createElement(DialogTitle.default, {
                  children: title
                }), React.createElement(DialogContent.default, {
                  children: React.createElement(Notes.make, {
                        value: item.notes,
                        disabled: true
                      })
                }), React.createElement(DialogActions.default, {
                  children: React.createElement(Button.default, {
                        color: "primary",
                        onClick: handleOnClose,
                        children: "close"
                      })
                }));
}

var Mui = /* alias */0;

var M = /* alias */0;

var S = /* alias */0;

var make = MaintenanceNoteDialog;

export {
  Mui ,
  M ,
  S ,
  concat ,
  make ,
  
}
/* Notes Not a pure module */
