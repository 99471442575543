// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as MenuHook from "./util/MenuHook.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Frequency from "../util/Frequency.bs.js";
import * as DistanceUnit from "../util/DistanceUnit.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as RowMenuButton from "./RowMenuButton.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as StringBodyTableCell from "./core/StringBodyTableCell.bs.js";
import * as StringHeadTableCell from "./core/StringHeadTableCell.bs.js";
import * as SimpleTableHeaderMenu from "./SimpleTableHeaderMenu.bs.js";
import * as Menu from "@material-ui/core/Menu";
import * as Hidden from "@material-ui/core/Hidden";
import * as Launch from "@material-ui/icons/Launch";
import * as MenuItem from "@material-ui/core/MenuItem";
import * as TableRow from "@material-ui/core/TableRow";
import * as TableCell from "@material-ui/core/TableCell";
import * as IconButton from "@material-ui/core/IconButton";
import * as InfoOutlined from "@material-ui/icons/InfoOutlined";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function dataConfigToString(param) {
  switch (param) {
    case /* From */0 :
        return "From";
    case /* To */1 :
        return "To";
    case /* End_date */2 :
        return "End Time";
    case /* Duration */3 :
        return "Duration";
    case /* Engine_hours */4 :
        return "Engine Hours";
    case /* Total_distance */5 :
        return "Distance (nm)";
    
  }
}

var allDataConfigs = /* :: */[
  /* From */0,
  /* :: */[
    /* To */1,
    /* :: */[
      /* End_date */2,
      /* :: */[
        /* Duration */3,
        /* :: */[
          /* Engine_hours */4,
          /* :: */[
            /* Total_distance */5,
            /* [] */0
          ]
        ]
      ]
    ]
  ]
];

function useTableConfig(param) {
  return React.useState((function () {
                return /* From */0;
              }));
}

function PassageRow$DataMenu(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(SimpleTableHeaderMenu.make, {
              config: tableConfig,
              values: allDataConfigs,
              toString: dataConfigToString,
              navType: /* Passage_header_data */33
            });
}

var DataMenu = {
  make: PassageRow$DataMenu
};

function PassageRow$CompactHead(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(React.Fragment, {
              children: React.createElement(StringHeadTableCell.make, {
                    value: dataConfigToString(tableConfig[0]),
                    colSpan: 2,
                    children: React.createElement(PassageRow$DataMenu, {
                          tableConfig: tableConfig
                        })
                  })
            });
}

var CompactHead = {
  make: PassageRow$CompactHead
};

function PassageRow$FullHead(Props) {
  return React.createElement(React.Fragment, {
              children: $$Array.of_list(Belt_List.mapU(allDataConfigs, (function (dataConfig) {
                          return React.createElement(StringHeadTableCell.make, {
                                      value: dataConfigToString(dataConfig),
                                      key: dataConfigToString(dataConfig)
                                    });
                        })))
            });
}

var FullHead = {
  make: PassageRow$FullHead
};

function PassageRow$Head(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringHeadTableCell.make, {
                  value: "Date"
                }), React.createElement(StringHeadTableCell.make, {
                  value: "Name"
                }), React.createElement(Hidden.default, {
                  xsDown: true,
                  children: null
                }, React.createElement(PassageRow$FullHead, { }), React.createElement(StringHeadTableCell.make, {
                      value: ""
                    })), React.createElement(Hidden.default, {
                  smUp: true,
                  children: React.createElement(PassageRow$CompactHead, {
                        tableConfig: tableConfig
                      })
                }));
}

var Head = {
  make: PassageRow$Head
};

function formatEndDate(param) {
  if (param !== undefined) {
    var match = param.end_unix_timestamp;
    if (match !== undefined) {
      return UnixTimestamp.formatToDate(match);
    } else {
      return "";
    }
  } else {
    return "";
  }
}

function formatDuration(param) {
  if (param !== undefined) {
    var match = param;
    var match$1 = match.start_unix_timestamp;
    if (match$1 !== undefined) {
      var match$2 = match.end_unix_timestamp;
      if (match$2 !== undefined) {
        return Frequency.secondsToApproximatePeriodString(match$2 - match$1);
      } else {
        return "";
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
}

function formatEngineHours(param) {
  if (param !== undefined) {
    var match = param;
    var v = match.total_engine_hours;
    if (match.latest_engine_on_unix_timestamp !== undefined) {
      return Curry._1(Printf.sprintf(/* Format */[
                      /* String_literal */Block.__(11, [
                          "> ",
                          /* Float */Block.__(8, [
                              /* Float_f */0,
                              /* No_padding */0,
                              /* Lit_precision */[1],
                              /* String_literal */Block.__(11, [
                                  " hrs",
                                  /* End_of_format */0
                                ])
                            ])
                        ]),
                      "> %.1f hrs"
                    ]), v);
    } else {
      return Curry._1(Printf.sprintf(/* Format */[
                      /* Float */Block.__(8, [
                          /* Float_f */0,
                          /* No_padding */0,
                          /* Lit_precision */[1],
                          /* String_literal */Block.__(11, [
                              " hrs",
                              /* End_of_format */0
                            ])
                        ]),
                      "%.1f hrs"
                    ]), v);
    }
  } else {
    return "";
  }
}

function formatDistance(param) {
  if (param !== undefined) {
    var total_distance = param.total_distance;
    if (total_distance > 0.0) {
      var d = DistanceUnit.convertTo(total_distance, /* Nautical_miles */0);
      var digits = DistanceUnit.digits(/* Nautical_miles */0);
      return d.toFixed(digits);
    } else {
      return "";
    }
  } else {
    return "";
  }
}

function dataConfigToElement(passage, stats, onClick, param) {
  switch (param) {
    case /* From */0 :
        return React.createElement(StringBodyTableCell.make, {
                    value: passage.from,
                    onClick: onClick,
                    key: "from"
                  });
    case /* To */1 :
        return React.createElement(StringBodyTableCell.make, {
                    value: passage.to_,
                    onClick: onClick,
                    key: "to"
                  });
    case /* End_date */2 :
        return React.createElement(StringBodyTableCell.make, {
                    value: formatEndDate(stats),
                    onClick: onClick,
                    key: "end_date"
                  });
    case /* Duration */3 :
        return React.createElement(StringBodyTableCell.make, {
                    value: formatDuration(stats),
                    onClick: onClick,
                    key: "duration"
                  });
    case /* Engine_hours */4 :
        return React.createElement(StringBodyTableCell.make, {
                    value: formatEngineHours(stats),
                    onClick: onClick,
                    key: "engine_hours"
                  });
    case /* Total_distance */5 :
        return React.createElement(StringBodyTableCell.make, {
                    value: formatDistance(stats),
                    onClick: onClick,
                    key: "total_distance"
                  });
    
  }
}

function PassageRow$CompactBody(Props) {
  var tableConfig = Props.tableConfig;
  var passage = Props.passage;
  var stats = Props.stats;
  var onClick = Props.onClick;
  return dataConfigToElement(passage, stats, onClick, tableConfig[0]);
}

var CompactBody = {
  make: PassageRow$CompactBody
};

function PassageRow$FullBody(Props) {
  var passage = Props.passage;
  var stats = Props.stats;
  var onClick = Props.onClick;
  return React.createElement(React.Fragment, {
              children: $$Array.of_list(Belt_List.mapU(allDataConfigs, (function (dataConfig) {
                          return dataConfigToElement(passage, stats, onClick, dataConfig);
                        })))
            });
}

var FullBody = {
  make: PassageRow$FullBody
};

var useStyles = MakeStyles.default({
      actionCell: {
        textAlign: "right"
      }
    });

function PassageRow$Body(Props) {
  var tableConfig = Props.tableConfig;
  var passage = Props.passage;
  var onAction = Props.onAction;
  var stats = Props.stats;
  var classes = useStyles();
  var match = MenuHook.useMenu(/* () */0);
  var toggle = match[2];
  var showLogs = function (param) {
    return Curry._1(onAction, /* ShowLogs */Block.__(2, [passage]));
  };
  var showInfo = function (param) {
    return Curry._1(onAction, /* ShowInfo */Block.__(3, [passage]));
  };
  var handleOnAction = function (action, e) {
    Curry._2(toggle, /* Hide */803692770, e);
    return Curry._1(onAction, action);
  };
  var formatDate = function (param) {
    if (param !== undefined) {
      var match = param.start_unix_timestamp;
      if (match !== undefined) {
        return UnixTimestamp.formatToDate(match);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  var handleCellClicked = function (param) {
    return Curry._1(onAction, /* Clicked */Block.__(4, [passage]));
  };
  var handleMoreMenuClicked = function (e) {
    Curry._2(toggle, /* Show */925630749, e);
    return Analytics.nav(Events_types.default_navigation(/* Passage_row_more_menu */37, undefined, /* () */0));
  };
  var partial_arg = /* Edit */Block.__(0, [passage]);
  var partial_arg$1 = /* Delete */Block.__(1, [passage]);
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringBodyTableCell.make, {
                  value: formatDate(stats),
                  onClick: handleCellClicked
                }), React.createElement(StringBodyTableCell.make, {
                  value: passage.name,
                  onClick: handleCellClicked
                }), React.createElement(Hidden.default, {
                  smUp: true,
                  children: React.createElement(PassageRow$CompactBody, {
                        tableConfig: tableConfig,
                        passage: passage,
                        stats: stats,
                        onClick: handleCellClicked
                      })
                }), React.createElement(Hidden.default, {
                  xsDown: true,
                  children: React.createElement(PassageRow$FullBody, {
                        passage: passage,
                        stats: stats,
                        onClick: handleCellClicked
                      })
                }), React.createElement(TableCell.default, {
                  className: classes.actionCell,
                  children: null
                }, React.createElement(IconButton.default, {
                      color: "secondary",
                      size: "small",
                      edge: "end",
                      onClick: showLogs,
                      children: React.createElement(Launch.default, {
                            fontSize: "small"
                          })
                    }), "  ", React.createElement(IconButton.default, {
                      color: "secondary",
                      size: "small",
                      edge: "end",
                      onClick: showInfo,
                      children: React.createElement(InfoOutlined.default, {
                            fontSize: "small"
                          })
                    }), React.createElement(RowMenuButton.make, {
                      onClick: handleMoreMenuClicked,
                      color: /* secondary */-499495052
                    }), React.createElement(Menu.default, {
                      open: match[1],
                      anchorEl: match[0],
                      keepMounted: true,
                      onClose: Curry._1(toggle, /* Hide */803692770),
                      children: null
                    }, React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg, param);
                            }),
                          children: "Edit"
                        }), React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg$1, param);
                            }),
                          children: "Delete"
                        }))));
}

var Body = {
  useStyles: useStyles,
  make: PassageRow$Body
};

var Mui = /* alias */0;

var Pa = /* alias */0;

var E = /* alias */0;

export {
  Mui ,
  Pa ,
  E ,
  dataConfigToString ,
  allDataConfigs ,
  useTableConfig ,
  DataMenu ,
  CompactHead ,
  FullHead ,
  Head ,
  formatEndDate ,
  formatDuration ,
  formatEngineHours ,
  formatDistance ,
  dataConfigToElement ,
  CompactBody ,
  FullBody ,
  Body ,
  
}
/* useStyles Not a pure module */
