// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";

function decode_cardinal_direction(json) {
  var match = Pbrt_bs.string(json, "cardinal_direction", "value");
  switch (match) {
    case "E" :
        return /* E */5;
    case "ENE" :
        return /* Ene */4;
    case "ESE" :
        return /* Ese */6;
    case "N" :
        return /* N */1;
    case "NE" :
        return /* Ne */3;
    case "NNE" :
        return /* Nne */2;
    case "NNW" :
        return /* Nnw */16;
    case "" :
    case "NONE" :
        return /* None */0;
    case "NW" :
        return /* Nw */15;
    case "S" :
        return /* S */9;
    case "SE" :
        return /* Se */7;
    case "SSE" :
        return /* Sse */8;
    case "SSW" :
        return /* Ssw */10;
    case "SW" :
        return /* Sw */11;
    case "W" :
        return /* W */13;
    case "WNW" :
        return /* Wnw */14;
    case "WSW" :
        return /* Wsw */12;
    default:
      return Pbrt_bs.E.malformed_variant("cardinal_direction");
  }
}

function encode_cardinal_direction(v) {
  switch (v) {
    case /* None */0 :
        return "NONE";
    case /* N */1 :
        return "N";
    case /* Nne */2 :
        return "NNE";
    case /* Ne */3 :
        return "NE";
    case /* Ene */4 :
        return "ENE";
    case /* E */5 :
        return "E";
    case /* Ese */6 :
        return "ESE";
    case /* Se */7 :
        return "SE";
    case /* Sse */8 :
        return "SSE";
    case /* S */9 :
        return "S";
    case /* Ssw */10 :
        return "SSW";
    case /* Sw */11 :
        return "SW";
    case /* Wsw */12 :
        return "WSW";
    case /* W */13 :
        return "W";
    case /* Wnw */14 :
        return "WNW";
    case /* Nw */15 :
        return "NW";
    case /* Nnw */16 :
        return "NNW";
    
  }
}

export {
  encode_cardinal_direction ,
  decode_cardinal_direction ,
  
}
/* Pbrt_bs Not a pure module */
