// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function default_cardinal_direction(param) {
  return /* None */0;
}

export {
  default_cardinal_direction ,
  
}
/* No side effect */
