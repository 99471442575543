// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Selector from "./core/Selector.bs.js";
import * as Frequency from "../util/Frequency.bs.js";

function ignoreOnChange(param) {
  return /* () */0;
}

function FrequencyTypeSelector(Props) {
  var value = Props.value;
  var $staropt$star = Props.onChange;
  var onChange = $staropt$star !== undefined ? $staropt$star : ignoreOnChange;
  var value$1 = Frequency.stringOfFrequencyType(value);
  var onChange$1 = function (s) {
    return Curry._1(onChange, Frequency.defaultFrequencyForString(s));
  };
  return React.createElement(Selector.make, {
              value: value$1,
              values: Frequency.allFrequencyType,
              toString: (function (prim) {
                  return prim;
                }),
              onChange: onChange$1
            });
}

var make = FrequencyTypeSelector;

export {
  ignoreOnChange ,
  make ,
  
}
/* react Not a pure module */
