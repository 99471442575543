// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function isError(error) {
  return error !== undefined;
}

function helperText(error) {
  if (error !== undefined) {
    return error;
  } else {
    return null;
  }
}

function make(msg) {
  return Caml_option.some(msg);
}

var noError = undefined;

export {
  isError ,
  helperText ,
  noError ,
  make ,
  
}
/* No side effect */
