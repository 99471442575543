// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";
import * as Frequency_types from "./frequency_types.bs.js";

function decode_period_unit(json) {
  var match = Pbrt_bs.string(json, "period_unit", "value");
  switch (match) {
    case "DAYS" :
        return /* Days */2;
    case "HOURS" :
        return /* Hours */1;
    case "" :
    case "MINUTES" :
        return /* Minutes */0;
    case "MONTHS" :
        return /* Months */4;
    case "WEEKS" :
        return /* Weeks */3;
    case "YEARS" :
        return /* Years */5;
    default:
      return Pbrt_bs.E.malformed_variant("period_unit");
  }
}

function decode_period(json) {
  var v = {
    period_unit: Frequency_types.default_period_unit(/* () */0),
    period_count: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "periodCount" :
          var json$1 = json["periodCount"];
          v.period_count = Pbrt_bs.$$int(json$1, "period", "period_count");
          break;
      case "periodUnit" :
          var json$2 = json["periodUnit"];
          v.period_unit = decode_period_unit(json$2);
          break;
      default:
        
    }
  }
  return {
          period_unit: v.period_unit,
          period_count: v.period_count
        };
}

function decode_engine_hours(json) {
  var v = 0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "value") {
      var json$1 = json["value"];
      v = Pbrt_bs.$$float(json$1, "engine_hours", "value");
    }
    
  }
  return {
          value: v
        };
}

function decode_distance(json) {
  var v = 0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "value") {
      var json$1 = json["value"];
      v = Pbrt_bs.$$float(json$1, "distance", "value");
    }
    
  }
  return {
          value: v
        };
}

function decode_before_date(json) {
  var v = 0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "unixTimestamp") {
      var json$1 = json["unixTimestamp"];
      v = Pbrt_bs.$$float(json$1, "before_date", "unix_timestamp");
    }
    
  }
  return {
          unix_timestamp: v
        };
}

function decode_frequency(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i !== -1) {
      var match = keys[i];
      switch (match) {
        case "adhoc" :
            return /* Adhoc */0;
        case "beforeDate" :
            var json$1 = json["beforeDate"];
            return /* Before_date */Block.__(3, [decode_before_date(Pbrt_bs.object_(json$1, "frequency", "Before_date"))]);
        case "distance" :
            var json$2 = json["distance"];
            return /* Distance */Block.__(2, [decode_distance(Pbrt_bs.object_(json$2, "frequency", "Distance"))]);
        case "engineHours" :
            var json$3 = json["engineHours"];
            return /* Engine_hours */Block.__(1, [decode_engine_hours(Pbrt_bs.object_(json$3, "frequency", "Engine_hours"))]);
        case "period" :
            var json$4 = json["period"];
            return /* Period */Block.__(0, [decode_period(Pbrt_bs.object_(json$4, "frequency", "Period"))]);
        default:
          _i = i - 1 | 0;
          continue ;
      }
    } else {
      return Pbrt_bs.E.malformed_variant("frequency");
    }
  };
}

function encode_period_unit(v) {
  switch (v) {
    case /* Minutes */0 :
        return "MINUTES";
    case /* Hours */1 :
        return "HOURS";
    case /* Days */2 :
        return "DAYS";
    case /* Weeks */3 :
        return "WEEKS";
    case /* Months */4 :
        return "MONTHS";
    case /* Years */5 :
        return "YEARS";
    
  }
}

function encode_period(v) {
  var json = { };
  json["periodUnit"] = encode_period_unit(v.period_unit);
  json["periodCount"] = String(v.period_count);
  return json;
}

function encode_engine_hours(v) {
  var json = { };
  json["value"] = v.value;
  return json;
}

function encode_distance(v) {
  var json = { };
  json["value"] = v.value;
  return json;
}

function encode_before_date(v) {
  var json = { };
  json["unixTimestamp"] = v.unix_timestamp;
  return json;
}

function encode_frequency(v) {
  var json = { };
  if (typeof v === "number") {
    json["adhoc"] = null;
  } else {
    switch (v.tag | 0) {
      case /* Period */0 :
          var json$prime = encode_period(v[0]);
          json["period"] = json$prime;
          break;
      case /* Engine_hours */1 :
          var json$prime$1 = encode_engine_hours(v[0]);
          json["engineHours"] = json$prime$1;
          break;
      case /* Distance */2 :
          var json$prime$2 = encode_distance(v[0]);
          json["distance"] = json$prime$2;
          break;
      case /* Before_date */3 :
          var json$prime$3 = encode_before_date(v[0]);
          json["beforeDate"] = json$prime$3;
          break;
      
    }
  }
  return json;
}

export {
  encode_period_unit ,
  encode_period ,
  encode_engine_hours ,
  encode_distance ,
  encode_before_date ,
  encode_frequency ,
  decode_period_unit ,
  decode_period ,
  decode_engine_hours ,
  decode_distance ,
  decode_before_date ,
  decode_frequency ,
  
}
/* Pbrt_bs Not a pure module */
