// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as TableCell from "@material-ui/core/TableCell";

function ignoreOnClick(param) {
  return /* () */0;
}

function StringBodyTableCell(Props) {
  var $staropt$star = Props.value;
  var $staropt$star$1 = Props.onClick;
  var value = $staropt$star !== undefined ? $staropt$star : "";
  var onClick = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnClick;
  return React.createElement(TableCell.default, {
              onClick: onClick,
              children: value
            });
}

var Mui = /* alias */0;

var make = StringBodyTableCell;

export {
  Mui ,
  ignoreOnClick ,
  make ,
  
}
/* react Not a pure module */
