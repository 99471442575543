// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Input from "./core/Input.bs.js";
import * as React from "react";
import * as Ignore from "../types/Ignore.bs.js";
import * as Selector from "./core/Selector.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Events_types from "../types/events_types.bs.js";
import * as Passage_types from "../types/passage_types.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";
import * as Delete from "@material-ui/icons/Delete";
import * as IconButton from "@material-ui/core/IconButton";
import * as AddCircleOutlineOutlined from "@material-ui/icons/AddCircleOutlineOutlined";

function toString(param) {
  switch (param) {
    case /* Captain */0 :
        return "Captain";
    case /* First_mate */1 :
        return "First mate";
    case /* Crew */2 :
        return "Crew";
    case /* Guest */3 :
        return "Guest";
    
  }
}

var allTypes = /* :: */[
  /* Captain */0,
  /* :: */[
    /* First_mate */1,
    /* :: */[
      /* Crew */2,
      /* :: */[
        /* Guest */3,
        /* [] */0
      ]
    ]
  ]
];

function PassageCrewEntry$SingleCrew(Props) {
  var member = Props.member;
  var actionType = Props.actionType;
  var $staropt$star = Props.disabledType;
  var $staropt$star$1 = Props.onChange;
  var $staropt$star$2 = Props.onAdd;
  var $staropt$star$3 = Props.onDelete;
  var $staropt$star$4 = Props.disabled;
  var disabledType = $staropt$star !== undefined ? $staropt$star : false;
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : Ignore.v1;
  var onAdd = $staropt$star$2 !== undefined ? $staropt$star$2 : Ignore.v0;
  var onDelete = $staropt$star$3 !== undefined ? $staropt$star$3 : Ignore.v0;
  var disabled = $staropt$star$4 !== undefined ? $staropt$star$4 : false;
  var updateType = function (type_) {
    return Curry._1(onChange, (function (pc) {
                  return {
                          id: pc.id,
                          type_: type_,
                          name: pc.name
                        };
                }));
  };
  var updateName = function (e) {
    var name = e.target.value;
    return Curry._1(onChange, (function (pc) {
                  return {
                          id: pc.id,
                          type_: pc.type_,
                          name: name
                        };
                }));
  };
  var add = function (param) {
    Analytics.nav(Events_types.default_navigation(/* Passage_data_entry_crew_add */28, undefined, /* () */0));
    return Curry._1(onAdd, /* () */0);
  };
  var $$delete = function (param) {
    Analytics.nav(Events_types.default_navigation(/* Passage_data_entry_crew_delete */29, undefined, /* () */0));
    return Curry._1(onDelete, /* () */0);
  };
  return React.createElement(Grid.default, {
              container: true,
              alignItems: "center",
              spacing: 2,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  sm: 1,
                  xs: 2,
                  children: actionType ? React.createElement(IconButton.default, {
                          size: "small",
                          disabled: disabled,
                          onClick: $$delete,
                          children: React.createElement(Delete.default, { })
                        }) : React.createElement(IconButton.default, {
                          size: "small",
                          disabled: disabled,
                          onClick: add,
                          children: React.createElement(AddCircleOutlineOutlined.default, { })
                        })
                }), React.createElement(Grid.default, {
                  item: true,
                  sm: 3,
                  xs: 5,
                  children: React.createElement(Selector.make, {
                        value: member.type_,
                        values: allTypes,
                        toString: toString,
                        onChange: updateType,
                        disabled: disabledType || disabled
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  sm: 8,
                  xs: 5,
                  children: React.createElement(Input.make, {
                        label: "Name",
                        value: member.name,
                        disabled: disabled,
                        onChange: updateName
                      })
                }));
}

var SingleCrew = {
  toString: toString,
  allTypes: allTypes,
  make: PassageCrewEntry$SingleCrew
};

function defaultCrew(param) {
  return /* :: */[
          Passage_types.default_passage_crew_member(UnixTimestamp.now(/* () */0) | 0, /* Captain */0, undefined, /* () */0),
          /* [] */0
        ];
}

function PassageCrewEntry(Props) {
  var crew = Props.crew;
  var $staropt$star = Props.onChange;
  var $staropt$star$1 = Props.disabled;
  var onChange = $staropt$star !== undefined ? $staropt$star : Ignore.v1;
  var disabled = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var onChange$prime = function (id, apply) {
    return Curry._1(onChange, (function (crew) {
                  return Belt_List.map(crew, (function (pc) {
                                if (pc.id === id) {
                                  return Curry._1(apply, pc);
                                } else {
                                  return pc;
                                }
                              }));
                }));
  };
  var onAdd = function (param) {
    return Curry._1(onChange, (function (crew) {
                  var newCrew = Passage_types.default_passage_crew_member(UnixTimestamp.now(/* () */0) | 0, /* Crew */2, undefined, /* () */0);
                  return Belt_List.concat(crew, /* :: */[
                              newCrew,
                              /* [] */0
                            ]);
                }));
  };
  var onDelete = function (id, param) {
    return Curry._1(onChange, (function (crew) {
                  return Belt_List.keep(crew, (function (pc) {
                                return pc.id !== id;
                              }));
                }));
  };
  var aux = function (isFirst, i, param) {
    if (param) {
      var tl = param[1];
      var hd = param[0];
      if (tl) {
        var partial_arg = hd.id;
        var partial_arg$1 = hd.id;
        return /* :: */[
                React.createElement(PassageCrewEntry$SingleCrew, {
                      member: hd,
                      actionType: isFirst ? /* ActionAdd */0 : /* ActionDelete */1,
                      disabledType: isFirst,
                      onChange: (function (param) {
                          return onChange$prime(partial_arg, param);
                        }),
                      onAdd: onAdd,
                      onDelete: (function (param) {
                          return onDelete(partial_arg$1, param);
                        }),
                      disabled: disabled,
                      key: hd.id.toString()
                    }),
                aux(false, i + 1 | 0, tl)
              ];
      } else {
        var partial_arg$2 = hd.id;
        var partial_arg$3 = hd.id;
        return /* :: */[
                React.createElement(PassageCrewEntry$SingleCrew, {
                      member: hd,
                      actionType: isFirst ? /* ActionAdd */0 : /* ActionDelete */1,
                      disabledType: isFirst,
                      onChange: (function (param) {
                          return onChange$prime(partial_arg$2, param);
                        }),
                      onAdd: onAdd,
                      onDelete: (function (param) {
                          return onDelete(partial_arg$3, param);
                        }),
                      disabled: disabled,
                      key: hd.id.toString()
                    }),
                /* [] */0
              ];
      }
    } else {
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "PassageCrewEntry.re",
              129,
              12
            ]
          ];
    }
  };
  return React.createElement(React.Fragment, {
              children: Belt_List.toArray(aux(true, 0, crew))
            });
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var E = /* alias */0;

var make = PassageCrewEntry;

export {
  Mui ,
  Pa ,
  E ,
  SingleCrew ,
  defaultCrew ,
  make ,
  
}
/* Input Not a pure module */
