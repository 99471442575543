// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as MenuHook from "./util/MenuHook.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Frequency from "../util/Frequency.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Events_types from "../types/events_types.bs.js";
import * as RowMenuButton from "./RowMenuButton.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as StringBodyTableCell from "./core/StringBodyTableCell.bs.js";
import * as StringHeadTableCell from "./core/StringHeadTableCell.bs.js";
import * as SimpleTableHeaderMenu from "./SimpleTableHeaderMenu.bs.js";
import * as Menu from "@material-ui/core/Menu";
import * as Hidden from "@material-ui/core/Hidden";
import * as MenuItem from "@material-ui/core/MenuItem";
import * as TableRow from "@material-ui/core/TableRow";
import * as TableCell from "@material-ui/core/TableCell";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function dataConfigToString(param) {
  switch (param) {
    case /* Value */0 :
        return "Value";
    case /* TimeTaken */1 :
        return "Time taken";
    case /* Cost */2 :
        return "Cost";
    
  }
}

var allDataConfigsWithValue = /* :: */[
  /* Value */0,
  /* :: */[
    /* TimeTaken */1,
    /* :: */[
      /* Cost */2,
      /* [] */0
    ]
  ]
];

var allDataConfigsWithoutValue = /* :: */[
  /* TimeTaken */1,
  /* :: */[
    /* Cost */2,
    /* [] */0
  ]
];

function timeConfigToString(param) {
  if (param) {
    return "Since";
  } else {
    return "Date (yyyy/mm/dd hh:mm)";
  }
}

var allTimeConfig = /* :: */[
  /* Absolute */0,
  /* :: */[
    /* SinceNow */1,
    /* [] */0
  ]
];

function useTableConfig(param) {
  return /* tuple */[
          React.useState((function () {
                  return /* TimeTaken */1;
                })),
          React.useState((function () {
                  return /* Absolute */0;
                }))
        ];
}

function MaintenanceItemLogRow$DataMenuWithValue(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(SimpleTableHeaderMenu.make, {
              config: tableConfig[0],
              values: allDataConfigsWithValue,
              toString: dataConfigToString,
              navType: /* Maintenance_item_log_header_data */21
            });
}

var DataMenuWithValue = {
  make: MaintenanceItemLogRow$DataMenuWithValue
};

function MaintenanceItemLogRow$DataMenuWithoutValue(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(SimpleTableHeaderMenu.make, {
              config: tableConfig[0],
              values: allDataConfigsWithoutValue,
              toString: dataConfigToString,
              navType: /* Maintenance_item_log_header_data */21
            });
}

var DataMenuWithoutValue = {
  make: MaintenanceItemLogRow$DataMenuWithoutValue
};

function MaintenanceItemLogRow$TimeMenu(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(SimpleTableHeaderMenu.make, {
              config: tableConfig[1],
              values: allTimeConfig,
              toString: timeConfigToString,
              navType: /* Maintenance_item_log_header_data */21
            });
}

var TimeMenu = {
  make: MaintenanceItemLogRow$TimeMenu
};

function MaintenanceItemLogRow$SingleDataFieldHead(Props) {
  var tableConfig = Props.tableConfig;
  var includeValue = Props.includeValue;
  var includeDescription = Props.includeDescription;
  var dataConfig = tableConfig[0][0];
  var minWidth = dataConfig !== 1 ? 0 : 98;
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringHeadTableCell.make, {
                  value: "Date",
                  minWidth: 54,
                  children: React.createElement(MaintenanceItemLogRow$TimeMenu, {
                        tableConfig: tableConfig
                      })
                }), includeDescription ? React.createElement(StringHeadTableCell.make, {
                    value: "Description"
                  }) : null, React.createElement(StringHeadTableCell.make, {
                  value: dataConfigToString(dataConfig),
                  colSpan: 2,
                  minWidth: minWidth,
                  children: includeValue ? React.createElement(MaintenanceItemLogRow$DataMenuWithValue, {
                          tableConfig: tableConfig
                        }) : React.createElement(MaintenanceItemLogRow$DataMenuWithoutValue, {
                          tableConfig: tableConfig
                        })
                }));
}

var SingleDataFieldHead = {
  make: MaintenanceItemLogRow$SingleDataFieldHead
};

function MaintenanceItemLogRow$AllFieldHead(Props) {
  var tableConfig = Props.tableConfig;
  var includeValue = Props.includeValue;
  var includeDescription = Props.includeDescription;
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringHeadTableCell.make, {
                  value: "Date",
                  children: React.createElement(MaintenanceItemLogRow$TimeMenu, {
                        tableConfig: tableConfig
                      })
                }), includeDescription ? React.createElement(StringHeadTableCell.make, {
                    value: "Description"
                  }) : null, includeValue ? React.createElement(StringHeadTableCell.make, {
                    value: "Value"
                  }) : null, React.createElement(StringHeadTableCell.make, {
                  value: "Time Taken"
                }), React.createElement(StringHeadTableCell.make, {
                  value: "Cost"
                }), React.createElement(StringHeadTableCell.make, {
                  value: ""
                }));
}

var AllFieldHead = {
  make: MaintenanceItemLogRow$AllFieldHead
};

function includeValue(param) {
  if (param !== 1) {
    return param === 3;
  } else {
    return true;
  }
}

function MaintenanceItemLogRow$Head(Props) {
  var tableConfig = Props.tableConfig;
  var maintenanceItemType = Props.maintenanceItemType;
  var $staropt$star = Props.includeDescription;
  var includeDescription = $staropt$star !== undefined ? $staropt$star : false;
  var includeValue$1 = includeValue(maintenanceItemType);
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Hidden.default, {
                  smUp: true,
                  children: React.createElement(MaintenanceItemLogRow$SingleDataFieldHead, {
                        tableConfig: tableConfig,
                        includeValue: includeValue$1,
                        includeDescription: includeDescription
                      })
                }), React.createElement(Hidden.default, {
                  xsDown: true,
                  children: React.createElement(MaintenanceItemLogRow$AllFieldHead, {
                        tableConfig: tableConfig,
                        includeValue: includeValue$1,
                        includeDescription: includeDescription
                      })
                }));
}

var Head = {
  make: MaintenanceItemLogRow$Head
};

function formatAbsolute(param) {
  var d = UnixTimestamp.toDate(param.unix_timestamp);
  return Curry._3(Printf.sprintf(/* Format */[
                  /* Float */Block.__(8, [
                      /* Float_f */0,
                      /* Lit_padding */Block.__(0, [
                          /* Right */1,
                          4
                        ]),
                      /* Lit_precision */[0],
                      /* Char_literal */Block.__(12, [
                          /* "/" */47,
                          /* Float */Block.__(8, [
                              /* Float_f */0,
                              /* Lit_padding */Block.__(0, [
                                  /* Zeros */2,
                                  2
                                ]),
                              /* Lit_precision */[0],
                              /* Char_literal */Block.__(12, [
                                  /* "/" */47,
                                  /* Float */Block.__(8, [
                                      /* Float_f */0,
                                      /* Lit_padding */Block.__(0, [
                                          /* Zeros */2,
                                          2
                                        ]),
                                      /* Lit_precision */[0],
                                      /* End_of_format */0
                                    ])
                                ])
                            ])
                        ])
                    ]),
                  "%4.f/%02.f/%02.f"
                ]), d.getFullYear(), d.getMonth() + 1.0, d.getDate());
}

function formatSinceNow(param) {
  var diff = UnixTimestamp.now(/* () */0) - param.unix_timestamp;
  return Frequency.secondsToApproximatePeriodString(diff).concat(" ago");
}

function formatDate(log, param) {
  if (param) {
    return formatSinceNow(log);
  } else {
    return formatAbsolute(log);
  }
}

function costAsString(param) {
  return param.cost.toString();
}

function timeTakenAsString(param) {
  return Belt_Option.mapWithDefault(param.time_taken, "", (function (t) {
                return String(t.period_count).concat(" ").concat(Frequency.stringOfPeriodUnit(t.period_unit));
              }));
}

function valueAsString(param) {
  var value = param.value;
  if (value !== undefined) {
    var match = value;
    if (match.tag) {
      return match[0].toString();
    } else if (match[0]) {
      return "Ok";
    } else {
      return "Failed";
    }
  } else {
    return "";
  }
}

function MaintenanceItemLogRow$SingleFieldBody(Props) {
  var tableConfig = Props.tableConfig;
  var log = Props.log;
  switch (tableConfig[0][0]) {
    case /* Value */0 :
        return React.createElement(StringBodyTableCell.make, {
                    value: valueAsString(log)
                  });
    case /* TimeTaken */1 :
        return React.createElement(StringBodyTableCell.make, {
                    value: timeTakenAsString(log)
                  });
    case /* Cost */2 :
        return React.createElement(StringBodyTableCell.make, {
                    value: costAsString(log)
                  });
    
  }
}

var SingleFieldBody = {
  make: MaintenanceItemLogRow$SingleFieldBody
};

function MaintenanceItemLogRow$AllFieldBody(Props) {
  var log = Props.log;
  var includeValue = Props.includeValue;
  return React.createElement(React.Fragment, {
              children: null
            }, includeValue ? React.createElement(StringBodyTableCell.make, {
                    value: valueAsString(log)
                  }) : null, React.createElement(StringBodyTableCell.make, {
                  value: timeTakenAsString(log)
                }), React.createElement(StringBodyTableCell.make, {
                  value: costAsString(log)
                }));
}

var AllFieldBody = {
  make: MaintenanceItemLogRow$AllFieldBody
};

function ignoreOnAction(param) {
  return /* () */0;
}

var useStyles = MakeStyles.default({
      actionCell: {
        textAlign: "right"
      }
    });

function MaintenanceItemLogRow$Body(Props) {
  var tableConfig = Props.tableConfig;
  var log = Props.log;
  var $staropt$star = Props.onAction;
  var maintenanceItemType = Props.maintenanceItemType;
  var description = Props.description;
  var onAction = $staropt$star !== undefined ? $staropt$star : ignoreOnAction;
  var classes = useStyles();
  var match = MenuHook.useMenu(/* () */0);
  var toggle = match[2];
  var handleOnAction = function (action, e) {
    Curry._2(toggle, /* Hide */803692770, e);
    return Curry._1(onAction, action);
  };
  var includeValue$1 = includeValue(maintenanceItemType);
  var handleMoreMenuClicked = function (e) {
    Curry._2(toggle, /* Show */925630749, e);
    return Analytics.nav(Events_types.default_navigation(/* Maintenance_item_log_more_menu */23, undefined, /* () */0));
  };
  var partial_arg = /* Edit */Block.__(0, [log]);
  var partial_arg$1 = /* Delete */Block.__(1, [log]);
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringBodyTableCell.make, {
                  value: formatDate(log, tableConfig[1][0])
                }), description !== undefined ? React.createElement(StringBodyTableCell.make, {
                    value: description
                  }) : null, React.createElement(Hidden.default, {
                  smUp: true,
                  children: React.createElement(MaintenanceItemLogRow$SingleFieldBody, {
                        tableConfig: tableConfig,
                        log: log
                      })
                }), React.createElement(Hidden.default, {
                  xsDown: true,
                  children: React.createElement(MaintenanceItemLogRow$AllFieldBody, {
                        log: log,
                        includeValue: includeValue$1
                      })
                }), React.createElement(TableCell.default, {
                  className: classes.actionCell,
                  children: null
                }, React.createElement(RowMenuButton.make, {
                      onClick: handleMoreMenuClicked,
                      color: /* secondary */-499495052
                    }), React.createElement(Menu.default, {
                      open: match[1],
                      anchorEl: match[0],
                      keepMounted: true,
                      onClose: Curry._1(toggle, /* Hide */803692770),
                      children: null
                    }, React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg, param);
                            }),
                          children: "Edit"
                        }), React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg$1, param);
                            }),
                          children: "Delete"
                        }))));
}

var Body = {
  useStyles: useStyles,
  make: MaintenanceItemLogRow$Body
};

var Mui = /* alias */0;

var M = /* alias */0;

var F = /* alias */0;

var E = /* alias */0;

export {
  Mui ,
  M ,
  F ,
  E ,
  concat ,
  dataConfigToString ,
  allDataConfigsWithValue ,
  allDataConfigsWithoutValue ,
  timeConfigToString ,
  allTimeConfig ,
  useTableConfig ,
  DataMenuWithValue ,
  DataMenuWithoutValue ,
  TimeMenu ,
  SingleDataFieldHead ,
  AllFieldHead ,
  includeValue ,
  Head ,
  formatAbsolute ,
  formatSinceNow ,
  formatDate ,
  costAsString ,
  timeTakenAsString ,
  valueAsString ,
  SingleFieldBody ,
  AllFieldBody ,
  ignoreOnAction ,
  Body ,
  
}
/* useStyles Not a pure module */
