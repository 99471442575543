// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Id from "../util/Id.bs.js";
import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Analytics from "./util/Analytics.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Collection from "../util/Collection.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Maintenance from "../util/Maintenance.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as DeleteConfirmation from "./DeleteConfirmation.bs.js";
import * as MaintenanceItemRow from "./MaintenanceItemRow.bs.js";
import * as MaintenanceLogEntry from "./MaintenanceLogEntry.bs.js";
import * as NoDataInGridMessage from "./NoDataInGridMessage.bs.js";
import * as MaintenanceItemEntry from "./MaintenanceItemEntry.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Fab from "@material-ui/core/Fab";
import * as MaintenanceNoteDialog from "./MaintenanceNoteDialog.bs.js";
import * as Add from "@material-ui/icons/Add";
import * as Table from "@material-ui/core/Table";
import * as TableBody from "@material-ui/core/TableBody";
import * as TableHead from "@material-ui/core/TableHead";
import * as TableContainer from "@material-ui/core/TableContainer";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      grid: {
        paddingTop: "15px"
      },
      addButton: {
        width: "100%",
        textAlign: "center",
        marginBottom: 10
      }
    });

function rowOfMaintenanceItem(onAction, system, tableConfig, passagesStats, isDataComplete, item) {
  return React.createElement(MaintenanceItemRow.Body.make, {
              tableConfig: tableConfig,
              system: system,
              item: item,
              onAction: onAction,
              passagesStats: passagesStats,
              showLogsDisabled: !isDataComplete,
              key: MaintenanceItemRow.key(item)
            });
}

function rowsOfSystem(onAction, tableConfig, passagesStats, isDataComplete, system) {
  return List.map((function (param) {
                return rowOfMaintenanceItem(onAction, system, tableConfig, passagesStats, isDataComplete, param);
              }), system.maintenance_items);
}

var noDataMsg = /* NoData */Block.__(0, ["Click `Add` above for new maintenance item"]);

function MaintenanceItemListTab(Props) {
  var $staropt$star = Props.boat;
  var $staropt$star$1 = Props.passagesStats;
  var $staropt$star$2 = Props.isDataComplete;
  var stateDispatch = Props.stateDispatch;
  var boat = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var passagesStats = $staropt$star$1 !== undefined ? $staropt$star$1 : /* [] */0;
  var isDataComplete = $staropt$star$2 !== undefined ? $staropt$star$2 : true;
  var classes = useStyles();
  var match = React.useState((function () {
          return /* NoDialog */0;
        }));
  var setDialog = match[1];
  var dialog = match[0];
  var hideDialog = function (param) {
    return Curry._1(setDialog, (function (param) {
                  return /* NoDialog */0;
                }));
  };
  var showAddDialog = function (param) {
    Analytics.data(Events_types.default_data(/* Maintenance_item */3, /* Open_dialog_entry_add */1, undefined, undefined, undefined, /* () */0));
    return Curry._1(setDialog, (function (param) {
                  return /* Add */1;
                }));
  };
  var showAddLogDialog = function (system, item) {
    Analytics.data(Events_types.default_data(/* Maintenance_item_log */4, /* Open_dialog_entry_add */1, undefined, undefined, undefined, /* () */0));
    return Curry._1(setDialog, (function (param) {
                  return /* AddLog */Block.__(3, [
                            system,
                            item
                          ]);
                }));
  };
  var onCloseMaintenanceEntry = function (param) {
    if (param) {
      return showAddLogDialog(param[0], param[1]);
    } else {
      return Curry._1(setDialog, (function (param) {
                    return /* NoDialog */0;
                  }));
    }
  };
  var match$1 = React.useContext(AppContext.ctx);
  var firestore = match$1.firestore;
  var handleOnMenuAction = function (param) {
    switch (param.tag | 0) {
      case /* Edit */0 :
          var system = param[0];
          var item = param[1];
          Analytics.data(Events_types.default_data(/* Maintenance_item */3, /* Open_dialog_entry_update */2, undefined, undefined, Id.firestorePath(item.id), /* () */0));
          return Curry._1(setDialog, (function (param) {
                        return /* Edit */Block.__(0, [
                                  system,
                                  item
                                ]);
                      }));
      case /* Delete */1 :
          var item$1 = param[0];
          Analytics.data(Events_types.default_data(/* Maintenance_item */3, /* Open_dialog_entry_delete */3, undefined, undefined, Id.firestorePath(item$1.id), /* () */0));
          return Curry._1(setDialog, (function (param) {
                        return /* Delete */Block.__(1, [item$1]);
                      }));
      case /* AddLog */2 :
          return showAddLogDialog(param[0], param[1]);
      case /* ShowLogs */3 :
          return Curry._1(stateDispatch, /* `SelectMaintenanceItem */[
                      377983242,
                      /* tuple */[
                        param[0].id,
                        param[1].id
                      ]
                    ]);
      case /* ShowInfo */4 :
          var item$2 = param[1];
          var system$1 = param[0];
          return Curry._1(setDialog, (function (param) {
                        return /* ShowInfo */Block.__(2, [
                                  system$1,
                                  item$2
                                ]);
                      }));
      
    }
  };
  var tableConfig = MaintenanceItemRow.useTableConfig(/* () */0);
  var systems = Belt_Option.mapWithDefaultU(boat, /* [] */0, (function (boat) {
          return Collection.valuesToList(boat.systems);
        }));
  var rowCount = React.useMemo((function () {
          return Belt_List.reduceU(systems, 0, (function (rowCount, system) {
                        return rowCount + Belt_List.size(system.maintenance_items) | 0;
                      }));
        }), [systems]);
  var tmp;
  var exit = 0;
  if (isDataComplete || rowCount !== 0) {
    exit = 1;
  } else {
    tmp = React.createElement(NoDataInGridMessage.make, {
          msg: /* LoadingData */0
        });
  }
  if (exit === 1) {
    tmp = rowCount !== 0 ? null : React.createElement(NoDataInGridMessage.make, {
            msg: noDataMsg
          });
  }
  var tmp$1;
  if (typeof dialog === "number") {
    if (dialog === /* NoDialog */0 || boat === undefined) {
      tmp$1 = null;
    } else {
      var mode = /* Add */Block.__(0, [boat]);
      tmp$1 = React.createElement(MaintenanceItemEntry.make, {
            mode: mode,
            onClose: onCloseMaintenanceEntry
          });
    }
  } else {
    switch (dialog.tag | 0) {
      case /* Edit */0 :
          var mode_000 = dialog[0];
          var mode_001 = dialog[1];
          var mode$1 = /* Edit */Block.__(1, [
              mode_000,
              mode_001
            ]);
          tmp$1 = React.createElement(MaintenanceItemEntry.make, {
                mode: mode$1,
                onClose: onCloseMaintenanceEntry
              });
          break;
      case /* Delete */1 :
          var item = dialog[0];
          var what = Maintenance.shortDescription(item);
          tmp$1 = React.createElement(DeleteConfirmation.make, {
                what: what,
                onClose: hideDialog,
                onOk: (function (param) {
                    var item$1 = item;
                    Db.deleteMaintenanceItem(firestore, item$1);
                    return Curry._1(setDialog, (function (param) {
                                  return /* NoDialog */0;
                                }));
                  }),
                screenIdentifier: "MaintenanceItem"
              });
          break;
      case /* ShowInfo */2 :
          tmp$1 = React.createElement(MaintenanceNoteDialog.make, {
                system: dialog[0],
                item: dialog[1],
                onClose: hideDialog
              });
          break;
      case /* AddLog */3 :
          tmp$1 = React.createElement(MaintenanceLogEntry.make, {
                onClose: hideDialog,
                mode: /* AddToMaintenance */Block.__(0, [
                    dialog[0],
                    dialog[1]
                  ])
              });
          break;
      
    }
  }
  return React.createElement(Box.default, {
              className: classes.grid,
              children: null
            }, React.createElement(Box.default, {
                  className: classes.addButton,
                  children: React.createElement(Fab.default, {
                        color: "primary",
                        size: "medium",
                        variant: "extended",
                        onClick: showAddDialog,
                        disabled: Belt_Option.isNone(boat),
                        children: null
                      }, React.createElement(Add.default, { }), " Add Maintenance Schedule")
                }), React.createElement(TableContainer.default, {
                  children: React.createElement(Table.default, {
                        children: null
                      }, React.createElement(TableHead.default, {
                            children: React.createElement(MaintenanceItemRow.Head.make, {
                                  tableConfig: tableConfig
                                })
                          }), React.createElement(TableBody.default, {
                            children: $$Array.of_list(List.flatten(List.map((function (param) {
                                            return rowsOfSystem(handleOnMenuAction, tableConfig, passagesStats, isDataComplete, param);
                                          }), systems)))
                          }))
                }), tmp, tmp$1);
}

var Mui = /* alias */0;

var B = /* alias */0;

var S = /* alias */0;

var M = /* alias */0;

var E = /* alias */0;

var Pa = /* alias */0;

var defaultBoat = undefined;

var defaultStats = /* [] */0;

var make = MaintenanceItemListTab;

export {
  Mui ,
  B ,
  S ,
  M ,
  E ,
  Pa ,
  useStyles ,
  rowOfMaintenanceItem ,
  rowsOfSystem ,
  defaultBoat ,
  defaultStats ,
  noDataMsg ,
  make ,
  
}
/* useStyles Not a pure module */
