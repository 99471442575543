// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Alert from "@material-ui/lab/Alert";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyle = MakeStyles.default({
      root: {
        marginTop: "20px",
        marginBottom: "20px"
      }
    });

function textOfMsg(param) {
  if (typeof param === "number") {
    return "Loading Data...";
  } else {
    return param[0];
  }
}

function NoDataInGridMessage(Props) {
  var msg = Props.msg;
  var classes = useStyle();
  return React.createElement(Alert.default, {
              className: classes.root,
              severity: "info",
              children: textOfMsg(msg)
            });
}

var Mui = /* alias */0;

var make = NoDataInGridMessage;

export {
  Mui ,
  useStyle ,
  textOfMsg ,
  make ,
  
}
/* useStyle Not a pure module */
