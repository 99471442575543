// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as Passage from "../util/Passage.bs.js";
import * as Distance from "../util/Distance.bs.js";
import * as MenuHook from "./util/MenuHook.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as SpeedUnit from "../util/SpeedUnit.bs.js";
import * as UserState from "../util/UserState.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as DistanceUnit from "../util/DistanceUnit.bs.js";
import * as PressureUnit from "../util/PressureUnit.bs.js";
import * as RowMenuButton from "./RowMenuButton.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as TemperatureUnit from "../util/TemperatureUnit.bs.js";
import * as StringBodyTableCell from "./core/StringBodyTableCell.bs.js";
import * as StringHeadTableCell from "./core/StringHeadTableCell.bs.js";
import * as SimpleTableHeaderMenu from "./SimpleTableHeaderMenu.bs.js";
import * as Menu from "@material-ui/core/Menu";
import * as Hidden from "@material-ui/core/Hidden";
import * as MenuItem from "@material-ui/core/MenuItem";
import * as TableRow from "@material-ui/core/TableRow";
import * as TableCell from "@material-ui/core/TableCell";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function fieldConfigAvailableFields(fields) {
  return Belt_List.keep(fields, (function (param) {
                return param < 13;
              }));
}

function locationConfigToString(param) {
  switch (param) {
    case /* Gps */0 :
        return "GPS Location";
    case /* Name */1 :
        return "Location Name";
    case /* Distance */2 :
        return "Distance";
    case /* Speed */3 :
        return "Speed";
    
  }
}

var allLocationConfigs = /* :: */[
  /* Gps */0,
  /* :: */[
    /* Name */1,
    /* :: */[
      /* Distance */2,
      /* :: */[
        /* Speed */3,
        /* [] */0
      ]
    ]
  ]
];

function timeConfigToString(param) {
  switch (param) {
    case /* SinceStart */0 :
        return "Time since start";
    case /* Absolute */1 :
        return "Date (yyyy/mm/dd hh:mm)";
    case /* SincePrev */2 :
        return "Time since previous";
    
  }
}

var allTimeConfigs = /* :: */[
  /* SinceStart */0,
  /* :: */[
    /* Absolute */1,
    /* :: */[
      /* SincePrev */2,
      /* [] */0
    ]
  ]
];

function useTableConfig(fields) {
  var match = React.useState((function () {
          return /* Gps */0;
        }));
  var match$1 = React.useState((function () {
          return /* SinceStart */0;
        }));
  var availableFields = React.useMemo((function () {
          var fields$1 = fieldConfigAvailableFields(fields);
          return Belt_List.sort(fields$1, Passage.fieldCmp);
        }), [fields]);
  var match$2 = React.useState((function () {
          return /* Remarks */12;
        }));
  return /* tuple */[
          /* tuple */[
            match[0],
            match[1]
          ],
          /* tuple */[
            match$2[0],
            availableFields,
            match$2[1]
          ],
          /* tuple */[
            match$1[0],
            match$1[1]
          ]
        ];
}

function PassageLogRow$LocationMenu(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(SimpleTableHeaderMenu.make, {
              config: tableConfig[0],
              values: allLocationConfigs,
              toString: locationConfigToString
            });
}

var LocationMenu = {
  make: PassageLogRow$LocationMenu
};

function PassageLogRow$TimeMenu(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(SimpleTableHeaderMenu.make, {
              config: tableConfig[2],
              values: allTimeConfigs,
              toString: timeConfigToString
            });
}

var TimeMenu = {
  make: PassageLogRow$TimeMenu
};

function PassageLogRow$FieldMenu(Props) {
  var tableConfig = Props.tableConfig;
  var match = MenuHook.useMenu(/* () */0);
  var match$1 = tableConfig[1];
  var setFieldConfig = match$1[2];
  var fieldConfig = match$1[0];
  var toggle = match[2];
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(RowMenuButton.make, {
                  onClick: Curry._1(toggle, /* Show */925630749)
                }), React.createElement(Menu.default, {
                  open: match[1],
                  anchorEl: match[0],
                  keepMounted: true,
                  onClose: Curry._1(toggle, /* Hide */803692770),
                  children: $$Array.of_list(Belt_List.mapU(match$1[1], (function (field) {
                              var selected = field === fieldConfig;
                              return React.createElement(MenuItem.default, {
                                          selected: selected,
                                          onClick: (function (param) {
                                              var field$1 = field;
                                              var e = param;
                                              Curry._2(toggle, /* Hide */803692770, e);
                                              return Curry._1(setFieldConfig, (function (param) {
                                                            return field$1;
                                                          }));
                                            }),
                                          children: Passage.fieldToString(field)
                                        });
                            })))
                }));
}

var FieldMenu = {
  make: PassageLogRow$FieldMenu
};

function PassageLogRow$SingleFieldHead(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringHeadTableCell.make, {
                  value: "Time",
                  minWidth: 57,
                  children: React.createElement(PassageLogRow$TimeMenu, {
                        tableConfig: tableConfig
                      })
                }), React.createElement(StringHeadTableCell.make, {
                  value: "Location",
                  minWidth: 83,
                  children: React.createElement(PassageLogRow$LocationMenu, {
                        tableConfig: tableConfig
                      })
                }), React.createElement(StringHeadTableCell.make, {
                  value: Passage.fieldToString(tableConfig[1][0]),
                  colSpan: 2,
                  children: React.createElement(PassageLogRow$FieldMenu, {
                        tableConfig: tableConfig
                      })
                }));
}

var SingleFieldHead = {
  make: PassageLogRow$SingleFieldHead
};

function PassageLogRow$AllFieldHead(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringHeadTableCell.make, {
                  value: "Time",
                  children: React.createElement(PassageLogRow$TimeMenu, {
                        tableConfig: tableConfig
                      })
                }), React.createElement(StringHeadTableCell.make, {
                  value: "GPS Location"
                }), React.createElement(StringHeadTableCell.make, {
                  value: "Distance"
                }), React.createElement(StringHeadTableCell.make, {
                  value: "Speed"
                }), React.createElement(StringHeadTableCell.make, {
                  value: "Location Name"
                }), $$Array.of_list(Belt_List.mapU(tableConfig[1][1], (function (field) {
                        return React.createElement(StringHeadTableCell.make, {
                                    value: Passage.fieldToShortString(field),
                                    key: Passage.fieldToString(field)
                                  });
                      }))), React.createElement(StringHeadTableCell.make, { }));
}

var AllFieldHead = {
  make: PassageLogRow$AllFieldHead
};

function PassageLogRow$Head(Props) {
  var tableConfig = Props.tableConfig;
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Hidden.default, {
                  mdUp: true,
                  children: React.createElement(PassageLogRow$SingleFieldHead, {
                        tableConfig: tableConfig
                      })
                }), React.createElement(Hidden.default, {
                  smDown: true,
                  children: React.createElement(PassageLogRow$AllFieldHead, {
                        tableConfig: tableConfig
                      })
                }));
}

var Head = {
  make: PassageLogRow$Head
};

function formatDuration(timestamp) {
  if (timestamp !== 0.0) {
    var timestamp$1 = timestamp | 0;
    var days = timestamp$1 / 86400 | 0;
    var seconds = timestamp$1 % 86400;
    var hours = seconds / 3600 | 0;
    var seconds$1 = seconds % 3600;
    var minutes = seconds$1 / 60 | 0;
    if (days !== 0) {
      return Curry._3(Printf.sprintf(/* Format */[
                      /* Int */Block.__(4, [
                          /* Int_d */0,
                          /* No_padding */0,
                          /* No_precision */0,
                          /* String_literal */Block.__(11, [
                              "d ",
                              /* Int */Block.__(4, [
                                  /* Int_d */0,
                                  /* Lit_padding */Block.__(0, [
                                      /* Right */1,
                                      0
                                    ]),
                                  /* Lit_precision */[2],
                                  /* Char_literal */Block.__(12, [
                                      /* "h" */104,
                                      /* Int */Block.__(4, [
                                          /* Int_d */0,
                                          /* Lit_padding */Block.__(0, [
                                              /* Right */1,
                                              0
                                            ]),
                                          /* Lit_precision */[2],
                                          /* End_of_format */0
                                        ])
                                    ])
                                ])
                            ])
                        ]),
                      "%dd %0.2dh%0.2d"
                    ]), days, hours, minutes);
    } else if (hours !== 0) {
      return Curry._2(Printf.sprintf(/* Format */[
                      /* Int */Block.__(4, [
                          /* Int_d */0,
                          /* Lit_padding */Block.__(0, [
                              /* Right */1,
                              0
                            ]),
                          /* Lit_precision */[2],
                          /* Char_literal */Block.__(12, [
                              /* "h" */104,
                              /* Int */Block.__(4, [
                                  /* Int_d */0,
                                  /* Lit_padding */Block.__(0, [
                                      /* Right */1,
                                      0
                                    ]),
                                  /* Lit_precision */[2],
                                  /* End_of_format */0
                                ])
                            ])
                        ]),
                      "%0.2dh%0.2d"
                    ]), hours, minutes);
    } else {
      return Curry._1(Printf.sprintf(/* Format */[
                      /* Int */Block.__(4, [
                          /* Int_d */0,
                          /* No_padding */0,
                          /* No_precision */0,
                          /* Char_literal */Block.__(12, [
                              /* "m" */109,
                              /* End_of_format */0
                            ])
                        ]),
                      "%dm"
                    ]), minutes);
    }
  } else {
    return "0m";
  }
}

function formatGeoLocation(param) {
  if (param !== undefined) {
    var match = param;
    return "(".concat(match.latitude.toFixed(6)).concat(", ").concat(match.longitude.toFixed(6)).concat(")");
  } else {
    return "";
  }
}

function formatRemarkForMotor(onOff, engine_hours_counter, remarks) {
  if (engine_hours_counter !== undefined) {
    if (remarks === "") {
      return Curry._2(Printf.sprintf(/* Format */[
                      /* String_literal */Block.__(11, [
                          "Motor ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* String_literal */Block.__(11, [
                                  " (",
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* Char_literal */Block.__(12, [
                                          /* ")" */41,
                                          /* End_of_format */0
                                        ])
                                    ])
                                ])
                            ])
                        ]),
                      "Motor %s (%s)"
                    ]), onOff, Passage.enginesHoursToDisplayString(engine_hours_counter));
    } else {
      return Curry._3(Printf.sprintf(/* Format */[
                      /* String_literal */Block.__(11, [
                          "Motor ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* String_literal */Block.__(11, [
                                  " (",
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* String_literal */Block.__(11, [
                                          ") | ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ]),
                      "Motor %s (%s) | %s"
                    ]), onOff, Passage.enginesHoursToDisplayString(engine_hours_counter), remarks);
    }
  } else if (remarks === "") {
    return Curry._1(Printf.sprintf(/* Format */[
                    /* String_literal */Block.__(11, [
                        "Motor ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* End_of_format */0
                          ])
                      ]),
                    "Motor %s"
                  ]), onOff);
  } else {
    return Curry._2(Printf.sprintf(/* Format */[
                    /* String_literal */Block.__(11, [
                        "Motor ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* String_literal */Block.__(11, [
                                " | ",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ])
                          ])
                      ]),
                    "Motor %s | %s"
                  ]), onOff, remarks);
  }
}

function fieldValue(passageLog, field, user) {
  var match = passageLog.type_;
  var exit = 0;
  var value;
  var value$1;
  var value$2;
  switch (field) {
    case /* Leg */0 :
        if (match !== undefined) {
          var match$1 = match;
          if (match$1.tag) {
            return "";
          } else {
            return match$1[0].leg.toString();
          }
        } else {
          return "";
        }
    case /* Sog */1 :
        if (match !== undefined) {
          var match$2 = match;
          if (match$2.tag) {
            return "";
          } else {
            value = match$2[0].sog;
            exit = 1;
          }
        } else {
          return "";
        }
        break;
    case /* Cog */2 :
        if (match !== undefined) {
          var match$3 = match;
          if (match$3.tag) {
            return "";
          } else {
            value$2 = match$3[0].cog;
            exit = 3;
          }
        } else {
          return "";
        }
        break;
    case /* Distance */3 :
        if (match !== undefined) {
          var match$4 = match;
          if (match$4.tag) {
            return "";
          } else {
            var displayUnit = UserState.mapWhenLoggedIn(user, (function (param) {
                    return param.distance_unit;
                  }));
            return Belt_Option.mapWithDefaultU(match$4[0].distance, "", (function (v) {
                          return DistanceUnit.toDisplayString(v, displayUnit);
                        }));
          }
        } else {
          return "";
        }
    case /* Chart_course */4 :
        if (match !== undefined) {
          var match$5 = match;
          if (match$5.tag) {
            return "";
          } else {
            value$2 = match$5[0].chart_course;
            exit = 3;
          }
        } else {
          return "";
        }
        break;
    case /* Wind_speed */5 :
        if (match !== undefined) {
          var match$6 = match;
          if (match$6.tag) {
            return "";
          } else {
            value = match$6[0].wind_speed;
            exit = 1;
          }
        } else {
          return "";
        }
        break;
    case /* Wind_direction */6 :
        if (match !== undefined) {
          var match$7 = match;
          if (match$7.tag) {
            return "";
          } else {
            return Passage.cardinalDirectionToString(match$7[0].wind_direction);
          }
        } else {
          return "";
        }
    case /* Sea_state_description */7 :
        if (match !== undefined) {
          var match$8 = match;
          if (match$8.tag) {
            return "";
          } else {
            return match$8[0].sea_state_description;
          }
        } else {
          return "";
        }
    case /* Barometric_pressure */8 :
        if (match !== undefined) {
          var match$9 = match;
          if (match$9.tag) {
            return "";
          } else {
            var displayUnit$1 = UserState.mapWhenLoggedIn(user, (function (param) {
                    return param.pressure_unit;
                  }));
            return Belt_Option.mapWithDefaultU(match$9[0].barometric_pressure, "", (function (v) {
                          return PressureUnit.toDisplayString(v, displayUnit$1);
                        }));
          }
        } else {
          return "";
        }
    case /* Air_temperature */9 :
        if (match !== undefined) {
          var match$10 = match;
          if (match$10.tag) {
            return "";
          } else {
            value$1 = match$10[0].air_temperature;
            exit = 2;
          }
        } else {
          return "";
        }
        break;
    case /* Sky */10 :
        if (match !== undefined) {
          var match$11 = match;
          if (match$11.tag) {
            return "";
          } else {
            return match$11[0].sky;
          }
        } else {
          return "";
        }
    case /* Sea_temperature */11 :
        if (match !== undefined) {
          var match$12 = match;
          if (match$12.tag) {
            return "";
          } else {
            value$1 = match$12[0].sea_temperature;
            exit = 2;
          }
        } else {
          return "";
        }
        break;
    case /* Remarks */12 :
        if (match !== undefined) {
          var match$13 = match;
          switch (match$13.tag | 0) {
            case /* Position_data */0 :
                return passageLog.remarks;
            case /* Motor_on */1 :
                return formatRemarkForMotor("ON", match$13[0].engine_hours_counter, passageLog.remarks);
            case /* Motor_off */2 :
                return formatRemarkForMotor("OFF", match$13[0].engine_hours_counter, passageLog.remarks);
            case /* Sail_change */3 :
                return "";
            
          }
        } else {
          return "";
        }
    case /* Geo_location */13 :
    case /* Location_name */14 :
        return "";
    
  }
  switch (exit) {
    case 1 :
        var displayUnit$2 = UserState.mapWhenLoggedIn(user, (function (param) {
                return param.speed_unit;
              }));
        return Belt_Option.mapWithDefaultU(value, "", (function (v) {
                      return SpeedUnit.toDisplayString(v, displayUnit$2);
                    }));
    case 2 :
        var displayUnit$3 = UserState.mapWhenLoggedIn(user, (function (param) {
                return param.temperature_unit;
              }));
        return Belt_Option.mapWithDefaultU(value$1, "", (function (v) {
                      return TemperatureUnit.toDisplayString(v, displayUnit$3);
                    }));
    case 3 :
        return Belt_Option.mapWithDefaultU(value$2, "", (function (v) {
                      return v.toFixed(1);
                    }));
    
  }
}

function distanceSincePrevious(current, prev) {
  if (current !== undefined && prev !== undefined) {
    var d = Distance.distance(current, prev);
    return DistanceUnit.toDisplayString(d, /* Nautical_miles */0);
  } else {
    return "";
  }
}

function speedSincePrevious(currentGL, prevGL, currentTs, prevTs) {
  if (currentGL !== undefined && prevGL !== undefined) {
    var d = Distance.distance(currentGL, prevGL);
    if (prevTs !== undefined) {
      var t = currentTs - prevTs;
      if (t > 1.0) {
        return SpeedUnit.toDisplayString(d / t, /* Knots */0);
      } else {
        return "";
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
}

function PassageLogRow$SingleFieldBody(Props) {
  var tableConfig = Props.tableConfig;
  var passageLog = Props.passageLog;
  var user = Props.user;
  var prevGeoLocation = Props.prevGeoLocation;
  var prevUnixTimestamp = Props.prevUnixTimestamp;
  var geo_location = passageLog.geo_location;
  var tmp;
  switch (tableConfig[0][0]) {
    case /* Gps */0 :
        tmp = React.createElement(StringBodyTableCell.make, {
              value: formatGeoLocation(geo_location)
            });
        break;
    case /* Name */1 :
        tmp = React.createElement(StringBodyTableCell.make, {
              value: passageLog.location_name
            });
        break;
    case /* Distance */2 :
        var value = distanceSincePrevious(geo_location, prevGeoLocation);
        tmp = React.createElement(StringBodyTableCell.make, {
              value: value
            });
        break;
    case /* Speed */3 :
        var value$1 = speedSincePrevious(geo_location, prevGeoLocation, passageLog.unix_timestamp, prevUnixTimestamp);
        tmp = React.createElement(StringBodyTableCell.make, {
              value: value$1
            });
        break;
    
  }
  return React.createElement(React.Fragment, {
              children: null
            }, tmp, React.createElement(StringBodyTableCell.make, {
                  value: fieldValue(passageLog, tableConfig[1][0], user)
                }));
}

var SingleFieldBody = {
  make: PassageLogRow$SingleFieldBody
};

function PassageLogRow$AllFieldBody(Props) {
  var passageLog = Props.passageLog;
  var tableConfig = Props.tableConfig;
  var user = Props.user;
  var prevGeoLocation = Props.prevGeoLocation;
  var prevUnixTimestamp = Props.prevUnixTimestamp;
  var geo_location = passageLog.geo_location;
  var distance = distanceSincePrevious(geo_location, prevGeoLocation);
  var speed = speedSincePrevious(geo_location, prevGeoLocation, passageLog.unix_timestamp, prevUnixTimestamp);
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(StringBodyTableCell.make, {
                  value: formatGeoLocation(geo_location)
                }), React.createElement(StringBodyTableCell.make, {
                  value: distance
                }), React.createElement(StringBodyTableCell.make, {
                  value: speed
                }), React.createElement(StringBodyTableCell.make, {
                  value: passageLog.location_name
                }), $$Array.of_list(Belt_List.mapU(tableConfig[1][1], (function (field) {
                        return React.createElement(StringBodyTableCell.make, {
                                    value: fieldValue(passageLog, field, user),
                                    key: Passage.fieldToShortString(field)
                                  });
                      }))));
}

var AllFieldBody = {
  make: PassageLogRow$AllFieldBody
};

function ignoreOnAction(param) {
  return /* () */0;
}

var useStyles = MakeStyles.default({
      actionCell: {
        textAlign: "right"
      }
    });

function PassageLogRow$Body(Props) {
  var passageLog = Props.passageLog;
  var $staropt$star = Props.startUnixTimestamp;
  var $staropt$star$1 = Props.prevUnixTimestamp;
  var $staropt$star$2 = Props.prevGeoLocation;
  var $staropt$star$3 = Props.onAction;
  var tableConfig = Props.tableConfig;
  var startUnixTimestamp = $staropt$star !== undefined ? $staropt$star : 0.0;
  var prevUnixTimestamp = $staropt$star$1 !== undefined ? Caml_option.valFromOption($staropt$star$1) : undefined;
  var prevGeoLocation = $staropt$star$2 !== undefined ? Caml_option.valFromOption($staropt$star$2) : undefined;
  var onAction = $staropt$star$3 !== undefined ? $staropt$star$3 : ignoreOnAction;
  var classes = useStyles();
  var match = React.useContext(AppContext.ctx);
  var user = match.user;
  var match$1 = MenuHook.useMenu(/* () */0);
  var toggle = match$1[2];
  var handleOnAction = function (action, e) {
    Curry._2(toggle, /* Hide */803692770, e);
    return Curry._1(onAction, action);
  };
  var unix_timestamp = passageLog.unix_timestamp;
  var timeValue;
  switch (tableConfig[2][0]) {
    case /* SinceStart */0 :
        timeValue = formatDuration(unix_timestamp - startUnixTimestamp);
        break;
    case /* Absolute */1 :
        timeValue = UnixTimestamp.formatToDateAndTime(unix_timestamp);
        break;
    case /* SincePrev */2 :
        timeValue = formatDuration(prevUnixTimestamp !== undefined ? unix_timestamp - prevUnixTimestamp : 0.0);
        break;
    
  }
  var partial_arg = /* Edit */Block.__(0, [passageLog]);
  var partial_arg$1 = /* Delete */Block.__(1, [passageLog]);
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringBodyTableCell.make, {
                  value: timeValue
                }), React.createElement(Hidden.default, {
                  mdUp: true,
                  children: React.createElement(PassageLogRow$SingleFieldBody, {
                        tableConfig: tableConfig,
                        passageLog: passageLog,
                        user: user,
                        prevGeoLocation: prevGeoLocation,
                        prevUnixTimestamp: prevUnixTimestamp
                      })
                }), React.createElement(Hidden.default, {
                  smDown: true,
                  children: React.createElement(PassageLogRow$AllFieldBody, {
                        passageLog: passageLog,
                        tableConfig: tableConfig,
                        user: user,
                        prevGeoLocation: prevGeoLocation,
                        prevUnixTimestamp: prevUnixTimestamp
                      })
                }), React.createElement(TableCell.default, {
                  className: classes.actionCell,
                  children: null
                }, React.createElement(RowMenuButton.make, {
                      onClick: Curry._1(toggle, /* Show */925630749),
                      color: /* secondary */-499495052
                    }), React.createElement(Menu.default, {
                      open: match$1[1],
                      anchorEl: match$1[0],
                      keepMounted: true,
                      onClose: Curry._1(toggle, /* Hide */803692770),
                      children: null
                    }, React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg, param);
                            }),
                          children: "Edit"
                        }), React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg$1, param);
                            }),
                          children: "Delete"
                        }))));
}

var Body = {
  ignoreOnAction: ignoreOnAction,
  useStyles: useStyles,
  make: PassageLogRow$Body
};

var Mui = /* alias */0;

var Pa = /* alias */0;

var U = /* alias */0;

var DU = /* alias */0;

var SU = /* alias */0;

var userStateMap = UserState.mapWhenLoggedIn;

export {
  Mui ,
  Pa ,
  U ,
  DU ,
  SU ,
  userStateMap ,
  concat ,
  fieldConfigAvailableFields ,
  locationConfigToString ,
  allLocationConfigs ,
  timeConfigToString ,
  allTimeConfigs ,
  useTableConfig ,
  LocationMenu ,
  TimeMenu ,
  FieldMenu ,
  SingleFieldHead ,
  AllFieldHead ,
  Head ,
  formatDuration ,
  formatGeoLocation ,
  formatRemarkForMotor ,
  fieldValue ,
  distanceSincePrevious ,
  speedSincePrevious ,
  SingleFieldBody ,
  AllFieldBody ,
  Body ,
  
}
/* useStyles Not a pure module */
