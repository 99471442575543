// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as DbUtil from "../../db/DbUtil.bs.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Printexc from "bs-platform/lib/es6/printexc.js";
import * as Events_bs from "../../types/events_bs.bs.js";
import * as AppContext from "./AppContext.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Events_types from "../../types/events_types.bs.js";

function login(user) {
  var method__ = user.isAnonymous ? "anonymous" : Belt_Option.getWithDefault(Caml_option.null_to_opt(user.providerId), "unknown");
  var data = Events_bs.encode_login({
        method_: method__
      });
  AppContext.analytics.setUserProperties({
        anonymous: user.isAnonymous
      });
  AppContext.analytics.logEvent("login", data);
  return /* () */0;
}

var dataEventName = "bh_data";

function data(e) {
  var data$1 = Events_bs.encode_data(e);
  AppContext.analytics.logEvent(dataEventName, data$1);
  return /* () */0;
}

var navEventName = "bh_navigation";

function nav(e) {
  var data = Events_bs.encode_navigation(e);
  AppContext.analytics.logEvent(navEventName, data);
  return /* () */0;
}

var exceptionEventName = "exception";

function except(context, e) {
  var description = context.concat(", message:").concat(Belt_Option.getWithDefault(e.message, "")).concat(", stack:").concat(Belt_Option.getWithDefault(e.stack, ""));
  var e$1 = Events_types.default_exception_(description, undefined, /* () */0);
  var data = Events_bs.encode_exception_(e$1);
  AppContext.analytics.logEvent(exceptionEventName, data);
  return /* () */0;
}

function exceptString(context, $staropt$star, e) {
  var fatal = $staropt$star !== undefined ? $staropt$star : false;
  var description = context.concat(", details: ").concat(e);
  var $$event = Events_types.default_exception_(description, fatal, /* () */0);
  var data = Events_bs.encode_exception_($$event);
  AppContext.analytics.logEvent(exceptionEventName, data);
  return /* () */0;
}

function setupGlobalErrorHandler(param) {
  var onError = function (msg, param, param$1, param$2, param$3) {
    exceptString("Global Error Handler", true, msg);
    return false;
  };
  window.onerror = onError;
  return /* () */0;
}

setupGlobalErrorHandler(/* () */0);

function setupGlobalDbAsyncExceptionHandler(param) {
  var exceptionContext = function (collectionName) {
    return "DB - loadSubCollection(".concat(collectionName).concat(")");
  };
  var handleException = function (e, collectionName) {
    if (e[0] === Js_exn.$$Error) {
      return except(exceptionContext(collectionName), e[1]);
    } else {
      var description = Printexc.to_string(e);
      return exceptString(exceptionContext(collectionName), undefined, description);
    }
  };
  DbUtil.asyncExceptionHandler.contents = handleException;
  return /* () */0;
}

setupGlobalDbAsyncExceptionHandler(/* () */0);

export {
  login ,
  dataEventName ,
  data ,
  navEventName ,
  nav ,
  exceptionEventName ,
  except ,
  exceptString ,
  setupGlobalErrorHandler ,
  setupGlobalDbAsyncExceptionHandler ,
  
}
/*  Not a pure module */
