// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Analytics from "./util/Analytics.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as Tab from "@material-ui/core/Tab";
import * as List$1 from "@material-ui/core/List";
import * as Tabs from "@material-ui/core/Tabs";
import * as Home from "@material-ui/icons/Home";
import * as Drawer from "@material-ui/core/Drawer";
import * as Divider from "@material-ui/core/Divider";
import * as ListItem from "@material-ui/core/ListItem";
import * as Explore from "@material-ui/icons/Explore";
import * as Assignment from "@material-ui/icons/Assignment";
import * as ListItemIcon from "@material-ui/core/ListItemIcon";
import * as ListItemText from "@material-ui/core/ListItemText";
import * as DirectionsBoat from "@material-ui/icons/DirectionsBoat";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var homeString = "Home";

var boatString = "Boat";

var maintenanceString = "Maintenance";

var passageString = "Passage";

function toString(param) {
  switch (param) {
    case /* Home */0 :
        return homeString;
    case /* Boat */1 :
        return boatString;
    case /* Maintenance */2 :
        return maintenanceString;
    case /* Passage */3 :
        return passageString;
    
  }
}

function fromString(param) {
  switch (param) {
    case "Boat" :
        return /* Boat */1;
    case "Home" :
        return /* Home */0;
    case "Maintenance" :
        return /* Maintenance */2;
    case "Passage" :
        return /* Passage */3;
    default:
      return /* Home */0;
  }
}

function toNavigationType(param) {
  switch (param) {
    case /* Home */0 :
        return /* App_menu_home */4;
    case /* Boat */1 :
        return /* App_menu_boat */1;
    case /* Maintenance */2 :
        return /* App_menu_maintenance */2;
    case /* Passage */3 :
        return /* App_menu_passage */3;
    
  }
}

var defaultControl = {
  isOpen: false,
  selected: /* Home */0
};

function reduceControl(sideMenu, param) {
  if (typeof param === "number") {
    if (param >= 881668074) {
      return {
              isOpen: true,
              selected: sideMenu.selected
            };
    } else {
      return {
              isOpen: false,
              selected: sideMenu.selected
            };
    }
  } else {
    return {
            isOpen: false,
            selected: param[1]
          };
  }
}

function useControl(param) {
  return React.useReducer(reduceControl, defaultControl);
}

function makeListItem(value, icon, selected, onChange, disabled) {
  var isSelected = value === selected;
  var onClick = function (param) {
    return Curry._1(onChange, value);
  };
  var primaryTypographyProps = {
    color: isSelected ? "primary" : "inherit"
  };
  var iconColor = isSelected ? /* primary */58474434 : /* action */373701558;
  var s = toString(value);
  return React.createElement(ListItem.default, {
              key: s,
              button: true,
              selected: isSelected,
              onClick: onClick,
              disabled: disabled,
              children: null
            }, React.createElement(ListItemIcon.default, {
                  children: Curry._1(icon, iconColor)
                }), React.createElement(ListItemText.default, {
                  primary: s,
                  primaryTypographyProps: primaryTypographyProps
                }));
}

function ignoreOnChange(param) {
  return /* () */0;
}

function AppMenu$List(Props) {
  var $staropt$star = Props.selected;
  var $staropt$star$1 = Props.onChange;
  var $staropt$star$2 = Props.disabled;
  var selected = $staropt$star !== undefined ? $staropt$star : /* Home */0;
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnChange;
  var disabled = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  return React.createElement("div", {
              width: (240).toString()
            }, React.createElement(Divider.default, { }), React.createElement(List$1.default, {
                  children: makeListItem(/* Home */0, (function (color) {
                          return React.createElement(Home.default, {
                                      color: (function () {
                                            switch (color) {
                                              case -601204732 :
                                                  return "inherit";
                                              case 58474434 :
                                                  return "primary";
                                              case -499495052 :
                                                  return "secondary";
                                              case 373701558 :
                                                  return "action";
                                              case -215364664 :
                                                  return "error";
                                              case -66829956 :
                                                  return "disabled";
                                              
                                            }
                                          })()
                                    });
                        }), selected, onChange, disabled)
                }), React.createElement(Divider.default, { }), React.createElement(List$1.default, {
                  children: $$Array.of_list(List.map((function (param) {
                              return makeListItem(param[0], param[1], selected, onChange, disabled);
                            }), /* :: */[
                            /* tuple */[
                              /* Boat */1,
                              (function (color) {
                                  return React.createElement(DirectionsBoat.default, {
                                              color: (function () {
                                                    switch (color) {
                                                      case -601204732 :
                                                          return "inherit";
                                                      case 58474434 :
                                                          return "primary";
                                                      case -499495052 :
                                                          return "secondary";
                                                      case 373701558 :
                                                          return "action";
                                                      case -215364664 :
                                                          return "error";
                                                      case -66829956 :
                                                          return "disabled";
                                                      
                                                    }
                                                  })()
                                            });
                                })
                            ],
                            /* :: */[
                              /* tuple */[
                                /* Maintenance */2,
                                (function (color) {
                                    return React.createElement(Assignment.default, {
                                                color: (function () {
                                                      switch (color) {
                                                        case -601204732 :
                                                            return "inherit";
                                                        case 58474434 :
                                                            return "primary";
                                                        case -499495052 :
                                                            return "secondary";
                                                        case 373701558 :
                                                            return "action";
                                                        case -215364664 :
                                                            return "error";
                                                        case -66829956 :
                                                            return "disabled";
                                                        
                                                      }
                                                    })()
                                              });
                                  })
                              ],
                              /* :: */[
                                /* tuple */[
                                  /* Passage */3,
                                  (function (color) {
                                      return React.createElement(Explore.default, {
                                                  color: (function () {
                                                        switch (color) {
                                                          case -601204732 :
                                                              return "inherit";
                                                          case 58474434 :
                                                              return "primary";
                                                          case -499495052 :
                                                              return "secondary";
                                                          case 373701558 :
                                                              return "action";
                                                          case -215364664 :
                                                              return "error";
                                                          case -66829956 :
                                                              return "disabled";
                                                          
                                                        }
                                                      })()
                                                });
                                    })
                                ],
                                /* [] */0
                              ]
                            ]
                          ]))
                }), React.createElement(Divider.default, { }));
}

var List$2 = {
  makeListItem: makeListItem,
  ignoreOnChange: ignoreOnChange,
  make: AppMenu$List
};

var useStyles = MakeStyles.default({
      drawerPaper: {
        width: 240
      }
    });

function AppMenu$Drawer(Props) {
  var appMenu = Props.appMenu;
  var appMenuDispatch = Props.appMenuDispatch;
  var variant = Props.variant;
  var $staropt$star = Props.disabled;
  var disabled = $staropt$star !== undefined ? $staropt$star : false;
  var classes = useStyles();
  var onChange = function (s) {
    Analytics.nav(Events_types.default_navigation(toNavigationType(s), undefined, /* () */0));
    return Curry._1(appMenuDispatch, /* `Select */[
                516394780,
                s
              ]);
  };
  var onClose = function (param) {
    return Curry._1(appMenuDispatch, /* Close */-611285096);
  };
  var classes$1 = {
    paper: classes.drawerPaper
  };
  return React.createElement(Drawer.default, {
              classes: classes$1,
              variant: variant,
              anchor: "left",
              open: appMenu.isOpen,
              onClose: onClose,
              children: React.createElement(AppMenu$List, {
                    selected: appMenu.selected,
                    onChange: onChange,
                    disabled: disabled
                  })
            });
}

var Drawer$1 = {
  useStyles: useStyles,
  make: AppMenu$Drawer
};

var useStyles$1 = MakeStyles.default({
      tabSelected: {
        backgroundColor: "rgba(1,87,155, 0.075)"
      },
      tabRoot: {
        paddingBottom: 40
      }
    });

function AppMenu$Tab(Props) {
  var appMenu = Props.appMenu;
  var appMenuDispatch = Props.appMenuDispatch;
  var disabled = Props.disabled;
  var classes = useStyles$1();
  var onChange = function (param, index) {
    var menu = fromString(index);
    Analytics.nav(Events_types.default_navigation(toNavigationType(menu), undefined, /* () */0));
    return Curry._1(appMenuDispatch, /* `Select */[
                516394780,
                menu
              ]);
  };
  var tabClasses = {
    selected: classes.tabSelected,
    root: classes.tabRoot
  };
  return React.createElement(Tabs.default, {
              indicatorColor: "primary",
              textColor: "primary",
              variant: "fullWidth",
              value: toString(appMenu.selected),
              onChange: onChange,
              children: null
            }, React.createElement(Tab.default, {
                  classes: tabClasses,
                  value: homeString,
                  disabled: disabled,
                  icon: React.createElement(Home.default, { })
                }), React.createElement(Tab.default, {
                  classes: tabClasses,
                  value: boatString,
                  disabled: disabled,
                  icon: React.createElement(DirectionsBoat.default, { })
                }), React.createElement(Tab.default, {
                  classes: tabClasses,
                  value: maintenanceString,
                  disabled: disabled,
                  icon: React.createElement(Assignment.default, { })
                }), React.createElement(Tab.default, {
                  classes: tabClasses,
                  value: passageString,
                  disabled: disabled,
                  icon: React.createElement(Explore.default, { })
                }));
}

var Tab$1 = {
  useStyles: useStyles$1,
  make: AppMenu$Tab
};

var Mui = /* alias */0;

var E = /* alias */0;

var drawerWidth = 240;

export {
  Mui ,
  E ,
  drawerWidth ,
  homeString ,
  boatString ,
  maintenanceString ,
  passageString ,
  toString ,
  fromString ,
  toNavigationType ,
  defaultControl ,
  reduceControl ,
  useControl ,
  List$2 as List,
  Drawer$1 as Drawer,
  Tab$1 as Tab,
  
}
/* useStyles Not a pure module */
