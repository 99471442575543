// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Input from "./Input.bs.js";
import * as React from "react";

var defaultLabel = "";

function toString(value, digits) {
  if (digits >= 0) {
    return value.toFixed(digits);
  } else {
    return value.toString();
  }
}

function reformatFloatValue(valueAsFloat, valueAsString, digits) {
  var diff = Math.abs(valueAsFloat - Number(valueAsString));
  if (diff > 0.000000000001) {
    return toString(valueAsFloat, digits);
  }
  
}

function ignoreOnChange(param) {
  return /* () */0;
}

function NumberInput$Float(Props) {
  var value = Props.value;
  var $staropt$star = Props.onChange;
  var $staropt$star$1 = Props.label;
  var $staropt$star$2 = Props.disabled;
  var $staropt$star$3 = Props.digits;
  var onChange = $staropt$star !== undefined ? $staropt$star : ignoreOnChange;
  var label = $staropt$star$1 !== undefined ? $staropt$star$1 : defaultLabel;
  var disabled = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var digits = $staropt$star$3 !== undefined ? $staropt$star$3 : -1;
  var match = React.useState((function () {
          return toString(value, digits);
        }));
  var setValueAsString = match[1];
  var valueAsString = match[0];
  React.useEffect((function () {
          var match = reformatFloatValue(value, valueAsString, digits);
          if (match !== undefined) {
            var s = match;
            Curry._1(setValueAsString, (function (param) {
                    return s;
                  }));
          }
          return ;
        }), /* tuple */[
        value,
        digits
      ]);
  var onChange$1 = React.useCallback((function (e) {
          var v = e.target.value;
          var f = Number(v);
          if (isNaN(f)) {
            return 0;
          } else {
            Curry._1(setValueAsString, (function (param) {
                    return v;
                  }));
            return Curry._1(onChange, f);
          }
        }));
  return React.createElement(Input.make, {
              label: label,
              value: valueAsString,
              disabled: disabled,
              onChange: onChange$1
            });
}

var Float = {
  ignoreOnChange: ignoreOnChange,
  make: NumberInput$Float
};

function ignoreOnChange$1(param) {
  return /* () */0;
}

function NumberInput$NullableFloat(Props) {
  var value = Props.value;
  var $staropt$star = Props.onChange;
  var $staropt$star$1 = Props.label;
  var $staropt$star$2 = Props.disabled;
  var $staropt$star$3 = Props.digits;
  var onChange = $staropt$star !== undefined ? $staropt$star : ignoreOnChange$1;
  var label = $staropt$star$1 !== undefined ? $staropt$star$1 : defaultLabel;
  var disabled = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var digits = $staropt$star$3 !== undefined ? $staropt$star$3 : -1;
  var match = React.useState((function () {
          if (value !== undefined) {
            return toString(value, digits);
          } else {
            return "";
          }
        }));
  var setValueAsString = match[1];
  var valueAsString = match[0];
  React.useEffect((function () {
          if (value !== undefined) {
            var match = reformatFloatValue(value, valueAsString, digits);
            if (match !== undefined) {
              var s = match;
              Curry._1(setValueAsString, (function (param) {
                      return s;
                    }));
            }
            
          } else if (valueAsString !== "") {
            Curry._1(setValueAsString, (function (param) {
                    return "";
                  }));
          }
          return ;
        }), /* tuple */[
        value,
        digits
      ]);
  var onChange$1 = React.useCallback((function (e) {
          var v = e.target.value;
          if (v === "") {
            Curry._1(setValueAsString, (function (param) {
                    return "";
                  }));
            return Curry._1(onChange, undefined);
          } else {
            var f = Number(v);
            if (isNaN(f)) {
              return 0;
            } else {
              Curry._1(setValueAsString, (function (param) {
                      return v;
                    }));
              return Curry._1(onChange, f);
            }
          }
        }));
  return React.createElement(Input.make, {
              label: label,
              value: valueAsString,
              disabled: disabled,
              onChange: onChange$1
            });
}

var NullableFloat = {
  ignoreOnChange: ignoreOnChange$1,
  make: NumberInput$NullableFloat
};

function ignoreOnChange$2(param) {
  return /* () */0;
}

function NumberInput$Int(Props) {
  var value = Props.value;
  var $staropt$star = Props.onChange;
  var $staropt$star$1 = Props.label;
  var $staropt$star$2 = Props.disabled;
  var onChange = $staropt$star !== undefined ? $staropt$star : ignoreOnChange$2;
  var label = $staropt$star$1 !== undefined ? $staropt$star$1 : defaultLabel;
  var disabled = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var match = React.useState((function () {
          return value.toString();
        }));
  var setValueAsString = match[1];
  var valueAsString = match[0];
  React.useEffect((function () {
          if (!(valueAsString === "" && value === 0)) {
            Curry._1(setValueAsString, (function (param) {
                    return value.toString();
                  }));
          }
          return ;
        }), [value]);
  var onChange$1 = React.useCallback((function (e) {
          var v = e.target.value;
          var i = Number(v) | 0;
          if (v === "") {
            Curry._1(setValueAsString, (function (param) {
                    return "";
                  }));
          } else {
            Curry._1(setValueAsString, (function (param) {
                    return i.toString();
                  }));
          }
          return Curry._1(onChange, i);
        }));
  return React.createElement(Input.make, {
              label: label,
              value: valueAsString,
              disabled: disabled,
              onChange: onChange$1
            });
}

var Int = {
  ignoreOnChange: ignoreOnChange$2,
  make: NumberInput$Int
};

export {
  defaultLabel ,
  toString ,
  reformatFloatValue ,
  Float ,
  NullableFloat ,
  Int ,
  
}
/* Input Not a pure module */
