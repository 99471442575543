// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "@material-ui/core/Button";
import * as Dialog from "@material-ui/core/Dialog";
import * as DialogTitle from "@material-ui/core/DialogTitle";
import * as DialogActions from "@material-ui/core/DialogActions";
import * as DialogContent from "@material-ui/core/DialogContent";
import * as CircularProgress from "@material-ui/core/CircularProgress";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function ignore(param) {
  return /* () */0;
}

var defaultChildren = null;

var savingTitle = "Saving...";

var savingMsg = React.createElement(CircularProgress.default, {
      color: "primary"
    });

function AddConfirmation(Props) {
  var onClose = Props.onClose;
  var mode = Props.mode;
  var handleOnDialogClose = function (param, param$1) {
    return Curry._1(onClose, /* () */0);
  };
  var handleOnClose = function (param) {
    return Curry._1(onClose, /* () */0);
  };
  var _open = typeof mode === "number" ? mode !== 0 : true;
  var title = typeof mode === "number" ? (
      mode !== 0 ? savingTitle : null
    ) : mode[0];
  var msg = typeof mode === "number" ? (
      mode !== 0 ? savingMsg : null
    ) : mode[1];
  var disabled = typeof mode === "number" ? true : false;
  return React.createElement(Dialog.default, {
              open: _open,
              fullWidth: true,
              maxWidth: "xs",
              onClose: handleOnDialogClose,
              children: null
            }, React.createElement(DialogTitle.default, {
                  children: title
                }), React.createElement(DialogContent.default, {
                  children: msg
                }), React.createElement(DialogActions.default, {
                  children: React.createElement(Button.default, {
                        color: "primary",
                        disabled: disabled,
                        onClick: handleOnClose,
                        children: "close"
                      })
                }));
}

var Mui = /* alias */0;

var make = AddConfirmation;

export {
  Mui ,
  concat ,
  ignore ,
  defaultChildren ,
  savingTitle ,
  savingMsg ,
  make ,
  
}
/* defaultChildren Not a pure module */
