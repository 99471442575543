// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Cardinal_direction_types from "./cardinal_direction_types.bs.js";

function default_crew_type(param) {
  return /* Captain */0;
}

function default_passage_crew_member($staropt$star, $staropt$star$1, $staropt$star$2, param) {
  var id = $staropt$star !== undefined ? $staropt$star : 0;
  var type_ = $staropt$star$1 !== undefined ? $staropt$star$1 : /* Captain */0;
  var name = $staropt$star$2 !== undefined ? $staropt$star$2 : "";
  return {
          id: id,
          type_: type_,
          name: name
        };
}

function default_log_field_type(param) {
  return /* Leg */0;
}

function default_log_position_data($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, $staropt$star$4, $staropt$star$5, $staropt$star$6, $staropt$star$7, $staropt$star$8, $staropt$star$9, $staropt$star$10, $staropt$star$11, $staropt$star$12, param) {
  var leg = $staropt$star !== undefined ? $staropt$star : 0;
  var sog = $staropt$star$1 !== undefined ? Caml_option.valFromOption($staropt$star$1) : undefined;
  var cog = $staropt$star$2 !== undefined ? Caml_option.valFromOption($staropt$star$2) : undefined;
  var distance = $staropt$star$3 !== undefined ? Caml_option.valFromOption($staropt$star$3) : undefined;
  var chart_course = $staropt$star$4 !== undefined ? Caml_option.valFromOption($staropt$star$4) : undefined;
  var wind_speed = $staropt$star$5 !== undefined ? Caml_option.valFromOption($staropt$star$5) : undefined;
  var wind_direction = $staropt$star$6 !== undefined ? $staropt$star$6 : Cardinal_direction_types.default_cardinal_direction(/* () */0);
  var sea_state_description = $staropt$star$7 !== undefined ? $staropt$star$7 : "";
  var barometric_pressure = $staropt$star$8 !== undefined ? Caml_option.valFromOption($staropt$star$8) : undefined;
  var air_temperature = $staropt$star$9 !== undefined ? Caml_option.valFromOption($staropt$star$9) : undefined;
  var sea_temperature = $staropt$star$10 !== undefined ? Caml_option.valFromOption($staropt$star$10) : undefined;
  var sky = $staropt$star$11 !== undefined ? $staropt$star$11 : "";
  var remarks = $staropt$star$12 !== undefined ? $staropt$star$12 : "";
  return {
          leg: leg,
          sog: sog,
          cog: cog,
          distance: distance,
          chart_course: chart_course,
          wind_speed: wind_speed,
          wind_direction: wind_direction,
          sea_state_description: sea_state_description,
          barometric_pressure: barometric_pressure,
          air_temperature: air_temperature,
          sea_temperature: sea_temperature,
          sky: sky,
          remarks: remarks
        };
}

function default_log_motor_on($staropt$star, param) {
  var engine_hours_counter = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  return {
          engine_hours_counter: engine_hours_counter
        };
}

function default_log_motor_off($staropt$star, param) {
  var engine_hours_counter = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  return {
          engine_hours_counter: engine_hours_counter
        };
}

function default_log_sail_change($staropt$star, param) {
  var details = $staropt$star !== undefined ? $staropt$star : "";
  return {
          details: details
        };
}

function default_passage_log_type(param) {
  return /* Position_data */Block.__(0, [default_log_position_data(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)]);
}

function default_geo_location($staropt$star, $staropt$star$1, param) {
  var longitude = $staropt$star !== undefined ? $staropt$star : 0;
  var latitude = $staropt$star$1 !== undefined ? $staropt$star$1 : 0;
  return {
          longitude: longitude,
          latitude: latitude
        };
}

function default_passage_log($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, $staropt$star$4, $staropt$star$5, param) {
  var id = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var unix_timestamp = $staropt$star$1 !== undefined ? $staropt$star$1 : 0;
  var geo_location = $staropt$star$2 !== undefined ? Caml_option.valFromOption($staropt$star$2) : undefined;
  var location_name = $staropt$star$3 !== undefined ? $staropt$star$3 : "";
  var remarks = $staropt$star$4 !== undefined ? $staropt$star$4 : "";
  var type_ = $staropt$star$5 !== undefined ? Caml_option.valFromOption($staropt$star$5) : undefined;
  return {
          id: id,
          unix_timestamp: unix_timestamp,
          geo_location: geo_location,
          location_name: location_name,
          remarks: remarks,
          type_: type_
        };
}

function default_retroactive_data($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, param) {
  var start_unix_timestamp = $staropt$star !== undefined ? $staropt$star : 0;
  var end_unix_timestamp = $staropt$star$1 !== undefined ? $staropt$star$1 : 0;
  var total_engine_hours = $staropt$star$2 !== undefined ? $staropt$star$2 : 0;
  var total_distance = $staropt$star$3 !== undefined ? $staropt$star$3 : 0;
  return {
          start_unix_timestamp: start_unix_timestamp,
          end_unix_timestamp: end_unix_timestamp,
          total_engine_hours: total_engine_hours,
          total_distance: total_distance
        };
}

function default_stats($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, $staropt$star$4, $staropt$star$5, $staropt$star$6, $staropt$star$7, param) {
  var start_unix_timestamp = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var end_unix_timestamp = $staropt$star$1 !== undefined ? Caml_option.valFromOption($staropt$star$1) : undefined;
  var total_engine_hours = $staropt$star$2 !== undefined ? $staropt$star$2 : 0;
  var latest_engine_hours_counter = $staropt$star$3 !== undefined ? Caml_option.valFromOption($staropt$star$3) : undefined;
  var latest_engine_on_unix_timestamp = $staropt$star$4 !== undefined ? Caml_option.valFromOption($staropt$star$4) : undefined;
  var current_leg = $staropt$star$5 !== undefined ? $staropt$star$5 : 0;
  var total_distance = $staropt$star$6 !== undefined ? $staropt$star$6 : 0;
  var latest_geo_location = $staropt$star$7 !== undefined ? Caml_option.valFromOption($staropt$star$7) : undefined;
  return {
          start_unix_timestamp: start_unix_timestamp,
          end_unix_timestamp: end_unix_timestamp,
          total_engine_hours: total_engine_hours,
          latest_engine_hours_counter: latest_engine_hours_counter,
          latest_engine_on_unix_timestamp: latest_engine_on_unix_timestamp,
          current_leg: current_leg,
          total_distance: total_distance,
          latest_geo_location: latest_geo_location
        };
}

function default_passage($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, $staropt$star$4, $staropt$star$5, $staropt$star$6, $staropt$star$7, $staropt$star$8, $staropt$star$9, param) {
  var id = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var name = $staropt$star$1 !== undefined ? $staropt$star$1 : "";
  var from = $staropt$star$2 !== undefined ? $staropt$star$2 : "";
  var to_ = $staropt$star$3 !== undefined ? $staropt$star$3 : "";
  var notes = $staropt$star$4 !== undefined ? $staropt$star$4 : "";
  var crew = $staropt$star$5 !== undefined ? $staropt$star$5 : /* [] */0;
  var logs = $staropt$star$6 !== undefined ? $staropt$star$6 : /* [] */0;
  var fields = $staropt$star$7 !== undefined ? $staropt$star$7 : /* [] */0;
  var retroactive_data = $staropt$star$8 !== undefined ? Caml_option.valFromOption($staropt$star$8) : undefined;
  var logs_stats = $staropt$star$9 !== undefined ? Caml_option.valFromOption($staropt$star$9) : undefined;
  return {
          id: id,
          name: name,
          from: from,
          to_: to_,
          notes: notes,
          crew: crew,
          logs: logs,
          fields: fields,
          retroactive_data: retroactive_data,
          logs_stats: logs_stats
        };
}

export {
  default_crew_type ,
  default_passage_crew_member ,
  default_log_field_type ,
  default_log_position_data ,
  default_log_motor_on ,
  default_log_motor_off ,
  default_log_sail_change ,
  default_passage_log_type ,
  default_geo_location ,
  default_passage_log ,
  default_retroactive_data ,
  default_stats ,
  default_passage ,
  
}
/* No side effect */
