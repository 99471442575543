// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as P from "../../util/P.bs.js";
import * as Db from "../../db/Db.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Analytics from "./Analytics.bs.js";
import * as AppContext from "./AppContext.bs.js";

function useAuth(onChange) {
  var unregister = React.useRef(undefined);
  React.useEffect((function () {
          if (unregister.current === undefined) {
            var ret = AppContext.auth.onAuthStateChanged((function (user$prime) {
                    Db.unSubscribeToAll(/* () */0);
                    if (user$prime == null) {
                      console.log("User has signed out");
                      P.mapCatch((function (e) {
                              Curry._1(onChange, /* LoggedOut */1);
                              console.log("Error signInAnonymously: ", e);
                              return /* () */0;
                            }), P.mapThen((function (param) {
                                  console.log("signInAnonymously success");
                                  return /* () */0;
                                }), AppContext.auth.signInAnonymously()));
                      return /* () */0;
                    } else {
                      AppContext.analytics.setUserId(user$prime.uid);
                      Analytics.login(user$prime);
                      console.log("New user has logged in");
                      P.mapCatch((function (e) {
                              console.log("Error reading user data: ", e);
                              AppContext.auth.signOut();
                              return /* () */0;
                            }), P.mapThen((function (user) {
                                  return Curry._1(onChange, /* LoggedIn */[user]);
                                }), Db.user(user$prime, AppContext.firestore)));
                      return /* () */0;
                    }
                  }));
            unregister.current = ret;
            return ;
          }
          
        }));
  return unregister;
}

var Pr = /* alias */0;

export {
  Pr ,
  useAuth ,
  
}
/* P Not a pure module */
