// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";

function decode_distance_unit(json) {
  var match = Pbrt_bs.string(json, "distance_unit", "value");
  switch (match) {
    case "KILOMETERS" :
        return /* Kilometers */2;
    case "METERS" :
        return /* Meters */1;
    case "MILES" :
        return /* Miles */3;
    case "" :
    case "NAUTICAL_MILES" :
        return /* Nautical_miles */0;
    default:
      return Pbrt_bs.E.malformed_variant("distance_unit");
  }
}

function encode_distance_unit(v) {
  switch (v) {
    case /* Nautical_miles */0 :
        return "NAUTICAL_MILES";
    case /* Meters */1 :
        return "METERS";
    case /* Kilometers */2 :
        return "KILOMETERS";
    case /* Miles */3 :
        return "MILES";
    
  }
}

export {
  encode_distance_unit ,
  decode_distance_unit ,
  
}
/* Pbrt_bs Not a pure module */
