// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Ignore from "../types/Ignore.bs.js";
import * as Passage from "../util/Passage.bs.js";
import * as AddConfirmation from "./AddConfirmation.bs.js";
import * as Button from "@material-ui/core/Button";
import * as Dialog from "@material-ui/core/Dialog";
import * as DialogTitle from "@material-ui/core/DialogTitle";
import * as DialogActions from "@material-ui/core/DialogActions";
import * as DialogContent from "@material-ui/core/DialogContent";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var emptyString = "";

function actionOfType(param) {
  if (param >= 795952288) {
    return " added";
  } else {
    return " updated";
  }
}

function AddPassageConfirmation(Props) {
  var mode = Props.mode;
  var $staropt$star = Props.onClose;
  var $staropt$star$1 = Props.onStartNow;
  var onClose = $staropt$star !== undefined ? $staropt$star : Ignore.v0;
  var onStartNow = $staropt$star$1 !== undefined ? $staropt$star$1 : Ignore.v1;
  var match;
  if (typeof mode === "number") {
    match = mode >= 19559306 ? /* tuple */[
        emptyString,
        emptyString
      ] : /* tuple */[
        AddConfirmation.savingTitle,
        AddConfirmation.savingMsg
      ];
  } else {
    var match$1 = mode[1];
    var passage = match$1[1];
    var type_ = match$1[0];
    var title = React.createElement(React.Fragment, {
          children: null
        }, "Passage ", React.createElement("strong", undefined, passage.name), actionOfType(type_).concat(" successfully"));
    var match$2 = Passage.passageType(passage);
    var msg = type_ !== 795952288 || !match$2 ? null : "You can start your passage by entering your first log entry";
    match = /* tuple */[
      title,
      msg
    ];
  }
  var _open = mode !== 19559306;
  var disabled = mode === -674467366;
  var onStartNow$1;
  if (typeof mode === "number") {
    onStartNow$1 = Ignore.v1;
  } else {
    var passage$1 = mode[1][1];
    onStartNow$1 = (function (param) {
        return Curry._1(onStartNow, passage$1);
      });
  }
  var showOnStartNow;
  if (typeof mode === "number" || mode[0] !== 759635106) {
    showOnStartNow = false;
  } else {
    var match$3 = mode[1];
    showOnStartNow = match$3[0] !== 795952288 ? false : Passage.passageType(match$3[1]) === /* Live */1;
  }
  return React.createElement(Dialog.default, {
              open: _open,
              fullWidth: true,
              maxWidth: "xs",
              onClose: (function (param, param$1) {
                  return Curry._1(onClose, /* () */0);
                }),
              children: null
            }, React.createElement(DialogTitle.default, {
                  children: match[0]
                }), React.createElement(DialogContent.default, {
                  children: match[1]
                }), React.createElement(DialogActions.default, {
                  children: null
                }, React.createElement(Button.default, {
                      color: "default",
                      variant: "outlined",
                      disabled: disabled,
                      onClick: (function (param) {
                          return Curry._1(onClose, /* () */0);
                        }),
                      children: "Close"
                    }), showOnStartNow ? React.createElement(Button.default, {
                        color: "primary",
                        variant: "outlined",
                        disabled: disabled,
                        onClick: onStartNow$1,
                        children: "Start Now"
                      }) : null));
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var make = AddPassageConfirmation;

export {
  Mui ,
  Pa ,
  concat ,
  emptyString ,
  actionOfType ,
  make ,
  
}
/* react Not a pure module */
