// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as State from "../util/State.bs.js";
import * as React from "react";
import * as Selector from "./core/Selector.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Collection from "../util/Collection.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Events_types from "../types/events_types.bs.js";

var loadingDataMsg = "Loading Data...";

var noBoatMsg = "Boat Name";

function makeBoatValue(b) {
  return /* tuple */[
          b.data.name,
          b.data.id
        ];
}

function makeMsgBasedSelectorData(msg) {
  var v = /* tuple */[
    msg,
    undefined
  ];
  return /* tuple */[
          v,
          /* :: */[
            v,
            /* [] */0
          ],
          true
        ];
}

function BoatNameSelector$Selector(Props) {
  var state = Props.state;
  var stateDispatch = Props.stateDispatch;
  var onChange = function (param) {
    Analytics.nav(Events_types.default_navigation(/* Boat_selector_name */15, /* Selection_changed */1, /* () */0));
    return Curry._1(stateDispatch, /* `SelectBoat */[
                644346172,
                param[1]
              ]);
  };
  var selectedBoat = State.getSelectedBoat(state);
  var boatValue = Belt_Option.mapWithDefault(selectedBoat, /* tuple */[
        "",
        undefined
      ], makeBoatValue);
  var boats = state.boats;
  var isComplete = State.isComplete(state);
  var match;
  var exit = 0;
  if (isComplete) {
    if (Collection.isEmpty(boats)) {
      match = makeMsgBasedSelectorData(noBoatMsg);
    } else {
      exit = 1;
    }
  } else if (Collection.isEmpty(boats)) {
    match = makeMsgBasedSelectorData(loadingDataMsg);
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var values = Collection.mapToList(boats, (function (boat) {
            if (selectedBoat !== undefined && selectedBoat === boat) {
              return boatValue;
            }
            return /* tuple */[
                    boat.data.name,
                    boat.data.id
                  ];
          }));
    match = /* tuple */[
      boatValue,
      values,
      false
    ];
  }
  return React.createElement(Selector.make, {
              value: match[0],
              values: match[1],
              toString: (function (prim) {
                  return prim[0];
                }),
              onChange: onChange,
              disabled: match[2]
            });
}

var Selector$1 = {
  makeBoatValue: makeBoatValue,
  makeMsgBasedSelectorData: makeMsgBasedSelectorData,
  make: BoatNameSelector$Selector
};

var Mui = /* alias */0;

var B = /* alias */0;

var E = /* alias */0;

export {
  Mui ,
  B ,
  E ,
  loadingDataMsg ,
  noBoatMsg ,
  Selector$1 as Selector,
  
}
/* State Not a pure module */
