// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Id from "../util/Id.bs.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as State from "../util/State.bs.js";
import * as React from "react";
import * as Analytics from "./util/Analytics.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as UserState from "../util/UserState.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Collection from "../util/Collection.bs.js";
import * as PassageRow from "./PassageRow.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Events_types from "../types/events_types.bs.js";
import * as PassageEntry from "./PassageEntry.bs.js";
import * as DeleteConfirmation from "./DeleteConfirmation.bs.js";
import * as NoDataInGridMessage from "./NoDataInGridMessage.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Fab from "@material-ui/core/Fab";
import * as Add from "@material-ui/icons/Add";
import * as Table from "@material-ui/core/Table";
import * as TableBody from "@material-ui/core/TableBody";
import * as TableHead from "@material-ui/core/TableHead";
import * as TableContainer from "@material-ui/core/TableContainer";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var useStyles = MakeStyles.default({
      addButton: {
        width: "100%",
        textAlign: "center",
        marginBottom: 10
      },
      listTab: {
        paddingTop: "15px"
      }
    });

function passageKey(passage, stats) {
  var match = passage.id;
  if (match !== undefined) {
    return match.firestore_id;
  } else if (stats !== undefined) {
    var match$1 = stats.start_unix_timestamp;
    if (match$1 !== undefined) {
      return match$1.toString();
    } else {
      return passage.name;
    }
  } else {
    return passage.name;
  }
}

function PassageListTab(Props) {
  var state = Props.state;
  var stateDispatch = Props.stateDispatch;
  var classes = useStyles();
  var match = React.useContext(AppContext.ctx);
  var firestore = match.firestore;
  var match$1 = React.useState((function () {
          return /* NoDialog */0;
        }));
  var setDialog = match$1[1];
  var dialog = match$1[0];
  var hideDialog = function (param) {
    return Curry._1(setDialog, (function (param) {
                  return /* NoDialog */0;
                }));
  };
  var boat = State.getSelectedBoat(state);
  var showAddDialog = function (param) {
    Analytics.data(Events_types.default_data(/* Passage */5, /* Open_dialog_entry_add */1, undefined, undefined, undefined, /* () */0));
    return Curry._1(setDialog, (function (param) {
                  if (boat !== undefined) {
                    var match = boat.data.id;
                    if (match !== undefined) {
                      return /* Add */Block.__(0, [match]);
                    } else {
                      return /* NoDialog */0;
                    }
                  } else {
                    return /* NoDialog */0;
                  }
                }));
  };
  var removeLogsStats = function (passage) {
    if (Belt_Option.isSome(passage.logs_stats)) {
      var passage_id = passage.id;
      var passage_name = passage.name;
      var passage_from = passage.from;
      var passage_to_ = passage.to_;
      var passage_notes = passage.notes;
      var passage_crew = passage.crew;
      var passage_logs = passage.logs;
      var passage_fields = passage.fields;
      var passage_retroactive_data = passage.retroactive_data;
      var passage$1 = {
        id: passage_id,
        name: passage_name,
        from: passage_from,
        to_: passage_to_,
        notes: passage_notes,
        crew: passage_crew,
        logs: passage_logs,
        fields: passage_fields,
        retroactive_data: passage_retroactive_data,
        logs_stats: undefined
      };
      var onDone = function (param) {
        if (typeof param === "number") {
          console.log("stats erased");
          return /* () */0;
        } else {
          console.log("stats erased -- Error");
          return Analytics.except("DB Update - Passage (reset stats)", param[1]);
        }
      };
      return Db.updatePassage(firestore, passage$1, onDone);
    } else {
      return 0;
    }
  };
  var onAction = function (param) {
    switch (param.tag | 0) {
      case /* Edit */0 :
          var passage = param[0];
          Analytics.data(Events_types.default_data(/* Passage */5, /* Open_dialog_entry_update */2, undefined, undefined, Id.firestorePath(passage.id), /* () */0));
          return Curry._1(setDialog, (function (param) {
                        return /* Edit */Block.__(1, [passage]);
                      }));
      case /* Delete */1 :
          var passage$1 = param[0];
          Analytics.data(Events_types.default_data(/* Passage */5, /* Open_dialog_entry_delete */3, undefined, undefined, Id.firestorePath(passage$1.id), /* () */0));
          return Curry._1(setDialog, (function (param) {
                        return /* Delete */Block.__(2, [passage$1]);
                      }));
      case /* ShowLogs */2 :
          var passage$2 = param[0];
          Analytics.nav(Events_types.default_navigation(/* Passage_row_expand */35, undefined, /* () */0));
          Curry._1(stateDispatch, /* `SelectPassage */[
                303176914,
                /* Logs */Block.__(0, [passage$2.id])
              ]);
          return removeLogsStats(passage$2);
      case /* ShowInfo */3 :
          var passage$3 = param[0];
          Analytics.nav(Events_types.default_navigation(/* Passage_row_info */36, undefined, /* () */0));
          return Curry._1(stateDispatch, /* `SelectPassage */[
                      303176914,
                      /* Info */Block.__(1, [passage$3.id])
                    ]);
      case /* Clicked */4 :
          var passage$4 = param[0];
          Analytics.nav(Events_types.default_navigation(/* Passage_row */34, undefined, /* () */0));
          Curry._1(stateDispatch, /* `SelectPassage */[
                303176914,
                /* Logs */Block.__(0, [passage$4.id])
              ]);
          return removeLogsStats(passage$4);
      
    }
  };
  var addDisabled = !UserState.isLoggedIn(match.user) || Belt_Option.isNone(boat);
  var passages = Belt_Option.mapWithDefaultU(boat, /* [] */0, (function (boat) {
          return Collection.valuesToList(boat.passages);
        }));
  var passages$1 = Belt_List.mapU(passages, (function (passage) {
          var stats = State.getPassageStats(state, passage);
          var sortValue;
          if (stats !== undefined) {
            var match = stats.start_unix_timestamp;
            sortValue = match !== undefined ? match : Pervasives.max_int;
          } else {
            sortValue = Pervasives.max_int;
          }
          return /* tuple */[
                  passage,
                  stats,
                  sortValue
                ];
        }));
  var revStartDate = function (param, param$1) {
    return param$1[2] - param[2] | 0;
  };
  var passages$2 = Belt_List.sort(passages$1, revStartDate);
  var tableConfig = PassageRow.useTableConfig(/* () */0);
  var onEntryClosed = function (param) {
    if (param) {
      Curry._1(stateDispatch, /* `SelectPassage */[
            303176914,
            /* Logs */Block.__(0, [param[0]])
          ]);
      return Curry._1(setDialog, (function (param) {
                    return /* NoDialog */0;
                  }));
    } else {
      return Curry._1(setDialog, (function (param) {
                    return /* NoDialog */0;
                  }));
    }
  };
  var tmp;
  if (typeof dialog === "number") {
    tmp = null;
  } else {
    switch (dialog.tag | 0) {
      case /* Add */0 :
          tmp = React.createElement(PassageEntry.make, {
                onClose: onEntryClosed,
                mode: /* Add */Block.__(0, [dialog[0]])
              });
          break;
      case /* Edit */1 :
          tmp = React.createElement(PassageEntry.make, {
                onClose: onEntryClosed,
                mode: /* Edit */Block.__(1, [dialog[0]])
              });
          break;
      case /* Delete */2 :
          var passage = dialog[0];
          var passageName = passage.name;
          tmp = React.createElement(DeleteConfirmation.make, {
                what: "passage ".concat(passageName),
                onClose: hideDialog,
                onOk: (function (param) {
                    var passage$1 = passage;
                    Db.deletePassage(firestore, passage$1);
                    Analytics.data(Events_types.default_data(/* Passage */5, /* Deleted */6, /* Success */1, undefined, Id.firestorePath(passage$1.id), /* () */0));
                    return Curry._1(setDialog, (function (param) {
                                  return /* NoDialog */0;
                                }));
                  }),
                screenIdentifier: "Passage"
              });
          break;
      
    }
  }
  return React.createElement(Box.default, {
              className: classes.listTab,
              children: null
            }, React.createElement(Box.default, {
                  className: classes.addButton,
                  children: React.createElement(Fab.default, {
                        color: "primary",
                        size: "medium",
                        variant: "extended",
                        onClick: showAddDialog,
                        disabled: addDisabled,
                        children: null
                      }, React.createElement(Add.default, { }), " Add Passage")
                }), React.createElement(TableContainer.default, {
                  children: React.createElement(Table.default, {
                        children: null
                      }, React.createElement(TableHead.default, {
                            children: React.createElement(PassageRow.Head.make, {
                                  tableConfig: tableConfig,
                                  key: "head"
                                })
                          }), Belt_Option.mapWithDefaultU(boat, null, (function (param) {
                              return React.createElement(TableBody.default, {
                                          children: $$Array.of_list(Belt_List.mapU(passages$2, (function (param) {
                                                      var stats = param[1];
                                                      var passage = param[0];
                                                      var key = passageKey(passage, stats);
                                                      return React.createElement(PassageRow.Body.make, {
                                                                  tableConfig: tableConfig,
                                                                  passage: passage,
                                                                  onAction: onAction,
                                                                  stats: stats,
                                                                  key: key
                                                                });
                                                    })))
                                        });
                            })))
                }), boat !== undefined && !passages$2 ? React.createElement(NoDataInGridMessage.make, {
                    msg: /* NoData */Block.__(0, ["No passage recorded yet. Click 'Add Passage' to get started"])
                  }) : null, tmp);
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var B = /* alias */0;

var I = /* alias */0;

var E = /* alias */0;

var make = PassageListTab;

export {
  Mui ,
  Pa ,
  B ,
  I ,
  E ,
  concat ,
  useStyles ,
  passageKey ,
  make ,
  
}
/* useStyles Not a pure module */
