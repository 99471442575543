// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Id from "../util/Id.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$Error from "./util/Error.bs.js";
import * as Input from "./core/Input.bs.js";
import * as Notes from "./Notes.bs.js";
import * as React from "react";
import * as Ignore from "../types/Ignore.bs.js";
import * as System from "../util/System.bs.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Frequency from "../util/Frequency.bs.js";
import * as Js_option from "bs-platform/lib/es6/js_option.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as EntryDialog from "./core/EntryDialog.bs.js";
import * as Maintenance from "../util/Maintenance.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as System_types from "../types/system_types.bs.js";
import * as FrequencyEntry from "./FrequencyEntry.bs.js";
import * as SystemSelector from "./SystemSelector.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as Maintenance_types from "../types/maintenance_types.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as Add from "@material-ui/icons/Add";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";
import * as FormLabel from "@material-ui/core/FormLabel";
import * as MaintenanceItemTypeSelector from "./MaintenanceItemTypeSelector.bs.js";
import * as AddMaintenanceItemConfirmation from "./AddMaintenanceItemConfirmation.bs.js";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var defaultMaintenanceItem = Maintenance_types.default_maintenance_item(undefined, undefined, undefined, Caml_option.some(Frequency.defaultFrequency), /* Check */1, undefined, /* () */0);

function isEdit(param) {
  if (param.tag) {
    return true;
  } else {
    return false;
  }
}

function titleOfMode(param) {
  if (param.tag) {
    return EntryDialog.makeTitle(undefined, "Edit ".concat(param[1].short_description).concat(" of ").concat(param[0].identifier));
  } else {
    return EntryDialog.makeTitle(undefined, "New maintenance");
  }
}

function actionOfMode(onAction, param) {
  if (param.tag) {
    return {
            largeText: "Update maintenance",
            smallText: "Update",
            icon: undefined,
            onAction: onAction
          };
  } else {
    return {
            largeText: "Add maintenance",
            smallText: "Add",
            icon: Caml_option.some(React.createElement(Add.default, { })),
            onAction: onAction
          };
  }
}

function systemIdentifierOfMode(param) {
  if (param.tag) {
    return System.displayName(param[0]);
  } else {
    return "";
  }
}

function screenOfMode(param) {
  if (param.tag) {
    return "Edit";
  } else {
    return "Add";
  }
}

function MaintenanceItemEntry(Props) {
  var mode = Props.mode;
  var $staropt$star = Props.onClose;
  var onClose = $staropt$star !== undefined ? $staropt$star : Ignore.v1;
  AnalyticsEffect.useCurrentScreen("MaintenanceItemEntry-".concat(screenOfMode(mode)));
  var match = React.useState((function () {
          return ;
        }));
  var setSystemIdentifier = match[1];
  var systemIdentifier = match[0];
  React.useEffect((function () {
          Curry._1(setSystemIdentifier, (function (param) {
                  return ;
                }));
          return ;
        }), [mode]);
  var updateSystem = function (s) {
    return Curry._1(setSystemIdentifier, (function (param) {
                  return s;
                }));
  };
  var match$1 = React.useState((function () {
          return $$Error.noError;
        }));
  var setSystemError = match$1[1];
  var validateSelectedSystem = function (param) {
    if (mode.tag || systemIdentifier !== undefined) {
      return $$Error.noError;
    } else {
      return $$Error.make("Required");
    }
  };
  var match$2 = React.useState((function () {
          return defaultMaintenanceItem;
        }));
  var setItem = match$2[1];
  var item = match$2[0];
  React.useEffect((function () {
          if (mode.tag) {
            var item = mode[1];
            Curry._1(setItem, (function (param) {
                    return item;
                  }));
          }
          return ;
        }), [mode]);
  var updateShortDescription = function (e) {
    var short_description = e.target.value;
    return Curry._1(setItem, (function (v) {
                  return {
                          id: v.id,
                          short_description: short_description,
                          notes: v.notes,
                          frequency: v.frequency,
                          type_: v.type_,
                          logs: v.logs
                        };
                }));
  };
  var updateNotes = function (notes) {
    return Curry._1(setItem, (function (item) {
                  return {
                          id: item.id,
                          short_description: item.short_description,
                          notes: notes,
                          frequency: item.frequency,
                          type_: item.type_,
                          logs: item.logs
                        };
                }));
  };
  var updateType = function (type_) {
    return Curry._1(setItem, (function (item) {
                  return {
                          id: item.id,
                          short_description: item.short_description,
                          notes: item.notes,
                          frequency: item.frequency,
                          type_: type_,
                          logs: item.logs
                        };
                }));
  };
  var updateFrequency = function (apply) {
    return Curry._1(setItem, (function (item) {
                  var frequency = Curry._1(apply, Js_option.getWithDefault(Frequency.defaultFrequency, item.frequency));
                  return {
                          id: item.id,
                          short_description: item.short_description,
                          notes: item.notes,
                          frequency: frequency,
                          type_: item.type_,
                          logs: item.logs
                        };
                }));
  };
  var match$3 = React.useState((function () {
          return Maintenance.noError;
        }));
  var setError = match$3[1];
  var error = match$3[0];
  var match$4 = React.useState((function () {
          return /* Hidden */19559306;
        }));
  var setConfirmationPopup = match$4[1];
  var showDoneConfirmation = function (system, item, type_) {
    return Curry._1(setConfirmationPopup, (function (param) {
                  return /* `Done */[
                          759635106,
                          /* tuple */[
                            system,
                            item,
                            type_
                          ]
                        ];
                }));
  };
  var match$5 = React.useContext(AppContext.ctx);
  var addErrorAlert = match$5.addErrorAlert;
  var firestore = match$5.firestore;
  var validateAndSave = function (param) {
    var systemError = validateSelectedSystem(/* () */0);
    var itemError = Maintenance.validate(item);
    Curry._1(setSystemError, (function (param) {
            return systemError;
          }));
    Curry._1(setError, (function (param) {
            return itemError;
          }));
    if (Caml_obj.caml_equal(systemError, $$Error.noError) && Caml_obj.caml_equal(itemError, Maintenance.noError)) {
      var systemIdentifier$1 = systemIdentifier;
      var item$1 = item;
      if (mode.tag) {
        var system = mode[0];
        Curry._1(setConfirmationPopup, (function (param) {
                return /* Saving */-674467366;
              }));
        return Db.updateMaintenanceItem(firestore, item$1, (function (param) {
                      if (typeof param === "number") {
                        Analytics.data(Events_types.default_data(/* Maintenance_item */3, /* Updated */5, /* Success */1, undefined, Id.firestorePath(item$1.id), /* () */0));
                        return showDoneConfirmation(system, item$1, /* Updated */-350164453);
                      } else {
                        Analytics.except("DB Update - Maintenance Item", param[1]);
                        Analytics.data(Events_types.default_data(/* Maintenance_item */3, /* Updated */5, /* Failure */2, undefined, Id.firestorePath(item$1.id), /* () */0));
                        Curry._1(addErrorAlert, "Error updating maintenance item");
                        return Curry._1(onClose, /* NoFurtherAction */0);
                      }
                    }));
      } else {
        var match = mode[0].data.id;
        if (match !== undefined) {
          var addMaintenanceItem = function (system, firestore, item) {
            var match = system.id;
            if (match !== undefined) {
              var id = match;
              return Db.addMaintenanceItem(id, firestore, item, (function (param) {
                            if (param[0] >= 106380200) {
                              Analytics.except("DB Add - Maintenance Item", param[1]);
                              Analytics.data(Events_types.default_data(/* Maintenance_item */3, /* Added */4, /* Failure */2, undefined, id.firestore_path, /* () */0));
                              return Curry._1(addErrorAlert, "Internal Error");
                            } else {
                              var id$1 = param[1];
                              Analytics.data(Events_types.default_data(/* Maintenance_item */3, /* Added */4, /* Success */1, undefined, id$1.firestore_path, /* () */0));
                              var item_id = id$1;
                              var item_short_description = item.short_description;
                              var item_notes = item.notes;
                              var item_frequency = item.frequency;
                              var item_type_ = item.type_;
                              var item_logs = item.logs;
                              var item$1 = {
                                id: item_id,
                                short_description: item_short_description,
                                notes: item_notes,
                                frequency: item_frequency,
                                type_: item_type_,
                                logs: item_logs
                              };
                              return showDoneConfirmation(system, item$1, /* Added */795952288);
                            }
                          }));
            } else {
              throw [
                    Caml_builtin_exceptions.assert_failure,
                    /* tuple */[
                      "MaintenanceItemEntry.re",
                      188,
                      22
                    ]
                  ];
            }
          };
          if (systemIdentifier$1 !== undefined) {
            var match$1 = systemIdentifier$1;
            switch (match$1.tag | 0) {
              case /* Existing */1 :
                  Curry._1(setConfirmationPopup, (function (param) {
                          return /* Saving */-674467366;
                        }));
                  return addMaintenanceItem(match$1[0], firestore, item$1);
              case /* NewKnownCategory */0 :
              case /* NewOtherCategory */2 :
                  break;
              
            }
            var match$2 = System.categoryOfString(match$1[0]);
            var system$1 = System_types.default_system(undefined, undefined, match$2[0], match$2[1], undefined, undefined, undefined, /* () */0);
            Curry._1(setConfirmationPopup, (function (param) {
                    return /* Saving */-674467366;
                  }));
            return Db.addSystem(match, firestore, system$1, (function (param) {
                          if (param[0] >= 106380200) {
                            Analytics.except("DB Add - System", param[1]);
                            Analytics.data(Events_types.default_data(/* System */2, /* Added */4, /* Failure */2, undefined, undefined, /* () */0));
                            return Curry._1(addErrorAlert, "Internal error new part. App will reset.");
                          } else {
                            var id = param[1];
                            Analytics.data(Events_types.default_data(/* System */2, /* Added */4, /* Success */1, undefined, id.firestore_path, /* () */0));
                            var system_id = id;
                            var system_identifier = system$1.identifier;
                            var system_category = system$1.category;
                            var system_other_category = system$1.other_category;
                            var system_brand = system$1.brand;
                            var system_model = system$1.model;
                            var system_maintenance_items = system$1.maintenance_items;
                            var system$2 = {
                              id: system_id,
                              identifier: system_identifier,
                              category: system_category,
                              other_category: system_other_category,
                              brand: system_brand,
                              model: system_model,
                              maintenance_items: system_maintenance_items
                            };
                            return addMaintenanceItem(system$2, firestore, item$1);
                          }
                        }));
          } else {
            throw [
                  Caml_builtin_exceptions.assert_failure,
                  /* tuple */[
                    "MaintenanceItemEntry.re",
                    216,
                    20
                  ]
                ];
          }
        } else {
          return /* () */0;
        }
      }
    } else {
      return 0;
    }
  };
  var frequency = Js_option.getWithDefault(Frequency.defaultFrequency, item.frequency);
  var tmp;
  tmp = mode.tag ? React.createElement(Input.make, {
          label: "Part",
          value: System.displayName(mode[0]),
          disabled: true
        }) : React.createElement(SystemSelector.make, {
          boat: mode[0],
          onChange: updateSystem
        });
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(EntryDialog.make, {
                  title: titleOfMode(mode),
                  action: actionOfMode(validateAndSave, mode),
                  onClose: (function (param) {
                      return Curry._1(onClose, /* NoFurtherAction */0);
                    }),
                  children: React.createElement(Grid.default, {
                        container: true,
                        justify: "space-between",
                        alignItems: "center",
                        spacing: 2,
                        children: null
                      }, React.createElement(Grid.default, {
                            item: true,
                            xs: 4,
                            children: React.createElement(MaintenanceItemTypeSelector.make, {
                                  value: item.type_,
                                  onChange: updateType
                                })
                          }), React.createElement(Grid.default, {
                            item: true,
                            xs: 8,
                            children: React.createElement(Input.make, {
                                  error: error.shortDescriptionError,
                                  label: "Description",
                                  value: item.short_description,
                                  onChange: updateShortDescription
                                })
                          }), React.createElement(Grid.default, {
                            item: true,
                            container: true,
                            alignItems: "center",
                            xs: 12,
                            spacing: 1,
                            children: null
                          }, React.createElement(Grid.default, {
                                item: true,
                                xs: 4,
                                children: React.createElement(FormLabel.default, {
                                      children: "for"
                                    })
                              }), React.createElement(Grid.default, {
                                item: true,
                                xs: 8,
                                children: tmp
                              })), React.createElement(Grid.default, {
                            item: true,
                            xs: 12,
                            children: React.createElement(Notes.make, {
                                  value: item.notes,
                                  onChange: updateNotes
                                })
                          }), React.createElement(Grid.default, {
                            item: true,
                            xs: 12,
                            children: React.createElement(FrequencyEntry.make, {
                                  min_unit: undefined,
                                  frequency: frequency,
                                  error: error.frequencyError,
                                  on_change: updateFrequency
                                })
                          }))
                }), React.createElement(AddMaintenanceItemConfirmation.make, {
                  onClose: (function (param) {
                      return Curry._1(onClose, /* NoFurtherAction */0);
                    }),
                  mode: match$4[0],
                  onAddPreviousLog: (function (s, m) {
                      return Curry._1(onClose, /* AddPreviousLog */[
                                  s,
                                  m
                                ]);
                    })
                }));
}

var Mui = /* alias */0;

var M = /* alias */0;

var F = /* alias */0;

var S = /* alias */0;

var B = /* alias */0;

var E = /* alias */0;

var I = /* alias */0;

var noSystems = /* [] */0;

var ignoreOnAddPreviousLog = Ignore.v2;

var make = MaintenanceItemEntry;

export {
  Mui ,
  M ,
  F ,
  S ,
  B ,
  E ,
  I ,
  concat ,
  defaultMaintenanceItem ,
  isEdit ,
  titleOfMode ,
  actionOfMode ,
  noSystems ,
  systemIdentifierOfMode ,
  screenOfMode ,
  ignoreOnAddPreviousLog ,
  make ,
  
}
/* defaultMaintenanceItem Not a pure module */
