// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Passage from "../../util/Passage.bs.js";
import * as Selector from "./Selector.bs.js";

var allCardinalDirections = /* :: */[
  /* None */0,
  /* :: */[
    /* N */1,
    /* :: */[
      /* Nne */2,
      /* :: */[
        /* Ne */3,
        /* :: */[
          /* Ene */4,
          /* :: */[
            /* E */5,
            /* :: */[
              /* Ese */6,
              /* :: */[
                /* Se */7,
                /* :: */[
                  /* Sse */8,
                  /* :: */[
                    /* S */9,
                    /* :: */[
                      /* Ssw */10,
                      /* :: */[
                        /* Sw */11,
                        /* :: */[
                          /* Wsw */12,
                          /* :: */[
                            /* W */13,
                            /* :: */[
                              /* Wnw */14,
                              /* :: */[
                                /* Nw */15,
                                /* :: */[
                                  /* Nnw */16,
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

function ignoreOnChange(param) {
  return /* () */0;
}

function CardinalDirectionSelector(Props) {
  var $staropt$star = Props.value;
  var $staropt$star$1 = Props.onChange;
  var value = $staropt$star !== undefined ? $staropt$star : /* None */0;
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnChange;
  return React.createElement(Selector.make, {
              value: value,
              values: allCardinalDirections,
              toString: Passage.cardinalDirectionToString,
              onChange: onChange
            });
}

var Mui = /* alias */0;

var CD = /* alias */0;

var toString = Passage.cardinalDirectionToString;

var make = CardinalDirectionSelector;

export {
  Mui ,
  CD ,
  toString ,
  allCardinalDirections ,
  ignoreOnChange ,
  make ,
  
}
/* react Not a pure module */
