// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function toRadian(v) {
  return v * 3.1415926535897932384626433832795028841971 / 180.0;
}

function distance(p1, p2) {
  var lat2 = p2.latitude;
  var lat1 = p1.latitude;
  var phi1 = toRadian(lat1);
  var phi2 = toRadian(lat2);
  var deltaPhi = toRadian(lat2 - lat1);
  var deltaLambda = toRadian(p2.longitude - p1.longitude);
  var a = Math.sin(deltaPhi / 2.0) * Math.sin(deltaPhi / 2.0) + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2.0) * Math.sin(deltaLambda / 2.0);
  var c = 2.0 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return 6371000.0 * c;
}

var Pa = /* alias */0;

var pi = 3.1415926535897932384626433832795028841971;

var earthRadius = 6371000.0;

export {
  Pa ,
  pi ,
  toRadian ,
  earthRadius ,
  distance ,
  
}
/* No side effect */
