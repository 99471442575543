// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function toString(param) {
  switch (param) {
    case /* Nautical_miles */0 :
        return "nm";
    case /* Meters */1 :
        return "m";
    case /* Kilometers */2 :
        return "km";
    case /* Miles */3 :
        return "mi";
    
  }
}

function convertFrom(value, param) {
  switch (param) {
    case /* Nautical_miles */0 :
        return value / 0.000539957;
    case /* Meters */1 :
        return value;
    case /* Kilometers */2 :
        return value * 1000.0;
    case /* Miles */3 :
        return value / 0.00062137141841645;
    
  }
}

function convertTo(value, param) {
  switch (param) {
    case /* Nautical_miles */0 :
        return value * 0.000539957;
    case /* Meters */1 :
        return value;
    case /* Kilometers */2 :
        return value / 1000.0;
    case /* Miles */3 :
        return value * 0.00062137141841645;
    
  }
}

function digits(param) {
  if (param !== 1) {
    return 2;
  } else {
    return 0;
  }
}

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function toDisplayString(value, displayUnit) {
  var displayUnit$1 = Belt_Option.getWithDefault(displayUnit, /* Nautical_miles */0);
  var digits$1 = digits(displayUnit$1);
  var value$1 = convertTo(value, displayUnit$1);
  return value$1.toFixed(digits$1).concat(" ").concat(toString(displayUnit$1));
}

var DU = /* alias */0;

var allDistanceUnit = /* :: */[
  /* Meters */1,
  /* :: */[
    /* Kilometers */2,
    /* :: */[
      /* Nautical_miles */0,
      /* :: */[
        /* Miles */3,
        /* [] */0
      ]
    ]
  ]
];

var $$default = /* Nautical_miles */0;

export {
  DU ,
  toString ,
  convertFrom ,
  convertTo ,
  digits ,
  allDistanceUnit ,
  $$default ,
  $$default as default,
  concat ,
  toDisplayString ,
  
}
/* No side effect */
