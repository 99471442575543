// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Passage_bs from "../types/passage_bs.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Passage_types from "../types/passage_types.bs.js";

function make(param) {
  return Passage_types.default_passage_log(undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);
}

function decode(data) {
  var logEntry = Passage_bs.decode_passage_log(data);
  var match = logEntry.type_;
  if (match !== undefined) {
    var match$1 = match;
    if (match$1.tag) {
      return logEntry;
    } else {
      var positionData = match$1[0];
      var remarks = positionData.remarks;
      if (remarks === "") {
        return logEntry;
      } else {
        var positionData_leg = positionData.leg;
        var positionData_sog = positionData.sog;
        var positionData_cog = positionData.cog;
        var positionData_distance = positionData.distance;
        var positionData_chart_course = positionData.chart_course;
        var positionData_wind_speed = positionData.wind_speed;
        var positionData_wind_direction = positionData.wind_direction;
        var positionData_sea_state_description = positionData.sea_state_description;
        var positionData_barometric_pressure = positionData.barometric_pressure;
        var positionData_air_temperature = positionData.air_temperature;
        var positionData_sea_temperature = positionData.sea_temperature;
        var positionData_sky = positionData.sky;
        var positionData$1 = {
          leg: positionData_leg,
          sog: positionData_sog,
          cog: positionData_cog,
          distance: positionData_distance,
          chart_course: positionData_chart_course,
          wind_speed: positionData_wind_speed,
          wind_direction: positionData_wind_direction,
          sea_state_description: positionData_sea_state_description,
          barometric_pressure: positionData_barometric_pressure,
          air_temperature: positionData_air_temperature,
          sea_temperature: positionData_sea_temperature,
          sky: positionData_sky,
          remarks: ""
        };
        return {
                id: logEntry.id,
                unix_timestamp: logEntry.unix_timestamp,
                geo_location: logEntry.geo_location,
                location_name: logEntry.location_name,
                remarks: remarks,
                type_: /* Position_data */Block.__(0, [positionData$1])
              };
      }
    }
  } else {
    return logEntry;
  }
}

function id(p) {
  return Belt_Option.getExn(p.id);
}

function withId(p, id) {
  return {
          id: id,
          unix_timestamp: p.unix_timestamp,
          geo_location: p.geo_location,
          location_name: p.location_name,
          remarks: p.remarks,
          type_: p.type_
        };
}

function prepareForDb(p) {
  return {
          id: undefined,
          unix_timestamp: p.unix_timestamp,
          geo_location: p.geo_location,
          location_name: p.location_name,
          remarks: p.remarks,
          type_: p.type_
        };
}

var encode = Passage_bs.encode_passage_log;

export {
  make ,
  encode ,
  decode ,
  id ,
  withId ,
  prepareForDb ,
  
}
/* Passage_bs Not a pure module */
