// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as P from "../util/P.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_HashMapString from "bs-platform/lib/es6/belt_HashMapString.js";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

var sep = "/";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var asyncExceptionHandler = {
  contents: (function (param, param$1) {
      return /* () */0;
    })
};

function logError(error, path) {
  console.log("Error for path: (".concat(path).concat(") msg: ").concat(error.message));
  return /* () */0;
}

function updateDocRef(ref, data, onDone) {
  try {
    P.mapCatch((function (e) {
            return Curry._1(onDone, /* `Error */[
                        106380200,
                        e
                      ]);
          }), P.mapThen((function (param) {
                return Curry._1(onDone, /* Success */94326179);
              }), ref.set(data)));
    return /* () */0;
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn[0] === Js_exn.$$Error) {
      return Curry._1(onDone, /* `Error */[
                  106380200,
                  exn[1]
                ]);
    } else {
      throw exn;
    }
  }
}

function updateDocRefWithPromise(ref, data, onDone) {
  try {
    return P.mapCatch((function (e) {
                  return Curry._1(onDone, /* `Error */[
                              106380200,
                              e
                            ]);
                }), P.mapThen((function (param) {
                      return Curry._1(onDone, /* Success */94326179);
                    }), ref.set(data)));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn[0] === Js_exn.$$Error) {
      return P.resolve(Curry._1(onDone, /* `Error */[
                      106380200,
                      exn[1]
                    ]));
    } else {
      throw exn;
    }
  }
}

function addToCollectionRef(reference, data, onDone) {
  P.mapCatch((function (e) {
          return Curry._1(onDone, /* `Error */[
                      106380200,
                      e
                    ]);
        }), P.mapThen((function (docRef) {
              var firestore_id = docRef.id;
              var firestore_path = docRef.path;
              return Curry._1(onDone, /* `Success */[
                          94326179,
                          {
                            firestore_id: firestore_id,
                            firestore_path: firestore_path
                          }
                        ]);
            }), reference.add(data)));
  return /* () */0;
}

var subscriptions = Belt_HashMapString.make(128);

function addSubscriptions(id, f) {
  var key = id.firestore_id;
  var match = Belt_HashMapString.get(subscriptions, key);
  if (match !== undefined) {
    return Belt_HashMapString.set(subscriptions, key, /* :: */[
                f,
                match
              ]);
  } else {
    return Belt_HashMapString.set(subscriptions, key, /* :: */[
                f,
                /* [] */0
              ]);
  }
}

function unSubscribeToAll(param) {
  Belt_HashMapString.forEach(subscriptions, (function (param, fs) {
          return Belt_List.forEach(fs, (function (f) {
                        return Curry._1(f, /* () */0);
                      }));
        }));
  return Belt_HashMapString.clear(subscriptions);
}

function unSubcribeId(id) {
  var key = id.firestore_id;
  var match = Belt_HashMapString.get(subscriptions, key);
  if (match !== undefined) {
    Belt_List.forEachU(match, (function (f) {
            return Curry._1(f, /* () */0);
          }));
    return Belt_HashMapString.remove(subscriptions, key);
  } else {
    return /* () */0;
  }
}

function MakeDb(T) {
  var toDbData = function (firestore, v) {
    var id = Curry._1(T.id, v);
    var ref = firestore.doc(id.firestore_path);
    var data = Curry._1(T.encode, Curry._1(T.prepareForDb, v));
    return /* tuple */[
            ref,
            data
          ];
  };
  var update = function (firestore, v, onDone) {
    var match = toDbData(firestore, v);
    return updateDocRef(match[0], match[1], onDone);
  };
  var updateP = function (firestore, v, onDone) {
    var match = toDbData(firestore, v);
    return updateDocRefWithPromise(match[0], match[1], onDone);
  };
  var add = function (firestore, parentId, collectionName, v, onDone) {
    try {
      var systemsRef = firestore.collection(parentId.firestore_path.concat(sep).concat(collectionName));
      var data = Curry._1(T.encode, Curry._1(T.prepareForDb, v));
      return addToCollectionRef(systemsRef, data, onDone);
    }
    catch (raw_exn){
      var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
      if (exn[0] === Js_exn.$$Error) {
        return Curry._1(onDone, /* `Error */[
                    106380200,
                    exn[1]
                  ]);
      } else {
        throw exn;
      }
    }
  };
  var $$delete = function (firestore, v) {
    var id = Curry._1(T.id, v);
    unSubcribeId(id);
    var ref = firestore.doc(id.firestore_path);
    ref.delete();
    return /* () */0;
  };
  var protoOfChange = function (change) {
    var doc = change.doc;
    var ref = doc.ref;
    var data = doc.data();
    var id_firestore_id = ref.id;
    var id_firestore_path = ref.path;
    var id = {
      firestore_id: id_firestore_id,
      firestore_path: id_firestore_path
    };
    return Curry._2(T.withId, Curry._1(T.decode, data), id);
  };
  var loadSubCollection = function (firestore, parentId, collectionName, onChange, stateDispatch) {
    var subPath = parentId.firestore_path.concat(sep).concat(collectionName);
    Curry._1(stateDispatch, /* `AddIncompleteData */[
          -213167927,
          subPath
        ]);
    var onNext = function (querySnapshot) {
      var changes = querySnapshot.docChanges();
      var allAdded = {
        contents: /* [] */0
      };
      Belt_Array.forEachU(changes, (function (change) {
              try {
                var v = protoOfChange(change);
                var match = change.type;
                switch (match) {
                  case "added" :
                      allAdded.contents = /* :: */[
                        v,
                        allAdded.contents
                      ];
                      return /* () */0;
                  case "modified" :
                      return Curry._1(onChange, /* `Modified */[
                                  -260466839,
                                  v
                                ]);
                  case "removed" :
                      return Curry._1(onChange, /* `Removed */[
                                  -1068415104,
                                  v
                                ]);
                  default:
                    throw [
                          Caml_builtin_exceptions.assert_failure,
                          /* tuple */[
                            "DbUtil.re",
                            181,
                            19
                          ]
                        ];
                }
              }
              catch (raw_e){
                var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                return Curry._2(asyncExceptionHandler.contents, e, collectionName);
              }
            }));
      Curry._1(stateDispatch, /* `MarkAsComplete */[
            253468248,
            subPath
          ]);
      Curry._1(onChange, /* `Added */[
            795952288,
            allAdded.contents
          ]);
      return Curry._1(onChange, /* Done */759635106);
    };
    var onError = function (error) {
      logError(error, subPath);
      return Curry._1(stateDispatch, /* `MarkAsComplete */[
                  253468248,
                  subPath
                ]);
    };
    var ref = firestore.collection(subPath);
    var unSubscribe = ref.onSnapshot(onNext, onError);
    return addSubscriptions(parentId, unSubscribe);
  };
  return {
          toDbData: toDbData,
          update: update,
          updateP: updateP,
          add: add,
          $$delete: $$delete,
          protoOfChange: protoOfChange,
          loadSubCollection: loadSubCollection
        };
}

var Firestore = /* alias */0;

var Pr = /* alias */0;

var Subscriptions = /* alias */0;

export {
  Firestore ,
  Pr ,
  sep ,
  concat ,
  asyncExceptionHandler ,
  logError ,
  updateDocRef ,
  updateDocRefWithPromise ,
  addToCollectionRef ,
  Subscriptions ,
  subscriptions ,
  addSubscriptions ,
  unSubscribeToAll ,
  unSubcribeId ,
  MakeDb ,
  
}
/* subscriptions Not a pure module */
