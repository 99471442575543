// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as P from "../util/P.bs.js";
import * as BoatT from "../util/BoatT.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as UserT from "../util/UserT.bs.js";
import * as DbUtil from "./DbUtil.bs.js";
import * as Passage from "../util/Passage.bs.js";
import * as SystemT from "../util/SystemT.bs.js";
import * as User_bs from "../types/user_bs.bs.js";
import * as PassageT from "../util/PassageT.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Collection from "../util/Collection.bs.js";
import * as User_types from "../types/user_types.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as PassageLogT from "../util/PassageLogT.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as Belt_SetString from "bs-platform/lib/es6/belt_SetString.js";
import * as MaintenanceLogT from "../util/MaintenanceLogT.bs.js";
import * as MaintenanceItemT from "../util/MaintenanceItemT.bs.js";
import * as Belt_HashSetString from "bs-platform/lib/es6/belt_HashSetString.js";

var sep = "/";

var usersCol = "users";

var boatsCol = "boats";

var systemsCol = "systems";

var maintenanceItemsCol = "maintenance_items";

var maintenanceItemLogsCol = "maintenance_item_logs";

var passagesCol = "passages";

var passageLogsCol = "passage_logs";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var allSubscriptions = {
  contents: /* [] */0
};

var passageSubscribed = {
  contents: Belt_HashSetString.make(128)
};

function unSubscribeToAll(param) {
  DbUtil.unSubscribeToAll(/* () */0);
  passageSubscribed.contents = Belt_HashSetString.make(128);
  return /* () */0;
}

function toProtoString(nullableString) {
  return Belt_Option.getWithDefault(nullableString === null ? undefined : Caml_option.some(nullableString), "");
}

function user(authUser, firestore) {
  var uid = authUser.uid;
  var userPath = usersCol.concat(sep).concat(uid);
  var userRef = firestore.doc(userPath);
  var id = {
    firestore_id: uid,
    firestore_path: userPath
  };
  var updateUser = function (user) {
    var nullableString = authUser.displayName;
    var nullableString$1 = authUser.email;
    return {
            id: id,
            display_name: Belt_Option.getWithDefault(nullableString === null ? undefined : Caml_option.some(nullableString), ""),
            email: Belt_Option.getWithDefault(nullableString$1 === null ? undefined : Caml_option.some(nullableString$1), ""),
            provider_id: user.provider_id,
            distance_unit: user.distance_unit,
            speed_unit: user.speed_unit,
            pressure_unit: user.pressure_unit,
            temperature_unit: user.temperature_unit,
            is_anonymous: authUser.isAnonymous,
            unix_timestamp: UnixTimestamp.now(/* () */0)
          };
  };
  var saveUser = function (user) {
    return userRef.set(User_bs.encode_user({
                    id: undefined,
                    display_name: user.display_name,
                    email: user.email,
                    provider_id: user.provider_id,
                    distance_unit: user.distance_unit,
                    speed_unit: user.speed_unit,
                    pressure_unit: user.pressure_unit,
                    temperature_unit: user.temperature_unit,
                    is_anonymous: user.is_anonymous,
                    unix_timestamp: user.unix_timestamp
                  }));
  };
  return P.mapThen((function (userSnapshot) {
                if (userSnapshot.exists) {
                  var user = User_bs.decode_user(userSnapshot.data());
                  var user$1 = updateUser(user);
                  saveUser(user$1);
                  return user$1;
                } else {
                  var user$2 = updateUser(User_types.default_user(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0));
                  saveUser(user$2);
                  return user$2;
                }
              }), userRef.get());
}

var UserDb = DbUtil.MakeDb(UserT);

function updateUser(firestore, user, onDone) {
  return Curry._3(UserDb.update, firestore, user, onDone);
}

var PassageDb = DbUtil.MakeDb(PassageT);

function addPassage(boatId, firestore, passage, onDone) {
  return Curry._5(PassageDb.add, firestore, boatId, passagesCol, passage, onDone);
}

function updatePassage(firestore, passage, onDone) {
  return Curry._3(PassageDb.update, firestore, passage, onDone);
}

function updatePassageWithPromise(firestore, passage, onDone) {
  return Curry._3(PassageDb.updateP, firestore, passage, onDone);
}

var PassageLogDb = DbUtil.MakeDb(PassageLogT);

function addPassageLog(passageId, firestore, passage, passageLog, onDone) {
  Curry._5(PassageLogDb.add, firestore, passageId, passageLogsCol, passageLog, onDone);
  return Curry._3(PassageDb.update, firestore, {
              id: passage.id,
              name: passage.name,
              from: passage.from,
              to_: passage.to_,
              notes: passage.notes,
              crew: passage.crew,
              logs: passage.logs,
              fields: passage.fields,
              retroactive_data: passage.retroactive_data,
              logs_stats: undefined
            }, (function (param) {
                return /* () */0;
              }));
}

function updatePassageLog(firestore, passage, passageLog, onDone) {
  Curry._3(PassageLogDb.update, firestore, passageLog, onDone);
  return Curry._3(PassageDb.update, firestore, {
              id: passage.id,
              name: passage.name,
              from: passage.from,
              to_: passage.to_,
              notes: passage.notes,
              crew: passage.crew,
              logs: passage.logs,
              fields: passage.fields,
              retroactive_data: passage.retroactive_data,
              logs_stats: undefined
            }, (function (param) {
                return /* () */0;
              }));
}

function deletePassageLog(firestore, passageLog) {
  return Curry._2(PassageLogDb.$$delete, firestore, passageLog);
}

function loadPassageLogs(boatId, passageId, firestore, stateDispatch) {
  var onChange = function (param) {
    if (typeof param === "number") {
      return Curry._1(stateDispatch, /* `ComputePassageStats */[
                  -375268184,
                  /* tuple */[
                    boatId,
                    passageId
                  ]
                ]);
    } else {
      var variant = param[0];
      if (variant !== -260466839) {
        if (variant >= 795952288) {
          return Curry._1(stateDispatch, /* `AddPassageLogs */[
                      1010560668,
                      /* tuple */[
                        boatId,
                        passageId,
                        param[1]
                      ]
                    ]);
        } else {
          return Curry._1(stateDispatch, /* `RemovePassageLog */[
                      459221530,
                      /* tuple */[
                        boatId,
                        passageId,
                        param[1]
                      ]
                    ]);
        }
      } else {
        return Curry._1(stateDispatch, /* `UpdatePassageLogDetails */[
                    978680419,
                    /* tuple */[
                      boatId,
                      passageId,
                      param[1]
                    ]
                  ]);
      }
    }
  };
  Curry._5(PassageLogDb.loadSubCollection, firestore, passageId, passageLogsCol, onChange, stateDispatch);
  return /* () */0;
}

function loadPassages(boatId, firestore, stateDispatch) {
  var loadPassageLogs$1 = function (passage, updateState) {
    var id = Belt_Option.getExn(passage.id);
    var match = Passage.passageType(passage);
    if (match) {
      var key = id.firestore_id;
      var alreadyRegistered = Belt_HashSetString.has(passageSubscribed.contents, key);
      var match$1 = passage.logs_stats;
      if (match$1 !== undefined) {
        if (alreadyRegistered) {
          return Curry._1(updateState, passage);
        } else {
          Curry._1(updateState, passage);
          return Curry._1(stateDispatch, /* `ComputePassageStats */[
                      -375268184,
                      /* tuple */[
                        boatId,
                        id
                      ]
                    ]);
        }
      } else {
        if (!alreadyRegistered) {
          Belt_HashSetString.add(passageSubscribed.contents, key);
          loadPassageLogs(boatId, id, firestore, stateDispatch);
        }
        return Curry._1(updateState, passage);
      }
    } else {
      Curry._1(updateState, passage);
      return Curry._1(stateDispatch, /* `ComputePassageStats */[
                  -375268184,
                  /* tuple */[
                    boatId,
                    id
                  ]
                ]);
    }
  };
  var onChange = function (param) {
    if (typeof param === "number") {
      return /* () */0;
    } else {
      var variant = param[0];
      if (variant !== -260466839) {
        if (variant >= 795952288) {
          return Belt_List.forEachU(param[1], (function (passage) {
                        return loadPassageLogs$1(passage, (function (passage) {
                                      return Curry._1(stateDispatch, /* `AddPassageToBoat */[
                                                  -798156920,
                                                  /* tuple */[
                                                    boatId,
                                                    passage
                                                  ]
                                                ]);
                                    }));
                      }));
        } else {
          return Curry._1(stateDispatch, /* `RemovePassage */[
                      504288394,
                      /* tuple */[
                        boatId,
                        param[1]
                      ]
                    ]);
        }
      } else {
        return loadPassageLogs$1(param[1], (function (passage) {
                      return Curry._1(stateDispatch, /* `UpdatePassageDetails */[
                                  26332637,
                                  /* tuple */[
                                    boatId,
                                    passage
                                  ]
                                ]);
                    }));
      }
    }
  };
  Curry._5(PassageDb.loadSubCollection, firestore, boatId, passagesCol, onChange, stateDispatch);
  return /* () */0;
}

function deletePassage(firestore, passage) {
  var logs = passage.logs;
  Curry._2(PassageDb.$$delete, firestore, passage);
  return Belt_List.forEachU(logs, (function (log) {
                return Curry._2(PassageLogDb.$$delete, firestore, log);
              }));
}

var MaintenanceLogDb = DbUtil.MakeDb(MaintenanceLogT);

function updateMaintenanceLog(firestore, item, onDone) {
  return Curry._3(MaintenanceLogDb.update, firestore, item, onDone);
}

function addMaintenanceLog(itemId, firestore, log, onDone) {
  return Curry._5(MaintenanceLogDb.add, firestore, itemId, maintenanceItemLogsCol, log, onDone);
}

function loadMaintenanceLogs(boatId, systemId, maintenanceId, firestore, stateDispatch) {
  var onChange = function (param) {
    if (typeof param === "number") {
      return /* () */0;
    } else {
      var variant = param[0];
      if (variant !== -260466839) {
        if (variant >= 795952288) {
          return Belt_List.forEachU(param[1], (function (log) {
                        return Curry._1(stateDispatch, /* `AddMaintenanceLogToBoat */[
                                    212346573,
                                    /* tuple */[
                                      boatId,
                                      systemId,
                                      maintenanceId,
                                      log
                                    ]
                                  ]);
                      }));
        } else {
          return Curry._1(stateDispatch, /* `RemoveMaintenanceLog */[
                      -1065311307,
                      /* tuple */[
                        boatId,
                        systemId,
                        maintenanceId,
                        param[1]
                      ]
                    ]);
        }
      } else {
        return Curry._1(stateDispatch, /* `UpdateMaintenanceLog */[
                    -838517702,
                    /* tuple */[
                      boatId,
                      systemId,
                      maintenanceId,
                      param[1]
                    ]
                  ]);
      }
    }
  };
  Curry._5(MaintenanceLogDb.loadSubCollection, firestore, maintenanceId, maintenanceItemLogsCol, onChange, stateDispatch);
  return /* () */0;
}

var deleteMaintenanceLog = MaintenanceLogDb.$$delete;

var MaintenanceItemDb = DbUtil.MakeDb(MaintenanceItemT);

function addMaintenanceItem(systemId, firestore, item, onDone) {
  return Curry._5(MaintenanceItemDb.add, firestore, systemId, maintenanceItemsCol, item, onDone);
}

function updateMaintenanceItem(firestore, item, onDone) {
  return Curry._3(MaintenanceItemDb.update, firestore, item, onDone);
}

function loadMaintenanceItems(boatId, systemId, firestore, stateDispatch) {
  var onChange = function (param) {
    if (typeof param === "number") {
      return /* () */0;
    } else {
      var variant = param[0];
      if (variant !== -260466839) {
        if (variant >= 795952288) {
          return Belt_List.forEachU(param[1], (function (maintenance_item) {
                        Curry._1(stateDispatch, /* `AddMaintenanceItemToBoat */[
                              20347072,
                              /* tuple */[
                                boatId,
                                systemId,
                                maintenance_item
                              ]
                            ]);
                        var id = Belt_Option.getExn(maintenance_item.id);
                        return loadMaintenanceLogs(boatId, systemId, id, firestore, stateDispatch);
                      }));
        } else {
          return Curry._1(stateDispatch, /* `RemoveMaintenanceItem */[
                      773243074,
                      /* tuple */[
                        boatId,
                        systemId,
                        param[1]
                      ]
                    ]);
        }
      } else {
        return Curry._1(stateDispatch, /* `UpdateMaintenanceItem */[
                    -191390563,
                    /* tuple */[
                      boatId,
                      systemId,
                      param[1]
                    ]
                  ]);
      }
    }
  };
  Curry._5(MaintenanceItemDb.loadSubCollection, firestore, systemId, maintenanceItemsCol, onChange, stateDispatch);
  return /* () */0;
}

function deleteMaintenanceItem(firestore, item) {
  var logs = item.logs;
  Curry._2(MaintenanceItemDb.$$delete, firestore, item);
  return Belt_List.forEach(logs, Curry._1(deleteMaintenanceLog, firestore));
}

var SystemDb = DbUtil.MakeDb(SystemT);

function addSystem(boatId, firestore, system, onDone) {
  return Curry._5(SystemDb.add, firestore, boatId, systemsCol, system, onDone);
}

function updateSystem(firestore, system, onDone) {
  return Curry._3(SystemDb.update, firestore, system, onDone);
}

function loadSystems(boatId, firestore, stateDispatch) {
  var onChange = function (param) {
    if (typeof param === "number") {
      return /* () */0;
    } else {
      var variant = param[0];
      if (variant !== -260466839) {
        if (variant >= 795952288) {
          return Belt_List.forEachU(param[1], (function (system) {
                        Curry._1(stateDispatch, /* `AddSystemToBoat */[
                              -1060782325,
                              /* tuple */[
                                boatId,
                                system
                              ]
                            ]);
                        var id = Belt_Option.getExn(system.id);
                        return loadMaintenanceItems(boatId, id, firestore, stateDispatch);
                      }));
        } else {
          return Curry._1(stateDispatch, /* `RemoveSystem */[
                      706903667,
                      /* tuple */[
                        boatId,
                        param[1]
                      ]
                    ]);
        }
      } else {
        return Curry._1(stateDispatch, /* `UpdateSystemDetails */[
                    -320788054,
                    /* tuple */[
                      boatId,
                      param[1]
                    ]
                  ]);
      }
    }
  };
  Curry._5(SystemDb.loadSubCollection, firestore, boatId, systemsCol, onChange, stateDispatch);
  return /* () */0;
}

function deleteSystem(firestore, system) {
  var items = system.maintenance_items;
  Curry._2(SystemDb.$$delete, firestore, system);
  return Belt_List.forEach(items, (function (param) {
                return deleteMaintenanceItem(firestore, param);
              }));
}

var BoatDb = DbUtil.MakeDb(BoatT);

function addBoat(userId, firestore, boat, onDone) {
  return Curry._5(BoatDb.add, firestore, userId, boatsCol, boat, onDone);
}

function updateBoat(firestore, boat, onDone) {
  return Curry._3(BoatDb.update, firestore, boat, onDone);
}

function loadBoats(userId, firestore, stateDispatch) {
  Curry._1(stateDispatch, /* RemoveAllBoats */612177942);
  var whiteList = process.env.NODE_ENV === "development" ? Belt_SetString.fromArray(["Test"]) : Belt_SetString.empty;
  var isEmpty = Belt_SetString.isEmpty(whiteList);
  var onChange = function (param) {
    if (typeof param === "number") {
      return /* () */0;
    } else {
      var variant = param[0];
      if (variant !== -260466839) {
        if (variant >= 795952288) {
          return Belt_List.forEachU(param[1], (function (boat) {
                        var id = Belt_Option.getExn(boat.id);
                        var match = Belt_SetString.has(whiteList, boat.name);
                        if (!isEmpty && !match) {
                          return /* () */0;
                        }
                        Curry._1(stateDispatch, /* `AddBoat */[
                              -894836063,
                              boat
                            ]);
                        loadSystems(id, firestore, stateDispatch);
                        return loadPassages(id, firestore, stateDispatch);
                      }));
        } else {
          return Curry._1(stateDispatch, /* `RemoveBoat */[
                      887862916,
                      Belt_Option.getExn(param[1].id)
                    ]);
        }
      } else {
        return Curry._1(stateDispatch, /* `UpdateBoatDetails */[
                    207288569,
                    param[1]
                  ]);
      }
    }
  };
  Curry._5(BoatDb.loadSubCollection, firestore, userId, boatsCol, onChange, stateDispatch);
  return /* () */0;
}

function deleteBoat(firestore, boat) {
  var systems = boat.systems;
  var passages = boat.passages;
  Curry._2(BoatDb.$$delete, firestore, boat.data);
  Collection.forEachValues(systems, (function (s) {
          return deleteSystem(firestore, s);
        }));
  return Collection.forEachValues(passages, (function (p) {
                return deletePassage(firestore, p);
              }));
}

var Firestore = /* alias */0;

var Auth = /* alias */0;

var User = /* alias */0;

var U = /* alias */0;

var B = /* alias */0;

var S = /* alias */0;

var M = /* alias */0;

var Pa = /* alias */0;

var Pr = /* alias */0;

var I = /* alias */0;

var Util = /* alias */0;

export {
  Firestore ,
  Auth ,
  User ,
  U ,
  B ,
  S ,
  M ,
  Pa ,
  Pr ,
  I ,
  Util ,
  sep ,
  usersCol ,
  boatsCol ,
  systemsCol ,
  maintenanceItemsCol ,
  maintenanceItemLogsCol ,
  passagesCol ,
  passageLogsCol ,
  concat ,
  allSubscriptions ,
  passageSubscribed ,
  unSubscribeToAll ,
  toProtoString ,
  user ,
  UserDb ,
  updateUser ,
  PassageDb ,
  addPassage ,
  updatePassage ,
  updatePassageWithPromise ,
  PassageLogDb ,
  addPassageLog ,
  updatePassageLog ,
  deletePassageLog ,
  loadPassageLogs ,
  loadPassages ,
  deletePassage ,
  MaintenanceLogDb ,
  updateMaintenanceLog ,
  addMaintenanceLog ,
  loadMaintenanceLogs ,
  deleteMaintenanceLog ,
  MaintenanceItemDb ,
  addMaintenanceItem ,
  updateMaintenanceItem ,
  loadMaintenanceItems ,
  deleteMaintenanceItem ,
  SystemDb ,
  addSystem ,
  updateSystem ,
  loadSystems ,
  deleteSystem ,
  BoatDb ,
  addBoat ,
  updateBoat ,
  loadBoats ,
  deleteBoat ,
  
}
/* passageSubscribed Not a pure module */
