// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Id from "../util/Id.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Input from "./core/Input.bs.js";
import * as Notes from "./Notes.bs.js";
import * as React from "react";
import * as Passage from "../util/Passage.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as SpeedEntry from "./core/SpeedEntry.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as EntryDialog from "./core/EntryDialog.bs.js";
import * as NumberInput from "./core/NumberInput.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as DistanceEntry from "./core/DistanceEntry.bs.js";
import * as Passage_types from "../types/passage_types.bs.js";
import * as PressureEntry from "./core/PressureEntry.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as DatetimePickers from "./core/DatetimePickers.bs.js";
import * as GeoLocationEntry from "./GeoLocationEntry.bs.js";
import * as TemperatureEntry from "./core/TemperatureEntry.bs.js";
import * as WindDirectionEntry from "./WindDirectionEntry.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";
import * as Typography from "@material-ui/core/Typography";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var useStyles = MakeStyles.default({
      firstFieldGroupTitle: {
        marginBottom: "15px"
      },
      secondFieldGroupTitle: {
        marginBottom: "15px",
        marginTop: "15px"
      }
    });

function updatePositionData(setLogEntry, f) {
  return Curry._1(setLogEntry, (function (logEntry) {
                var match = logEntry.type_;
                if (match !== undefined) {
                  var match$1 = match;
                  if (match$1.tag) {
                    throw [
                          Caml_builtin_exceptions.assert_failure,
                          /* tuple */[
                            "PassagePositionLogEntry.re",
                            25,
                            13
                          ]
                        ];
                  } else {
                    var positionData = f(match$1[0]);
                    return {
                            id: logEntry.id,
                            unix_timestamp: logEntry.unix_timestamp,
                            geo_location: logEntry.geo_location,
                            location_name: logEntry.location_name,
                            remarks: logEntry.remarks,
                            type_: /* Position_data */Block.__(0, [positionData])
                          };
                  }
                } else {
                  throw [
                        Caml_builtin_exceptions.assert_failure,
                        /* tuple */[
                          "PassagePositionLogEntry.re",
                          25,
                          13
                        ]
                      ];
                }
              }));
}

function actionOfMode(onAction, param) {
  if (param.tag) {
    return EntryDialog.makeUpdate(onAction, "log");
  } else {
    return EntryDialog.makeAdd(onAction, "log");
  }
}

function defaultLeg(param) {
  if (param !== undefined) {
    return param.current_leg;
  } else {
    return 0;
  }
}

function logEntryOfMode(passageStats, param) {
  if (param.tag) {
    return param[1];
  } else {
    return Passage_types.default_passage_log(undefined, UnixTimestamp.now(/* () */0), undefined, undefined, undefined, Caml_option.some(/* Position_data */Block.__(0, [Passage_types.default_log_position_data(defaultLeg(passageStats), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0)])), /* () */0);
  }
}

function screenOfMode(param) {
  if (param.tag) {
    return "Edit";
  } else {
    return "Add";
  }
}

function ignoreOnClose(param) {
  return /* () */0;
}

function PassagePositionLogEntry(Props) {
  var mode = Props.mode;
  var isFieldEnabled = Props.isFieldEnabled;
  var isAtLeastOneFieldEnabled = Props.isAtLeastOneFieldEnabled;
  var $staropt$star = Props.onClose;
  var $staropt$star$1 = Props.passageStats;
  var onClose = $staropt$star !== undefined ? $staropt$star : ignoreOnClose;
  var passageStats = $staropt$star$1 !== undefined ? Caml_option.valFromOption($staropt$star$1) : undefined;
  var classes = useStyles();
  AnalyticsEffect.useCurrentScreen("PassagePositionLogEntry-".concat(screenOfMode(mode)));
  var match = React.useState((function () {
          return logEntryOfMode(passageStats, mode);
        }));
  var setLogEntry = match[1];
  var logEntry = match[0];
  React.useEffect((function (param) {
          Curry._1(setLogEntry, (function (param) {
                  return logEntryOfMode(passageStats, mode);
                }));
          return ;
        }), /* tuple */[
        mode,
        setLogEntry,
        passageStats
      ]);
  var match$1 = logEntry.type_;
  var positionData;
  if (match$1 !== undefined) {
    var match$2 = match$1;
    if (match$2.tag) {
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "PassagePositionLogEntry.re",
              83,
              11
            ]
          ];
    } else {
      positionData = match$2[0];
    }
  } else {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "PassagePositionLogEntry.re",
            83,
            11
          ]
        ];
  }
  var updateUnixTimestamp = function (unix_timestamp) {
    return Curry._1(setLogEntry, (function (l) {
                  return {
                          id: l.id,
                          unix_timestamp: unix_timestamp,
                          geo_location: l.geo_location,
                          location_name: l.location_name,
                          remarks: l.remarks,
                          type_: l.type_
                        };
                }));
  };
  var updateGeoLocation = function (geo_location) {
    return Curry._1(setLogEntry, (function (l) {
                  return {
                          id: l.id,
                          unix_timestamp: l.unix_timestamp,
                          geo_location: geo_location,
                          location_name: l.location_name,
                          remarks: l.remarks,
                          type_: l.type_
                        };
                }));
  };
  var updateLocationName = function (e) {
    var location_name = e.target.value;
    return Curry._1(setLogEntry, (function (l) {
                  return {
                          id: l.id,
                          unix_timestamp: l.unix_timestamp,
                          geo_location: l.geo_location,
                          location_name: location_name,
                          remarks: l.remarks,
                          type_: l.type_
                        };
                }));
  };
  var updateLeg = function (leg) {
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: leg,
                          sog: positionData.sog,
                          cog: positionData.cog,
                          distance: positionData.distance,
                          chart_course: positionData.chart_course,
                          wind_speed: positionData.wind_speed,
                          wind_direction: positionData.wind_direction,
                          sea_state_description: positionData.sea_state_description,
                          barometric_pressure: positionData.barometric_pressure,
                          air_temperature: positionData.air_temperature,
                          sea_temperature: positionData.sea_temperature,
                          sky: positionData.sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateDistance = function (distance) {
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: positionData.leg,
                          sog: positionData.sog,
                          cog: positionData.cog,
                          distance: distance,
                          chart_course: positionData.chart_course,
                          wind_speed: positionData.wind_speed,
                          wind_direction: positionData.wind_direction,
                          sea_state_description: positionData.sea_state_description,
                          barometric_pressure: positionData.barometric_pressure,
                          air_temperature: positionData.air_temperature,
                          sea_temperature: positionData.sea_temperature,
                          sky: positionData.sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateSog = function (sog) {
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: positionData.leg,
                          sog: sog,
                          cog: positionData.cog,
                          distance: positionData.distance,
                          chart_course: positionData.chart_course,
                          wind_speed: positionData.wind_speed,
                          wind_direction: positionData.wind_direction,
                          sea_state_description: positionData.sea_state_description,
                          barometric_pressure: positionData.barometric_pressure,
                          air_temperature: positionData.air_temperature,
                          sea_temperature: positionData.sea_temperature,
                          sky: positionData.sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateCog = function (cog) {
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: positionData.leg,
                          sog: positionData.sog,
                          cog: cog,
                          distance: positionData.distance,
                          chart_course: positionData.chart_course,
                          wind_speed: positionData.wind_speed,
                          wind_direction: positionData.wind_direction,
                          sea_state_description: positionData.sea_state_description,
                          barometric_pressure: positionData.barometric_pressure,
                          air_temperature: positionData.air_temperature,
                          sea_temperature: positionData.sea_temperature,
                          sky: positionData.sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateChartCourse = function (chart_course) {
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: positionData.leg,
                          sog: positionData.sog,
                          cog: positionData.cog,
                          distance: positionData.distance,
                          chart_course: chart_course,
                          wind_speed: positionData.wind_speed,
                          wind_direction: positionData.wind_direction,
                          sea_state_description: positionData.sea_state_description,
                          barometric_pressure: positionData.barometric_pressure,
                          air_temperature: positionData.air_temperature,
                          sea_temperature: positionData.sea_temperature,
                          sky: positionData.sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateWindSpeed = function (wind_speed) {
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: positionData.leg,
                          sog: positionData.sog,
                          cog: positionData.cog,
                          distance: positionData.distance,
                          chart_course: positionData.chart_course,
                          wind_speed: wind_speed,
                          wind_direction: positionData.wind_direction,
                          sea_state_description: positionData.sea_state_description,
                          barometric_pressure: positionData.barometric_pressure,
                          air_temperature: positionData.air_temperature,
                          sea_temperature: positionData.sea_temperature,
                          sky: positionData.sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateWindDirection = function (wind_direction) {
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: positionData.leg,
                          sog: positionData.sog,
                          cog: positionData.cog,
                          distance: positionData.distance,
                          chart_course: positionData.chart_course,
                          wind_speed: positionData.wind_speed,
                          wind_direction: wind_direction,
                          sea_state_description: positionData.sea_state_description,
                          barometric_pressure: positionData.barometric_pressure,
                          air_temperature: positionData.air_temperature,
                          sea_temperature: positionData.sea_temperature,
                          sky: positionData.sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateSeaStateDescription = function (e) {
    var sea_state_description = e.target.value;
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: positionData.leg,
                          sog: positionData.sog,
                          cog: positionData.cog,
                          distance: positionData.distance,
                          chart_course: positionData.chart_course,
                          wind_speed: positionData.wind_speed,
                          wind_direction: positionData.wind_direction,
                          sea_state_description: sea_state_description,
                          barometric_pressure: positionData.barometric_pressure,
                          air_temperature: positionData.air_temperature,
                          sea_temperature: positionData.sea_temperature,
                          sky: positionData.sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateBarometricPressure = function (barometric_pressure) {
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: positionData.leg,
                          sog: positionData.sog,
                          cog: positionData.cog,
                          distance: positionData.distance,
                          chart_course: positionData.chart_course,
                          wind_speed: positionData.wind_speed,
                          wind_direction: positionData.wind_direction,
                          sea_state_description: positionData.sea_state_description,
                          barometric_pressure: barometric_pressure,
                          air_temperature: positionData.air_temperature,
                          sea_temperature: positionData.sea_temperature,
                          sky: positionData.sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateAirTemperature = function (air_temperature) {
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: positionData.leg,
                          sog: positionData.sog,
                          cog: positionData.cog,
                          distance: positionData.distance,
                          chart_course: positionData.chart_course,
                          wind_speed: positionData.wind_speed,
                          wind_direction: positionData.wind_direction,
                          sea_state_description: positionData.sea_state_description,
                          barometric_pressure: positionData.barometric_pressure,
                          air_temperature: air_temperature,
                          sea_temperature: positionData.sea_temperature,
                          sky: positionData.sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateSeaTemperature = function (sea_temperature) {
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: positionData.leg,
                          sog: positionData.sog,
                          cog: positionData.cog,
                          distance: positionData.distance,
                          chart_course: positionData.chart_course,
                          wind_speed: positionData.wind_speed,
                          wind_direction: positionData.wind_direction,
                          sea_state_description: positionData.sea_state_description,
                          barometric_pressure: positionData.barometric_pressure,
                          air_temperature: positionData.air_temperature,
                          sea_temperature: sea_temperature,
                          sky: positionData.sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateSky = function (e) {
    var sky = e.target.value;
    return updatePositionData(setLogEntry, (function (positionData) {
                  return {
                          leg: positionData.leg,
                          sog: positionData.sog,
                          cog: positionData.cog,
                          distance: positionData.distance,
                          chart_course: positionData.chart_course,
                          wind_speed: positionData.wind_speed,
                          wind_direction: positionData.wind_direction,
                          sea_state_description: positionData.sea_state_description,
                          barometric_pressure: positionData.barometric_pressure,
                          air_temperature: positionData.air_temperature,
                          sea_temperature: positionData.sea_temperature,
                          sky: sky,
                          remarks: positionData.remarks
                        };
                }));
  };
  var updateRemarks = function (remarks) {
    return Curry._1(setLogEntry, (function (logEntry) {
                  return {
                          id: logEntry.id,
                          unix_timestamp: logEntry.unix_timestamp,
                          geo_location: logEntry.geo_location,
                          location_name: logEntry.location_name,
                          remarks: remarks,
                          type_: logEntry.type_
                        };
                }));
  };
  var match$3 = React.useContext(AppContext.ctx);
  var addErrorAlert = match$3.addErrorAlert;
  var firestore = match$3.firestore;
  var savePassageLog = function (param) {
    var logData = function (action, status, firestore_path) {
      return Analytics.data(Events_types.default_data(/* Passage_log */6, action, status, /* Position_data */1, firestore_path, /* () */0));
    };
    if (mode.tag) {
      Db.updatePassageLog(firestore, mode[0], logEntry, (function (param) {
              if (typeof param === "number") {
                return logData(/* Updated */5, /* Success */1, Id.firestorePath(logEntry.id));
              } else {
                Analytics.except("DB Edit - Passage Position Log", param[1]);
                logData(/* Updated */5, /* Failure */2, Id.firestorePath(logEntry.id));
                return Curry._1(addErrorAlert, "Error saving position");
              }
            }));
    } else {
      var passage = mode[0];
      var match = passage.id;
      if (match !== undefined) {
        Db.addPassageLog(match, firestore, passage, logEntry, (function (param) {
                if (param[0] >= 106380200) {
                  Analytics.except("DB Add - Passage Position Log", param[1]);
                  logData(/* Added */4, /* Failure */2, "");
                  return Curry._1(addErrorAlert, "Internal error saving passage log (app will reset)");
                } else {
                  return logData(/* Added */4, /* Success */1, param[1].firestore_path);
                }
              }));
      }
      
    }
    return Curry._1(onClose, /* () */0);
  };
  var title;
  title = mode.tag ? EntryDialog.makeTitle("Passage Log", "Edit Passage log") : EntryDialog.makeTitle("Passage Log", "Add Passage Log");
  return React.createElement(EntryDialog.make, {
              title: title,
              action: actionOfMode(savePassageLog, mode),
              onClose: onClose,
              children: React.createElement(Grid.default, {
                    container: true,
                    alignItems: "center",
                    spacing: 2,
                    children: null
                  }, React.createElement(Grid.default, {
                        item: true,
                        md: 6,
                        sm: 12,
                        children: React.createElement(DatetimePickers.make, {
                              unixTimestamp: logEntry.unix_timestamp,
                              onChange: updateUnixTimestamp
                            })
                      }), isAtLeastOneFieldEnabled(Passage.navigationFields) ? React.createElement(Grid.default, {
                          className: classes.firstFieldGroupTitle,
                          item: true,
                          xs: 12,
                          children: React.createElement(Typography.default, {
                                color: "textPrimary",
                                variant: "subtitle1",
                                children: "Navigation Fields"
                              })
                        }) : null, isFieldEnabled(/* Leg */0) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(NumberInput.Int.make, {
                                value: positionData.leg,
                                onChange: updateLeg,
                                label: "Leg #"
                              })
                        }) : null, isFieldEnabled(/* Geo_location */13) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(GeoLocationEntry.make, {
                                position: logEntry.geo_location,
                                onChange: updateGeoLocation
                              })
                        }) : null, isFieldEnabled(/* Location_name */14) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(Input.make, {
                                label: "Location Name",
                                value: logEntry.location_name,
                                onChange: updateLocationName
                              })
                        }) : null, isFieldEnabled(/* Distance */3) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(DistanceEntry.make, {
                                value: positionData.distance,
                                onChange: updateDistance
                              })
                        }) : null, isFieldEnabled(/* Sog */1) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(SpeedEntry.make, {
                                value: positionData.sog,
                                onChange: updateSog,
                                label: "Speed Over Ground"
                              })
                        }) : null, isFieldEnabled(/* Cog */2) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(NumberInput.NullableFloat.make, {
                                value: positionData.cog,
                                onChange: updateCog,
                                label: "Course Over Ground"
                              })
                        }) : null, isFieldEnabled(/* Chart_course */4) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(NumberInput.NullableFloat.make, {
                                value: positionData.chart_course,
                                onChange: updateChartCourse,
                                label: "Chart Course"
                              })
                        }) : null, isAtLeastOneFieldEnabled(Passage.weatherFields) ? React.createElement(Grid.default, {
                          className: classes.secondFieldGroupTitle,
                          item: true,
                          xs: 12,
                          children: React.createElement(Typography.default, {
                                color: "textPrimary",
                                variant: "subtitle1",
                                children: "Weather Fields"
                              })
                        }) : null, isFieldEnabled(/* Wind_speed */5) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(SpeedEntry.make, {
                                value: positionData.wind_speed,
                                onChange: updateWindSpeed,
                                label: "Wind Speed"
                              })
                        }) : null, isFieldEnabled(/* Wind_speed */5) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(WindDirectionEntry.make, {
                                value: positionData.wind_direction,
                                onChange: updateWindDirection
                              })
                        }) : null, isFieldEnabled(/* Sea_state_description */7) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(Input.make, {
                                label: "Sea State (description)",
                                value: positionData.sea_state_description,
                                onChange: updateSeaStateDescription
                              })
                        }) : null, isFieldEnabled(/* Barometric_pressure */8) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(PressureEntry.make, {
                                value: positionData.barometric_pressure,
                                onChange: updateBarometricPressure,
                                label: "Barometric Pressure"
                              })
                        }) : null, isFieldEnabled(/* Air_temperature */9) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(TemperatureEntry.make, {
                                value: positionData.air_temperature,
                                onChange: updateAirTemperature,
                                label: "Air Temperature"
                              })
                        }) : null, isFieldEnabled(/* Sea_temperature */11) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(TemperatureEntry.make, {
                                value: positionData.sea_temperature,
                                onChange: updateSeaTemperature,
                                label: "Sea Temperature"
                              })
                        }) : null, isFieldEnabled(/* Sky */10) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(Input.make, {
                                label: "Sky (description)",
                                value: positionData.sky,
                                onChange: updateSky
                              })
                        }) : null, isAtLeastOneFieldEnabled(Passage.otherFields) ? React.createElement(Grid.default, {
                          className: classes.secondFieldGroupTitle,
                          item: true,
                          xs: 12,
                          children: React.createElement(Typography.default, {
                                color: "textPrimary",
                                variant: "subtitle1",
                                children: "Other Fields"
                              })
                        }) : null, isFieldEnabled(/* Remarks */12) ? React.createElement(Grid.default, {
                          item: true,
                          xs: 12,
                          children: React.createElement(Notes.make, {
                                value: logEntry.remarks,
                                onChange: updateRemarks,
                                label: "Remarks"
                              })
                        }) : null)
            });
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var I = /* alias */0;

var E = /* alias */0;

var defaultPassageStats = undefined;

var make = PassagePositionLogEntry;

export {
  Mui ,
  Pa ,
  I ,
  E ,
  concat ,
  useStyles ,
  updatePositionData ,
  actionOfMode ,
  defaultLeg ,
  logEntryOfMode ,
  screenOfMode ,
  ignoreOnClose ,
  defaultPassageStats ,
  make ,
  
}
/* useStyles Not a pure module */
