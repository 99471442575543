// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Selector from "./core/Selector.bs.js";
import * as Frequency from "../util/Frequency.bs.js";

function ignoreOnChange(param) {
  return /* () */0;
}

function PeriodUnitSelector(Props) {
  var min = Props.min;
  var value = Props.value;
  var $staropt$star = Props.onChange;
  var onChange = $staropt$star !== undefined ? $staropt$star : ignoreOnChange;
  var values = Frequency.allPeriodUnit(min, /* () */0);
  return React.createElement(Selector.make, {
              value: value,
              values: values,
              toString: Frequency.stringOfPeriodUnit,
              onChange: onChange
            });
}

var make = PeriodUnitSelector;

export {
  ignoreOnChange ,
  make ,
  
}
/* react Not a pure module */
