// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function default_maintenance_item_type(param) {
  return /* Other */0;
}

function default_maintenance_item_log_value(param) {
  return /* Check_value */Block.__(0, [false]);
}

function default_maintenance_item_log($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, $staropt$star$4, $staropt$star$5, param) {
  var id = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var unix_timestamp = $staropt$star$1 !== undefined ? $staropt$star$1 : 0;
  var notes = $staropt$star$2 !== undefined ? $staropt$star$2 : "";
  var time_taken = $staropt$star$3 !== undefined ? Caml_option.valFromOption($staropt$star$3) : undefined;
  var cost = $staropt$star$4 !== undefined ? $staropt$star$4 : 0;
  var value = $staropt$star$5 !== undefined ? Caml_option.valFromOption($staropt$star$5) : undefined;
  return {
          id: id,
          unix_timestamp: unix_timestamp,
          notes: notes,
          time_taken: time_taken,
          cost: cost,
          value: value
        };
}

function default_maintenance_item($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, $staropt$star$4, $staropt$star$5, param) {
  var id = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var short_description = $staropt$star$1 !== undefined ? $staropt$star$1 : "";
  var notes = $staropt$star$2 !== undefined ? $staropt$star$2 : "";
  var frequency = $staropt$star$3 !== undefined ? Caml_option.valFromOption($staropt$star$3) : undefined;
  var type_ = $staropt$star$4 !== undefined ? $staropt$star$4 : /* Other */0;
  var logs = $staropt$star$5 !== undefined ? $staropt$star$5 : /* [] */0;
  return {
          id: id,
          short_description: short_description,
          notes: notes,
          frequency: frequency,
          type_: type_,
          logs: logs
        };
}

export {
  default_maintenance_item_type ,
  default_maintenance_item_log_value ,
  default_maintenance_item_log ,
  default_maintenance_item ,
  
}
/* No side effect */
