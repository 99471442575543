// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function isLoggedIn(param) {
  if (typeof param === "number") {
    return false;
  } else {
    return true;
  }
}

function isLoggedInAnonymous(param) {
  if (typeof param === "number" || !param[0].is_anonymous) {
    return false;
  } else {
    return true;
  }
}

function mapWhenLoggedIn(v, f) {
  if (typeof v === "number") {
    return ;
  } else {
    return Caml_option.some(f(v[0]));
  }
}

export {
  isLoggedIn ,
  isLoggedInAnonymous ,
  mapWhenLoggedIn ,
  
}
/* No side effect */
