// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Id_bs from "./id_bs.bs.js";
import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";
import * as System_types from "./system_types.bs.js";
import * as Maintenance_bs from "./maintenance_bs.bs.js";

function default_system_mutable(param) {
  return {
          id: undefined,
          identifier: "",
          category: System_types.default_category(/* () */0),
          other_category: "",
          brand: "",
          model: "",
          maintenance_items: /* [] */0
        };
}

function decode_category(json) {
  var match = Pbrt_bs.string(json, "category", "value");
  switch (match) {
    case "AIR_CONDITIONERS" :
        return /* Air_conditioners */93;
    case "AIS" :
        return /* Ais */39;
    case "ALTERNATORS" :
        return /* Alternators */26;
    case "ANCHORS" :
        return /* Anchors */1;
    case "ANCHOR_LINES" :
        return /* Anchor_lines */2;
    case "ANCHOR_MOUNTS" :
        return /* Anchor_mounts */7;
    case "ANCHOR_ROLLERS" :
        return /* Anchor_rollers */6;
    case "ANODES" :
        return /* Anodes */65;
    case "AUTOPILOTS" :
        return /* Autopilots */36;
    case "BACKSTAYS" :
        return /* Backstays */73;
    case "BAROMETERS" :
        return /* Barometers */51;
    case "BATTERIES" :
        return /* Batteries */27;
    case "BILGE_PUMPS" :
        return /* Bilge_pumps */78;
    case "BIMINIS" :
        return /* Biminis */21;
    case "BLOCKS" :
        return /* Blocks */111;
    case "BOARDING_LADDERS" :
        return /* Boarding_ladders */14;
    case "BOAT_COVERS" :
        return /* Boat_covers */22;
    case "BOAT_HOOK" :
        return /* Boat_hook */10;
    case "BOW_STERN_THRUSTERS" :
        return /* Bow_stern_thrusters */11;
    case "CABINETS" :
        return /* Cabinets */67;
    case "CHAINPLATES" :
        return /* Chainplates */75;
    case "CHAINS" :
        return /* Chains */3;
    case "CHARGERS" :
        return /* Chargers */28;
    case "CLEATS" :
        return /* Cleats */66;
    case "COCKPIT" :
        return /* Cockpit */63;
    case "COCKPIT_LOCKERS" :
        return /* Cockpit_lockers */76;
    case "COCKPIT_SHOWERS" :
        return /* Cockpit_showers */60;
    case "COCKPIT_TABLES" :
        return /* Cockpit_tables */59;
    case "COMPASSES" :
        return /* Compasses */48;
    case "COOLERS" :
        return /* Coolers */50;
    case "DECK" :
        return /* Deck */62;
    case "DECK_FILLS" :
        return /* Deck_fills */81;
    case "DECK_LIGHTS" :
        return /* Deck_lights */31;
    case "DEHUMIDIFIERS" :
        return /* Dehumidifiers */95;
    case "DEPTH_INSTRUMENTS" :
        return /* Depth_instruments */40;
    case "DIESEL_ENGINE" :
        return /* Diesel_engine */116;
    case "DISTRIBUTION_PANELS" :
        return /* Distribution_panels */30;
    case "DOCK_LINES" :
        return /* Dock_lines */8;
    case "DRYERS" :
        return /* Dryers */58;
    case "ELECTRICAL_SYSTEMS" :
        return /* Electrical_systems */29;
    case "ELECTRIC_OUTBOARDS" :
        return /* Electric_outboards */16;
    case "EPIRBS" :
        return /* Epirbs */104;
    case "FAUCETS" :
        return /* Faucets */85;
    case "FENDERS" :
        return /* Fenders */9;
    case "FIRST_AID_KITS" :
        return /* First_aid_kits */106;
    case "FISHFINDERS" :
        return /* Fishfinders */44;
    case "FLARES" :
        return /* Flares */102;
    case "FREEZERS" :
        return /* Freezers */56;
    case "FRESHWATER_PUMPS" :
        return /* Freshwater_pumps */79;
    case "FRIDGES" :
        return /* Fridges */52;
    case "FURLERS" :
        return /* Furlers */115;
    case "GALLEY" :
        return /* Galley */54;
    case "GAS_OUTBOARD" :
        return /* Gas_outboard */118;
    case "GRILLS" :
        return /* Grills */49;
    case "HATCHES" :
        return /* Hatches */96;
    case "HEADS" :
        return /* Heads */91;
    case "HEADSTAYS" :
        return /* Headstays */74;
    case "HEATERS" :
        return /* Heaters */94;
    case "HOLDING_TANKS" :
        return /* Holding_tanks */92;
    case "HULL" :
        return /* Hull */61;
    case "INTERIOR_LIGHTING" :
        return /* Interior_lighting */32;
    case "INVERTERS" :
        return /* Inverters */23;
    case "KNOTMETER_INSTRUMENTS" :
        return /* Knotmeter_instruments */41;
    case "LIFELINES" :
        return /* Lifelines */110;
    case "LIFE_JACKETS" :
        return /* Life_jackets */100;
    case "LIFE_RAFTS" :
        return /* Life_rafts */105;
    case "MACERATOR_PUMPS" :
        return /* Macerator_pumps */80;
    case "MARINE_SPEAKERS" :
        return /* Marine_speakers */47;
    case "MARINE_STEREOS" :
        return /* Marine_stereos */46;
    case "MAST" :
        return /* Mast */70;
    case "MOORING_LINES" :
        return /* Mooring_lines */12;
    case "MOORING_PENDANTS" :
        return /* Mooring_pendants */13;
    case "MULTI_FUNCTION_DISPLAYS" :
        return /* Multi_function_displays */34;
    case "NAVIGATION_LIGHTS" :
        return /* Navigation_lights */33;
    case "" :
    case "NONE" :
        return /* None */0;
    case "OTHER" :
        return /* Other */119;
    case "PLUMBING_FITTINGS" :
        return /* Plumbing_fittings */82;
    case "PORTLIGHTS" :
        return /* Portlights */97;
    case "PROPANE_OUTBOARDS" :
        return /* Propane_outboards */17;
    case "PROPANE_SYSTEM" :
        return /* Propane_system */55;
    case "PROPELLERS" :
        return /* Propellers */19;
    case "PROPELLER_SHAFTS" :
        return /* Propeller_shafts */64;
    case "RADARS" :
        return /* Radars */35;
    case "RAILS" :
        return /* Rails */68;
    case "RESCUE_SLINGS" :
        return /* Rescue_slings */101;
    case "RUB_RAILS" :
        return /* Rub_rails */69;
    case "RUNNING_RIGGING" :
        return /* Running_rigging */107;
    case "SAFETY_LIGHTS" :
        return /* Safety_lights */103;
    case "SAIL_RIGGING" :
        return /* Sail_rigging */109;
    case "SALTWATER_PUMPS" :
        return /* Saltwater_pumps */99;
    case "SHACKLES" :
        return /* Shackles */4;
    case "SHOWERS" :
        return /* Showers */87;
    case "SHROUDS" :
        return /* Shrouds */72;
    case "SINKS" :
        return /* Sinks */88;
    case "SOLAR_PANELS" :
        return /* Solar_panels */24;
    case "SPINNAKER_POLES" :
        return /* Spinnaker_poles */114;
    case "SPREADERS" :
        return /* Spreaders */71;
    case "STANDING_RIGGING" :
        return /* Standing_rigging */108;
    case "STEERING_SYSTEMS" :
        return /* Steering_systems */20;
    case "STERN_PLATFORMS" :
        return /* Stern_platforms */15;
    case "STOVES" :
        return /* Stoves */53;
    case "STRAINERS" :
        return /* Strainers */83;
    case "TEAK_ACCENT" :
        return /* Teak_accent */77;
    case "TRANSDUCERS" :
        return /* Transducers */45;
    case "TROLLING_MOTORS" :
        return /* Trolling_motors */18;
    case "VALVES" :
        return /* Valves */84;
    case "VANGS" :
        return /* Vangs */112;
    case "VENTS" :
        return /* Vents */98;
    case "VHF" :
        return /* Vhf */37;
    case "VHF_ANTENNAS" :
        return /* Vhf_antennas */38;
    case "WASHERS" :
        return /* Washers */57;
    case "WATER_FILTERS" :
        return /* Water_filters */86;
    case "WATER_HEATERS" :
        return /* Water_heaters */89;
    case "WATER_TANKS" :
        return /* Water_tanks */90;
    case "WEATHER_INSTRUMENTS" :
        return /* Weather_instruments */42;
    case "WHISKER_POLES" :
        return /* Whisker_poles */113;
    case "WINCHES" :
        return /* Winches */117;
    case "WINDLASS" :
        return /* Windlass */5;
    case "WIND_GENERATORS" :
        return /* Wind_generators */25;
    case "WIND_INSTRUMENTS" :
        return /* Wind_instruments */43;
    default:
      return Pbrt_bs.E.malformed_variant("category");
  }
}

function decode_system(json) {
  var v = default_system_mutable(/* () */0);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "brand" :
          var json$1 = json["brand"];
          v.brand = Pbrt_bs.string(json$1, "system", "brand");
          break;
      case "category" :
          var json$2 = json["category"];
          v.category = decode_category(json$2);
          break;
      case "id" :
          var json$3 = json["id"];
          v.id = Id_bs.decode_id(Pbrt_bs.object_(json$3, "system", "id"));
          break;
      case "identifier" :
          var json$4 = json["identifier"];
          v.identifier = Pbrt_bs.string(json$4, "system", "identifier");
          break;
      case "maintenanceItems" :
          var a = json["maintenanceItems"];
          var a$1 = Pbrt_bs.array_(a, "system", "maintenance_items");
          v.maintenance_items = $$Array.to_list($$Array.map((function (json) {
                      return Maintenance_bs.decode_maintenance_item(Pbrt_bs.object_(json, "system", "maintenance_items"));
                    }), a$1));
          break;
      case "model" :
          var json$5 = json["model"];
          v.model = Pbrt_bs.string(json$5, "system", "model");
          break;
      case "otherCategory" :
          var json$6 = json["otherCategory"];
          v.other_category = Pbrt_bs.string(json$6, "system", "other_category");
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          identifier: v.identifier,
          category: v.category,
          other_category: v.other_category,
          brand: v.brand,
          model: v.model,
          maintenance_items: v.maintenance_items
        };
}

function encode_category(v) {
  switch (v) {
    case /* None */0 :
        return "NONE";
    case /* Anchors */1 :
        return "ANCHORS";
    case /* Anchor_lines */2 :
        return "ANCHOR_LINES";
    case /* Chains */3 :
        return "CHAINS";
    case /* Shackles */4 :
        return "SHACKLES";
    case /* Windlass */5 :
        return "WINDLASS";
    case /* Anchor_rollers */6 :
        return "ANCHOR_ROLLERS";
    case /* Anchor_mounts */7 :
        return "ANCHOR_MOUNTS";
    case /* Dock_lines */8 :
        return "DOCK_LINES";
    case /* Fenders */9 :
        return "FENDERS";
    case /* Boat_hook */10 :
        return "BOAT_HOOK";
    case /* Bow_stern_thrusters */11 :
        return "BOW_STERN_THRUSTERS";
    case /* Mooring_lines */12 :
        return "MOORING_LINES";
    case /* Mooring_pendants */13 :
        return "MOORING_PENDANTS";
    case /* Boarding_ladders */14 :
        return "BOARDING_LADDERS";
    case /* Stern_platforms */15 :
        return "STERN_PLATFORMS";
    case /* Electric_outboards */16 :
        return "ELECTRIC_OUTBOARDS";
    case /* Propane_outboards */17 :
        return "PROPANE_OUTBOARDS";
    case /* Trolling_motors */18 :
        return "TROLLING_MOTORS";
    case /* Propellers */19 :
        return "PROPELLERS";
    case /* Steering_systems */20 :
        return "STEERING_SYSTEMS";
    case /* Biminis */21 :
        return "BIMINIS";
    case /* Boat_covers */22 :
        return "BOAT_COVERS";
    case /* Inverters */23 :
        return "INVERTERS";
    case /* Solar_panels */24 :
        return "SOLAR_PANELS";
    case /* Wind_generators */25 :
        return "WIND_GENERATORS";
    case /* Alternators */26 :
        return "ALTERNATORS";
    case /* Batteries */27 :
        return "BATTERIES";
    case /* Chargers */28 :
        return "CHARGERS";
    case /* Electrical_systems */29 :
        return "ELECTRICAL_SYSTEMS";
    case /* Distribution_panels */30 :
        return "DISTRIBUTION_PANELS";
    case /* Deck_lights */31 :
        return "DECK_LIGHTS";
    case /* Interior_lighting */32 :
        return "INTERIOR_LIGHTING";
    case /* Navigation_lights */33 :
        return "NAVIGATION_LIGHTS";
    case /* Multi_function_displays */34 :
        return "MULTI_FUNCTION_DISPLAYS";
    case /* Radars */35 :
        return "RADARS";
    case /* Autopilots */36 :
        return "AUTOPILOTS";
    case /* Vhf */37 :
        return "VHF";
    case /* Vhf_antennas */38 :
        return "VHF_ANTENNAS";
    case /* Ais */39 :
        return "AIS";
    case /* Depth_instruments */40 :
        return "DEPTH_INSTRUMENTS";
    case /* Knotmeter_instruments */41 :
        return "KNOTMETER_INSTRUMENTS";
    case /* Weather_instruments */42 :
        return "WEATHER_INSTRUMENTS";
    case /* Wind_instruments */43 :
        return "WIND_INSTRUMENTS";
    case /* Fishfinders */44 :
        return "FISHFINDERS";
    case /* Transducers */45 :
        return "TRANSDUCERS";
    case /* Marine_stereos */46 :
        return "MARINE_STEREOS";
    case /* Marine_speakers */47 :
        return "MARINE_SPEAKERS";
    case /* Compasses */48 :
        return "COMPASSES";
    case /* Grills */49 :
        return "GRILLS";
    case /* Coolers */50 :
        return "COOLERS";
    case /* Barometers */51 :
        return "BAROMETERS";
    case /* Fridges */52 :
        return "FRIDGES";
    case /* Stoves */53 :
        return "STOVES";
    case /* Galley */54 :
        return "GALLEY";
    case /* Propane_system */55 :
        return "PROPANE_SYSTEM";
    case /* Freezers */56 :
        return "FREEZERS";
    case /* Washers */57 :
        return "WASHERS";
    case /* Dryers */58 :
        return "DRYERS";
    case /* Cockpit_tables */59 :
        return "COCKPIT_TABLES";
    case /* Cockpit_showers */60 :
        return "COCKPIT_SHOWERS";
    case /* Hull */61 :
        return "HULL";
    case /* Deck */62 :
        return "DECK";
    case /* Cockpit */63 :
        return "COCKPIT";
    case /* Propeller_shafts */64 :
        return "PROPELLER_SHAFTS";
    case /* Anodes */65 :
        return "ANODES";
    case /* Cleats */66 :
        return "CLEATS";
    case /* Cabinets */67 :
        return "CABINETS";
    case /* Rails */68 :
        return "RAILS";
    case /* Rub_rails */69 :
        return "RUB_RAILS";
    case /* Mast */70 :
        return "MAST";
    case /* Spreaders */71 :
        return "SPREADERS";
    case /* Shrouds */72 :
        return "SHROUDS";
    case /* Backstays */73 :
        return "BACKSTAYS";
    case /* Headstays */74 :
        return "HEADSTAYS";
    case /* Chainplates */75 :
        return "CHAINPLATES";
    case /* Cockpit_lockers */76 :
        return "COCKPIT_LOCKERS";
    case /* Teak_accent */77 :
        return "TEAK_ACCENT";
    case /* Bilge_pumps */78 :
        return "BILGE_PUMPS";
    case /* Freshwater_pumps */79 :
        return "FRESHWATER_PUMPS";
    case /* Macerator_pumps */80 :
        return "MACERATOR_PUMPS";
    case /* Deck_fills */81 :
        return "DECK_FILLS";
    case /* Plumbing_fittings */82 :
        return "PLUMBING_FITTINGS";
    case /* Strainers */83 :
        return "STRAINERS";
    case /* Valves */84 :
        return "VALVES";
    case /* Faucets */85 :
        return "FAUCETS";
    case /* Water_filters */86 :
        return "WATER_FILTERS";
    case /* Showers */87 :
        return "SHOWERS";
    case /* Sinks */88 :
        return "SINKS";
    case /* Water_heaters */89 :
        return "WATER_HEATERS";
    case /* Water_tanks */90 :
        return "WATER_TANKS";
    case /* Heads */91 :
        return "HEADS";
    case /* Holding_tanks */92 :
        return "HOLDING_TANKS";
    case /* Air_conditioners */93 :
        return "AIR_CONDITIONERS";
    case /* Heaters */94 :
        return "HEATERS";
    case /* Dehumidifiers */95 :
        return "DEHUMIDIFIERS";
    case /* Hatches */96 :
        return "HATCHES";
    case /* Portlights */97 :
        return "PORTLIGHTS";
    case /* Vents */98 :
        return "VENTS";
    case /* Saltwater_pumps */99 :
        return "SALTWATER_PUMPS";
    case /* Life_jackets */100 :
        return "LIFE_JACKETS";
    case /* Rescue_slings */101 :
        return "RESCUE_SLINGS";
    case /* Flares */102 :
        return "FLARES";
    case /* Safety_lights */103 :
        return "SAFETY_LIGHTS";
    case /* Epirbs */104 :
        return "EPIRBS";
    case /* Life_rafts */105 :
        return "LIFE_RAFTS";
    case /* First_aid_kits */106 :
        return "FIRST_AID_KITS";
    case /* Running_rigging */107 :
        return "RUNNING_RIGGING";
    case /* Standing_rigging */108 :
        return "STANDING_RIGGING";
    case /* Sail_rigging */109 :
        return "SAIL_RIGGING";
    case /* Lifelines */110 :
        return "LIFELINES";
    case /* Blocks */111 :
        return "BLOCKS";
    case /* Vangs */112 :
        return "VANGS";
    case /* Whisker_poles */113 :
        return "WHISKER_POLES";
    case /* Spinnaker_poles */114 :
        return "SPINNAKER_POLES";
    case /* Furlers */115 :
        return "FURLERS";
    case /* Diesel_engine */116 :
        return "DIESEL_ENGINE";
    case /* Winches */117 :
        return "WINCHES";
    case /* Gas_outboard */118 :
        return "GAS_OUTBOARD";
    case /* Other */119 :
        return "OTHER";
    
  }
}

function encode_system(v) {
  var json = { };
  var match = v.id;
  if (match !== undefined) {
    var json$prime = Id_bs.encode_id(match);
    json["id"] = json$prime;
  }
  json["identifier"] = v.identifier;
  json["category"] = encode_category(v.category);
  json["otherCategory"] = v.other_category;
  json["brand"] = v.brand;
  json["model"] = v.model;
  var __x__ = v.maintenance_items;
  if (__x__) {
    var maintenance_items$prime = $$Array.map(Maintenance_bs.encode_maintenance_item, $$Array.of_list(__x__));
    json["maintenanceItems"] = maintenance_items$prime;
  }
  return json;
}

export {
  encode_category ,
  encode_system ,
  decode_category ,
  decode_system ,
  
}
/* Id_bs Not a pure module */
