// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as SvgIcon from "@material-ui/core/SvgIcon";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      icon: (function (props) {
          return {
                  minHeight: props.width,
                  minWidth: props.height
                };
        })
    });

function LogoIcon(Props) {
  var width = Props.width;
  var height = Props.height;
  var color = Props.color;
  var classes = useStyles({
        width: width,
        height: height
      });
  var tmp = {
    className: classes.icon,
    viewBox: "0 0 100 100",
    children: React.createElement("path", {
          d: "M50,5C25.186,5,5,25.186,5,50s20.186,45,45,45s45-20.187,45-45S74.813,5,50,5z M27.5,50C27.5,37.593,37.593,27.5,50,27.5  C62.407,27.5,72.5,37.593,72.5,50c0,12.407-10.093,22.5-22.5,22.5C37.593,72.5,27.5,62.407,27.5,50z M73.055,60.824  C74.606,57.533,75.5,53.873,75.5,50c0-3.684-0.802-7.179-2.214-10.344l15.795-5.008C90.958,39.406,92,44.582,92,50  c0,5.604-1.112,10.951-3.113,15.844L73.055,60.824z M65.044,10.797l-5,15.772C56.959,25.241,53.565,24.5,50,24.5  c-3.981,0-7.737,0.943-11.098,2.577l-5.027-15.854C38.843,9.15,44.289,8,50,8C55.3,8,60.37,8.997,65.044,10.797z M10.783,34.99  l15.769,5C25.234,43.065,24.5,46.448,24.5,50c0,3.741,0.83,7.286,2.284,10.489l-15.807,5.012C9.064,60.701,8,55.474,8,50  C8,44.712,8.992,39.655,10.783,34.99z M34.191,88.901l5.019-15.83C42.492,74.613,46.14,75.5,50,75.5c3.445,0,6.729-0.695,9.73-1.939  l4.999,15.766C60.142,91.051,55.181,92,50,92C44.409,92,39.074,90.893,34.191,88.901z"
        })
  };
  if (color !== undefined) {
    tmp.color = (function () {
          switch (Caml_option.valFromOption(color)) {
            case -601204732 :
                return "inherit";
            case 58474434 :
                return "primary";
            case -499495052 :
                return "secondary";
            case 373701558 :
                return "action";
            case -215364664 :
                return "error";
            case -66829956 :
                return "disabled";
            
          }
        })();
  }
  return React.createElement(SvgIcon.default, tmp);
}

var make = LogoIcon;

export {
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
