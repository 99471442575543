// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as State from "../util/State.bs.js";
import * as React from "react";
import * as AppMenu from "./AppMenu.bs.js";
import * as LogoIcon from "./LogoIcon.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Collection from "../util/Collection.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Card from "@material-ui/core/Card";
import * as Grid from "@material-ui/core/Grid";
import * as Button from "@material-ui/core/Button";
import * as Hidden from "@material-ui/core/Hidden";
import * as Explore from "@material-ui/icons/Explore";
import * as Typography from "@material-ui/core/Typography";
import * as CardContent from "@material-ui/core/CardContent";
import * as Assignment from "@material-ui/icons/Assignment";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function makeStylesRaw (theme){return {
    content: {
      marginTop: '15px',
      marginBottom: '10px',
      [theme.breakpoints.down('sm')]: {
        minHeight: '200px',
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: 'unset',
      },
    },
    root: {
      marginTop: '20px',
      maxWidth: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }};

var useStyles = MakeStyles.default(makeStylesRaw);

function HomeSection$Info(Props) {
  var icon = Props.icon;
  var title = Props.title;
  var content = Props.content;
  var classes = useStyles();
  return React.createElement(Card.default, {
              className: classes.root,
              children: React.createElement(CardContent.default, {
                    children: null
                  }, React.createElement(Grid.default, {
                        container: true,
                        alignItems: "center",
                        spacing: 2,
                        children: null
                      }, React.createElement(Grid.default, {
                            item: true,
                            children: icon
                          }), React.createElement(Grid.default, {
                            children: React.createElement(Typography.default, {
                                  color: "primary",
                                  variant: "h6",
                                  children: title
                                })
                          })), React.createElement(Box.default, {
                        className: classes.content,
                        children: React.createElement(Typography.default, {
                              variant: "body1",
                              children: content
                            })
                      }))
            });
}

var Info = {
  makeStylesRaw: makeStylesRaw,
  useStyles: useStyles,
  make: HomeSection$Info
};

var title = "Keep track of maintenance";

var content = React.createElement(React.Fragment, {
      children: null
    }, "\nYou can configure your maintenance schedule based on\ntime periods, engine hours or miles sailed. \n        ", React.createElement("br", undefined), "\nYour maintenance records will be\nkept organized and easily accessible, adding resale value to your boat.\n      ");

var icon = React.createElement(Assignment.default, {
      color: "primary"
    });

function HomeSection$MaintenanceInfo(Props) {
  return React.createElement(HomeSection$Info, {
              icon: icon,
              title: title,
              content: content
            });
}

var MaintenanceInfo = {
  title: title,
  content: content,
  icon: icon,
  make: HomeSection$MaintenanceInfo
};

var title$1 = "Keep track of passages";

var content$1 = React.createElement(React.Fragment, {
      children: null
    }, "\nYou can record your passages on the water by entering logs in digital format and keep those memories forever.\n        ", React.createElement("br", undefined), "\nBoat Health is a modern web application that works offline and will automatically sync your data when connectivity is restored. \n      ");

var icon$1 = React.createElement(Explore.default, {
      color: "primary"
    });

function HomeSection$PassageInfo(Props) {
  return React.createElement(HomeSection$Info, {
              icon: icon$1,
              title: title$1,
              content: content$1
            });
}

var PassageInfo = {
  title: title$1,
  content: content$1,
  icon: icon$1,
  make: HomeSection$PassageInfo
};

var mission = "\nSpend more time on the water by staying on top of your boat maintenance.\n  Record passage logs, maintenance records and more.\n";

function makeStylesRaw$1 (theme){return {
  root: {
    [theme.breakpoints.up('md')]: {
      marginTop: 140,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 120,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 120,
    },
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  mdMission: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  button: {
    textAlign: 'center',
    marginTop: '20px',
    minHeight: '40px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '20px',
    },
  },
}};

var useStyles$1 = MakeStyles.default(makeStylesRaw$1);

function hasNoSystem(boat) {
  return Collection.isEmpty(boat.systems);
}

function buttonText(user, state) {
  var match = State.getSelectedBoat(state);
  if (typeof user === "number") {
    return ;
  } else if (match !== undefined) {
    return "Setup your maintenance schedule";
  } else {
    return "Start Configuring your boat";
  }
}

function HomeSection(Props) {
  var onStart = Props.onStart;
  var state = Props.state;
  var classes = useStyles$1();
  AnalyticsEffect.useCurrentScreen(AppMenu.homeString);
  var match = React.useContext(AppContext.ctx);
  var onStart$1 = function (param) {
    return Curry._1(onStart, /* () */0);
  };
  var match$1 = buttonText(match.user, state);
  return React.createElement(Box.default, {
              className: classes.root,
              children: null
            }, React.createElement(Grid.default, {
                  container: true,
                  justify: "space-around",
                  alignItems: "flex-start",
                  spacing: 3,
                  children: null
                }, React.createElement(Grid.default, {
                      className: classes.content,
                      item: true,
                      sm: 4,
                      xs: 12,
                      children: React.createElement(LogoIcon.make, {
                            width: 150,
                            height: 150,
                            color: /* primary */58474434
                          })
                    }), React.createElement(Hidden.default, {
                      smUp: true,
                      children: null
                    }, React.createElement(Grid.default, {
                          item: true,
                          xs: 12,
                          children: React.createElement(Typography.default, {
                                color: "primary",
                                align: "center",
                                variant: "h4",
                                children: "Boat Health"
                              })
                        }), React.createElement(Grid.default, {
                          item: true,
                          xs: 12,
                          children: React.createElement(Typography.default, {
                                className: classes.mdMission,
                                color: "primary",
                                align: "center",
                                variant: "h6",
                                children: mission
                              })
                        })), React.createElement(Hidden.default, {
                      xsDown: true,
                      children: React.createElement(Grid.default, {
                            item: true,
                            container: true,
                            sm: 8,
                            spacing: 3,
                            children: null
                          }, React.createElement(Grid.default, {
                                item: true,
                                xs: 12,
                                children: React.createElement(Typography.default, {
                                      color: "primary",
                                      align: "left",
                                      variant: "h4",
                                      children: "Boat Health"
                                    })
                              }), React.createElement(Grid.default, {
                                item: true,
                                xs: 12,
                                children: React.createElement(Typography.default, {
                                      className: classes.mdMission,
                                      color: "primary",
                                      align: "left",
                                      variant: "h6",
                                      children: mission
                                    })
                              }))
                    })), React.createElement(Box.default, {
                  className: classes.button,
                  children: match$1 !== undefined ? React.createElement(Button.default, {
                          color: "primary",
                          variant: "outlined",
                          onClick: onStart$1,
                          children: match$1
                        }) : null
                }), React.createElement(Hidden.default, {
                  smUp: true,
                  children: null
                }, React.createElement(HomeSection$MaintenanceInfo, { }), React.createElement(HomeSection$PassageInfo, { })), React.createElement(Hidden.default, {
                  xsDown: true,
                  children: React.createElement(Grid.default, {
                        container: true,
                        justify: "center",
                        alignItems: "center",
                        spacing: 2,
                        children: null
                      }, React.createElement(Grid.default, {
                            className: classes.cell,
                            item: true,
                            xs: 6,
                            children: React.createElement(HomeSection$MaintenanceInfo, { })
                          }), React.createElement(Grid.default, {
                            className: classes.cell,
                            item: true,
                            xs: 6,
                            children: React.createElement(HomeSection$PassageInfo, { })
                          }))
                }));
}

var Mui = /* alias */0;

var U = /* alias */0;

var make = HomeSection;

export {
  Mui ,
  U ,
  Info ,
  MaintenanceInfo ,
  PassageInfo ,
  mission ,
  makeStylesRaw$1 as makeStylesRaw,
  useStyles$1 as useStyles,
  hasNoSystem ,
  buttonText ,
  make ,
  
}
/* useStyles Not a pure module */
