// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Passage from "../util/Passage.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Events_types from "../types/events_types.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as Checkbox from "@material-ui/core/Checkbox";
import * as Typography from "@material-ui/core/Typography";
import * as ExpandMore from "@material-ui/icons/ExpandMore";
import * as ExpansionPanel from "@material-ui/core/ExpansionPanel";
import * as FormControlLabel from "@material-ui/core/FormControlLabel";
import * as ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import * as ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";

var expandIcon = React.createElement(ExpandMore.default, { });

function ignoreOnSelectionChange(param, param$1) {
  return /* () */0;
}

function PassageFieldsSelector(Props) {
  var $staropt$star = Props.title;
  var $staropt$star$1 = Props.fields;
  var $staropt$star$2 = Props.disabled;
  var $staropt$star$3 = Props.selectedFields;
  var $staropt$star$4 = Props.onSelectionChange;
  var navType = Props.navType;
  var title = $staropt$star !== undefined ? $staropt$star : "";
  var fields = $staropt$star$1 !== undefined ? $staropt$star$1 : /* [] */0;
  var disabled = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var selectedFields = $staropt$star$3 !== undefined ? $staropt$star$3 : /* [] */0;
  var onSelectionChange = $staropt$star$4 !== undefined ? $staropt$star$4 : ignoreOnSelectionChange;
  var makeField = function (field) {
    var s = Passage.fieldToString(field);
    var checked = Belt_List.has(selectedFields, field, (function (prim, prim$1) {
            return prim === prim$1;
          }));
    return React.createElement(Grid.default, {
                item: true,
                sm: 4,
                children: React.createElement(FormControlLabel.default, {
                      checked: checked,
                      control: React.createElement(Checkbox.default, {
                            checked: checked
                          }),
                      disabled: disabled,
                      label: s,
                      onChange: (function (param) {
                          var field$1 = field;
                          var e = param;
                          var target = e.target;
                          var isChecked = target.checked;
                          return Curry._2(onSelectionChange, field$1, isChecked);
                        })
                    }),
                key: s
              });
  };
  var onExpansionChange = function (param, param$1) {
    if (param$1) {
      return Analytics.nav(Events_types.default_navigation(navType, undefined, /* () */0));
    } else {
      return /* () */0;
    }
  };
  return React.createElement(ExpansionPanel.default, {
              onChange: onExpansionChange,
              children: null
            }, React.createElement(ExpansionPanelSummary.default, {
                  expandIcon: expandIcon,
                  children: React.createElement(Typography.default, {
                        children: title
                      })
                }), React.createElement(ExpansionPanelDetails.default, {
                  children: React.createElement(Grid.default, {
                        container: true,
                        alignItems: "center",
                        spacing: 2,
                        children: Belt_List.toArray(Belt_List.map(fields, makeField))
                      })
                }));
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var E = /* alias */0;

var defaultFields = /* [] */0;

var toString = Passage.fieldToString;

var make = PassageFieldsSelector;

export {
  Mui ,
  Pa ,
  E ,
  expandIcon ,
  defaultFields ,
  toString ,
  ignoreOnSelectionChange ,
  make ,
  
}
/* expandIcon Not a pure module */
