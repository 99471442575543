// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Selector from "./core/Selector.bs.js";

function toString(param) {
  if (param) {
    return "ft";
  } else {
    return "m";
  }
}

function defaultOnChange(param) {
  return /* () */0;
}

function LengthUnitSelector(Props) {
  var $staropt$star = Props.value;
  var $staropt$star$1 = Props.onChange;
  var value = $staropt$star !== undefined ? $staropt$star : /* Feet */1;
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : defaultOnChange;
  return React.createElement(Selector.make, {
              value: value,
              values: /* :: */[
                /* Meter */0,
                /* :: */[
                  /* Feet */1,
                  /* [] */0
                ]
              ],
              toString: toString,
              onChange: onChange
            });
}

var Mui = /* alias */0;

var B = /* alias */0;

var defaultValue = /* Feet */1;

var make = LengthUnitSelector;

export {
  Mui ,
  B ,
  toString ,
  defaultOnChange ,
  defaultValue ,
  make ,
  
}
/* react Not a pure module */
