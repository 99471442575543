// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function toString(param) {
  switch (param) {
    case /* Millibar */0 :
        return "mb";
    case /* Pascal */1 :
        return "pa";
    case /* Inch_of_mercury */2 :
        return "inHg";
    case /* Millimeters_of_mercury */3 :
        return "mmHg";
    
  }
}

function convertFrom(value, param) {
  switch (param) {
    case /* Millibar */0 :
        return value * 100.0;
    case /* Pascal */1 :
        return value;
    case /* Inch_of_mercury */2 :
        return value * 3386.39;
    case /* Millimeters_of_mercury */3 :
        return value * 133.322;
    
  }
}

function digits(param) {
  if (param !== 0) {
    switch (param - 1 | 0) {
      case /* Millibar */0 :
          return 0;
      case /* Pascal */1 :
          return 2;
      case /* Inch_of_mercury */2 :
          return 1;
      
    }
  } else {
    return 1;
  }
}

function convertTo(value, param) {
  switch (param) {
    case /* Millibar */0 :
        return value / 100.0;
    case /* Pascal */1 :
        return value;
    case /* Inch_of_mercury */2 :
        return value / 3386.39;
    case /* Millimeters_of_mercury */3 :
        return value / 133.322;
    
  }
}

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function toDisplayString(value, displayUnit) {
  var displayUnit$1 = Belt_Option.getWithDefault(displayUnit, /* Millibar */0);
  var digits$1 = digits(displayUnit$1);
  var value$1 = convertTo(value, displayUnit$1);
  return value$1.toFixed(digits$1).concat(" ").concat(toString(displayUnit$1));
}

var PU = /* alias */0;

var allUnits = /* :: */[
  /* Millibar */0,
  /* :: */[
    /* Inch_of_mercury */2,
    /* :: */[
      /* Pascal */1,
      /* :: */[
        /* Millimeters_of_mercury */3,
        /* [] */0
      ]
    ]
  ]
];

var $$default = /* Millibar */0;

export {
  PU ,
  toString ,
  convertFrom ,
  digits ,
  convertTo ,
  allUnits ,
  $$default ,
  $$default as default,
  concat ,
  toDisplayString ,
  
}
/* No side effect */
