// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AddConfirmation from "./AddConfirmation.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function ignore(param) {
  return /* () */0;
}

var defaultChildren = null;

var msg = "\nContinue your configuration by setting up a maintenance item\n";

function AddBoatConfirmation(Props) {
  var onClose = Props.onClose;
  var mode = Props.mode;
  AnalyticsEffect.useCurrentScreen("AddBoatConfirmation");
  var mode$1;
  if (typeof mode === "number") {
    mode$1 = mode >= 19559306 ? /* Hidden */0 : /* Saving */1;
  } else {
    var title = React.createElement(React.Fragment, {
          children: null
        }, "Boat ", React.createElement("strong", undefined, mode[1]), " added successfully");
    mode$1 = /* Done */[
      title,
      msg
    ];
  }
  return React.createElement(AddConfirmation.make, {
              onClose: onClose,
              mode: mode$1
            });
}

var Mui = /* alias */0;

var make = AddBoatConfirmation;

export {
  Mui ,
  concat ,
  ignore ,
  defaultChildren ,
  msg ,
  make ,
  
}
/* defaultChildren Not a pure module */
