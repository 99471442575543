// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "@material-ui/core/Button";
import * as Dialog from "@material-ui/core/Dialog";
import * as DialogTitle from "@material-ui/core/DialogTitle";
import * as DialogActions from "@material-ui/core/DialogActions";
import * as DialogContent from "@material-ui/core/DialogContent";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function ignore(param) {
  return /* () */0;
}

function LogoutConfirmation(Props) {
  var $staropt$star = Props.onClose;
  var $staropt$star$1 = Props.onOk;
  var onClose = $staropt$star !== undefined ? $staropt$star : ignore;
  var onOk = $staropt$star$1 !== undefined ? $staropt$star$1 : ignore;
  var handleOnDialogClose = function (param, param$1) {
    return Curry._1(onClose, /* () */0);
  };
  var handleOnClose = function (param) {
    return Curry._1(onClose, /* () */0);
  };
  var handleOk = function (param) {
    return Curry._1(onOk, /* () */0);
  };
  return React.createElement(Dialog.default, {
              open: true,
              onClose: handleOnDialogClose,
              children: null
            }, React.createElement(DialogTitle.default, {
                  children: "Logging out"
                }), React.createElement(DialogContent.default, {
                  children: "Are you sure you want to log out, if offline all local changes ".concat(" will be lost")
                }), React.createElement(DialogActions.default, {
                  children: null
                }, React.createElement(Button.default, {
                      color: "primary",
                      onClick: handleOnClose,
                      children: "Cancel"
                    }), React.createElement(Button.default, {
                      color: "primary",
                      onClick: handleOk,
                      children: "Ok"
                    })));
}

var Mui = /* alias */0;

var make = LogoutConfirmation;

export {
  Mui ,
  concat ,
  ignore ,
  make ,
  
}
/* react Not a pure module */
