// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Select from "@material-ui/core/Select";
import * as MenuItem from "@material-ui/core/MenuItem";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      selector: {
        width: "100%"
      }
    });

function ignoreOnChange(param) {
  return /* () */0;
}

function Selector(Props) {
  var value = Props.value;
  var values = Props.values;
  var toString = Props.toString;
  var $staropt$star = Props.onChange;
  var $staropt$star$1 = Props.disabled;
  var $staropt$star$2 = Props.autoFocus;
  var onChange = $staropt$star !== undefined ? $staropt$star : ignoreOnChange;
  var disabled = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var autoFocus = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var classes = useStyles();
  var onChange$1 = function (e) {
    return Curry._1(onChange, e.target.value);
  };
  return React.createElement(Select.default, {
              className: classes.selector,
              variant: "outlined",
              value: value,
              onChange: onChange$1,
              disabled: disabled,
              autoFocus: autoFocus,
              children: Belt_List.toArray(Belt_List.mapU(values, (function (v) {
                          var s = Curry._1(toString, v);
                          return React.createElement(MenuItem.default, {
                                      key: s,
                                      value: v,
                                      children: s
                                    });
                        })))
            });
}

var Mui = /* alias */0;

var make = Selector;

export {
  Mui ,
  useStyles ,
  ignoreOnChange ,
  make ,
  
}
/* useStyles Not a pure module */
