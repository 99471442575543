// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as BackArrow from "./BackArrow.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as DeleteConfirmation from "./DeleteConfirmation.bs.js";
import * as MaintenanceLogEntry from "./MaintenanceLogEntry.bs.js";
import * as NoDataInGridMessage from "./NoDataInGridMessage.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Fab from "@material-ui/core/Fab";
import * as MaintenanceItemLogRow from "./MaintenanceItemLogRow.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as Add from "@material-ui/icons/Add";
import * as Table from "@material-ui/core/Table";
import * as TableBody from "@material-ui/core/TableBody";
import * as TableHead from "@material-ui/core/TableHead";
import * as TableContainer from "@material-ui/core/TableContainer";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var useStyles = MakeStyles.default({
      root: {
        marginTop: 10,
        marginLeft: "20px",
        marginRight: "20px"
      },
      addButton: {
        marginTop: 10,
        textAlign: "center"
      }
    });

function keyOfLog(log) {
  var match = log.id;
  if (match !== undefined) {
    return match.firestore_id;
  } else {
    return log.unix_timestamp.toString();
  }
}

function MaintenanceItemLogs(Props) {
  var systemIdentifier = Props.systemIdentifier;
  var item = Props.item;
  var onBack = Props.onBack;
  var $staropt$star = Props.isDataComplete;
  var isDataComplete = $staropt$star !== undefined ? $staropt$star : true;
  var classes = useStyles();
  AnalyticsEffect.useCurrentScreen("MaintenanceItemLogs");
  var match = React.useContext(AppContext.ctx);
  var firestore = match.firestore;
  var match$1 = React.useState((function () {
          return ;
        }));
  var setEntryMode = match$1[1];
  var entryMode = match$1[0];
  var hideEntryDialog = function (param) {
    return Curry._1(setEntryMode, (function (param) {
                  return ;
                }));
  };
  var match$2 = React.useState((function () {
          return ;
        }));
  var setDeleteConfirmation = match$2[1];
  var deleteConfirmation = match$2[0];
  var hideDeleteConfirmation = function (param) {
    return Curry._1(setDeleteConfirmation, (function (param) {
                  return ;
                }));
  };
  var onAction = function (param) {
    if (param.tag) {
      var log = param[0];
      return Curry._1(setDeleteConfirmation, (function (param) {
                    return log;
                  }));
    } else {
      var log$1 = param[0];
      return Curry._1(setEntryMode, (function (param) {
                    return /* Edit */Block.__(1, [log$1]);
                  }));
    }
  };
  var showEntryDialog = function (param) {
    var modeValue = /* AddToMaintenance */Block.__(0, [
        systemIdentifier,
        item
      ]);
    return Curry._1(setEntryMode, (function (param) {
                  return modeValue;
                }));
  };
  var tableConfig = MaintenanceItemLogRow.useTableConfig(/* () */0);
  var maintenanceItemType = item.type_;
  var match$3 = Belt_List.length(item.logs);
  var tmp;
  var exit = 0;
  if (match$3 !== 0 || !isDataComplete) {
    exit = 1;
  } else {
    tmp = React.createElement(NoDataInGridMessage.make, {
          msg: /* NoData */Block.__(0, ["Click `Add` above for adding a new maintenance log"])
        });
  }
  if (exit === 1) {
    tmp = isDataComplete ? null : React.createElement(NoDataInGridMessage.make, {
            msg: /* LoadingData */0
          });
  }
  var tmp$1;
  if (deleteConfirmation !== undefined) {
    var log = deleteConfirmation;
    tmp$1 = React.createElement(DeleteConfirmation.make, {
          what: "maintenance log",
          onClose: hideDeleteConfirmation,
          onOk: (function (param) {
              var log$1 = log;
              Curry._2(Db.deleteMaintenanceLog, firestore, log$1);
              return Curry._1(setDeleteConfirmation, (function (param) {
                            return ;
                          }));
            }),
          screenIdentifier: "MaintenanceItemLog"
        });
  } else {
    tmp$1 = null;
  }
  return React.createElement(Box.default, {
              children: null
            }, React.createElement(Box.default, {
                  className: classes.root,
                  children: React.createElement(Grid.default, {
                        container: true,
                        justify: "space-between",
                        alignItems: "center",
                        spacing: 1,
                        children: null
                      }, React.createElement(Grid.default, {
                            item: true,
                            xs: 1,
                            children: React.createElement(BackArrow.make, {
                                  onBack: onBack
                                })
                          }), React.createElement(Grid.default, {
                            className: classes.addButton,
                            item: true,
                            xs: 10,
                            children: React.createElement(Fab.default, {
                                  color: "primary",
                                  variant: "extended",
                                  onClick: showEntryDialog,
                                  children: null
                                }, React.createElement(Add.default, { }), "Add Log")
                          }), React.createElement(Grid.default, {
                            item: true,
                            xs: 1
                          }))
                }), React.createElement(TableContainer.default, {
                  children: React.createElement(Table.default, {
                        children: null
                      }, React.createElement(TableHead.default, {
                            children: React.createElement(MaintenanceItemLogRow.Head.make, {
                                  tableConfig: tableConfig,
                                  maintenanceItemType: maintenanceItemType
                                })
                          }), React.createElement(TableBody.default, {
                            children: Belt_List.toArray(Belt_List.map(item.logs, (function (log) {
                                        return React.createElement(MaintenanceItemLogRow.Body.make, {
                                                    tableConfig: tableConfig,
                                                    log: log,
                                                    onAction: onAction,
                                                    maintenanceItemType: maintenanceItemType,
                                                    key: keyOfLog(log)
                                                  });
                                      })))
                          }))
                }), entryMode !== undefined ? React.createElement(MaintenanceLogEntry.make, {
                    onClose: hideEntryDialog,
                    mode: entryMode
                  }) : null, tmp, tmp$1);
}

var Mui = /* alias */0;

var B = /* alias */0;

var M = /* alias */0;

var S = /* alias */0;

var I = /* alias */0;

var make = MaintenanceItemLogs;

export {
  Mui ,
  B ,
  M ,
  S ,
  I ,
  concat ,
  useStyles ,
  keyOfLog ,
  make ,
  
}
/* useStyles Not a pure module */
