// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AppContext from "./AppContext.bs.js";

function useCurrentScreen(s) {
  React.useEffect((function () {
          AppContext.analytics.setCurrentScreen(s);
          return ;
        }));
  return /* () */0;
}

export {
  useCurrentScreen ,
  
}
/* react Not a pure module */
