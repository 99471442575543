// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";

function category(param) {
  return param.category;
}

function identifier(param) {
  return param.identifier;
}

function categoryToString(other_category, param) {
  switch (param) {
    case /* None */0 :
        return "";
    case /* Anchors */1 :
        return "Anchors";
    case /* Anchor_lines */2 :
        return "Anchor lines";
    case /* Chains */3 :
        return "Chains";
    case /* Shackles */4 :
        return "Shackles";
    case /* Windlass */5 :
        return "Windlass";
    case /* Anchor_rollers */6 :
        return "Anchor rollers";
    case /* Anchor_mounts */7 :
        return "Anchor mounts";
    case /* Dock_lines */8 :
        return "Dock lines";
    case /* Fenders */9 :
        return "Fenders";
    case /* Boat_hook */10 :
        return "Boat hook";
    case /* Bow_stern_thrusters */11 :
        return "Bow/stern thrusters";
    case /* Mooring_lines */12 :
        return "Mooring lines";
    case /* Mooring_pendants */13 :
        return "Mooring pendants";
    case /* Boarding_ladders */14 :
        return "Boarding ladders";
    case /* Stern_platforms */15 :
        return "Stern platforms";
    case /* Electric_outboards */16 :
        return "Electric outboards";
    case /* Propane_outboards */17 :
        return "Propane outboards";
    case /* Trolling_motors */18 :
        return "Trolling motors";
    case /* Propellers */19 :
        return "Propellers";
    case /* Steering_systems */20 :
        return "Steering systems";
    case /* Biminis */21 :
        return "Biminis";
    case /* Boat_covers */22 :
        return "Boat covers";
    case /* Inverters */23 :
        return "Inverters";
    case /* Solar_panels */24 :
        return "Solar panels";
    case /* Wind_generators */25 :
        return "Wind generators";
    case /* Alternators */26 :
        return "Alternators";
    case /* Batteries */27 :
        return "Batteries";
    case /* Chargers */28 :
        return "Chargers";
    case /* Electrical_systems */29 :
        return "Electrical systems";
    case /* Distribution_panels */30 :
        return "Distribution panels";
    case /* Deck_lights */31 :
        return "Deck lights";
    case /* Interior_lighting */32 :
        return "Interior lighting";
    case /* Navigation_lights */33 :
        return "Navigation lights";
    case /* Multi_function_displays */34 :
        return "Multi function displays";
    case /* Radars */35 :
        return "Radars";
    case /* Autopilots */36 :
        return "Autopilots";
    case /* Vhf */37 :
        return "Vhf";
    case /* Vhf_antennas */38 :
        return "Vhf antennas";
    case /* Ais */39 :
        return "Ais";
    case /* Depth_instruments */40 :
        return "Depth instruments";
    case /* Knotmeter_instruments */41 :
        return "Knotmeter instruments";
    case /* Weather_instruments */42 :
        return "Weather instruments";
    case /* Wind_instruments */43 :
        return "Wind instruments";
    case /* Fishfinders */44 :
        return "Fishfinders";
    case /* Transducers */45 :
        return "Transducers";
    case /* Marine_stereos */46 :
        return "Marine stereos";
    case /* Marine_speakers */47 :
        return "Marine speakers";
    case /* Compasses */48 :
        return "Compasses";
    case /* Grills */49 :
        return "Grills";
    case /* Coolers */50 :
        return "Coolers";
    case /* Barometers */51 :
        return "Barometers";
    case /* Fridges */52 :
        return "Fridges";
    case /* Stoves */53 :
        return "Stoves";
    case /* Galley */54 :
        return "Galley";
    case /* Propane_system */55 :
        return "Propane system";
    case /* Freezers */56 :
        return "Freezers";
    case /* Washers */57 :
        return "Washers";
    case /* Dryers */58 :
        return "Dryers";
    case /* Cockpit_tables */59 :
        return "Cockpit tables";
    case /* Cockpit_showers */60 :
        return "Cockpit showers";
    case /* Hull */61 :
        return "Hull";
    case /* Deck */62 :
        return "Deck";
    case /* Cockpit */63 :
        return "Cockpit";
    case /* Propeller_shafts */64 :
        return "Propeller shafts";
    case /* Anodes */65 :
        return "Anodes";
    case /* Cleats */66 :
        return "Cleats";
    case /* Cabinets */67 :
        return "Cabinets";
    case /* Rails */68 :
        return "Rails";
    case /* Rub_rails */69 :
        return "Rub rails";
    case /* Mast */70 :
        return "Mast";
    case /* Spreaders */71 :
        return "Spreaders";
    case /* Shrouds */72 :
        return "Shrouds";
    case /* Backstays */73 :
        return "Backstays";
    case /* Headstays */74 :
        return "Headstays";
    case /* Chainplates */75 :
        return "Chainplates";
    case /* Cockpit_lockers */76 :
        return "Cockpit lockers";
    case /* Teak_accent */77 :
        return "Teak accent";
    case /* Bilge_pumps */78 :
        return "Bilge pumps";
    case /* Freshwater_pumps */79 :
        return "Freshwater pumps";
    case /* Macerator_pumps */80 :
        return "Macerator pumps";
    case /* Deck_fills */81 :
        return "Deck fills";
    case /* Plumbing_fittings */82 :
        return "Plumbing fittings";
    case /* Strainers */83 :
        return "Strainers";
    case /* Valves */84 :
        return "Valves";
    case /* Faucets */85 :
        return "Faucets";
    case /* Water_filters */86 :
        return "Water filters";
    case /* Showers */87 :
        return "Showers";
    case /* Sinks */88 :
        return "Sinks";
    case /* Water_heaters */89 :
        return "Water heaters";
    case /* Water_tanks */90 :
        return "Water tanks";
    case /* Heads */91 :
        return "Heads";
    case /* Holding_tanks */92 :
        return "Holding tanks";
    case /* Air_conditioners */93 :
        return "Air conditioners";
    case /* Heaters */94 :
        return "Heaters";
    case /* Dehumidifiers */95 :
        return "Dehumidifiers";
    case /* Hatches */96 :
        return "Hatches";
    case /* Portlights */97 :
        return "Portlights";
    case /* Vents */98 :
        return "Vents";
    case /* Saltwater_pumps */99 :
        return "Saltwater pumps";
    case /* Life_jackets */100 :
        return "Life jackets";
    case /* Rescue_slings */101 :
        return "Rescue slings";
    case /* Flares */102 :
        return "Flares";
    case /* Safety_lights */103 :
        return "Safety lights";
    case /* Epirbs */104 :
        return "Epirbs";
    case /* Life_rafts */105 :
        return "Life rafts";
    case /* First_aid_kits */106 :
        return "First_aid kits";
    case /* Running_rigging */107 :
        return "Running rigging";
    case /* Standing_rigging */108 :
        return "Standing rigging";
    case /* Sail_rigging */109 :
        return "Sail rigging";
    case /* Lifelines */110 :
        return "Lifelines";
    case /* Blocks */111 :
        return "Blocks";
    case /* Vangs */112 :
        return "Vangs";
    case /* Whisker_poles */113 :
        return "Whisker poles";
    case /* Spinnaker_poles */114 :
        return "Spinnaker poles";
    case /* Furlers */115 :
        return "Furlers";
    case /* Diesel_engine */116 :
        return "Diesel engine";
    case /* Winches */117 :
        return "Winches";
    case /* Gas_outboard */118 :
        return "Gas outboard";
    case /* Other */119 :
        return other_category;
    
  }
}

var allCategories = [
  /* Anchors */1,
  /* Air_conditioners */93,
  /* Ais */39,
  /* Alternators */26,
  /* Anchor_lines */2,
  /* Anchor_mounts */7,
  /* Anchor_rollers */6,
  /* Anodes */65,
  /* Autopilots */36,
  /* Backstays */73,
  /* Barometers */51,
  /* Batteries */27,
  /* Bilge_pumps */78,
  /* Biminis */21,
  /* Blocks */111,
  /* Boarding_ladders */14,
  /* Boat_covers */22,
  /* Boat_hook */10,
  /* Bow_stern_thrusters */11,
  /* Cabinets */67,
  /* Chainplates */75,
  /* Chains */3,
  /* Chargers */28,
  /* Cleats */66,
  /* Cockpit */63,
  /* Cockpit_lockers */76,
  /* Cockpit_showers */60,
  /* Cockpit_tables */59,
  /* Compasses */48,
  /* Coolers */50,
  /* Deck */62,
  /* Deck_fills */81,
  /* Deck_lights */31,
  /* Dehumidifiers */95,
  /* Depth_instruments */40,
  /* Diesel_engine */116,
  /* Distribution_panels */30,
  /* Dock_lines */8,
  /* Dryers */58,
  /* Electric_outboards */16,
  /* Electrical_systems */29,
  /* Epirbs */104,
  /* Faucets */85,
  /* Fenders */9,
  /* First_aid_kits */106,
  /* Fishfinders */44,
  /* Flares */102,
  /* Freezers */56,
  /* Freshwater_pumps */79,
  /* Fridges */52,
  /* Furlers */115,
  /* Galley */54,
  /* Gas_outboard */118,
  /* Grills */49,
  /* Hatches */96,
  /* Heads */91,
  /* Headstays */74,
  /* Heaters */94,
  /* Holding_tanks */92,
  /* Hull */61,
  /* Interior_lighting */32,
  /* Inverters */23,
  /* Knotmeter_instruments */41,
  /* Life_jackets */100,
  /* Life_rafts */105,
  /* Lifelines */110,
  /* Macerator_pumps */80,
  /* Marine_speakers */47,
  /* Marine_stereos */46,
  /* Mast */70,
  /* Mooring_lines */12,
  /* Mooring_pendants */13,
  /* Multi_function_displays */34,
  /* Navigation_lights */33,
  /* Plumbing_fittings */82,
  /* Portlights */97,
  /* Propane_outboards */17,
  /* Propane_system */55,
  /* Propeller_shafts */64,
  /* Propellers */19,
  /* Propellers */19,
  /* Radars */35,
  /* Rails */68,
  /* Rescue_slings */101,
  /* Rub_rails */69,
  /* Running_rigging */107,
  /* Safety_lights */103,
  /* Sail_rigging */109,
  /* Saltwater_pumps */99,
  /* Shackles */4,
  /* Showers */87,
  /* Shrouds */72,
  /* Sinks */88,
  /* Solar_panels */24,
  /* Spinnaker_poles */114,
  /* Spreaders */71,
  /* Standing_rigging */108,
  /* Steering_systems */20,
  /* Stern_platforms */15,
  /* Stoves */53,
  /* Strainers */83,
  /* Teak_accent */77,
  /* Transducers */45,
  /* Trolling_motors */18,
  /* Valves */84,
  /* Vangs */112,
  /* Vents */98,
  /* Vhf */37,
  /* Vhf_antennas */38,
  /* Washers */57,
  /* Water_filters */86,
  /* Water_heaters */89,
  /* Water_tanks */90,
  /* Weather_instruments */42,
  /* Whisker_poles */113,
  /* Winches */117,
  /* Wind_generators */25,
  /* Wind_instruments */43,
  /* Windlass */5
];

var allCategoriesAsString = $$Array.map((function (param) {
        return categoryToString("", param);
      }), allCategories);

function categoryOfString(s) {
  var optC = Belt_Array.getBy(allCategories, (function (c) {
          return categoryToString("", c) === s;
        }));
  if (optC !== undefined) {
    return /* tuple */[
            optC,
            ""
          ];
  } else {
    return /* tuple */[
            /* Other */119,
            s
          ];
  }
}

function displayName(system) {
  var category = categoryToString(system.other_category, system.category);
  var identifier = system.identifier;
  if (identifier === "") {
    return category;
  } else {
    return Curry._2(Printf.sprintf(/* Format */[
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* String_literal */Block.__(11, [
                            " (",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* Char_literal */Block.__(12, [
                                    /* ")" */41,
                                    /* End_of_format */0
                                  ])
                              ])
                          ])
                      ]),
                    "%s (%s)"
                  ]), category, identifier);
  }
}

var S = /* alias */0;

export {
  S ,
  category ,
  identifier ,
  categoryToString ,
  allCategories ,
  allCategoriesAsString ,
  categoryOfString ,
  displayName ,
  
}
/* allCategoriesAsString Not a pure module */
