// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Ignore from "../types/Ignore.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as Card from "@material-ui/core/Card";
import * as Grid from "@material-ui/core/Grid";
import * as $$History from "@material-ui/icons/History";
import * as Typography from "@material-ui/core/Typography";
import * as CardContent from "@material-ui/core/CardContent";
import * as CardActionArea from "@material-ui/core/CardActionArea";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";
import * as PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";

function PassageTypeSelection$Card(Props) {
  var $staropt$star = Props.onClick;
  var children = Props.children;
  var onClick = $staropt$star !== undefined ? $staropt$star : Ignore.v0;
  var onClick$1 = function (param) {
    return Curry._1(onClick, /* () */0);
  };
  return React.createElement(Card.default, {
              children: React.createElement(CardActionArea.default, {
                    onClick: onClick$1,
                    children: React.createElement(CardContent.default, {
                          children: children
                        })
                  })
            });
}

var Card$1 = {
  make: PassageTypeSelection$Card
};

function PassageTypeSelection$Cell(Props) {
  var type_ = Props.type_;
  return React.createElement(Grid.default, {
              container: true,
              spacing: 2,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  xs: 2,
                  children: type_ ? React.createElement(PlayCircleOutline.default, {
                          color: "primary",
                          fontSize: "large"
                        }) : React.createElement($$History.default, {
                          color: "primary",
                          fontSize: "large"
                        })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 10,
                  children: React.createElement(Typography.default, {
                        align: "left",
                        variant: "h5",
                        children: type_ ? "Live" : "Retroactive"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 12,
                  children: React.createElement(Typography.default, {
                        align: "left",
                        variant: "subtitle1",
                        children: type_ ? "\nRecord live your passage by adding log entries as you sail " : "\nRecord quickly the summary of previous passages "
                      })
                }));
}

var Cell = {
  make: PassageTypeSelection$Cell
};

var useStyles = MakeStyles.default({
      root: {
        width: "100%"
      },
      cell: {
        maxWidth: "350px"
      }
    });

function PassageTypeSelection(Props) {
  var $staropt$star = Props.onSelected;
  var onSelected = $staropt$star !== undefined ? $staropt$star : Ignore.v1;
  var classes = useStyles();
  var onClick = function (type_, param) {
    var navType = type_ ? /* Passage_type_selection_live */25 : /* Passage_type_selection_retroactive */24;
    Analytics.nav(Events_types.default_navigation(navType, undefined, /* () */0));
    return Curry._1(onSelected, type_);
  };
  return React.createElement(Grid.default, {
              className: classes.root,
              container: true,
              justify: "center",
              alignItems: "center",
              spacing: 3,
              children: null
            }, React.createElement(Grid.default, {
                  className: classes.cell,
                  item: true,
                  sm: 6,
                  xs: 12,
                  children: React.createElement(PassageTypeSelection$Card, {
                        onClick: (function (param) {
                            return onClick(/* Retroactive */0, param);
                          }),
                        children: React.createElement(PassageTypeSelection$Cell, {
                              type_: /* Retroactive */0
                            })
                      })
                }), React.createElement(Grid.default, {
                  className: classes.cell,
                  item: true,
                  sm: 6,
                  xs: 12,
                  children: React.createElement(PassageTypeSelection$Card, {
                        onClick: (function (param) {
                            return onClick(/* Live */1, param);
                          }),
                        children: React.createElement(PassageTypeSelection$Cell, {
                              type_: /* Live */1
                            })
                      })
                }));
}

var Mui = /* alias */0;

var E = /* alias */0;

var make = PassageTypeSelection;

export {
  Mui ,
  E ,
  Card$1 as Card,
  Cell ,
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
