// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as NumberInput from "./core/NumberInput.bs.js";
import * as StringFormLabel from "./core/StringFormLabel.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as Alert from "@material-ui/lab/Alert";

function ignoreOnChange(param) {
  return /* () */0;
}

var infoString1 = "\nAccurate engine hours value allows boat health to remind you of maintenance\n  based on engine time. \n";

var infoString2 = "\nIf your boat is not equipped with such counter, estimates will be computed using\nmotor start and motor off times.\n";

function EngineHoursEntry$Alert(Props) {
  return React.createElement(Alert.default, {
              severity: "info",
              children: null
            }, infoString1, React.createElement("br", undefined), React.createElement("br", undefined), infoString2);
}

var Alert$1 = {
  make: EngineHoursEntry$Alert
};

function EngineHoursEntry(Props) {
  var $staropt$star = Props.value;
  var $staropt$star$1 = Props.onChange;
  var value = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnChange;
  return React.createElement(Grid.default, {
              item: true,
              container: true,
              alignItems: "center",
              spacing: 2,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  sm: 3,
                  xs: 4,
                  children: React.createElement(StringFormLabel.make, {
                        text: "Engine Hours"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  sm: 9,
                  xs: 8,
                  children: React.createElement(NumberInput.NullableFloat.make, {
                        value: value,
                        onChange: onChange
                      })
                }));
}

var Mui = /* alias */0;

var B = /* alias */0;

var S = /* alias */0;

var Pa = /* alias */0;

var defaultValue = undefined;

var make = EngineHoursEntry;

export {
  Mui ,
  B ,
  S ,
  Pa ,
  ignoreOnChange ,
  defaultValue ,
  infoString1 ,
  infoString2 ,
  Alert$1 as Alert,
  make ,
  
}
/* react Not a pure module */
