// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Notes from "./Notes.bs.js";
import * as React from "react";
import * as Passage_types from "../types/passage_types.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as PassageCrewEntry from "./PassageCrewEntry.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Grid from "@material-ui/core/Grid";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      tabUpperSection: {
        marginLeft: "20px",
        marginRight: "20px"
      }
    });

function defaultNewCrewMember(param) {
  return Passage_types.default_passage_crew_member(UnixTimestamp.now(/* () */0) | 0, /* Crew */2, undefined, /* () */0);
}

function PassageExtraInfo(Props) {
  var passage = Props.passage;
  var setPassage = Props.setPassage;
  var $staropt$star = Props.disabled;
  var disabled = $staropt$star !== undefined ? $staropt$star : false;
  var classes = useStyles();
  var updateNotes = React.useCallback((function (notes) {
          return Curry._1(setPassage, (function (p) {
                        return {
                                id: p.id,
                                name: p.name,
                                from: p.from,
                                to_: p.to_,
                                notes: notes,
                                crew: p.crew,
                                logs: p.logs,
                                fields: p.fields,
                                retroactive_data: p.retroactive_data,
                                logs_stats: p.logs_stats
                              };
                      }));
        }), []);
  var handleCrewChange = function (apply) {
    return Curry._1(setPassage, (function (p) {
                  return {
                          id: p.id,
                          name: p.name,
                          from: p.from,
                          to_: p.to_,
                          notes: p.notes,
                          crew: Curry._1(apply, p.crew),
                          logs: p.logs,
                          fields: p.fields,
                          retroactive_data: p.retroactive_data,
                          logs_stats: p.logs_stats
                        };
                }));
  };
  return React.createElement(Box.default, {
              className: classes.tabUpperSection,
              children: React.createElement(Grid.default, {
                    container: true,
                    alignItems: "flex-start",
                    spacing: 3,
                    children: null
                  }, React.createElement(Grid.default, {
                        item: true,
                        xs: 12,
                        children: React.createElement(Notes.make, {
                              row: 6,
                              rowsMax: 6,
                              value: passage.notes,
                              onChange: updateNotes,
                              disabled: disabled
                            })
                      }), React.createElement(Grid.default, {
                        item: true,
                        xs: 12,
                        children: React.createElement(PassageCrewEntry.make, {
                              crew: passage.crew,
                              onChange: handleCrewChange,
                              disabled: disabled
                            })
                      }))
            });
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var make = PassageExtraInfo;

export {
  Mui ,
  Pa ,
  useStyles ,
  defaultNewCrewMember ,
  make ,
  
}
/* useStyles Not a pure module */
