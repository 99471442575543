// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as Passage from "./Passage.bs.js";
import * as Frequency from "./Frequency.bs.js";
import * as Maintenance from "./Maintenance.bs.js";
import * as DistanceUnit from "./DistanceUnit.bs.js";
import * as UnixTimestamp from "./UnixTimestamp.bs.js";

function computeNextMaintenance(item, passagesStats) {
  var frequency = item.frequency;
  var latestLog = Maintenance.latestLog(item);
  if (latestLog !== undefined && frequency !== undefined) {
    var match = frequency;
    var t = latestLog.unix_timestamp;
    if (typeof match === "number") {
      return /* NotComputable */0;
    } else {
      switch (match.tag | 0) {
        case /* Period */0 :
            var withinSeconds = t - UnixTimestamp.now(/* () */0) + Frequency.periodInSeconds(match[0]);
            return /* Time */Block.__(0, [withinSeconds]);
        case /* Engine_hours */1 :
            var ehSinceLatestLog = Passage.computeHoursSince(t, passagesStats);
            return /* EngineHours */Block.__(1, [match[0].value - ehSinceLatestLog]);
        case /* Distance */2 :
            var distanceSinceLatestLog = Passage.computeDistanceSince(t, passagesStats);
            return /* Distance */Block.__(2, [match[0].value - distanceSinceLatestLog]);
        case /* Before_date */3 :
            var withinSeconds$1 = match[0].unix_timestamp - UnixTimestamp.now(/* () */0);
            return /* Time */Block.__(0, [withinSeconds$1]);
        
      }
    }
  } else {
    return /* NotComputable */0;
  }
}

function nextMaintenanceToString(distanceUnit, param) {
  if (typeof param === "number") {
    return "";
  } else {
    switch (param.tag | 0) {
      case /* Time */0 :
          var t = param[0];
          if (t >= 0.0) {
            return Curry._1(Printf.sprintf(/* Format */[
                            /* String_literal */Block.__(11, [
                                "In ",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ]),
                            "In %s"
                          ]), Frequency.secondsToApproximatePeriodString(t));
          } else {
            return Curry._1(Printf.sprintf(/* Format */[
                            /* String_literal */Block.__(11, [
                                "Overdue by ",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ]),
                            "Overdue by %s"
                          ]), Frequency.secondsToApproximatePeriodString(t * -1.0));
          }
      case /* EngineHours */1 :
          var v = param[0];
          if (v >= 0.0) {
            return Curry._1(Printf.sprintf(/* Format */[
                            /* String_literal */Block.__(11, [
                                "In ",
                                /* Float */Block.__(8, [
                                    /* Float_f */0,
                                    /* Lit_padding */Block.__(0, [
                                        /* Right */1,
                                        0
                                      ]),
                                    /* Lit_precision */[1],
                                    /* String_literal */Block.__(11, [
                                        " eng. hrs",
                                        /* End_of_format */0
                                      ])
                                  ])
                              ]),
                            "In %0.1f eng. hrs"
                          ]), v);
          } else {
            return Curry._1(Printf.sprintf(/* Format */[
                            /* String_literal */Block.__(11, [
                                "Overdue by ",
                                /* Float */Block.__(8, [
                                    /* Float_f */0,
                                    /* Lit_padding */Block.__(0, [
                                        /* Right */1,
                                        0
                                      ]),
                                    /* Lit_precision */[1],
                                    /* String_literal */Block.__(11, [
                                        " eng. hrs",
                                        /* End_of_format */0
                                      ])
                                  ])
                              ]),
                            "Overdue by %0.1f eng. hrs"
                          ]), -1.0 * v);
          }
      case /* Distance */2 :
          var v$1 = param[0];
          if (v$1 >= 0.0) {
            return Curry._1(Printf.sprintf(/* Format */[
                            /* String_literal */Block.__(11, [
                                "In ",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ]),
                            "In %s"
                          ]), DistanceUnit.toDisplayString(v$1, distanceUnit));
          } else {
            return Curry._1(Printf.sprintf(/* Format */[
                            /* String_literal */Block.__(11, [
                                "Overdue by ",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ]),
                            "Overdue by %s"
                          ]), DistanceUnit.toDisplayString(-v$1, distanceUnit));
          }
      
    }
  }
}

var M = /* alias */0;

var F = /* alias */0;

export {
  M ,
  F ,
  computeNextMaintenance ,
  nextMaintenanceToString ,
  
}
/* Passage Not a pure module */
