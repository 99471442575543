// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as AddConfirmation from "./AddConfirmation.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function ignore(param) {
  return /* () */0;
}

var defaultChildren = null;

function AddMaintenanceItemLogConfirmation(Props) {
  var onClose = Props.onClose;
  var mode = Props.mode;
  AnalyticsEffect.useCurrentScreen("AddMaintenanceItemLogConfirmation");
  var mode$1 = mode !== 19559306 ? (
      mode >= 759635106 ? /* Done */[
          "Maintenance log added successfully",
          "Keep it up... it's hard but worth it."
        ] : /* Saving */1
    ) : /* Hidden */0;
  return React.createElement(AddConfirmation.make, {
              onClose: onClose,
              mode: mode$1
            });
}

var Mui = /* alias */0;

var make = AddMaintenanceItemLogConfirmation;

export {
  Mui ,
  concat ,
  ignore ,
  defaultChildren ,
  make ,
  
}
/* defaultChildren Not a pure module */
