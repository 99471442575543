// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as State from "../util/State.bs.js";
import * as React from "react";
import * as System from "../util/System.bs.js";
import * as AppMenu from "./AppMenu.bs.js";
import * as Maintenance from "../util/Maintenance.bs.js";
import * as Hidden from "@material-ui/core/Hidden";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function passageName(state) {
  var match = State.getSelectedPassage(state);
  var match$1 = match[0];
  if (match$1 !== undefined) {
    return match$1.name;
  } else {
    return "";
  }
}

function AppTitle(Props) {
  var appMenu = Props.appMenu;
  var state = Props.state;
  var selected = appMenu.selected;
  var contextText;
  if (selected !== 0) {
    switch (selected - 1 | 0) {
      case /* Home */0 :
          var match = State.getSelectedItem(state);
          if (match !== undefined) {
            var match$1 = match;
            contextText = Maintenance.shortDescription(match$1[1]).concat(" for ").concat(System.displayName(match$1[0]));
          } else {
            contextText = "Boat";
          }
          break;
      case /* Boat */1 :
          contextText = AppMenu.toString(selected);
          break;
      case /* Maintenance */2 :
          var match$2 = state.selectedPassage;
          contextText = typeof match$2 === "number" ? "Passage" : (
              match$2.tag ? passageName(state).concat(" Info") : passageName(state).concat(" Logs")
            );
          break;
      
    }
  } else {
    contextText = AppMenu.toString(selected);
  }
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Hidden.default, {
                  smDown: true,
                  children: "Boat Health - ".concat(contextText)
                }), React.createElement(Hidden.default, {
                  mdUp: true,
                  children: contextText
                }));
}

var Mui = /* alias */0;

var S = /* alias */0;

var Pa = /* alias */0;

var make = AppTitle;

export {
  Mui ,
  S ,
  Pa ,
  concat ,
  passageName ,
  make ,
  
}
/* State Not a pure module */
