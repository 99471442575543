// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";

function decode_speed_unit(json) {
  var match = Pbrt_bs.string(json, "speed_unit", "value");
  switch (match) {
    case "KILOMETERS_PER_HOURS" :
        return /* Kilometers_per_hours */2;
    case "" :
    case "KNOTS" :
        return /* Knots */0;
    case "METERS_PER_SECONDS" :
        return /* Meters_per_seconds */1;
    case "MILES_PER_HOURS" :
        return /* Miles_per_hours */3;
    default:
      return Pbrt_bs.E.malformed_variant("speed_unit");
  }
}

function encode_speed_unit(v) {
  switch (v) {
    case /* Knots */0 :
        return "KNOTS";
    case /* Meters_per_seconds */1 :
        return "METERS_PER_SECONDS";
    case /* Kilometers_per_hours */2 :
        return "KILOMETERS_PER_HOURS";
    case /* Miles_per_hours */3 :
        return "MILES_PER_HOURS";
    
  }
}

export {
  encode_speed_unit ,
  decode_speed_unit ,
  
}
/* Pbrt_bs Not a pure module */
