// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Id_bs from "./id_bs.bs.js";
import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";
import * as Frequency_bs from "./frequency_bs.bs.js";
import * as Maintenance_types from "./maintenance_types.bs.js";

function default_maintenance_item_mutable(param) {
  return {
          id: undefined,
          short_description: "",
          notes: "",
          frequency: undefined,
          type_: Maintenance_types.default_maintenance_item_type(/* () */0),
          logs: /* [] */0
        };
}

function decode_maintenance_item_type(json) {
  var match = Pbrt_bs.string(json, "maintenance_item_type", "value");
  switch (match) {
    case "CHECK" :
        return /* Check */1;
    case "" :
    case "OTHER" :
        return /* Other */0;
    case "PERFORM" :
        return /* Perform */4;
    case "RECORD" :
        return /* Record */3;
    case "REPLACE" :
        return /* Replace */2;
    default:
      return Pbrt_bs.E.malformed_variant("maintenance_item_type");
  }
}

function decode_maintenance_item_log_value(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i !== -1) {
      var match = keys[i];
      switch (match) {
        case "checkValue" :
            var json$1 = json["checkValue"];
            return /* Check_value */Block.__(0, [Pbrt_bs.bool(json$1, "maintenance_item_log_value", "Check_value")]);
        case "recordValue" :
            var json$2 = json["recordValue"];
            return /* Record_value */Block.__(1, [Pbrt_bs.$$float(json$2, "maintenance_item_log_value", "Record_value")]);
        default:
          _i = i - 1 | 0;
          continue ;
      }
    } else {
      return Pbrt_bs.E.malformed_variant("maintenance_item_log_value");
    }
  };
}

function decode_maintenance_item_log(json) {
  var v = {
    id: undefined,
    unix_timestamp: 0,
    notes: "",
    time_taken: undefined,
    cost: 0,
    value: undefined
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "cost" :
          var json$1 = json["cost"];
          v.cost = Pbrt_bs.$$float(json$1, "maintenance_item_log", "cost");
          break;
      case "id" :
          var json$2 = json["id"];
          v.id = Id_bs.decode_id(Pbrt_bs.object_(json$2, "maintenance_item_log", "id"));
          break;
      case "notes" :
          var json$3 = json["notes"];
          v.notes = Pbrt_bs.string(json$3, "maintenance_item_log", "notes");
          break;
      case "timeTaken" :
          var json$4 = json["timeTaken"];
          v.time_taken = Frequency_bs.decode_period(Pbrt_bs.object_(json$4, "maintenance_item_log", "time_taken"));
          break;
      case "unixTimestamp" :
          var json$5 = json["unixTimestamp"];
          v.unix_timestamp = Pbrt_bs.$$float(json$5, "maintenance_item_log", "unix_timestamp");
          break;
      case "value" :
          var json$6 = json["value"];
          v.value = decode_maintenance_item_log_value(Pbrt_bs.object_(json$6, "maintenance_item_log", "value"));
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          unix_timestamp: v.unix_timestamp,
          notes: v.notes,
          time_taken: v.time_taken,
          cost: v.cost,
          value: v.value
        };
}

function decode_maintenance_item(json) {
  var v = default_maintenance_item_mutable(/* () */0);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "frequency" :
          var json$1 = json["frequency"];
          v.frequency = Frequency_bs.decode_frequency(Pbrt_bs.object_(json$1, "maintenance_item", "frequency"));
          break;
      case "id" :
          var json$2 = json["id"];
          v.id = Id_bs.decode_id(Pbrt_bs.object_(json$2, "maintenance_item", "id"));
          break;
      case "logs" :
          var a = json["logs"];
          var a$1 = Pbrt_bs.array_(a, "maintenance_item", "logs");
          v.logs = $$Array.to_list($$Array.map((function (json) {
                      return decode_maintenance_item_log(Pbrt_bs.object_(json, "maintenance_item", "logs"));
                    }), a$1));
          break;
      case "notes" :
          var json$3 = json["notes"];
          v.notes = Pbrt_bs.string(json$3, "maintenance_item", "notes");
          break;
      case "shortDescription" :
          var json$4 = json["shortDescription"];
          v.short_description = Pbrt_bs.string(json$4, "maintenance_item", "short_description");
          break;
      case "type" :
          var json$5 = json["type"];
          v.type_ = decode_maintenance_item_type(json$5);
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          short_description: v.short_description,
          notes: v.notes,
          frequency: v.frequency,
          type_: v.type_,
          logs: v.logs
        };
}

function encode_maintenance_item_type(v) {
  switch (v) {
    case /* Other */0 :
        return "OTHER";
    case /* Check */1 :
        return "CHECK";
    case /* Replace */2 :
        return "REPLACE";
    case /* Record */3 :
        return "RECORD";
    case /* Perform */4 :
        return "PERFORM";
    
  }
}

function encode_maintenance_item_log_value(v) {
  var json = { };
  if (v.tag) {
    json["recordValue"] = v[0];
  } else {
    json["checkValue"] = v[0];
  }
  return json;
}

function encode_maintenance_item_log(v) {
  var json = { };
  var match = v.id;
  if (match !== undefined) {
    var json$prime = Id_bs.encode_id(match);
    json["id"] = json$prime;
  }
  json["unixTimestamp"] = v.unix_timestamp;
  json["notes"] = v.notes;
  var match$1 = v.time_taken;
  if (match$1 !== undefined) {
    var json$prime$1 = Frequency_bs.encode_period(match$1);
    json["timeTaken"] = json$prime$1;
  }
  json["cost"] = v.cost;
  var match$2 = v.value;
  if (match$2 !== undefined) {
    var json$prime$2 = encode_maintenance_item_log_value(match$2);
    json["value"] = json$prime$2;
  }
  return json;
}

function encode_maintenance_item(v) {
  var json = { };
  var match = v.id;
  if (match !== undefined) {
    var json$prime = Id_bs.encode_id(match);
    json["id"] = json$prime;
  }
  json["shortDescription"] = v.short_description;
  json["notes"] = v.notes;
  var match$1 = v.frequency;
  if (match$1 !== undefined) {
    var json$prime$1 = Frequency_bs.encode_frequency(match$1);
    json["frequency"] = json$prime$1;
  }
  json["type"] = encode_maintenance_item_type(v.type_);
  var __x__ = v.logs;
  if (__x__) {
    var logs$prime = $$Array.map(encode_maintenance_item_log, $$Array.of_list(__x__));
    json["logs"] = logs$prime;
  }
  return json;
}

export {
  encode_maintenance_item_type ,
  encode_maintenance_item_log_value ,
  encode_maintenance_item_log ,
  encode_maintenance_item ,
  decode_maintenance_item_type ,
  decode_maintenance_item_log_value ,
  decode_maintenance_item_log ,
  decode_maintenance_item ,
  
}
/* Id_bs Not a pure module */
