// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";

function decode_id(json) {
  var v = {
    firestore_id: "",
    firestore_path: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "firestoreId" :
          var json$1 = json["firestoreId"];
          v.firestore_id = Pbrt_bs.string(json$1, "id", "firestore_id");
          break;
      case "firestorePath" :
          var json$2 = json["firestorePath"];
          v.firestore_path = Pbrt_bs.string(json$2, "id", "firestore_path");
          break;
      default:
        
    }
  }
  return {
          firestore_id: v.firestore_id,
          firestore_path: v.firestore_path
        };
}

function encode_id(v) {
  var json = { };
  json["firestoreId"] = v.firestore_id;
  json["firestorePath"] = v.firestore_path;
  return json;
}

export {
  encode_id ,
  decode_id ,
  
}
/* Pbrt_bs Not a pure module */
