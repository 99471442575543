// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function default_pressure_unit(param) {
  return /* Millibar */0;
}

export {
  default_pressure_unit ,
  
}
/* No side effect */
