// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as IconButton from "@material-ui/core/IconButton";
import * as KeyboardBackspaceOutlined from "@material-ui/icons/KeyboardBackspaceOutlined";

function BackArrow(Props) {
  var onBack = Props.onBack;
  return React.createElement(IconButton.default, {
              color: "secondary",
              size: "medium",
              edge: "start",
              onClick: (function (param) {
                  return Curry._1(onBack, /* () */0);
                }),
              children: React.createElement(KeyboardBackspaceOutlined.default, {
                    fontSize: "default"
                  })
            });
}

var Mui = /* alias */0;

var make = BackArrow;

export {
  Mui ,
  make ,
  
}
/* react Not a pure module */
