// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Frequency from "./Frequency.bs.js";

function validate(param) {
  var frequency = param.frequency;
  var shortDescriptionError = param.short_description === "" ? "Short description is empty" : undefined;
  var frequencyError = frequency !== undefined ? Frequency.validate(frequency) : Frequency.noError;
  return {
          shortDescriptionError: shortDescriptionError,
          frequencyError: frequencyError
        };
}

var noError = {
  shortDescriptionError: undefined,
  frequencyError: Frequency.noError
};

function typeFromString(param) {
  switch (param) {
    case "Check" :
        return /* Check */1;
    case "Other" :
        return /* Other */0;
    case "Perform" :
        return /* Perform */4;
    case "Record" :
        return /* Record */3;
    case "Replace" :
        return /* Replace */2;
    default:
      return /* Other */0;
  }
}

function typeToString(param) {
  switch (param) {
    case /* Other */0 :
        return "Other";
    case /* Check */1 :
        return "Check";
    case /* Replace */2 :
        return "Replace";
    case /* Record */3 :
        return "Record";
    case /* Perform */4 :
        return "Perform";
    
  }
}

function shortDescription(param) {
  var type_ = param.type_;
  var short_description = param.short_description;
  if (type_ !== 0) {
    return typeToString(type_).concat(": ").concat(short_description);
  } else {
    return short_description;
  }
}

function reverseChrono(l, r) {
  return r.unix_timestamp - l.unix_timestamp | 0;
}

function latestLog(param) {
  return Belt_List.head(Belt_List.sortU(param.logs, reverseChrono));
}

export {
  noError ,
  validate ,
  typeFromString ,
  typeToString ,
  shortDescription ,
  latestLog ,
  
}
/* No side effect */
