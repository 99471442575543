// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Id_bs from "./id_bs.bs.js";
import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";
import * as Speed_unit_bs from "./speed_unit_bs.bs.js";
import * as Distance_unit_bs from "./distance_unit_bs.bs.js";
import * as Pressure_unit_bs from "./pressure_unit_bs.bs.js";
import * as Speed_unit_types from "./speed_unit_types.bs.js";
import * as Distance_unit_types from "./distance_unit_types.bs.js";
import * as Pressure_unit_types from "./pressure_unit_types.bs.js";
import * as Temperature_unit_bs from "./temperature_unit_bs.bs.js";
import * as Temperature_unit_types from "./temperature_unit_types.bs.js";

function default_user_mutable(param) {
  return {
          id: undefined,
          display_name: "",
          email: "",
          provider_id: "",
          distance_unit: Distance_unit_types.default_distance_unit(/* () */0),
          speed_unit: Speed_unit_types.default_speed_unit(/* () */0),
          pressure_unit: Pressure_unit_types.default_pressure_unit(/* () */0),
          temperature_unit: Temperature_unit_types.default_temperature_unit(/* () */0),
          is_anonymous: false,
          unix_timestamp: 0
        };
}

function decode_user(json) {
  var v = default_user_mutable(/* () */0);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "displayName" :
          var json$1 = json["displayName"];
          v.display_name = Pbrt_bs.string(json$1, "user", "display_name");
          break;
      case "distanceUnit" :
          var json$2 = json["distanceUnit"];
          v.distance_unit = Distance_unit_bs.decode_distance_unit(json$2);
          break;
      case "email" :
          var json$3 = json["email"];
          v.email = Pbrt_bs.string(json$3, "user", "email");
          break;
      case "id" :
          var json$4 = json["id"];
          v.id = Id_bs.decode_id(Pbrt_bs.object_(json$4, "user", "id"));
          break;
      case "isAnonymous" :
          var json$5 = json["isAnonymous"];
          v.is_anonymous = Pbrt_bs.bool(json$5, "user", "is_anonymous");
          break;
      case "pressureUnit" :
          var json$6 = json["pressureUnit"];
          v.pressure_unit = Pressure_unit_bs.decode_pressure_unit(json$6);
          break;
      case "providerId" :
          var json$7 = json["providerId"];
          v.provider_id = Pbrt_bs.string(json$7, "user", "provider_id");
          break;
      case "speedUnit" :
          var json$8 = json["speedUnit"];
          v.speed_unit = Speed_unit_bs.decode_speed_unit(json$8);
          break;
      case "temperatureUnit" :
          var json$9 = json["temperatureUnit"];
          v.temperature_unit = Temperature_unit_bs.decode_temperature_unit(json$9);
          break;
      case "unixTimestamp" :
          var json$10 = json["unixTimestamp"];
          v.unix_timestamp = Pbrt_bs.$$float(json$10, "user", "unix_timestamp");
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          display_name: v.display_name,
          email: v.email,
          provider_id: v.provider_id,
          distance_unit: v.distance_unit,
          speed_unit: v.speed_unit,
          pressure_unit: v.pressure_unit,
          temperature_unit: v.temperature_unit,
          is_anonymous: v.is_anonymous,
          unix_timestamp: v.unix_timestamp
        };
}

function encode_user(v) {
  var json = { };
  var match = v.id;
  if (match !== undefined) {
    var json$prime = Id_bs.encode_id(match);
    json["id"] = json$prime;
  }
  json["displayName"] = v.display_name;
  json["email"] = v.email;
  json["providerId"] = v.provider_id;
  json["distanceUnit"] = Distance_unit_bs.encode_distance_unit(v.distance_unit);
  json["speedUnit"] = Speed_unit_bs.encode_speed_unit(v.speed_unit);
  json["pressureUnit"] = Pressure_unit_bs.encode_pressure_unit(v.pressure_unit);
  json["temperatureUnit"] = Temperature_unit_bs.encode_temperature_unit(v.temperature_unit);
  json["isAnonymous"] = v.is_anonymous;
  json["unixTimestamp"] = v.unix_timestamp;
  return json;
}

export {
  encode_user ,
  decode_user ,
  
}
/* Id_bs Not a pure module */
