// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function default_length_unit(param) {
  return /* Meter */0;
}

function default_length($staropt$star, $staropt$star$1, param) {
  var length = $staropt$star !== undefined ? $staropt$star : 0;
  var unit_ = $staropt$star$1 !== undefined ? $staropt$star$1 : /* Meter */0;
  return {
          length: length,
          unit_: unit_
        };
}

function default_boat($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, $staropt$star$4, $staropt$star$5, param) {
  var id = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var name = $staropt$star$1 !== undefined ? $staropt$star$1 : "";
  var builder = $staropt$star$2 !== undefined ? $staropt$star$2 : "";
  var model = $staropt$star$3 !== undefined ? $staropt$star$3 : "";
  var year = $staropt$star$4 !== undefined ? $staropt$star$4 : 0;
  var loa = $staropt$star$5 !== undefined ? Caml_option.valFromOption($staropt$star$5) : undefined;
  return {
          id: id,
          name: name,
          builder: builder,
          model: model,
          year: year,
          loa: loa
        };
}

export {
  default_length_unit ,
  default_length ,
  default_boat ,
  
}
/* No side effect */
