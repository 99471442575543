// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as App from "./components/App.bs.js";
import * as React from "react";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as ServiceWorker from "./external/serviceWorker.bs.js";
import * as ThemeProvider from "@material-ui/styles/ThemeProvider";
import * as CreateMuiTheme from "@material-ui/core/styles/createMuiTheme";

var theme = CreateMuiTheme.default({
      palette: {
        primary: {
          main: "#01579b"
        },
        secondary: {
          main: "#f06292"
        },
        action: {
          selected: "rgba(1, 87, 155, 0.30)"
        }
      },
      overrides: {
        MuiTableCell: {
          body: {
            padding: "12px"
          },
          head: {
            padding: "12px"
          }
        }
      }
    });

ReactDOMRe.renderToElementWithId(React.createElement(ThemeProvider.default, {
          theme: theme,
          children: React.createElement(App.make, { })
        }), "root");

ServiceWorker.register_service_worker({
      onUpdate: (function (param) {
          console.log("Service worker onUpdate...");
          return /* () */0;
        }),
      onSuccess: (function (param) {
          console.log("Service worker onSuccess...");
          return /* () */0;
        })
    });

export {
  theme ,
  
}
/* theme Not a pure module */
