// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function toString(param) {
  switch (param) {
    case /* Celcius */0 :
        return "C";
    case /* Fahrenheit */1 :
        return "F";
    case /* Kelvin */2 :
        return "K";
    
  }
}

function convertFrom(value, param) {
  switch (param) {
    case /* Celcius */0 :
        return value + 273.15;
    case /* Fahrenheit */1 :
        return (value - 32.0) * 5.0 / 9.0 + 273.15;
    case /* Kelvin */2 :
        return value;
    
  }
}

function digits(param) {
  return 1;
}

function convertTo(value, param) {
  switch (param) {
    case /* Celcius */0 :
        return value - 273.15;
    case /* Fahrenheit */1 :
        return (value - 273.15) * 9.0 / 5.0 + 32.0;
    case /* Kelvin */2 :
        return value;
    
  }
}

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function toDisplayString(value, displayUnit) {
  var displayUnit$1 = Belt_Option.getWithDefault(displayUnit, /* Celcius */0);
  var value$1 = convertTo(value, displayUnit$1);
  return value$1.toFixed(1).concat(" ").concat(toString(displayUnit$1));
}

var TU = /* alias */0;

var allUnits = /* :: */[
  /* Celcius */0,
  /* :: */[
    /* Fahrenheit */1,
    /* :: */[
      /* Kelvin */2,
      /* [] */0
    ]
  ]
];

var $$default = /* Celcius */0;

export {
  TU ,
  toString ,
  convertFrom ,
  digits ,
  convertTo ,
  allUnits ,
  $$default ,
  $$default as default,
  concat ,
  toDisplayString ,
  
}
/* No side effect */
