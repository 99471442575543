// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as State from "../util/State.bs.js";
import * as React from "react";
import * as AppMenu from "./AppMenu.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as PassageLogs from "./PassageLogs.bs.js";
import * as BoatSelector from "./BoatSelector.bs.js";
import * as PassageInfoTab from "./PassageInfoTab.bs.js";
import * as PassageListTab from "./PassageListTab.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as SectionWithMargin from "./SectionWithMargin.bs.js";
import * as NoDataInGridMessage from "./NoDataInGridMessage.bs.js";
import * as Paper from "@material-ui/core/Paper";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var useStyles = MakeStyles.default({
      boatSelectorPaper: {
        padding: "10px"
      },
      dataPaper: {
        padding: "0px",
        marginTop: "15px"
      },
      tab: {
        margin: "auto 0px 10px 0px"
      }
    });

function getSelectedBoatId(state) {
  var match = State.getSelectedBoat(state);
  if (match !== undefined) {
    return match.data.id;
  }
  
}

function getBoatLatestEngineHours(state, boat) {
  if (boat !== undefined) {
    return State.getBoatLatestEngineHours(state, boat);
  }
  
}

function PassageSection(Props) {
  var state = Props.state;
  var stateDispatch = Props.stateDispatch;
  var classes = useStyles();
  AnalyticsEffect.useCurrentScreen(AppMenu.passageString);
  var match = React.useState((function () {
          return State.getSelectedPassage(state);
        }));
  var setPassage = match[1];
  var match$1 = match[0];
  var boat = match$1[1];
  var passage = match$1[0];
  React.useEffect((function () {
          Curry._1(setPassage, (function (param) {
                  return State.getSelectedPassage(state);
                }));
          return ;
        }), [state]);
  var isDataComplete = State.isComplete(state);
  var passageStats = passage !== undefined ? State.getPassageStats(state, passage) : undefined;
  var boatLatestEngineHoursCounter = getBoatLatestEngineHours(state, boat);
  var match$2 = React.useContext(AppContext.ctx);
  var onBack = function (param) {
    return Curry._1(stateDispatch, /* `SelectPassage */[
                303176914,
                /* NoneSelected */0
              ]);
  };
  var display;
  if (typeof match$2.user === "number") {
    display = /* LoginInfoMessage */188047408;
  } else if (passage !== undefined) {
    var passage$1 = passage;
    var match$3 = state.selectedPassage;
    display = typeof match$3 === "number" ? /* PassageList */-532846612 : (
        match$3.tag ? /* `SelectedPassageInfo */[
            526256481,
            passage$1
          ] : /* `SelectedPassageLogs */[
            559575138,
            passage$1
          ]
      );
  } else {
    display = /* PassageList */-532846612;
  }
  return React.createElement(SectionWithMargin.make, {
              children: null
            }, typeof display === "number" ? React.createElement(Paper.default, {
                    className: classes.boatSelectorPaper,
                    children: React.createElement(BoatSelector.make, {
                          state: state,
                          stateDispatch: stateDispatch
                        })
                  }) : null, React.createElement(Paper.default, {
                  className: classes.dataPaper,
                  children: typeof display === "number" ? (
                      display >= 188047408 ? React.createElement(NoDataInGridMessage.make, {
                              msg: /* NoData */Block.__(0, ["You must be logged in to manage your boat configuration"])
                            }) : React.createElement(React.Fragment, {
                              children: null
                            }, React.createElement(PassageListTab.make, {
                                  state: state,
                                  stateDispatch: stateDispatch
                                }), boat !== undefined ? null : React.createElement(NoDataInGridMessage.make, {
                                    msg: /* NoData */Block.__(0, ["You must first configure your boat in the Boat section"])
                                  }))
                    ) : (
                      display[0] >= 559575138 ? React.createElement(PassageLogs.make, {
                              passage: display[1],
                              isDataComplete: isDataComplete,
                              passageStats: passageStats,
                              boatLatestEngineHoursCounter: boatLatestEngineHoursCounter,
                              onBack: onBack
                            }) : React.createElement(PassageInfoTab.make, {
                              passage: display[1],
                              onBack: onBack
                            })
                    )
                }));
}

var B = /* alias */0;

var Pa = /* alias */0;

var Mui = /* alias */0;

var logsTab = "Logs";

var infoTab = "Info";

var passageList = "List";

var make = PassageSection;

export {
  B ,
  Pa ,
  Mui ,
  concat ,
  logsTab ,
  infoTab ,
  passageList ,
  useStyles ,
  getSelectedBoatId ,
  getBoatLatestEngineHours ,
  make ,
  
}
/* useStyles Not a pure module */
