// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Notes from "./Notes.bs.js";
import * as React from "react";
import * as Passage from "../util/Passage.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as EntryDialog from "./core/EntryDialog.bs.js";
import * as Passage_types from "../types/passage_types.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as DatetimePickers from "./core/DatetimePickers.bs.js";
import * as EngineHoursEntry from "./EngineHoursEntry.bs.js";
import * as GeoLocationEntry from "./GeoLocationEntry.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as Typography from "@material-ui/core/Typography";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var useStyles = MakeStyles.default({
      secondFieldGroupTitle: {
        marginBottom: "15px",
        marginTop: "15px"
      }
    });

function actionOfMode(onAction, param) {
  if (param.tag) {
    return EntryDialog.makeUpdate(onAction, "log");
  } else {
    return EntryDialog.makeAdd(onAction, "log");
  }
}

function makeMotorOffLogType(engine_hours_counter, param) {
  return /* Motor_off */Block.__(2, [{
              engine_hours_counter: engine_hours_counter
            }]);
}

function makeMotorOnLogType(engine_hours_counter, param) {
  return /* Motor_on */Block.__(1, [{
              engine_hours_counter: engine_hours_counter
            }]);
}

function motorLogType(logEntry) {
  var match = logEntry.type_;
  if (match !== undefined) {
    switch (match.tag | 0) {
      case /* Motor_on */1 :
          return /* On */0;
      case /* Motor_off */2 :
          return /* Off */1;
      case /* Position_data */0 :
      case /* Sail_change */3 :
          return ;
      
    }
  }
  
}

function defaultLogEntryOfMode(engine_hours_counter, mode) {
  if (mode.tag) {
    return mode[1];
  } else if (mode[1]) {
    return Passage_types.default_passage_log(undefined, UnixTimestamp.now(/* () */0), undefined, undefined, undefined, Caml_option.some(/* Motor_off */Block.__(2, [{
                        engine_hours_counter: engine_hours_counter
                      }])), /* () */0);
  } else {
    return Passage_types.default_passage_log(undefined, UnixTimestamp.now(/* () */0), undefined, undefined, undefined, Caml_option.some(/* Motor_on */Block.__(1, [{
                        engine_hours_counter: engine_hours_counter
                      }])), /* () */0);
  }
}

function screenOfMode(param) {
  if (param.tag) {
    return "Edit";
  } else {
    return "Add";
  }
}

function updateEngineHours(logEntry, value) {
  var match = motorLogType(logEntry);
  if (match !== undefined) {
    if (match) {
      return {
              id: logEntry.id,
              unix_timestamp: logEntry.unix_timestamp,
              geo_location: logEntry.geo_location,
              location_name: logEntry.location_name,
              remarks: logEntry.remarks,
              type_: /* Motor_off */Block.__(2, [{
                    engine_hours_counter: value
                  }])
            };
    } else {
      return {
              id: logEntry.id,
              unix_timestamp: logEntry.unix_timestamp,
              geo_location: logEntry.geo_location,
              location_name: logEntry.location_name,
              remarks: logEntry.remarks,
              type_: /* Motor_on */Block.__(1, [{
                    engine_hours_counter: value
                  }])
            };
    }
  } else {
    return logEntry;
  }
}

function ignoreOnClose(param) {
  return /* () */0;
}

function PassageMotorLogEntry(Props) {
  var mode = Props.mode;
  var isFieldEnabled = Props.isFieldEnabled;
  var isAtLeastOneFieldEnabled = Props.isAtLeastOneFieldEnabled;
  var $staropt$star = Props.onClose;
  var $staropt$star$1 = Props.boatLatestEngineHoursCounter;
  var onClose = $staropt$star !== undefined ? $staropt$star : ignoreOnClose;
  var boatLatestEngineHoursCounter = $staropt$star$1 !== undefined ? Caml_option.valFromOption($staropt$star$1) : undefined;
  var classes = useStyles();
  AnalyticsEffect.useCurrentScreen("PassageMotorLogEntry-".concat(screenOfMode(mode)));
  var match = React.useState((function () {
          return defaultLogEntryOfMode(boatLatestEngineHoursCounter, mode);
        }));
  var setLogEntry = match[1];
  var logEntry = match[0];
  React.useEffect((function (param) {
          Curry._1(setLogEntry, (function (param) {
                  return defaultLogEntryOfMode(boatLatestEngineHoursCounter, mode);
                }));
          return ;
        }), /* tuple */[
        mode,
        setLogEntry,
        boatLatestEngineHoursCounter
      ]);
  var updateUnixTimestamp = function (unix_timestamp) {
    return Curry._1(setLogEntry, (function (logEntry) {
                  return {
                          id: logEntry.id,
                          unix_timestamp: unix_timestamp,
                          geo_location: logEntry.geo_location,
                          location_name: logEntry.location_name,
                          remarks: logEntry.remarks,
                          type_: logEntry.type_
                        };
                }));
  };
  var updateGeoLocation = function (geo_location) {
    return Curry._1(setLogEntry, (function (logEntry) {
                  return {
                          id: logEntry.id,
                          unix_timestamp: logEntry.unix_timestamp,
                          geo_location: geo_location,
                          location_name: logEntry.location_name,
                          remarks: logEntry.remarks,
                          type_: logEntry.type_
                        };
                }));
  };
  var updateRemarks = function (remarks) {
    return Curry._1(setLogEntry, (function (logEntry) {
                  return {
                          id: logEntry.id,
                          unix_timestamp: logEntry.unix_timestamp,
                          geo_location: logEntry.geo_location,
                          location_name: logEntry.location_name,
                          remarks: remarks,
                          type_: logEntry.type_
                        };
                }));
  };
  var match$1 = logEntry.type_;
  var engineHours;
  if (match$1 !== undefined) {
    var match$2 = match$1;
    switch (match$2.tag | 0) {
      case /* Motor_on */1 :
      case /* Motor_off */2 :
          engineHours = match$2[0].engine_hours_counter;
          break;
      case /* Position_data */0 :
      case /* Sail_change */3 :
          engineHours = undefined;
          break;
      
    }
  } else {
    engineHours = undefined;
  }
  var match$3 = React.useContext(AppContext.ctx);
  var addErrorAlert = match$3.addErrorAlert;
  var firestore = match$3.firestore;
  var savePassageLog = function (param) {
    if (mode.tag) {
      Db.updatePassageLog(firestore, mode[0], logEntry, (function (param) {
              if (typeof param === "number") {
                return /* () */0;
              } else {
                Analytics.except("DB Edit - Passage Motor Log", param[1]);
                return Curry._1(addErrorAlert, "Error updating motor log");
              }
            }));
    } else {
      var passage = mode[0];
      var match = passage.id;
      if (match !== undefined) {
        Db.addPassageLog(match, firestore, passage, logEntry, (function (param) {
                if (param[0] >= 106380200) {
                  Analytics.except("DB Add - Passage Motor Log", param[1]);
                  return Curry._1(addErrorAlert, "Internal error saving passage log (app will reset)");
                } else {
                  return /* () */0;
                }
              }));
      }
      
    }
    return Curry._1(onClose, /* () */0);
  };
  var match$4 = motorLogType(logEntry);
  var title = match$4 !== undefined ? (
      match$4 ? EntryDialog.makeTitle("Motor OFF", "Motor OFF Log") : EntryDialog.makeTitle("Motor On", "Motor ON Log")
    ) : EntryDialog.makeTitle(undefined, "Error");
  var updateEngineHours$1 = function (engineHours) {
    return Curry._1(setLogEntry, (function (logEntry) {
                  return updateEngineHours(logEntry, engineHours);
                }));
  };
  var match$5 = motorLogType(logEntry);
  var match$6 = motorLogType(logEntry);
  return React.createElement(EntryDialog.make, {
              title: title,
              action: actionOfMode(savePassageLog, mode),
              onClose: onClose,
              children: React.createElement(Grid.default, {
                    container: true,
                    alignItems: "center",
                    spacing: 2,
                    children: null
                  }, React.createElement(Grid.default, {
                        item: true,
                        md: 6,
                        sm: 12,
                        children: React.createElement(DatetimePickers.make, {
                              unixTimestamp: logEntry.unix_timestamp,
                              onChange: updateUnixTimestamp
                            })
                      }), isFieldEnabled(/* Geo_location */13) ? React.createElement(Grid.default, {
                          item: true,
                          md: 6,
                          xs: 12,
                          children: React.createElement(GeoLocationEntry.make, {
                                position: logEntry.geo_location,
                                onChange: updateGeoLocation
                              })
                        }) : null, match$5 !== undefined ? React.createElement(Grid.default, {
                          item: true,
                          xs: 12,
                          children: React.createElement(EngineHoursEntry.make, {
                                value: engineHours,
                                onChange: updateEngineHours$1
                              })
                        }) : null, isAtLeastOneFieldEnabled(Passage.otherFields) ? React.createElement(Grid.default, {
                          className: classes.secondFieldGroupTitle,
                          item: true,
                          xs: 12,
                          children: React.createElement(Typography.default, {
                                color: "textPrimary",
                                variant: "subtitle1",
                                children: "Other Fields"
                              })
                        }) : null, isFieldEnabled(/* Remarks */12) ? React.createElement(Grid.default, {
                          item: true,
                          xs: 12,
                          children: React.createElement(Notes.make, {
                                value: logEntry.remarks,
                                onChange: updateRemarks,
                                label: "Remarks"
                              })
                        }) : null, match$6 !== undefined ? React.createElement(Grid.default, {
                          item: true,
                          xs: 12,
                          children: React.createElement(EngineHoursEntry.Alert.make, { })
                        }) : null)
            });
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var defaultBoatLatestEngineHoursCounter = undefined;

var make = PassageMotorLogEntry;

export {
  Mui ,
  Pa ,
  concat ,
  useStyles ,
  actionOfMode ,
  makeMotorOffLogType ,
  makeMotorOnLogType ,
  motorLogType ,
  defaultLogEntryOfMode ,
  screenOfMode ,
  updateEngineHours ,
  ignoreOnClose ,
  defaultBoatLatestEngineHoursCounter ,
  make ,
  
}
/* useStyles Not a pure module */
