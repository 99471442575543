// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";
import * as Events_types from "./events_types.bs.js";

function default_data_mutable(param) {
  return {
          type_: Events_types.default_data_type(/* () */0),
          action: Events_types.default_data_action(/* () */0),
          status: Events_types.default_data_status(/* () */0),
          passage_log_type: Events_types.default_data_passage_log_type(/* () */0),
          firestore_path: ""
        };
}

function default_navigation_mutable(param) {
  return {
          type_: Events_types.default_navigation_type(/* () */0),
          action: Events_types.default_navigation_action(/* () */0)
        };
}

function decode_login(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "method") {
      var json$1 = json["method"];
      v = Pbrt_bs.string(json$1, "login", "method_");
    }
    
  }
  return {
          method_: v
        };
}

function decode_exception_(json) {
  var v = {
    description: "",
    fatal: false
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "description" :
          var json$1 = json["description"];
          v.description = Pbrt_bs.string(json$1, "exception_", "description");
          break;
      case "fatal" :
          var json$2 = json["fatal"];
          v.fatal = Pbrt_bs.bool(json$2, "exception_", "fatal");
          break;
      default:
        
    }
  }
  return {
          description: v.description,
          fatal: v.fatal
        };
}

function decode_data_type(json) {
  var match = Pbrt_bs.string(json, "data_type", "value");
  switch (match) {
    case "BOAT" :
        return /* Boat */1;
    case "MAINTENANCE_ITEM" :
        return /* Maintenance_item */3;
    case "MAINTENANCE_ITEM_LOG" :
        return /* Maintenance_item_log */4;
    case "" :
    case "NO_DATA_TYPE" :
        return /* No_data_type */0;
    case "PASSAGE" :
        return /* Passage */5;
    case "PASSAGE_LOG" :
        return /* Passage_log */6;
    case "SYSTEM" :
        return /* System */2;
    default:
      return Pbrt_bs.E.malformed_variant("data_type");
  }
}

function decode_data_action(json) {
  var match = Pbrt_bs.string(json, "data_action", "value");
  switch (match) {
    case "ADDED" :
        return /* Added */4;
    case "DELETED" :
        return /* Deleted */6;
    case "" :
    case "NO_ACTION" :
        return /* No_action */0;
    case "OPEN_DIALOG_ENTRY_ADD" :
        return /* Open_dialog_entry_add */1;
    case "OPEN_DIALOG_ENTRY_DELETE" :
        return /* Open_dialog_entry_delete */3;
    case "OPEN_DIALOG_ENTRY_UPDATE" :
        return /* Open_dialog_entry_update */2;
    case "UPDATED" :
        return /* Updated */5;
    default:
      return Pbrt_bs.E.malformed_variant("data_action");
  }
}

function decode_data_status(json) {
  var match = Pbrt_bs.string(json, "data_status", "value");
  switch (match) {
    case "FAILURE" :
        return /* Failure */2;
    case "" :
    case "NO_STATUS" :
        return /* No_status */0;
    case "SUCCESS" :
        return /* Success */1;
    default:
      return Pbrt_bs.E.malformed_variant("data_status");
  }
}

function decode_data_passage_log_type(json) {
  var match = Pbrt_bs.string(json, "data_passage_log_type", "value");
  switch (match) {
    case "MOTOR_OFF" :
        return /* Motor_off */3;
    case "MOTOR_ON" :
        return /* Motor_on */2;
    case "" :
    case "NO_PASSAGE_LOG_TYPE" :
        return /* No_passage_log_type */0;
    case "POSITION_DATA" :
        return /* Position_data */1;
    default:
      return Pbrt_bs.E.malformed_variant("data_passage_log_type");
  }
}

function decode_data(json) {
  var v = default_data_mutable(/* () */0);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "action" :
          var json$1 = json["action"];
          v.action = decode_data_action(json$1);
          break;
      case "firestorePath" :
          var json$2 = json["firestorePath"];
          v.firestore_path = Pbrt_bs.string(json$2, "data", "firestore_path");
          break;
      case "passageLogType" :
          var json$3 = json["passageLogType"];
          v.passage_log_type = decode_data_passage_log_type(json$3);
          break;
      case "status" :
          var json$4 = json["status"];
          v.status = decode_data_status(json$4);
          break;
      case "type" :
          var json$5 = json["type"];
          v.type_ = decode_data_type(json$5);
          break;
      default:
        
    }
  }
  return {
          type_: v.type_,
          action: v.action,
          status: v.status,
          passage_log_type: v.passage_log_type,
          firestore_path: v.firestore_path
        };
}

function decode_navigation_type(json) {
  var match = Pbrt_bs.string(json, "navigation_type", "value");
  switch (match) {
    case "APP_BAR_LOGIN" :
        return /* App_bar_login */6;
    case "APP_BAR_MENU" :
        return /* App_bar_menu */5;
    case "APP_BAR_USER_MENU" :
        return /* App_bar_user_menu */7;
    case "APP_BAR_USER_MENU_ABOUT" :
        return /* App_bar_user_menu_about */12;
    case "APP_BAR_USER_MENU_LOGIN" :
        return /* App_bar_user_menu_login */8;
    case "APP_BAR_USER_MENU_LOGOUT" :
        return /* App_bar_user_menu_logout */9;
    case "APP_BAR_USER_MENU_PRIVACY_POLICY" :
        return /* App_bar_user_menu_privacy_policy */10;
    case "APP_BAR_USER_MENU_TERMS_AND_CONDITIONS" :
        return /* App_bar_user_menu_terms_and_conditions */11;
    case "APP_MENU_BOAT" :
        return /* App_menu_boat */1;
    case "APP_MENU_HOME" :
        return /* App_menu_home */4;
    case "APP_MENU_MAINTENANCE" :
        return /* App_menu_maintenance */2;
    case "APP_MENU_PASSAGE" :
        return /* App_menu_passage */3;
    case "BOAT_SELECTOR_NAME" :
        return /* Boat_selector_name */15;
    case "BOAT_SELECTOR_SETTING_MENU" :
        return /* Boat_selector_setting_menu */13;
    case "BOAT_SELECTOR_SETTING_MENU_SHOW_BOAT_INFO" :
        return /* Boat_selector_setting_menu_show_boat_info */14;
    case "MAINTENANCE_ITEM_HEADER_DATA" :
        return /* Maintenance_item_header_data */20;
    case "MAINTENANCE_ITEM_LOG_HEADER_DATA" :
        return /* Maintenance_item_log_header_data */21;
    case "MAINTENANCE_ITEM_LOG_HEADER_TIME" :
        return /* Maintenance_item_log_header_time */22;
    case "MAINTENANCE_ITEM_LOG_MORE_MENU" :
        return /* Maintenance_item_log_more_menu */23;
    case "MAINTENANCE_ITEM_ROW" :
        return /* Maintenance_item_row */16;
    case "MAINTENANCE_ITEM_ROW_EXPAND" :
        return /* Maintenance_item_row_expand */17;
    case "MAINTENANCE_ITEM_ROW_MORE_MENU" :
        return /* Maintenance_item_row_more_menu */18;
    case "MAINTENANCE_ITEM_ROW_MORE_MENU_SHOW_INFO" :
        return /* Maintenance_item_row_more_menu_show_info */19;
    case "" :
    case "NO_NAVIGATION_TYPE" :
        return /* No_navigation_type */0;
    case "PASSAGE_DATA_ENTRY_CREW_ADD" :
        return /* Passage_data_entry_crew_add */28;
    case "PASSAGE_DATA_ENTRY_CREW_DELETE" :
        return /* Passage_data_entry_crew_delete */29;
    case "PASSAGE_DATA_ENTRY_EXPAND_NAVIGATION_FIELDS" :
        return /* Passage_data_entry_expand_navigation_fields */31;
    case "PASSAGE_DATA_ENTRY_EXPAND_OTHER_FIELDS" :
        return /* Passage_data_entry_expand_other_fields */32;
    case "PASSAGE_DATA_ENTRY_EXPAND_WEATHER_FIELDS" :
        return /* Passage_data_entry_expand_weather_fields */30;
    case "PASSAGE_DATA_ENTRY_NEXT" :
        return /* Passage_data_entry_next */26;
    case "PASSAGE_DATA_ENTRY_PREVIOUS" :
        return /* Passage_data_entry_previous */27;
    case "PASSAGE_HEADER_DATA" :
        return /* Passage_header_data */33;
    case "PASSAGE_ROW" :
        return /* Passage_row */34;
    case "PASSAGE_ROW_EXPAND" :
        return /* Passage_row_expand */35;
    case "PASSAGE_ROW_INFO" :
        return /* Passage_row_info */36;
    case "PASSAGE_ROW_MORE_MENU" :
        return /* Passage_row_more_menu */37;
    case "PASSAGE_TYPE_SELECTION_LIVE" :
        return /* Passage_type_selection_live */25;
    case "PASSAGE_TYPE_SELECTION_RETROACTIVE" :
        return /* Passage_type_selection_retroactive */24;
    default:
      return Pbrt_bs.E.malformed_variant("navigation_type");
  }
}

function decode_navigation_action(json) {
  var match = Pbrt_bs.string(json, "navigation_action", "value");
  switch (match) {
    case "" :
    case "CLICKED" :
        return /* Clicked */0;
    case "SELECTION_CHANGED" :
        return /* Selection_changed */1;
    default:
      return Pbrt_bs.E.malformed_variant("navigation_action");
  }
}

function decode_navigation(json) {
  var v = default_navigation_mutable(/* () */0);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "action" :
          var json$1 = json["action"];
          v.action = decode_navigation_action(json$1);
          break;
      case "type" :
          var json$2 = json["type"];
          v.type_ = decode_navigation_type(json$2);
          break;
      default:
        
    }
  }
  return {
          type_: v.type_,
          action: v.action
        };
}

function encode_login(v) {
  var json = { };
  json["method"] = v.method_;
  return json;
}

function encode_exception_(v) {
  var json = { };
  json["description"] = v.description;
  json["fatal"] = v.fatal;
  return json;
}

function encode_data_type(v) {
  switch (v) {
    case /* No_data_type */0 :
        return "NO_DATA_TYPE";
    case /* Boat */1 :
        return "BOAT";
    case /* System */2 :
        return "SYSTEM";
    case /* Maintenance_item */3 :
        return "MAINTENANCE_ITEM";
    case /* Maintenance_item_log */4 :
        return "MAINTENANCE_ITEM_LOG";
    case /* Passage */5 :
        return "PASSAGE";
    case /* Passage_log */6 :
        return "PASSAGE_LOG";
    
  }
}

function encode_data_action(v) {
  switch (v) {
    case /* No_action */0 :
        return "NO_ACTION";
    case /* Open_dialog_entry_add */1 :
        return "OPEN_DIALOG_ENTRY_ADD";
    case /* Open_dialog_entry_update */2 :
        return "OPEN_DIALOG_ENTRY_UPDATE";
    case /* Open_dialog_entry_delete */3 :
        return "OPEN_DIALOG_ENTRY_DELETE";
    case /* Added */4 :
        return "ADDED";
    case /* Updated */5 :
        return "UPDATED";
    case /* Deleted */6 :
        return "DELETED";
    
  }
}

function encode_data_status(v) {
  switch (v) {
    case /* No_status */0 :
        return "NO_STATUS";
    case /* Success */1 :
        return "SUCCESS";
    case /* Failure */2 :
        return "FAILURE";
    
  }
}

function encode_data_passage_log_type(v) {
  switch (v) {
    case /* No_passage_log_type */0 :
        return "NO_PASSAGE_LOG_TYPE";
    case /* Position_data */1 :
        return "POSITION_DATA";
    case /* Motor_on */2 :
        return "MOTOR_ON";
    case /* Motor_off */3 :
        return "MOTOR_OFF";
    
  }
}

function encode_data(v) {
  var json = { };
  json["type"] = encode_data_type(v.type_);
  json["action"] = encode_data_action(v.action);
  json["status"] = encode_data_status(v.status);
  json["passageLogType"] = encode_data_passage_log_type(v.passage_log_type);
  json["firestorePath"] = v.firestore_path;
  return json;
}

function encode_navigation_type(v) {
  switch (v) {
    case /* No_navigation_type */0 :
        return "NO_NAVIGATION_TYPE";
    case /* App_menu_boat */1 :
        return "APP_MENU_BOAT";
    case /* App_menu_maintenance */2 :
        return "APP_MENU_MAINTENANCE";
    case /* App_menu_passage */3 :
        return "APP_MENU_PASSAGE";
    case /* App_menu_home */4 :
        return "APP_MENU_HOME";
    case /* App_bar_menu */5 :
        return "APP_BAR_MENU";
    case /* App_bar_login */6 :
        return "APP_BAR_LOGIN";
    case /* App_bar_user_menu */7 :
        return "APP_BAR_USER_MENU";
    case /* App_bar_user_menu_login */8 :
        return "APP_BAR_USER_MENU_LOGIN";
    case /* App_bar_user_menu_logout */9 :
        return "APP_BAR_USER_MENU_LOGOUT";
    case /* App_bar_user_menu_privacy_policy */10 :
        return "APP_BAR_USER_MENU_PRIVACY_POLICY";
    case /* App_bar_user_menu_terms_and_conditions */11 :
        return "APP_BAR_USER_MENU_TERMS_AND_CONDITIONS";
    case /* App_bar_user_menu_about */12 :
        return "APP_BAR_USER_MENU_ABOUT";
    case /* Boat_selector_setting_menu */13 :
        return "BOAT_SELECTOR_SETTING_MENU";
    case /* Boat_selector_setting_menu_show_boat_info */14 :
        return "BOAT_SELECTOR_SETTING_MENU_SHOW_BOAT_INFO";
    case /* Boat_selector_name */15 :
        return "BOAT_SELECTOR_NAME";
    case /* Maintenance_item_row */16 :
        return "MAINTENANCE_ITEM_ROW";
    case /* Maintenance_item_row_expand */17 :
        return "MAINTENANCE_ITEM_ROW_EXPAND";
    case /* Maintenance_item_row_more_menu */18 :
        return "MAINTENANCE_ITEM_ROW_MORE_MENU";
    case /* Maintenance_item_row_more_menu_show_info */19 :
        return "MAINTENANCE_ITEM_ROW_MORE_MENU_SHOW_INFO";
    case /* Maintenance_item_header_data */20 :
        return "MAINTENANCE_ITEM_HEADER_DATA";
    case /* Maintenance_item_log_header_data */21 :
        return "MAINTENANCE_ITEM_LOG_HEADER_DATA";
    case /* Maintenance_item_log_header_time */22 :
        return "MAINTENANCE_ITEM_LOG_HEADER_TIME";
    case /* Maintenance_item_log_more_menu */23 :
        return "MAINTENANCE_ITEM_LOG_MORE_MENU";
    case /* Passage_type_selection_retroactive */24 :
        return "PASSAGE_TYPE_SELECTION_RETROACTIVE";
    case /* Passage_type_selection_live */25 :
        return "PASSAGE_TYPE_SELECTION_LIVE";
    case /* Passage_data_entry_next */26 :
        return "PASSAGE_DATA_ENTRY_NEXT";
    case /* Passage_data_entry_previous */27 :
        return "PASSAGE_DATA_ENTRY_PREVIOUS";
    case /* Passage_data_entry_crew_add */28 :
        return "PASSAGE_DATA_ENTRY_CREW_ADD";
    case /* Passage_data_entry_crew_delete */29 :
        return "PASSAGE_DATA_ENTRY_CREW_DELETE";
    case /* Passage_data_entry_expand_weather_fields */30 :
        return "PASSAGE_DATA_ENTRY_EXPAND_WEATHER_FIELDS";
    case /* Passage_data_entry_expand_navigation_fields */31 :
        return "PASSAGE_DATA_ENTRY_EXPAND_NAVIGATION_FIELDS";
    case /* Passage_data_entry_expand_other_fields */32 :
        return "PASSAGE_DATA_ENTRY_EXPAND_OTHER_FIELDS";
    case /* Passage_header_data */33 :
        return "PASSAGE_HEADER_DATA";
    case /* Passage_row */34 :
        return "PASSAGE_ROW";
    case /* Passage_row_expand */35 :
        return "PASSAGE_ROW_EXPAND";
    case /* Passage_row_info */36 :
        return "PASSAGE_ROW_INFO";
    case /* Passage_row_more_menu */37 :
        return "PASSAGE_ROW_MORE_MENU";
    
  }
}

function encode_navigation_action(v) {
  if (v) {
    return "SELECTION_CHANGED";
  } else {
    return "CLICKED";
  }
}

function encode_navigation(v) {
  var json = { };
  json["type"] = encode_navigation_type(v.type_);
  json["action"] = v.action ? "SELECTION_CHANGED" : "CLICKED";
  return json;
}

export {
  encode_login ,
  encode_exception_ ,
  encode_data_type ,
  encode_data_action ,
  encode_data_status ,
  encode_data_passage_log_type ,
  encode_data ,
  encode_navigation_type ,
  encode_navigation_action ,
  encode_navigation ,
  decode_login ,
  decode_exception_ ,
  decode_data_type ,
  decode_data_action ,
  decode_data_status ,
  decode_data_passage_log_type ,
  decode_data ,
  decode_navigation_type ,
  decode_navigation_action ,
  decode_navigation ,
  
}
/* Pbrt_bs Not a pure module */
