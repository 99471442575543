// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Events_types from "../types/events_types.bs.js";
import * as PassageFields from "./PassageFields.bs.js";
import * as PassageExtraInfo from "./PassageExtraInfo.bs.js";
import * as PassageInfoEntry from "./PassageInfoEntry.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Grid from "@material-ui/core/Grid";
import * as Step from "@material-ui/core/Step";
import * as Button from "@material-ui/core/Button";
import * as Hidden from "@material-ui/core/Hidden";
import * as Stepper from "@material-ui/core/Stepper";
import * as StepLabel from "@material-ui/core/StepLabel";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      button: {
        width: "100%",
        margin: "5px 10px 10px auto",
        textAlign: "right"
      },
      stepper: {
        padding: 0,
        marginBottom: 10
      },
      content: {
        marginTop: 15
      }
    });

var liveSteps = [
  /* tuple */[
    "Basic Info",
    "Info"
  ],
  /* tuple */[
    "Notes && Crew",
    "Crew"
  ],
  /* tuple */[
    "Entry fields",
    "Fields"
  ]
];

var retroactiveSteps = [
  /* tuple */[
    "Summary",
    "Summary"
  ],
  /* tuple */[
    "Notes && Crew",
    "Crew"
  ]
];

function useLocal(param) {
  var match = React.useState((function () {
          return PassageInfoEntry.noError;
        }));
  return /* tuple */[
          match[0],
          match[1]
        ];
}

function validate(local, p) {
  var e = PassageInfoEntry.validate(p);
  Curry._1(local[1], (function (param) {
          return e;
        }));
  return Caml_obj.caml_equal(e, PassageInfoEntry.noError);
}

function PassageDataEntry(Props) {
  var passage = Props.passage;
  var setPassage = Props.setPassage;
  var local = Props.local;
  var type_ = Props.type_;
  var classes = useStyles();
  var setInfoError = local[1];
  var match = React.useState((function () {
          return 0;
        }));
  var setActiveStep = match[1];
  var activeStep = match[0];
  var prev = function (param) {
    Analytics.nav(Events_types.default_navigation(/* Passage_data_entry_previous */27, undefined, /* () */0));
    return Curry._1(setActiveStep, (function (x) {
                  return x - 1 | 0;
                }));
  };
  var next = function (param) {
    Analytics.nav(Events_types.default_navigation(/* Passage_data_entry_next */26, undefined, /* () */0));
    if (activeStep !== 0) {
      return Curry._1(setActiveStep, (function (x) {
                    return x + 1 | 0;
                  }));
    } else {
      var e = PassageInfoEntry.validate(passage);
      Curry._1(setInfoError, (function (param) {
              return e;
            }));
      if (Caml_obj.caml_equal(e, PassageInfoEntry.noError)) {
        return Curry._1(setActiveStep, (function (x) {
                      return x + 1 | 0;
                    }));
      } else {
        return /* () */0;
      }
    }
  };
  var allSteps = type_ ? liveSteps : retroactiveSteps;
  var allStepsLen = allSteps.length;
  var tmp;
  switch (activeStep) {
    case 0 :
        tmp = React.createElement(PassageInfoEntry.make, {
              passage: passage,
              setPassage: setPassage,
              error: local[0],
              type_: type_
            });
        break;
    case 1 :
        tmp = React.createElement(PassageExtraInfo.make, {
              passage: passage,
              setPassage: setPassage
            });
        break;
    case 2 :
        tmp = React.createElement(PassageFields.make, {
              passage: passage,
              setPassage: setPassage
            });
        break;
    default:
      tmp = null;
  }
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Stepper.default, {
                  className: classes.stepper,
                  activeStep: activeStep,
                  children: Belt_Array.mapU(allSteps, (function (param) {
                          return React.createElement(Step.default, {
                                      children: React.createElement(StepLabel.default, {
                                            children: null
                                          }, React.createElement(Hidden.default, {
                                                smUp: true,
                                                children: param[1]
                                              }), React.createElement(Hidden.default, {
                                                xsDown: true,
                                                children: param[0]
                                              }))
                                    });
                        }))
                }), React.createElement(Grid.default, {
                  container: true,
                  justify: "flex-start",
                  alignItems: "center",
                  spacing: 3,
                  children: null
                }, React.createElement(Grid.default, {
                      item: true,
                      sm: 2,
                      xs: 4,
                      children: React.createElement(Button.default, {
                            className: classes.button,
                            color: "primary",
                            variant: "outlined",
                            disabled: activeStep === 0,
                            onClick: prev,
                            children: "Prev"
                          })
                    }), React.createElement(Grid.default, {
                      item: true,
                      sm: 2,
                      xs: 4,
                      children: React.createElement(Button.default, {
                            className: classes.button,
                            color: "secondary",
                            variant: "outlined",
                            disabled: activeStep === (allStepsLen - 1 | 0),
                            onClick: next,
                            children: "Next"
                          })
                    })), React.createElement(Box.default, {
                  className: classes.content,
                  children: tmp
                }));
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var E = /* alias */0;

var make = PassageDataEntry;

export {
  Mui ,
  Pa ,
  E ,
  useStyles ,
  liveSteps ,
  retroactiveSteps ,
  useLocal ,
  validate ,
  make ,
  
}
/* useStyles Not a pure module */
