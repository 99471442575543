// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Printf from "bs-platform/lib/es6/printf.js";

function now(param) {
  var msSinceEpoch = Date.now();
  return msSinceEpoch / 1000.0;
}

function toDate(secSincEpoch) {
  return new Date(secSincEpoch * 1000);
}

function fromDate(d) {
  var msSinceEpoch = d.getTime();
  return msSinceEpoch / 1000.0;
}

function monthString(param) {
  switch (param) {
    case 0 :
        return "Jan";
    case 1 :
        return "Feb";
    case 2 :
        return "Mar";
    case 3 :
        return "Apr";
    case 4 :
        return "May";
    case 5 :
        return "Jun";
    case 6 :
        return "Jul";
    case 7 :
        return "Aug";
    case 8 :
        return "Sep";
    case 9 :
        return "Oct";
    case 10 :
        return "Nov";
    case 11 :
        return "Dec";
    default:
      return "";
  }
}

function formatToDateAndTime(d) {
  var d$1 = new Date(d * 1000);
  return Curry._5(Printf.sprintf(/* Format */[
                  /* Float */Block.__(8, [
                      /* Float_f */0,
                      /* Lit_padding */Block.__(0, [
                          /* Right */1,
                          4
                        ]),
                      /* Lit_precision */[0],
                      /* Char_literal */Block.__(12, [
                          /* " " */32,
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* Char_literal */Block.__(12, [
                                  /* " " */32,
                                  /* Float */Block.__(8, [
                                      /* Float_f */0,
                                      /* Lit_padding */Block.__(0, [
                                          /* Zeros */2,
                                          2
                                        ]),
                                      /* Lit_precision */[0],
                                      /* Char_literal */Block.__(12, [
                                          /* " " */32,
                                          /* Float */Block.__(8, [
                                              /* Float_f */0,
                                              /* Lit_padding */Block.__(0, [
                                                  /* Zeros */2,
                                                  2
                                                ]),
                                              /* Lit_precision */[0],
                                              /* Char_literal */Block.__(12, [
                                                  /* ":" */58,
                                                  /* Float */Block.__(8, [
                                                      /* Float_f */0,
                                                      /* Lit_padding */Block.__(0, [
                                                          /* Zeros */2,
                                                          2
                                                        ]),
                                                      /* Lit_precision */[0],
                                                      /* End_of_format */0
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ]),
                  "%4.f %s %02.f %02.f:%02.f"
                ]), d$1.getFullYear(), monthString(d$1.getMonth() | 0), d$1.getDate(), d$1.getHours(), d$1.getMinutes());
}

function formatToDate(d) {
  var d$1 = new Date(d * 1000);
  return Curry._3(Printf.sprintf(/* Format */[
                  /* Float */Block.__(8, [
                      /* Float_f */0,
                      /* Lit_padding */Block.__(0, [
                          /* Right */1,
                          4
                        ]),
                      /* Lit_precision */[0],
                      /* Char_literal */Block.__(12, [
                          /* " " */32,
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* Char_literal */Block.__(12, [
                                  /* " " */32,
                                  /* Float */Block.__(8, [
                                      /* Float_f */0,
                                      /* Lit_padding */Block.__(0, [
                                          /* Zeros */2,
                                          2
                                        ]),
                                      /* Lit_precision */[0],
                                      /* End_of_format */0
                                    ])
                                ])
                            ])
                        ])
                    ]),
                  "%4.f %s %02.f"
                ]), d$1.getFullYear(), monthString(d$1.getMonth() | 0), d$1.getDate());
}

export {
  now ,
  toDate ,
  fromDate ,
  monthString ,
  formatToDateAndTime ,
  formatToDate ,
  
}
/* No side effect */
