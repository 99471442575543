// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";

function decode_temperature_unit(json) {
  var match = Pbrt_bs.string(json, "temperature_unit", "value");
  switch (match) {
    case "" :
    case "CELCIUS" :
        return /* Celcius */0;
    case "FAHRENHEIT" :
        return /* Fahrenheit */1;
    case "KELVIN" :
        return /* Kelvin */2;
    default:
      return Pbrt_bs.E.malformed_variant("temperature_unit");
  }
}

function encode_temperature_unit(v) {
  switch (v) {
    case /* Celcius */0 :
        return "CELCIUS";
    case /* Fahrenheit */1 :
        return "FAHRENHEIT";
    case /* Kelvin */2 :
        return "KELVIN";
    
  }
}

export {
  encode_temperature_unit ,
  decode_temperature_unit ,
  
}
/* Pbrt_bs Not a pure module */
