// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Passage_bs from "../types/passage_bs.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Passage_types from "../types/passage_types.bs.js";

function make(param) {
  return Passage_types.default_passage(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);
}

function id(p) {
  return Belt_Option.getExn(p.id);
}

function withId(p, id) {
  return {
          id: id,
          name: p.name,
          from: p.from,
          to_: p.to_,
          notes: p.notes,
          crew: p.crew,
          logs: p.logs,
          fields: p.fields,
          retroactive_data: p.retroactive_data,
          logs_stats: p.logs_stats
        };
}

function prepareForDb(p) {
  return {
          id: undefined,
          name: p.name,
          from: p.from,
          to_: p.to_,
          notes: p.notes,
          crew: p.crew,
          logs: /* [] */0,
          fields: p.fields,
          retroactive_data: p.retroactive_data,
          logs_stats: p.logs_stats
        };
}

var encode = Passage_bs.encode_passage;

var decode = Passage_bs.decode_passage;

export {
  make ,
  encode ,
  decode ,
  id ,
  withId ,
  prepareForDb ,
  
}
/* Passage_bs Not a pure module */
