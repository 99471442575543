// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Ignore from "../types/Ignore.bs.js";
import * as Maintenance from "../util/Maintenance.bs.js";
import * as AddConfirmation from "./AddConfirmation.bs.js";
import * as Button from "@material-ui/core/Button";
import * as Dialog from "@material-ui/core/Dialog";
import * as DialogTitle from "@material-ui/core/DialogTitle";
import * as DialogActions from "@material-ui/core/DialogActions";
import * as DialogContent from "@material-ui/core/DialogContent";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function ignore(param) {
  return /* () */0;
}

var defaultChildren = null;

var emptyString = "";

function actionOfType(param) {
  if (param >= 795952288) {
    return "added";
  } else {
    return "updated";
  }
}

function AddMaintenanceItemConfirmation(Props) {
  var onClose = Props.onClose;
  var mode = Props.mode;
  var $staropt$star = Props.onAddPreviousLog;
  var onAddPreviousLog = $staropt$star !== undefined ? $staropt$star : Ignore.v2;
  var match;
  if (typeof mode === "number") {
    match = mode >= 19559306 ? /* tuple */[
        emptyString,
        emptyString
      ] : /* tuple */[
        AddConfirmation.savingTitle,
        AddConfirmation.savingMsg
      ];
  } else {
    var match$1 = mode[1];
    var type_ = match$1[2];
    var title = React.createElement(React.Fragment, {
          children: null
        }, React.createElement("strong", undefined, Maintenance.shortDescription(match$1[1])), " ".concat(actionOfType(type_)).concat(" successfully"));
    var msg = type_ >= 795952288 ? "You can now add a log for the last time you did that maintenance" : null;
    match = /* tuple */[
      title,
      msg
    ];
  }
  var _open = mode !== 19559306;
  var disabled = mode === -674467366;
  var onAddPreviousLog$1;
  if (typeof mode === "number") {
    onAddPreviousLog$1 = Ignore.v1;
  } else {
    var match$2 = mode[1];
    var item = match$2[1];
    var system = match$2[0];
    onAddPreviousLog$1 = (function (param) {
        return Curry._2(onAddPreviousLog, system, item);
      });
  }
  var showAddPreviousLog = typeof mode === "number" || mode[0] !== 759635106 ? false : mode[1][2] === 795952288;
  return React.createElement(Dialog.default, {
              open: _open,
              fullWidth: true,
              maxWidth: "xs",
              onClose: (function (param, param$1) {
                  return Curry._1(onClose, /* () */0);
                }),
              children: null
            }, React.createElement(DialogTitle.default, {
                  children: match[0]
                }), React.createElement(DialogContent.default, {
                  children: match[1]
                }), React.createElement(DialogActions.default, {
                  children: null
                }, React.createElement(Button.default, {
                      color: "default",
                      variant: "outlined",
                      disabled: disabled,
                      onClick: (function (param) {
                          return Curry._1(onClose, /* () */0);
                        }),
                      children: "close"
                    }), showAddPreviousLog ? React.createElement(Button.default, {
                        color: "primary",
                        variant: "outlined",
                        disabled: disabled,
                        onClick: onAddPreviousLog$1,
                        children: "Add previous log"
                      }) : null));
}

var Mui = /* alias */0;

var make = AddMaintenanceItemConfirmation;

export {
  Mui ,
  concat ,
  ignore ,
  defaultChildren ,
  emptyString ,
  actionOfType ,
  make ,
  
}
/* defaultChildren Not a pure module */
