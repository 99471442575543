// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";

function decode_pressure_unit(json) {
  var match = Pbrt_bs.string(json, "pressure_unit", "value");
  switch (match) {
    case "INCH_OF_MERCURY" :
        return /* Inch_of_mercury */2;
    case "" :
    case "MILLIBAR" :
        return /* Millibar */0;
    case "MILLIMETERS_OF_MERCURY" :
        return /* Millimeters_of_mercury */3;
    case "PASCAL" :
        return /* Pascal */1;
    default:
      return Pbrt_bs.E.malformed_variant("pressure_unit");
  }
}

function encode_pressure_unit(v) {
  switch (v) {
    case /* Millibar */0 :
        return "MILLIBAR";
    case /* Pascal */1 :
        return "PASCAL";
    case /* Inch_of_mercury */2 :
        return "INCH_OF_MERCURY";
    case /* Millimeters_of_mercury */3 :
        return "MILLIMETERS_OF_MERCURY";
    
  }
}

export {
  encode_pressure_unit ,
  decode_pressure_unit ,
  
}
/* Pbrt_bs Not a pure module */
