// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Id_bs from "./id_bs.bs.js";
import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";
import * as Boat_types from "./boat_types.bs.js";

function decode_length_unit(json) {
  var match = Pbrt_bs.string(json, "length_unit", "value");
  switch (match) {
    case "FEET" :
        return /* Feet */1;
    case "" :
    case "METER" :
        return /* Meter */0;
    default:
      return Pbrt_bs.E.malformed_variant("length_unit");
  }
}

function decode_length(json) {
  var v = {
    length: 0,
    unit_: Boat_types.default_length_unit(/* () */0)
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "length" :
          var json$1 = json["length"];
          v.length = Pbrt_bs.$$float(json$1, "length", "length");
          break;
      case "unit" :
          var json$2 = json["unit"];
          v.unit_ = decode_length_unit(json$2);
          break;
      default:
        
    }
  }
  return {
          length: v.length,
          unit_: v.unit_
        };
}

function decode_boat(json) {
  var v = {
    id: undefined,
    name: "",
    builder: "",
    model: "",
    year: 0,
    loa: undefined
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "builder" :
          var json$1 = json["builder"];
          v.builder = Pbrt_bs.string(json$1, "boat", "builder");
          break;
      case "id" :
          var json$2 = json["id"];
          v.id = Id_bs.decode_id(Pbrt_bs.object_(json$2, "boat", "id"));
          break;
      case "loa" :
          var json$3 = json["loa"];
          v.loa = decode_length(Pbrt_bs.object_(json$3, "boat", "loa"));
          break;
      case "model" :
          var json$4 = json["model"];
          v.model = Pbrt_bs.string(json$4, "boat", "model");
          break;
      case "name" :
          var json$5 = json["name"];
          v.name = Pbrt_bs.string(json$5, "boat", "name");
          break;
      case "year" :
          var json$6 = json["year"];
          v.year = Pbrt_bs.$$int(json$6, "boat", "year");
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          name: v.name,
          builder: v.builder,
          model: v.model,
          year: v.year,
          loa: v.loa
        };
}

function encode_length_unit(v) {
  if (v) {
    return "FEET";
  } else {
    return "METER";
  }
}

function encode_length(v) {
  var json = { };
  json["length"] = v.length;
  json["unit"] = v.unit_ ? "FEET" : "METER";
  return json;
}

function encode_boat(v) {
  var json = { };
  var match = v.id;
  if (match !== undefined) {
    var json$prime = Id_bs.encode_id(match);
    json["id"] = json$prime;
  }
  json["name"] = v.name;
  json["builder"] = v.builder;
  json["model"] = v.model;
  json["year"] = String(v.year);
  var match$1 = v.loa;
  if (match$1 !== undefined) {
    var json$prime$1 = encode_length(match$1);
    json["loa"] = json$prime$1;
  }
  return json;
}

export {
  encode_length_unit ,
  encode_length ,
  encode_boat ,
  decode_length_unit ,
  decode_length ,
  decode_boat ,
  
}
/* Id_bs Not a pure module */
