// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as State from "../util/State.bs.js";
import * as React from "react";
import * as AppMenu from "./AppMenu.bs.js";
import * as BoatSelector from "./BoatSelector.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as SectionWithMargin from "./SectionWithMargin.bs.js";
import * as BoatAllMaintenanceLogs from "./BoatAllMaintenanceLogs.bs.js";
import * as Paper from "@material-ui/core/Paper";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      boatSelectorPaper: {
        padding: "10px"
      },
      root: {
        marginTop: "15px",
        paddingTop: "15px"
      }
    });

function MaintenanceSection(Props) {
  var state = Props.state;
  var stateDispatch = Props.stateDispatch;
  var classes = useStyles();
  AnalyticsEffect.useCurrentScreen(AppMenu.maintenanceString);
  var boat = State.getSelectedBoat(state);
  return React.createElement(SectionWithMargin.make, {
              children: null
            }, React.createElement(Paper.default, {
                  className: classes.boatSelectorPaper,
                  children: React.createElement(BoatSelector.make, {
                        state: state,
                        stateDispatch: stateDispatch
                      })
                }), React.createElement(Paper.default, {
                  className: classes.root,
                  children: React.createElement(BoatAllMaintenanceLogs.make, {
                        boat: boat
                      })
                }));
}

var Mui = /* alias */0;

var B = /* alias */0;

var M = /* alias */0;

var make = MaintenanceSection;

export {
  Mui ,
  B ,
  M ,
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
