// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Id from "../util/Id.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as System from "../util/System.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Collection from "../util/Collection.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Maintenance from "../util/Maintenance.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as DeleteConfirmation from "./DeleteConfirmation.bs.js";
import * as MaintenanceLogEntry from "./MaintenanceLogEntry.bs.js";
import * as NoDataInGridMessage from "./NoDataInGridMessage.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Fab from "@material-ui/core/Fab";
import * as MaintenanceItemLogRow from "./MaintenanceItemLogRow.bs.js";
import * as Add from "@material-ui/icons/Add";
import * as Table from "@material-ui/core/Table";
import * as TableBody from "@material-ui/core/TableBody";
import * as TableHead from "@material-ui/core/TableHead";
import * as TableContainer from "@material-ui/core/TableContainer";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      addButton: {
        width: "100%",
        textAlign: "center",
        marginBottom: 10
      }
    });

function revChrono(l, r) {
  return r[1] - l[1] | 0;
}

function keyOfLog(log) {
  var match = log.id;
  if (match !== undefined) {
    return match.firestore_id;
  } else {
    return log.unix_timestamp.toString();
  }
}

function logsOfBoat(boat) {
  var systems = Belt_Option.mapWithDefault(boat, /* [] */0, (function (boat) {
          return Collection.valuesToList(boat.systems);
        }));
  var items = Belt_List.flatten(Belt_List.mapU(systems, (function (system) {
              var systemDisplayName = System.displayName(system);
              return Belt_List.map(system.maintenance_items, (function (item) {
                            return /* tuple */[
                                    systemDisplayName,
                                    item
                                  ];
                          }));
            })));
  var items$1 = Belt_List.map(items, (function (param) {
          var item = param[1];
          var description = Maintenance.shortDescription(item).concat(" for ").concat(param[0]);
          var logs = Belt_List.map(item.logs, (function (log) {
                  return /* tuple */[
                          description,
                          log.unix_timestamp,
                          log
                        ];
                }));
          return /* tuple */[
                  /* tuple */[
                    description,
                    item
                  ],
                  logs
                ];
        }));
  var logs = Belt_List.flatten(Belt_List.map(items$1, (function (prim) {
              return prim[1];
            })));
  var logs$1 = Belt_List.sort(logs, revChrono);
  var items$2 = Belt_List.map(items$1, (function (prim) {
          return prim[0];
        }));
  return /* tuple */[
          items$2,
          logs$1
        ];
}

var noBoatMsg = React.createElement(NoDataInGridMessage.make, {
      msg: /* NoData */Block.__(0, ["You must first configure your boat in the Boat section"])
    });

var noMaintenanceItemMsg = React.createElement(NoDataInGridMessage.make, {
      msg: /* NoData */Block.__(0, ["\n    No maintenance schedule configured. \n    Go to boat section first to add one.\n  "])
    });

function BoatAllMaintenanceLogs(Props) {
  var boat = Props.boat;
  var classes = useStyles();
  var match = React.useState((function () {
          return /* NoDialog */0;
        }));
  var setDialog = match[1];
  var dialog = match[0];
  var hideDialogs = function (param) {
    return Curry._1(setDialog, (function (param) {
                  return /* NoDialog */0;
                }));
  };
  var showAddDialog = function (param) {
    Analytics.data(Events_types.default_data(/* Maintenance_item_log */4, /* Open_dialog_entry_add */1, undefined, undefined, undefined, /* () */0));
    return Curry._1(setDialog, (function (param) {
                  return /* Add */1;
                }));
  };
  var match$1 = React.useContext(AppContext.ctx);
  var firestore = match$1.firestore;
  var match$2 = React.useMemo((function () {
          return logsOfBoat(boat);
        }), [boat]);
  var items = match$2[0];
  var tableConfig = MaintenanceItemLogRow.useTableConfig(/* () */0);
  var onAction = function (param) {
    if (param.tag) {
      var log = param[0];
      Curry._1(setDialog, (function (param) {
              return /* Delete */Block.__(1, [log]);
            }));
      return Analytics.data(Events_types.default_data(/* Maintenance_item_log */4, /* Open_dialog_entry_delete */3, undefined, undefined, Id.firestorePath(log.id), /* () */0));
    } else {
      var log$1 = param[0];
      Curry._1(setDialog, (function (param) {
              return /* Edit */Block.__(0, [log$1]);
            }));
      return Analytics.data(Events_types.default_data(/* Maintenance_item_log */4, /* Open_dialog_entry_update */2, undefined, undefined, Id.firestorePath(log$1.id), /* () */0));
    }
  };
  var tmp;
  if (typeof dialog === "number") {
    if (dialog === /* NoDialog */0) {
      tmp = null;
    } else {
      var mode = /* AddToBoat */Block.__(2, [items]);
      tmp = React.createElement(MaintenanceLogEntry.make, {
            onClose: hideDialogs,
            mode: mode
          });
    }
  } else if (dialog.tag) {
    var log = dialog[0];
    tmp = React.createElement(DeleteConfirmation.make, {
          what: "maintenance log",
          onClose: hideDialogs,
          onOk: (function (param) {
              var log$1 = log;
              Curry._2(Db.deleteMaintenanceLog, firestore, log$1);
              Curry._1(setDialog, (function (param) {
                      return /* NoDialog */0;
                    }));
              return Analytics.data(Events_types.default_data(/* Maintenance_item_log */4, /* Deleted */6, /* Success */1, undefined, Id.firestorePath(log$1.id), /* () */0));
            }),
          screenIdentifier: "MaintenanceItemLog"
        });
  } else {
    var mode$1 = /* Edit */Block.__(1, [dialog[0]]);
    tmp = React.createElement(MaintenanceLogEntry.make, {
          onClose: hideDialogs,
          mode: mode$1
        });
  }
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Box.default, {
                  className: classes.addButton,
                  children: React.createElement(Fab.default, {
                        color: "primary",
                        size: "medium",
                        variant: "extended",
                        onClick: showAddDialog,
                        disabled: Belt_Option.isNone(boat) || items === /* [] */0,
                        children: null
                      }, React.createElement(Add.default, { }), " Add Log")
                }), React.createElement(TableContainer.default, {
                  children: React.createElement(Table.default, {
                        children: null
                      }, React.createElement(TableHead.default, {
                            children: React.createElement(MaintenanceItemLogRow.Head.make, {
                                  tableConfig: tableConfig,
                                  maintenanceItemType: /* Check */1,
                                  includeDescription: true
                                })
                          }), React.createElement(TableBody.default, {
                            children: Belt_List.toArray(Belt_List.map(match$2[1], (function (param) {
                                        var log = param[2];
                                        return React.createElement(MaintenanceItemLogRow.Body.make, {
                                                    tableConfig: tableConfig,
                                                    log: log,
                                                    onAction: onAction,
                                                    maintenanceItemType: /* Check */1,
                                                    description: param[0],
                                                    key: keyOfLog(log)
                                                  });
                                      })))
                          }))
                }), tmp, boat !== undefined ? (
                items ? null : noMaintenanceItemMsg
              ) : noBoatMsg);
}

var Mui = /* alias */0;

var S = /* alias */0;

var M = /* alias */0;

var I = /* alias */0;

var E = /* alias */0;

var maintenanceItemType = /* Check */1;

var make = BoatAllMaintenanceLogs;

export {
  Mui ,
  S ,
  M ,
  I ,
  E ,
  useStyles ,
  revChrono ,
  keyOfLog ,
  logsOfBoat ,
  maintenanceItemType ,
  noBoatMsg ,
  noMaintenanceItemMsg ,
  make ,
  
}
/* useStyles Not a pure module */
