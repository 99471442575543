// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Frequency from "../util/Frequency.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as NumberInput from "./core/NumberInput.bs.js";
import * as PeriodEntry from "./PeriodEntry.bs.js";
import * as DistanceEntry from "./core/DistanceEntry.bs.js";
import * as DatetimePickers from "./core/DatetimePickers.bs.js";
import * as FrequencyTypeSelector from "./FrequencyTypeSelector.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as FormLabel from "@material-ui/core/FormLabel";

function FrequencyEntry(Props) {
  var min_unit = Props.min_unit;
  var frequency = Props.frequency;
  var $staropt$star = Props.error;
  var on_change = Props.on_change;
  var error = $staropt$star !== undefined ? $staropt$star : Frequency.noError;
  var updateEngineHours = function (value) {
    return Curry._1(on_change, (function (x) {
                  if (typeof x === "number" || x.tag !== /* Engine_hours */1) {
                    return x;
                  } else {
                    return /* Engine_hours */Block.__(1, [{
                                value: value
                              }]);
                  }
                }));
  };
  var updateDistance = function (value) {
    return Curry._1(on_change, (function (x) {
                  if (typeof x === "number" || x.tag !== /* Distance */2) {
                    return x;
                  } else {
                    return /* Distance */Block.__(2, [{
                                value: Belt_Option.getWithDefault(value, 0.0)
                              }]);
                  }
                }));
  };
  var tmp;
  if (typeof frequency === "number") {
    tmp = null;
  } else {
    switch (frequency.tag | 0) {
      case /* Period */0 :
          tmp = React.createElement(PeriodEntry.make, {
                min_unit: min_unit,
                period: frequency[0],
                error: error.periodCountError,
                on_change: (function (amend_period) {
                    return Curry._1(on_change, (function (frequency) {
                                  if (typeof frequency === "number" || frequency.tag) {
                                    return frequency;
                                  } else {
                                    return /* Period */Block.__(0, [Curry._1(amend_period, frequency[0])]);
                                  }
                                }));
                  })
              });
          break;
      case /* Engine_hours */1 :
          tmp = React.createElement(NumberInput.Float.make, {
                value: frequency[0].value,
                onChange: updateEngineHours,
                digits: 1
              });
          break;
      case /* Distance */2 :
          tmp = React.createElement(DistanceEntry.make, {
                value: frequency[0].value,
                onChange: updateDistance
              });
          break;
      case /* Before_date */3 :
          tmp = React.createElement(DatetimePickers.make, {
                unixTimestamp: frequency[0].unix_timestamp,
                includeLabels: true,
                disableFuture: false
              });
          break;
      
    }
  }
  return React.createElement(Grid.default, {
              container: true,
              justify: "flex-start",
              alignItems: "center",
              spacing: 1,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  container: true,
                  justify: "flex-start",
                  alignItems: "center",
                  sm: 6,
                  xs: 12,
                  children: null
                }, React.createElement(Grid.default, {
                      item: true,
                      sm: 4,
                      xs: 4,
                      children: React.createElement(FormLabel.default, {
                            children: "When",
                            required: true
                          })
                    }), React.createElement(Grid.default, {
                      item: true,
                      sm: 8,
                      xs: 8,
                      children: React.createElement(FrequencyTypeSelector.make, {
                            value: frequency,
                            onChange: (function (frequency) {
                                return Curry._1(on_change, (function (param) {
                                              return frequency;
                                            }));
                              })
                          })
                    })), React.createElement(Grid.default, {
                  item: true,
                  container: true,
                  justify: "flex-start",
                  alignItems: "center",
                  sm: 6,
                  xs: 12,
                  children: tmp
                }));
}

var F = /* alias */0;

var make = FrequencyEntry;

export {
  F ,
  make ,
  
}
/* react Not a pure module */
