// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function copy(old) {
  return Object.assign({ }, old);
}

function empty(param) {
  return { };
}

var get = Js_dict.get;

function set(t, key, value) {
  var t$1 = copy(t);
  t$1[key] = value;
  return t$1;
}

function updateIfPresent(t, key, f) {
  var value = Js_dict.get(t, key);
  if (value !== undefined) {
    var t$1 = copy(t);
    t$1[key] = f(Caml_option.valFromOption(value));
    return Caml_option.some(t$1);
  }
  
}

function keys(t) {
  return Object.keys(t);
}

function $$delete(t, keyToDelete) {
  var target = { };
  var keys = Object.keys(t);
  var l = keys.length;
  for(var i = 0 ,i_finish = l - 1 | 0; i <= i_finish; ++i){
    var key = keys[i];
    if (key !== keyToDelete) {
      target[key] = t[key];
    }
    
  }
  return target;
}

function isEmpty(t) {
  return Object.keys(t).length === 0;
}

function forEachValues(t, f) {
  var keys = Object.keys(t);
  keys.forEach((function (key) {
          return f(t[key]);
        }));
  return /* () */0;
}

function forEeachEntries(t, f) {
  var keys = Object.keys(t);
  keys.forEach((function (key) {
          return f(key, t[key]);
        }));
  return /* () */0;
}

function mapToList(t, f) {
  var ret = {
    contents: /* [] */0
  };
  forEachValues(t, (function (v) {
          ret.contents = /* :: */[
            f(v),
            ret.contents
          ];
          return /* () */0;
        }));
  return ret.contents;
}

function valuesToList(t) {
  var ret = {
    contents: /* [] */0
  };
  forEachValues(t, (function (v) {
          ret.contents = /* :: */[
            v,
            ret.contents
          ];
          return /* () */0;
        }));
  return ret.contents;
}

function reduceValues(t, v0, f) {
  var ret = {
    contents: v0
  };
  forEachValues(t, (function (v) {
          ret.contents = Curry._2(f, ret.contents, v);
          return /* () */0;
        }));
  return ret.contents;
}

function head(t) {
  var keys = Object.keys(t);
  if (keys.length === 0) {
    return ;
  } else {
    return Js_dict.get(t, keys[0]);
  }
}

export {
  copy ,
  empty ,
  get ,
  set ,
  updateIfPresent ,
  keys ,
  $$delete ,
  isEmpty ,
  forEachValues ,
  forEeachEntries ,
  mapToList ,
  valuesToList ,
  reduceValues ,
  head ,
  
}
/* No side effect */
