// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as MenuHook from "./util/MenuHook.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as SettingsMenuButton from "./SettingsMenuButton.bs.js";
import * as Menu from "@material-ui/core/Menu";
import * as Divider from "@material-ui/core/Divider";
import * as MenuItem from "@material-ui/core/MenuItem";

function ignoreOnAction(param) {
  return /* () */0;
}

function TopSelectorButtons$Menu(Props) {
  var $staropt$star = Props.onAction;
  var $staropt$star$1 = Props.addDisabled;
  var $staropt$star$2 = Props.editDisabled;
  var onAction = $staropt$star !== undefined ? $staropt$star : ignoreOnAction;
  var addDisabled = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var editDisabled = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var match = MenuHook.useMenu(/* () */0);
  var toggle = match[2];
  var onClick = function (action, e) {
    if (action >= 3) {
      Analytics.nav(Events_types.default_navigation(/* Boat_selector_setting_menu_show_boat_info */14, undefined, /* () */0));
    }
    Curry._1(onAction, action);
    return Curry._2(toggle, /* Hide */803692770, e);
  };
  var handleOnMenuClicked = function (e) {
    Analytics.nav(Events_types.default_navigation(/* Boat_selector_setting_menu */13, undefined, /* () */0));
    return Curry._2(toggle, /* Show */925630749, e);
  };
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(SettingsMenuButton.make, {
                  onClick: handleOnMenuClicked,
                  color: /* secondary */-499495052
                }), React.createElement(Menu.default, {
                  open: match[1],
                  anchorEl: match[0],
                  keepMounted: true,
                  onClose: Curry._1(toggle, /* Hide */803692770),
                  children: null
                }, React.createElement(MenuItem.default, {
                      onClick: (function (param) {
                          return onClick(/* Edit */1, param);
                        }),
                      disabled: editDisabled,
                      children: "Edit Boat"
                    }), React.createElement(MenuItem.default, {
                      onClick: (function (param) {
                          return onClick(/* Delete */2, param);
                        }),
                      disabled: editDisabled,
                      children: "Delete Boat"
                    }), React.createElement(MenuItem.default, {
                      onClick: (function (param) {
                          return onClick(/* Show */3, param);
                        }),
                      disabled: editDisabled,
                      children: "Show Boat Info"
                    }), React.createElement(Divider.default, { }), React.createElement(MenuItem.default, {
                      onClick: (function (param) {
                          return onClick(/* Add */0, param);
                        }),
                      disabled: addDisabled,
                      children: "Add Boat"
                    })));
}

var Menu$1 = {
  make: TopSelectorButtons$Menu
};

var Buttons = { };

var Mui = /* alias */0;

var E = /* alias */0;

export {
  Mui ,
  E ,
  ignoreOnAction ,
  Menu$1 as Menu,
  Buttons ,
  
}
/* react Not a pure module */
