// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";

function default_period_unit(param) {
  return /* Minutes */0;
}

function default_period($staropt$star, $staropt$star$1, param) {
  var period_unit = $staropt$star !== undefined ? $staropt$star : /* Minutes */0;
  var period_count = $staropt$star$1 !== undefined ? $staropt$star$1 : 0;
  return {
          period_unit: period_unit,
          period_count: period_count
        };
}

function default_engine_hours($staropt$star, param) {
  var value = $staropt$star !== undefined ? $staropt$star : 0;
  return {
          value: value
        };
}

function default_distance($staropt$star, param) {
  var value = $staropt$star !== undefined ? $staropt$star : 0;
  return {
          value: value
        };
}

function default_before_date($staropt$star, param) {
  var unix_timestamp = $staropt$star !== undefined ? $staropt$star : 0;
  return {
          unix_timestamp: unix_timestamp
        };
}

function default_frequency(param) {
  return /* Period */Block.__(0, [default_period(undefined, undefined, /* () */0)]);
}

export {
  default_period_unit ,
  default_period ,
  default_engine_hours ,
  default_distance ,
  default_before_date ,
  default_frequency ,
  
}
/* No side effect */
