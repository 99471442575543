// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$Error from "../util/Error.bs.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as TextField from "@material-ui/core/TextField";
import * as Autocomplete from "@material-ui/lab/Autocomplete";

function renderInput(label, error, helperText, params) {
  var match = $$Error.isError(error);
  var helperText$1 = match ? $$Error.helperText(error) : (
      helperText === "" ? null : helperText
    );
  return React.createElement(TextField.default, Object.assign(params, {
                  label: label,
                  margin: "none",
                  error: $$Error.isError(error),
                  helperText: helperText$1,
                  variant: "outlined",
                  fullWidth: true,
                  type: "search"
                }));
}

var emptyOptions = [];

function ignoreOnChange(param) {
  return /* () */0;
}

function SearchInput$Controlled(Props) {
  var $staropt$star = Props.value;
  var $staropt$star$1 = Props.options;
  var $staropt$star$2 = Props.onChange;
  var $staropt$star$3 = Props.label;
  var $staropt$star$4 = Props.error;
  var $staropt$star$5 = Props.disabled;
  var $staropt$star$6 = Props.allowArbitraryInput;
  var $staropt$star$7 = Props.helperText;
  var value = $staropt$star !== undefined ? $staropt$star : "";
  var options = $staropt$star$1 !== undefined ? $staropt$star$1 : emptyOptions;
  var onChange = $staropt$star$2 !== undefined ? $staropt$star$2 : ignoreOnChange;
  var label = $staropt$star$3 !== undefined ? $staropt$star$3 : "";
  var error = $staropt$star$4 !== undefined ? Caml_option.valFromOption($staropt$star$4) : $$Error.noError;
  var disabled = $staropt$star$5 !== undefined ? $staropt$star$5 : false;
  var allowArbitraryInput = $staropt$star$6 !== undefined ? $staropt$star$6 : false;
  var helperText = $staropt$star$7 !== undefined ? $staropt$star$7 : "";
  return React.createElement(Autocomplete.default, {
              disabled: disabled,
              freeSolo: allowArbitraryInput,
              disableClearable: true,
              options: options,
              renderInput: (function (param) {
                  return renderInput(label, error, helperText, param);
                }),
              value: value,
              onChange: (function (param, v) {
                  return Curry._1(onChange, v);
                })
            });
}

var Controlled = {
  make: SearchInput$Controlled
};

function SearchInput$UnControlled(Props) {
  var $staropt$star = Props.value;
  var $staropt$star$1 = Props.options;
  var $staropt$star$2 = Props.onChange;
  var $staropt$star$3 = Props.label;
  var $staropt$star$4 = Props.error;
  var $staropt$star$5 = Props.disabled;
  var $staropt$star$6 = Props.allowArbitraryInput;
  var $staropt$star$7 = Props.helperText;
  var groupBy = Props.groupBy;
  var value = $staropt$star !== undefined ? $staropt$star : "";
  var options = $staropt$star$1 !== undefined ? $staropt$star$1 : emptyOptions;
  var onChange = $staropt$star$2 !== undefined ? $staropt$star$2 : ignoreOnChange;
  var label = $staropt$star$3 !== undefined ? $staropt$star$3 : "";
  var error = $staropt$star$4 !== undefined ? Caml_option.valFromOption($staropt$star$4) : $$Error.noError;
  var disabled = $staropt$star$5 !== undefined ? $staropt$star$5 : false;
  var allowArbitraryInput = $staropt$star$6 !== undefined ? $staropt$star$6 : false;
  var helperText = $staropt$star$7 !== undefined ? $staropt$star$7 : "";
  if (groupBy !== undefined) {
    return React.createElement(Autocomplete.default, {
                disabled: disabled,
                freeSolo: allowArbitraryInput,
                disableClearable: true,
                options: options,
                groupBy: groupBy,
                renderInput: (function (param) {
                    return renderInput(label, error, helperText, param);
                  }),
                inputValue: value,
                onInputChange: (function (param, v, param$1) {
                    return Curry._1(onChange, v);
                  })
              });
  } else {
    return React.createElement(Autocomplete.default, {
                disabled: disabled,
                freeSolo: allowArbitraryInput,
                disableClearable: true,
                options: options,
                renderInput: (function (param) {
                    return renderInput(label, error, helperText, param);
                  }),
                inputValue: value,
                onInputChange: (function (param, v, param$1) {
                    return Curry._1(onChange, v);
                  })
              });
  }
}

var UnControlled = {
  make: SearchInput$UnControlled
};

export {
  renderInput ,
  emptyOptions ,
  ignoreOnChange ,
  Controlled ,
  UnControlled ,
  
}
/* react Not a pure module */
