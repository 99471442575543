// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Id from "../util/Id.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Input from "./core/Input.bs.js";
import * as State from "../util/State.bs.js";
import * as React from "react";
import * as Analytics from "./util/Analytics.bs.js";
import * as BoatEntry from "./BoatEntry.bs.js";
import * as UserState from "../util/UserState.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Events_types from "../types/events_types.bs.js";
import * as BoatNameSelector from "./BoatNameSelector.bs.js";
import * as DeleteConfirmation from "./DeleteConfirmation.bs.js";
import * as TopSelectorButtons from "./TopSelectorButtons.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Grid from "@material-ui/core/Grid";
import * as Hidden from "@material-ui/core/Hidden";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var useStyles = MakeStyles.default({
      root: {
        maxWidth: 1600
      },
      actionMenu: {
        textAlign: "center"
      }
    });

function boatInfoSummary(user, state, stateDispatch, classes, onAction, includeSettings) {
  var boat = State.getSelectedBoat(state);
  var make = Belt_Option.mapWithDefault(boat, "", (function (param) {
          var match = param.data;
          return match.builder.concat(" ").concat(match.model);
        }));
  var editDisabled = Belt_Option.isNone(boat);
  var addDisabled = !UserState.isLoggedIn(user);
  return React.createElement(Grid.default, {
              container: true,
              justify: "flex-end",
              alignItems: "center",
              spacing: 2,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  sm: 7,
                  xs: 10,
                  children: React.createElement(BoatNameSelector.Selector.make, {
                        state: state,
                        stateDispatch: stateDispatch
                      })
                }), React.createElement(Hidden.default, {
                  xsDown: true,
                  children: React.createElement(Grid.default, {
                        item: true,
                        sm: 4,
                        children: React.createElement(Input.make, {
                              label: "Make",
                              value: make,
                              disabled: true
                            })
                      })
                }), React.createElement(Grid.default, {
                  className: classes.actionMenu,
                  item: true,
                  sm: 1,
                  xs: 2,
                  children: includeSettings ? React.createElement(TopSelectorButtons.Menu.make, {
                          onAction: onAction,
                          addDisabled: addDisabled,
                          editDisabled: editDisabled
                        }) : null
                }));
}

function ignoreBoatSelected(param) {
  return /* () */0;
}

function ignoreOnShow(param) {
  return /* () */0;
}

function BoatSelector(Props) {
  var state = Props.state;
  var stateDispatch = Props.stateDispatch;
  var $staropt$star = Props.onShow;
  var $staropt$star$1 = Props.includeSettings;
  var onShow = $staropt$star !== undefined ? $staropt$star : ignoreOnShow;
  var includeSettings = $staropt$star$1 !== undefined ? $staropt$star$1 : false;
  var classes = useStyles();
  var match = React.useState((function () {
          return /* NoDialog */0;
        }));
  var setDialog = match[1];
  var dialog = match[0];
  var hideDialogs = function (param) {
    return Curry._1(setDialog, (function (param) {
                  return /* NoDialog */0;
                }));
  };
  var match$1 = React.useContext(AppContext.ctx);
  var firestore = match$1.firestore;
  var onAction = function (param) {
    switch (param) {
      case /* Add */0 :
          Analytics.data(Events_types.default_data(/* Boat */1, /* Open_dialog_entry_add */1, undefined, undefined, undefined, /* () */0));
          return Curry._1(setDialog, (function (param) {
                        return /* Add */1;
                      }));
      case /* Edit */1 :
          var match = State.getSelectedBoat(state);
          if (match !== undefined) {
            var b = match;
            Analytics.data(Events_types.default_data(/* Boat */1, /* Open_dialog_entry_update */2, undefined, undefined, Id.firestorePath(b.data.id), /* () */0));
            return Curry._1(setDialog, (function (param) {
                          return /* Edit */Block.__(0, [b]);
                        }));
          } else {
            return /* () */0;
          }
      case /* Delete */2 :
          var match$1 = State.getSelectedBoat(state);
          if (match$1 !== undefined) {
            var b$1 = match$1;
            Analytics.data(Events_types.default_data(/* Boat */1, /* Open_dialog_entry_delete */3, undefined, undefined, Id.firestorePath(b$1.data.id), /* () */0));
            return Curry._1(setDialog, (function (param) {
                          return /* Delete */Block.__(1, [b$1]);
                        }));
          } else {
            return /* () */0;
          }
      case /* Show */3 :
          return Curry._1(onShow, /* () */0);
      
    }
  };
  var tmp;
  if (typeof dialog === "number") {
    tmp = dialog === /* NoDialog */0 ? null : React.createElement(BoatEntry.make, {
            mode: /* Add */0,
            onClose: hideDialogs
          });
  } else if (dialog.tag) {
    var b = dialog[0];
    var boatName = b.data.name;
    tmp = React.createElement(DeleteConfirmation.make, {
          what: boatName,
          onClose: hideDialogs,
          onOk: (function (param) {
              var boat = b;
              Db.deleteBoat(firestore, boat);
              Analytics.data(Events_types.default_data(/* Boat */1, /* Deleted */6, /* Success */1, undefined, Id.firestorePath(boat.data.id), /* () */0));
              return Curry._1(setDialog, (function (param) {
                            return /* NoDialog */0;
                          }));
            }),
          screenIdentifier: "Boat"
        });
  } else {
    tmp = React.createElement(BoatEntry.make, {
          mode: /* Edit */[dialog[0]],
          onClose: hideDialogs
        });
  }
  return React.createElement("div", undefined, React.createElement(Box.default, {
                  className: classes.root,
                  children: boatInfoSummary(match$1.user, state, stateDispatch, classes, onAction, includeSettings)
                }), tmp);
}

var B = /* alias */0;

var Mui = /* alias */0;

var I = /* alias */0;

var E = /* alias */0;

var make = BoatSelector;

export {
  B ,
  Mui ,
  I ,
  E ,
  concat ,
  useStyles ,
  boatInfoSummary ,
  ignoreBoatSelected ,
  ignoreOnShow ,
  make ,
  
}
/* useStyles Not a pure module */
