// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as P from "../util/P.bs.js";
import * as Db from "../db/Db.bs.js";
import * as Id from "../util/Id.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Analytics from "./util/Analytics.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Firebaseui from "firebaseui";
import * as EntryDialog from "./core/EntryDialog.bs.js";
import * as App from "firebase/app";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as StyledFirebaseAuthJs from "react-firebaseui/StyledFirebaseAuth.js";

function useControl(param) {
  var match = React.useState((function () {
          return false;
        }));
  var setIsAuthDialogShown = match[1];
  var toggle = function (param) {
    if (param >= 925630749) {
      return Curry._1(setIsAuthDialogShown, (function (param) {
                    return true;
                  }));
    } else {
      return Curry._1(setIsAuthDialogShown, (function (param) {
                    return false;
                  }));
    }
  };
  return /* tuple */[
          match[0],
          toggle
        ];
}

function ignoreOnClose(param) {
  return /* () */0;
}

function AuthDialog(Props) {
  var $staropt$star = Props.onClose;
  var onUserUpgraded = Props.onUserUpgraded;
  var onSuccess = Props.onSuccess;
  var onClose = $staropt$star !== undefined ? $staropt$star : ignoreOnClose;
  AnalyticsEffect.useCurrentScreen("AuthDialog");
  var match = React.useContext(AppContext.ctx);
  var user = match.user;
  var auth = match.auth;
  var firestore = match.firestore;
  var onSignInSuccess = function (userCredential, param) {
    var authUser = userCredential.user;
    var newUid = authUser.uid;
    var newIsAnonymous = authUser.isAnonymous;
    Curry._1(onSuccess, /* () */0);
    if (typeof user === "number") {
      return false;
    } else {
      var user$1 = user[0];
      var prevUid = Id.firestoreId(user$1.id);
      var prevIsAnonymous = user$1.is_anonymous;
      var userUpgraded = prevUid === newUid && prevIsAnonymous && !newIsAnonymous;
      if (userUpgraded) {
        P.mapCatch((function (e) {
                console.log("Error reading user data: ", e);
                AppContext.auth.signOut();
                return Curry._1(onClose, /* () */0);
              }), P.mapThen((function (user) {
                    Curry._1(onUserUpgraded, /* LoggedIn */[user]);
                    return Curry._1(onClose, /* () */0);
                  }), Db.user(authUser, firestore)));
        return false;
      } else {
        Curry._1(onClose, /* () */0);
        return false;
      }
    }
  };
  return React.createElement(EntryDialog.make, {
              title: EntryDialog.makeTitle("Sign in", "Sign in with method of choice"),
              onClose: onClose,
              usePaperForContent: false,
              subContentWidth: "none",
              children: React.createElement(StyledFirebaseAuthJs.default, {
                    firebaseAuth: auth,
                    uiConfig: {
                      signInFlow: "popup",
                      signInOptions: [
                        App.auth.GoogleAuthProvider.PROVIDER_ID,
                        App.auth.FacebookAuthProvider.PROVIDER_ID,
                        App.auth.EmailAuthProvider.PROVIDER_ID
                      ],
                      autoUpgradeAnonymousUsers: true,
                      tosUrl: "https://boat-health.net/terms.html",
                      privacyPolicyUrl: "https://boat-health.net/privacy.html",
                      credentialHelper: Firebaseui.auth.CredentialHelper.NONE,
                      callbacks: {
                        signInSuccessWithAuthResult: onSignInSuccess,
                        signInFailure: (function (e) {
                            var code = e.code;
                            if (code !== "firebaseui/anonymous-upgrade-merge-conflict") {
                              console.log("Unrecoverable auth failure, code:", code);
                              Analytics.exceptString("Auth-SignInFailure Callback", undefined, code);
                              Curry._1(onClose, /* () */0);
                              return P.unit;
                            } else {
                              return P.mapThen((function (param) {
                                            return Curry._1(onClose, /* () */0);
                                          }), auth.signInWithCredential(e.credential));
                            }
                          })
                      }
                    }
                  })
            });
}

var Mui = /* alias */0;

var U = /* alias */0;

var Pr = /* alias */0;

var UserCredential = /* alias */0;

var User = /* alias */0;

var make = AuthDialog;

export {
  Mui ,
  U ,
  Pr ,
  useControl ,
  ignoreOnClose ,
  UserCredential ,
  User ,
  make ,
  
}
/* P Not a pure module */
