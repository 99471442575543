// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

function addFirestorePathToList(l, param) {
  if (param !== undefined) {
    return /* :: */[
            param.firestore_path,
            l
          ];
  } else {
    return l;
  }
}

function firestorePath(param) {
  if (param !== undefined) {
    return param.firestore_path;
  } else {
    return "";
  }
}

function firestoreId(param) {
  if (param !== undefined) {
    return param.firestore_id;
  } else {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "Id.re",
            14,
            12
          ]
        ];
  }
}

var I = /* alias */0;

export {
  I ,
  addFirestorePathToList ,
  firestorePath ,
  firestoreId ,
  
}
/* No side effect */
