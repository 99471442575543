// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as DistanceUnit from "./DistanceUnit.bs.js";
import * as UnixTimestamp from "./UnixTimestamp.bs.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

var defaultPeriod = {
  period_unit: /* Months */4,
  period_count: 6
};

var defaultFrequency = /* Period */Block.__(0, [defaultPeriod]);

function stringOfPeriodUnit(param) {
  switch (param) {
    case /* Minutes */0 :
        return "Minutes";
    case /* Hours */1 :
        return "Hours";
    case /* Days */2 :
        return "Days";
    case /* Weeks */3 :
        return "Weeks";
    case /* Months */4 :
        return "Months";
    case /* Years */5 :
        return "Years";
    
  }
}

function periodUnitOfString(param) {
  switch (param) {
    case "Days" :
        return /* Days */2;
    case "Hours" :
        return /* Hours */1;
    case "Minutes" :
        return /* Minutes */0;
    case "Months" :
        return /* Months */4;
    case "Weeks" :
        return /* Weeks */3;
    case "Years" :
        return /* Years */5;
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "Frequency.re",
              23,
              9
            ]
          ];
  }
}

var noError = {
  periodCountError: undefined
};

function validate(param) {
  if (typeof param === "number" || param.tag || param[0].period_count > 0) {
    return noError;
  } else {
    return {
            periodCountError: ""
          };
  }
}

function periodUnitInSeconds(param) {
  switch (param) {
    case /* Minutes */0 :
        return 60.0;
    case /* Hours */1 :
        return 3600.0;
    case /* Days */2 :
        return 86400.0;
    case /* Weeks */3 :
        return 604800.0;
    case /* Months */4 :
        return 2628000.0;
    case /* Years */5 :
        return 31536000.0;
    
  }
}

function periodInSeconds(param) {
  return param.period_count * periodUnitInSeconds(param.period_unit);
}

function secondsToApproximatePeriodString(s) {
  var _param = /* :: */[
    /* Years */5,
    /* :: */[
      /* Months */4,
      /* :: */[
        /* Weeks */3,
        /* :: */[
          /* Days */2,
          /* :: */[
            /* Hours */1,
            /* :: */[
              /* Minutes */0,
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ];
  while(true) {
    var param = _param;
    if (param) {
      var match = param[1];
      var u1 = param[0];
      if (match) {
        var u2 = match[0];
        var sec1 = periodUnitInSeconds(u1);
        var v1 = s / sec1;
        var v1i = v1 | 0;
        if (v1i === 0) {
          _param = /* :: */[
            u2,
            match[1]
          ];
          continue ;
        } else {
          var v2 = (s - v1i * sec1) / periodUnitInSeconds(u2) | 0;
          if (v2 === 0) {
            return Curry._2(Printf.sprintf(/* Format */[
                            /* Int */Block.__(4, [
                                /* Int_d */0,
                                /* No_padding */0,
                                /* No_precision */0,
                                /* Char_literal */Block.__(12, [
                                    /* " " */32,
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ])
                              ]),
                            "%d %s"
                          ]), v1i, stringOfPeriodUnit(u1));
          } else {
            return Curry._4(Printf.sprintf(/* Format */[
                            /* Int */Block.__(4, [
                                /* Int_d */0,
                                /* No_padding */0,
                                /* No_precision */0,
                                /* Char_literal */Block.__(12, [
                                    /* " " */32,
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* Char_literal */Block.__(12, [
                                            /* " " */32,
                                            /* Int */Block.__(4, [
                                                /* Int_d */0,
                                                /* No_padding */0,
                                                /* No_precision */0,
                                                /* Char_literal */Block.__(12, [
                                                    /* " " */32,
                                                    /* String */Block.__(2, [
                                                        /* No_padding */0,
                                                        /* End_of_format */0
                                                      ])
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ]),
                            "%d %s %d %s"
                          ]), v1i, stringOfPeriodUnit(u1), v2, stringOfPeriodUnit(u2));
          }
        }
      } else {
        var v = s / periodUnitInSeconds(u1) | 0;
        if (v !== 0) {
          return Curry._2(Printf.sprintf(/* Format */[
                          /* Int */Block.__(4, [
                              /* Int_d */0,
                              /* No_padding */0,
                              /* No_precision */0,
                              /* Char_literal */Block.__(12, [
                                  /* " " */32,
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* End_of_format */0
                                    ])
                                ])
                            ]),
                          "%d %s"
                        ]), v, stringOfPeriodUnit(u1));
        } else {
          return "Now";
        }
      }
    } else {
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "Frequency.re",
              86,
              12
            ]
          ];
    }
  };
}

var allPeriodUnit = [
  /* Minutes */0,
  /* Hours */1,
  /* Days */2,
  /* Weeks */3,
  /* Months */4,
  /* Years */5
];

var nbOfPeriodUnit = allPeriodUnit.length;

function subListOfArray(a, start, len) {
  return $$Array.to_list($$Array.sub(a, start, len));
}

function allPeriodUnit$1(min, param) {
  if (min !== undefined) {
    switch (min) {
      case /* Minutes */0 :
          return $$Array.to_list(allPeriodUnit);
      case /* Hours */1 :
          return subListOfArray(allPeriodUnit, 1, nbOfPeriodUnit - 1 | 0);
      case /* Days */2 :
          return subListOfArray(allPeriodUnit, 2, nbOfPeriodUnit - 2 | 0);
      case /* Months */4 :
          return subListOfArray(allPeriodUnit, 3, nbOfPeriodUnit - 3 | 0);
      case /* Weeks */3 :
      case /* Years */5 :
          throw [
                Caml_builtin_exceptions.assert_failure,
                /* tuple */[
                  "Frequency.re",
                  114,
                  15
                ]
              ];
      
    }
  } else {
    return $$Array.to_list(allPeriodUnit);
  }
}

var periodString = "Every time period";

var engineHoursString = "Every engine hours";

var distanceString = "Every distance sailed";

var adhocString = "When Possible/Needed";

var beforeDateString = "Before date";

var allFrequencyType_001 = /* :: */[
  engineHoursString,
  /* :: */[
    distanceString,
    /* :: */[
      adhocString,
      /* :: */[
        beforeDateString,
        /* [] */0
      ]
    ]
  ]
];

var allFrequencyType = /* :: */[
  periodString,
  allFrequencyType_001
];

function defaultFrequencyForString(v) {
  if (v === periodString) {
    return /* Period */Block.__(0, [{
                period_unit: /* Months */4,
                period_count: 1
              }]);
  } else if (v === engineHoursString) {
    return /* Engine_hours */Block.__(1, [{
                value: 50.0
              }]);
  } else if (v === distanceString) {
    return /* Distance */Block.__(2, [{
                value: 1852000.0
              }]);
  } else if (v === adhocString) {
    return /* Adhoc */0;
  } else if (v === beforeDateString) {
    var now = UnixTimestamp.now(/* () */0);
    var t = periodInSeconds(defaultPeriod);
    return /* Before_date */Block.__(3, [{
                unix_timestamp: now + t
              }]);
  } else {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "Frequency.re",
            146,
            9
          ]
        ];
  }
}

function stringOfFrequencyType(param) {
  if (typeof param === "number") {
    return adhocString;
  } else {
    switch (param.tag | 0) {
      case /* Period */0 :
          return periodString;
      case /* Engine_hours */1 :
          return engineHoursString;
      case /* Distance */2 :
          return distanceString;
      case /* Before_date */3 :
          return beforeDateString;
      
    }
  }
}

function stringOfFrequency(distanceUnit, param) {
  if (typeof param === "number") {
    return "When Possible/Needed";
  } else {
    switch (param.tag | 0) {
      case /* Period */0 :
          var match = param[0];
          return Curry._2(Printf.sprintf(/* Format */[
                          /* String_literal */Block.__(11, [
                              "Every ",
                              /* Int */Block.__(4, [
                                  /* Int_i */3,
                                  /* No_padding */0,
                                  /* No_precision */0,
                                  /* Char_literal */Block.__(12, [
                                      /* " " */32,
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* End_of_format */0
                                        ])
                                    ])
                                ])
                            ]),
                          "Every %i %s"
                        ]), match.period_count, stringOfPeriodUnit(match.period_unit));
      case /* Engine_hours */1 :
          return Curry._1(Printf.sprintf(/* Format */[
                          /* String_literal */Block.__(11, [
                              "Every ",
                              /* Float */Block.__(8, [
                                  /* Float_f */0,
                                  /* Lit_padding */Block.__(0, [
                                      /* Right */1,
                                      0
                                    ]),
                                  /* Lit_precision */[1],
                                  /* String_literal */Block.__(11, [
                                      " engine hours",
                                      /* End_of_format */0
                                    ])
                                ])
                            ]),
                          "Every %0.1f engine hours"
                        ]), param[0].value);
      case /* Distance */2 :
          return Curry._1(Printf.sprintf(/* Format */[
                          /* String_literal */Block.__(11, [
                              "Every ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ]),
                          "Every %s"
                        ]), DistanceUnit.toDisplayString(param[0].value, distanceUnit));
      case /* Before_date */3 :
          return Curry._1(Printf.sprintf(/* Format */[
                          /* String_literal */Block.__(11, [
                              "Before ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ]),
                          "Before %s"
                        ]), UnixTimestamp.formatToDate(param[0].unix_timestamp));
      
    }
  }
}

export {
  stringOfPeriodUnit ,
  periodUnitOfString ,
  allPeriodUnit$1 as allPeriodUnit,
  defaultPeriod ,
  periodInSeconds ,
  secondsToApproximatePeriodString ,
  defaultFrequency ,
  stringOfFrequencyType ,
  defaultFrequencyForString ,
  stringOfFrequency ,
  allFrequencyType ,
  noError ,
  validate ,
  
}
/* No side effect */
