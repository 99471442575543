// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Passage from "../util/Passage.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Box from "@material-ui/core/Box";
import * as PassageFieldsSelector from "./PassageFieldsSelector.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      tabUpperSection: {
        marginLeft: "5px",
        marginRight: "5px"
      }
    });

function addField(field, passage) {
  var fields = passage.fields;
  if (Belt_List.has(fields, field, (function (prim, prim$1) {
            return prim === prim$1;
          }))) {
    return passage;
  } else {
    return {
            id: passage.id,
            name: passage.name,
            from: passage.from,
            to_: passage.to_,
            notes: passage.notes,
            crew: passage.crew,
            logs: passage.logs,
            fields: /* :: */[
              field,
              fields
            ],
            retroactive_data: passage.retroactive_data,
            logs_stats: passage.logs_stats
          };
  }
}

function removeFied(field, passage) {
  var fields = passage.fields;
  var fields$1 = Belt_List.keepU(fields, (function (field$prime) {
          return field$prime !== field;
        }));
  return {
          id: passage.id,
          name: passage.name,
          from: passage.from,
          to_: passage.to_,
          notes: passage.notes,
          crew: passage.crew,
          logs: passage.logs,
          fields: fields$1,
          retroactive_data: passage.retroactive_data,
          logs_stats: passage.logs_stats
        };
}

function PassageFields(Props) {
  var passage = Props.passage;
  var setPassage = Props.setPassage;
  var $staropt$star = Props.disabled;
  var disabled = $staropt$star !== undefined ? $staropt$star : false;
  var classes = useStyles();
  var onSelectionChange = function (field, isChecked) {
    if (isChecked) {
      return Curry._1(setPassage, (function (param) {
                    return addField(field, param);
                  }));
    } else {
      return Curry._1(setPassage, (function (param) {
                    return removeFied(field, param);
                  }));
    }
  };
  var navigationFields = React.useMemo((function () {
          return React.createElement(PassageFieldsSelector.make, {
                      title: "Navigation Fields",
                      fields: Passage.navigationFields,
                      disabled: disabled,
                      selectedFields: passage.fields,
                      onSelectionChange: onSelectionChange,
                      navType: /* Passage_data_entry_expand_navigation_fields */31
                    });
        }), [passage]);
  var weatherFields = React.useMemo((function () {
          return React.createElement(PassageFieldsSelector.make, {
                      title: "Weather Fields",
                      fields: Passage.weatherFields,
                      disabled: disabled,
                      selectedFields: passage.fields,
                      onSelectionChange: onSelectionChange,
                      navType: /* Passage_data_entry_expand_weather_fields */30
                    });
        }), [passage]);
  var otherFields = React.useMemo((function () {
          return React.createElement(PassageFieldsSelector.make, {
                      title: "Other Fields",
                      fields: Passage.otherFields,
                      disabled: disabled,
                      selectedFields: passage.fields,
                      onSelectionChange: onSelectionChange,
                      navType: /* Passage_data_entry_expand_other_fields */32
                    });
        }), [passage]);
  return React.createElement(Box.default, {
              className: classes.tabUpperSection,
              children: React.createElement(Grid.default, {
                    container: true,
                    alignItems: "center",
                    spacing: 2,
                    children: null
                  }, React.createElement(Grid.default, {
                        item: true,
                        xs: 12,
                        children: navigationFields
                      }), React.createElement(Grid.default, {
                        item: true,
                        xs: 12,
                        children: weatherFields
                      }), React.createElement(Grid.default, {
                        item: true,
                        xs: 12,
                        children: otherFields
                      }))
            });
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var E = /* alias */0;

var make = PassageFields;

export {
  Mui ,
  Pa ,
  E ,
  useStyles ,
  addField ,
  removeFied ,
  make ,
  
}
/* useStyles Not a pure module */
