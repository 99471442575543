// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Fab from "@material-ui/core/Fab";
import * as Grid from "@material-ui/core/Grid";
import * as Add from "@material-ui/icons/Add";
import * as Toys from "@material-ui/icons/Toys";
import * as Hidden from "@material-ui/core/Hidden";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      leftButton: {
        textAlign: "right"
      },
      rightButton: {
        textAlign: "left"
      }
    });

function makeButton(text, smallText, startIcon, onClick, className, disabled) {
  return React.createElement(Grid.default, {
              className: className,
              item: true,
              sm: 6,
              children: React.createElement(Fab.default, {
                    color: "primary",
                    variant: "extended",
                    onClick: onClick,
                    disabled: disabled,
                    children: null
                  }, startIcon, React.createElement(Hidden.default, {
                        smUp: true,
                        children: smallText
                      }), React.createElement(Hidden.default, {
                        xsDown: true,
                        children: text
                      }))
            });
}

function ignore(param) {
  return /* () */0;
}

function PassageActions(Props) {
  var $staropt$star = Props.passageStats;
  var $staropt$star$1 = Props.onAddLog;
  var $staropt$star$2 = Props.onMotorOn;
  var $staropt$star$3 = Props.onMotorOff;
  var $staropt$star$4 = Props.disabled;
  var passageStats = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var onAddLog = $staropt$star$1 !== undefined ? $staropt$star$1 : ignore;
  var onMotorOn = $staropt$star$2 !== undefined ? $staropt$star$2 : ignore;
  var onMotorOff = $staropt$star$3 !== undefined ? $staropt$star$3 : ignore;
  var disabled = $staropt$star$4 !== undefined ? $staropt$star$4 : false;
  var classes = useStyles();
  var motorAction = passageStats !== undefined && passageStats.latest_engine_on_unix_timestamp !== undefined ? /* Off */1 : /* On */0;
  var onAddClick = function (param) {
    return Curry._1(onAddLog, /* () */0);
  };
  var onMotorClick = function (param) {
    if (motorAction) {
      return Curry._1(onMotorOff, /* () */0);
    } else {
      return Curry._1(onMotorOn, /* () */0);
    }
  };
  var match = motorAction ? /* tuple */[
      "Motor Off",
      "Off"
    ] : /* tuple */[
      "Motor On",
      "On"
    ];
  return React.createElement(Grid.default, {
              container: true,
              justify: "center",
              alignItems: "center",
              spacing: 2,
              children: null
            }, makeButton("Add Log", "Add", React.createElement(Add.default, { }), onAddClick, classes.leftButton, disabled), makeButton(match[0], match[1], React.createElement(Toys.default, { }), onMotorClick, classes.rightButton, disabled));
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var defaultPassageStats = undefined;

var make = PassageActions;

export {
  Mui ,
  Pa ,
  useStyles ,
  makeButton ,
  ignore ,
  defaultPassageStats ,
  make ,
  
}
/* useStyles Not a pure module */
