// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function default_temperature_unit(param) {
  return /* Celcius */0;
}

export {
  default_temperature_unit ,
  
}
/* No side effect */
