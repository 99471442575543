// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as MenuHook from "./util/MenuHook.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Events_types from "../types/events_types.bs.js";
import * as RowMenuButton from "./RowMenuButton.bs.js";
import * as Menu from "@material-ui/core/Menu";
import * as MenuItem from "@material-ui/core/MenuItem";

function SimpleTableHeaderMenu(Props) {
  var config = Props.config;
  var values = Props.values;
  var toString = Props.toString;
  var navType = Props.navType;
  var match = MenuHook.useMenu(/* () */0);
  var setValue = config[1];
  var value = config[0];
  var toggle = match[2];
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(RowMenuButton.make, {
                  onClick: Curry._1(toggle, /* Show */925630749)
                }), React.createElement(Menu.default, {
                  open: match[1],
                  anchorEl: match[0],
                  keepMounted: true,
                  onClose: Curry._1(toggle, /* Hide */803692770),
                  children: $$Array.of_list(Belt_List.mapU(values, (function (v) {
                              return React.createElement(MenuItem.default, {
                                          selected: Caml_obj.caml_equal(value, v),
                                          onClick: (function (param) {
                                              var config = v;
                                              var e = param;
                                              if (navType !== undefined) {
                                                Analytics.nav(Events_types.default_navigation(navType, undefined, /* () */0));
                                              }
                                              Curry._2(toggle, /* Hide */803692770, e);
                                              return Curry._1(setValue, (function (param) {
                                                            return config;
                                                          }));
                                            }),
                                          children: Curry._1(toString, v)
                                        });
                            })))
                }));
}

var Mui = /* alias */0;

var E = /* alias */0;

var make = SimpleTableHeaderMenu;

export {
  Mui ,
  E ,
  make ,
  
}
/* react Not a pure module */
