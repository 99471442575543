// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Id from "../util/Id.bs.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$Error from "./util/Error.bs.js";
import * as Notes from "./Notes.bs.js";
import * as React from "react";
import * as System from "../util/System.bs.js";
import * as Selector from "./core/Selector.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as EntryDialog from "./core/EntryDialog.bs.js";
import * as Maintenance from "../util/Maintenance.bs.js";
import * as NumberInput from "./core/NumberInput.bs.js";
import * as PeriodEntry from "./PeriodEntry.bs.js";
import * as SearchInput from "./core/SearchInput.bs.js";
import * as Events_types from "../types/events_types.bs.js";
import * as LogValueEntry from "./LogValueEntry.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as DatetimePickers from "./core/DatetimePickers.bs.js";
import * as StringFormLabel from "./core/StringFormLabel.bs.js";
import * as Maintenance_types from "../types/maintenance_types.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as AddMaintenanceItemLogConfirmation from "./AddMaintenanceItemLogConfirmation.bs.js";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var empty = {
  value: "",
  item: undefined,
  error: $$Error.noError
};

function hasError(selection) {
  return $$Error.isError(selection.error);
}

function validate(selection) {
  if (selection.value === "") {
    return {
            value: selection.value,
            item: selection.item,
            error: $$Error.make("Maintenance is mandatory")
          };
  } else {
    return selection;
  }
}

function MaintenanceLogEntry$ItemSelector(Props) {
  var selection = Props.selection;
  var items = Props.items;
  var onChange = Props.onChange;
  var options = React.useMemo((function () {
          return $$Array.of_list(Belt_List.map(items, (function (prim) {
                            return prim[0];
                          })));
        }), [items]);
  var onChange$1 = function (s) {
    var v = Belt_List.getBy(items, (function (param) {
            return param[0] === s;
          }));
    if (v !== undefined) {
      var match = v;
      return Curry._1(onChange, {
                  value: match[0],
                  item: match[1],
                  error: $$Error.noError
                });
    } else {
      return /* () */0;
    }
  };
  return React.createElement(SearchInput.Controlled.make, {
              value: selection.value,
              options: options,
              onChange: onChange$1,
              label: "Maintenance item",
              error: selection.error
            });
}

var ItemSelector = {
  empty: empty,
  hasError: hasError,
  validate: validate,
  make: MaintenanceLogEntry$ItemSelector
};

function titleOfMode(param) {
  switch (param.tag | 0) {
    case /* Edit */1 :
        return EntryDialog.makeTitle(undefined, "Edit maintenance log");
    case /* AddToMaintenance */0 :
    case /* AddToBoat */2 :
        return EntryDialog.makeTitle(undefined, "Add maintenance log");
    
  }
}

function actionOfMode(onAction, param) {
  switch (param.tag | 0) {
    case /* Edit */1 :
        return EntryDialog.makeUpdate(onAction, "log");
    case /* AddToMaintenance */0 :
    case /* AddToBoat */2 :
        return EntryDialog.makeAdd(onAction, "log");
    
  }
}

function screenOfMode(param) {
  switch (param.tag | 0) {
    case /* AddToMaintenance */0 :
        return "AddToMaintenance";
    case /* Edit */1 :
        return "Edit";
    case /* AddToBoat */2 :
        return "AddToBoat";
    
  }
}

function ignoreOnClose(param) {
  return /* () */0;
}

var defaultTimeTaken = {
  period_unit: /* Hours */1,
  period_count: 0
};

function defaultMaintenanceItem(unixTimestamp, maintenanceType) {
  var value;
  switch (maintenanceType) {
    case /* Check */1 :
        value = /* Check_value */Block.__(0, [false]);
        break;
    case /* Replace */2 :
        value = undefined;
        break;
    case /* Record */3 :
        value = /* Record_value */Block.__(1, [0.0]);
        break;
    default:
      value = undefined;
  }
  return Maintenance_types.default_maintenance_item_log(undefined, unixTimestamp, undefined, Caml_option.some(defaultTimeTaken), undefined, Caml_option.some(value), /* () */0);
}

function timeTaken(param) {
  return Belt_Option.getWithDefault(param.time_taken, defaultTimeTaken);
}

function MaintenanceLogEntry(Props) {
  var $staropt$star = Props.onClose;
  var mode = Props.mode;
  var onClose = $staropt$star !== undefined ? $staropt$star : ignoreOnClose;
  AnalyticsEffect.useCurrentScreen("MaintenanceLogEntry-".concat(screenOfMode(mode)));
  var match = React.useReducer((function (log, param) {
          var variant = param[0];
          if (variant >= 4150146) {
            if (variant >= 787392782) {
              if (variant >= 855133217) {
                return {
                        id: log.id,
                        unix_timestamp: log.unix_timestamp,
                        notes: param[1],
                        time_taken: log.time_taken,
                        cost: log.cost,
                        value: log.value
                      };
              } else {
                return {
                        id: log.id,
                        unix_timestamp: param[1],
                        notes: log.notes,
                        time_taken: log.time_taken,
                        cost: log.cost,
                        value: log.value
                      };
              }
            } else if (variant >= 748546669) {
              return {
                      id: log.id,
                      unix_timestamp: log.unix_timestamp,
                      notes: log.notes,
                      time_taken: log.time_taken,
                      cost: param[1],
                      value: log.value
                    };
            } else {
              return param[1];
            }
          } else if (variant !== -508675366) {
            if (variant >= -101336657) {
              return defaultMaintenanceItem(UnixTimestamp.now(/* () */0), param[1]);
            } else {
              return {
                      id: log.id,
                      unix_timestamp: log.unix_timestamp,
                      notes: log.notes,
                      time_taken: log.time_taken,
                      cost: log.cost,
                      value: param[1]
                    };
            }
          } else {
            var time_taken = Curry._1(param[1], timeTaken(log));
            return {
                    id: log.id,
                    unix_timestamp: log.unix_timestamp,
                    notes: log.notes,
                    time_taken: time_taken,
                    cost: log.cost,
                    value: log.value
                  };
          }
        }), defaultMaintenanceItem(UnixTimestamp.now(/* () */0), /* Other */0));
  var dispatchForLog = match[1];
  var log = match[0];
  var match$1 = React.useState((function () {
          return empty;
        }));
  var setSelection = match$1[1];
  var selection = match$1[0];
  React.useEffect((function () {
          switch (mode.tag | 0) {
            case /* AddToMaintenance */0 :
                Curry._1(dispatchForLog, /* `Reset */[
                      -101336657,
                      mode[1].type_
                    ]);
                break;
            case /* Edit */1 :
                Curry._1(dispatchForLog, /* `Set */[
                      4150146,
                      mode[0]
                    ]);
                break;
            case /* AddToBoat */2 :
                Curry._1(dispatchForLog, /* `Reset */[
                      -101336657,
                      /* Check */1
                    ]);
                break;
            
          }
          Curry._1(setSelection, (function (param) {
                  return empty;
                }));
          return ;
        }), [mode]);
  var value = log.value;
  var updateCost = React.useCallback((function (value) {
          return Curry._1(dispatchForLog, /* `Cost */[
                      748546669,
                      value
                    ]);
        }));
  var updateValue = React.useCallback((function (value) {
          return Curry._1(dispatchForLog, /* `Value */[
                      -991563951,
                      value
                    ]);
        }));
  var updateUnixTimestamp = React.useCallback((function (v) {
          return Curry._1(dispatchForLog, /* `UnixTimestamp */[
                      787392782,
                      v
                    ]);
        }));
  var updateTimeTaken = React.useCallback((function (applyFun) {
          return Curry._1(dispatchForLog, /* `TimeTaken */[
                      -508675366,
                      applyFun
                    ]);
        }));
  var updateNotes = React.useCallback((function (s) {
          return Curry._1(dispatchForLog, /* `Notes */[
                      855133217,
                      s
                    ]);
        }));
  var updateSelectedMaintenance = function (s) {
    Curry._1(setSelection, (function (param) {
            return s;
          }));
    var match = s.item;
    if (match !== undefined) {
      return Curry._1(dispatchForLog, /* `Reset */[
                  -101336657,
                  match.type_
                ]);
    } else {
      return /* () */0;
    }
  };
  var match$2 = React.useState((function () {
          return /* Hidden */19559306;
        }));
  var setConfirmationPopup = match$2[1];
  var match$3 = React.useContext(AppContext.ctx);
  var addErrorAlert = match$3.addErrorAlert;
  var firestore = match$3.firestore;
  var validateAndSave = function (param) {
    var addMaintenanceLog = function (id, log) {
      var logData = function (status, firestore_path) {
        return Analytics.data(Events_types.default_data(/* Maintenance_item_log */4, /* Added */4, status, undefined, firestore_path, /* () */0));
      };
      return Db.addMaintenanceLog(id, firestore, log, (function (param) {
                    if (param[0] >= 106380200) {
                      Analytics.except("DB Add - Maintenance Item Log", param[1]);
                      logData(/* Success */1, "");
                      Curry._1(addErrorAlert, "Internal error adding maintenance. App will reset");
                      return Curry._1(setConfirmationPopup, (function (param) {
                                    return /* Done */759635106;
                                  }));
                    } else {
                      logData(/* Success */1, param[1].firestore_path);
                      return Curry._1(setConfirmationPopup, (function (param) {
                                    return /* Done */759635106;
                                  }));
                    }
                  }));
    };
    Curry._1(setConfirmationPopup, (function (param) {
            return /* Saving */-674467366;
          }));
    switch (mode.tag | 0) {
      case /* AddToMaintenance */0 :
          var match = mode[1].id;
          if (match !== undefined) {
            return addMaintenanceLog(match, log);
          } else {
            return Curry._1(setConfirmationPopup, (function (param) {
                          return /* Done */759635106;
                        }));
          }
      case /* Edit */1 :
          var logData = function (status) {
            return Analytics.data(Events_types.default_data(/* Maintenance_item_log */4, /* Updated */5, status, undefined, Id.firestorePath(log.id), /* () */0));
          };
          return Db.updateMaintenanceLog(firestore, log, (function (param) {
                        if (typeof param === "number") {
                          logData(/* Success */1);
                          return Curry._1(setConfirmationPopup, (function (param) {
                                        return /* Done */759635106;
                                      }));
                        } else {
                          Analytics.except("DB Edit - Maintenance Item Log", param[1]);
                          logData(/* Failure */2);
                          Curry._1(addErrorAlert, "Error updating maintenance log");
                          return Curry._1(setConfirmationPopup, (function (param) {
                                        return /* Done */759635106;
                                      }));
                        }
                      }));
      case /* AddToBoat */2 :
          var selection$1 = validate(selection);
          var match$1 = selection$1.item;
          if ($$Error.isError(selection$1.error)) {
            return Curry._1(setSelection, (function (param) {
                          return selection$1;
                        }));
          } else if (match$1 !== undefined) {
            var match$2 = match$1.id;
            if (match$2 !== undefined) {
              return addMaintenanceLog(match$2, log);
            } else {
              return /* () */0;
            }
          } else {
            return /* () */0;
          }
      
    }
  };
  var action = actionOfMode(validateAndSave, mode);
  var datePickerWidth = value !== undefined ? 6 : 12;
  var tmp;
  switch (mode.tag | 0) {
    case /* AddToMaintenance */0 :
        var description = Maintenance.shortDescription(mode[1]);
        var systemDisplayName = System.displayName(mode[0]);
        tmp = React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(Selector.make, {
                        value: systemDisplayName,
                        values: /* :: */[
                          systemDisplayName,
                          /* [] */0
                        ],
                        toString: (function (prim) {
                            return prim;
                          }),
                        disabled: true
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(Selector.make, {
                        value: description,
                        values: /* :: */[
                          description,
                          /* [] */0
                        ],
                        toString: (function (prim) {
                            return prim;
                          }),
                        disabled: true
                      })
                }));
        break;
    case /* Edit */1 :
        tmp = null;
        break;
    case /* AddToBoat */2 :
        tmp = React.createElement(Grid.default, {
              item: true,
              xs: 12,
              children: React.createElement(MaintenanceLogEntry$ItemSelector, {
                    selection: selection,
                    items: mode[0],
                    onChange: updateSelectedMaintenance
                  })
            });
        break;
    
  }
  var match$4 = log.time_taken;
  return React.createElement(EntryDialog.make, {
              title: titleOfMode(mode),
              action: action,
              onClose: onClose,
              children: null
            }, React.createElement(Grid.default, {
                  container: true,
                  alignItems: "center",
                  spacing: 2,
                  children: null
                }, tmp, React.createElement(Grid.default, {
                      item: true,
                      sm: datePickerWidth,
                      xs: 12,
                      children: React.createElement(DatetimePickers.make, {
                            unixTimestamp: log.unix_timestamp,
                            onChange: updateUnixTimestamp
                          })
                    }), value !== undefined ? React.createElement(Grid.default, {
                        item: true,
                        sm: 6,
                        xs: 12,
                        children: React.createElement(LogValueEntry.make, {
                              value: value,
                              onChange: updateValue
                            })
                      }) : null, React.createElement(Grid.default, {
                      item: true,
                      sm: 2,
                      xs: 4,
                      children: React.createElement(StringFormLabel.make, {
                            text: "Time taken"
                          })
                    }), React.createElement(Grid.default, {
                      item: true,
                      sm: 4,
                      xs: 8,
                      children: match$4 !== undefined ? React.createElement(PeriodEntry.make, {
                              min_unit: undefined,
                              period: match$4,
                              error: $$Error.noError,
                              on_change: updateTimeTaken
                            }) : React.createElement(StringFormLabel.make, {
                              text: "Not applicable"
                            })
                    }), React.createElement(Grid.default, {
                      item: true,
                      sm: 2,
                      xs: 4,
                      children: React.createElement(StringFormLabel.make, {
                            text: "Cost"
                          })
                    }), React.createElement(Grid.default, {
                      item: true,
                      sm: 4,
                      xs: 8,
                      children: React.createElement(NumberInput.Float.make, {
                            value: log.cost,
                            onChange: updateCost
                          })
                    }), React.createElement(Grid.default, {
                      item: true,
                      xs: 12,
                      children: React.createElement(Notes.make, {
                            value: log.notes,
                            onChange: updateNotes
                          })
                    })), React.createElement(AddMaintenanceItemLogConfirmation.make, {
                  onClose: onClose,
                  mode: match$2[0]
                }));
}

var Mui = /* alias */0;

var M = /* alias */0;

var S = /* alias */0;

var E = /* alias */0;

var I = /* alias */0;

var defaultMaintenanceType = /* Other */0;

var make = MaintenanceLogEntry;

export {
  Mui ,
  M ,
  S ,
  E ,
  I ,
  concat ,
  ItemSelector ,
  titleOfMode ,
  actionOfMode ,
  screenOfMode ,
  ignoreOnClose ,
  defaultMaintenanceType ,
  defaultTimeTaken ,
  defaultMaintenanceItem ,
  timeTaken ,
  make ,
  
}
/* Db Not a pure module */
