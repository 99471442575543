// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$Error from "./util/Error.bs.js";
import * as Input from "./core/Input.bs.js";
import * as React from "react";
import * as Belt_Int from "bs-platform/lib/es6/belt_Int.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as PeriodUnitSelector from "./PeriodUnitSelector.bs.js";
import * as Grid from "@material-ui/core/Grid";

function inputOfPeriodCount(periodCount) {
  if (periodCount === 0) {
    return "";
  } else {
    return String(periodCount);
  }
}

function periodCountOfInput(input) {
  if (input === "") {
    return 0;
  } else {
    var match = Belt_Int.fromString(input);
    if (match !== undefined) {
      return match;
    } else {
      return 0;
    }
  }
}

function PeriodEntry(Props) {
  var min_unit = Props.min_unit;
  var period = Props.period;
  var $staropt$star = Props.error;
  var on_change = Props.on_change;
  var error = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : $$Error.noError;
  return React.createElement(Grid.default, {
              container: true,
              justify: "space-between",
              alignItems: "center",
              spacing: 1,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  md: 4,
                  xs: 4,
                  children: React.createElement(Input.make, {
                        error: error,
                        value: inputOfPeriodCount(period.period_count),
                        onChange: (function (e) {
                            var period_count = periodCountOfInput(e.target.value);
                            return Curry._1(on_change, (function (period) {
                                          return {
                                                  period_unit: period.period_unit,
                                                  period_count: period_count
                                                };
                                        }));
                          })
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  md: 8,
                  xs: 8,
                  children: React.createElement(PeriodUnitSelector.make, {
                        min: min_unit,
                        value: period.period_unit,
                        onChange: (function (period_unit) {
                            return Curry._1(on_change, (function (period) {
                                          return {
                                                  period_unit: period_unit,
                                                  period_count: period.period_count
                                                };
                                        }));
                          })
                      })
                }));
}

var make = PeriodEntry;

export {
  inputOfPeriodCount ,
  periodCountOfInput ,
  make ,
  
}
/* Input Not a pure module */
