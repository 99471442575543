// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function default_speed_unit(param) {
  return /* Knots */0;
}

export {
  default_speed_unit ,
  
}
/* No side effect */
