// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";

function geolocation (){
	if ('geolocation' in navigator) {
		return navigator.geolocation;
	} else {
		return undefined;
	}
};

function getCurrentGeoLocation(f) {
  var match = Curry._1(geolocation, /* () */0);
  if (match == null) {
    return Curry._1(f, /* Error */Block.__(1, ["Position not available in browser"]));
  } else {
    var onSuccess = function (position) {
      return Curry._1(f, /* Ok */Block.__(0, [position]));
    };
    var onError = function (positionError) {
      var match = positionError.code;
      var msg;
      switch (match) {
        case 1 :
            msg = "Permission error";
            break;
        case 2 :
            msg = "Position unavailable";
            break;
        case 3 :
            msg = "Timeout";
            break;
        default:
          msg = "Unknow error code";
      }
      return Curry._1(f, /* Error */Block.__(1, [msg]));
    };
    match.getCurrentPosition(onSuccess, onError);
    return /* () */0;
  }
}

export {
  geolocation ,
  getCurrentGeoLocation ,
  
}
/* No side effect */
