// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Id from "./Id.bs.js";
import * as Boat from "./Boat.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Passage from "./Passage.bs.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Collection from "./Collection.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Belt_MapString from "bs-platform/lib/es6/belt_MapString.js";
import * as Belt_SetString from "bs-platform/lib/es6/belt_SetString.js";

function revChronoMaintenanceLogs(l, r) {
  return r.unix_timestamp - l.unix_timestamp | 0;
}

function makeDefault(param) {
  return {
          selectedBoat: undefined,
          selectedPassage: /* NoneSelected */0,
          selectedItem: undefined,
          boats: Collection.empty(/* () */0),
          incompleteData: Belt_SetString.empty,
          passageStats: Belt_MapString.empty,
          alert: /* NoAlert */0
        };
}

function getSelectedBoat(param) {
  var selectedBoat = param.selectedBoat;
  if (selectedBoat !== undefined) {
    return Collection.get(param.boats, selectedBoat.firestore_id);
  }
  
}

function getSelectedPassage(state) {
  var match = getSelectedBoat(state);
  var match$1 = state.selectedPassage;
  if (typeof match$1 === "number") {
    return /* tuple */[
            undefined,
            match
          ];
  } else if (match !== undefined) {
    var b = match;
    var id = Id.firestoreId(match$1[0]);
    return /* tuple */[
            Collection.get(b.passages, id),
            b
          ];
  } else {
    return /* tuple */[
            undefined,
            undefined
          ];
  }
}

function getSelectedItem(state) {
  var match = getSelectedBoat(state);
  var match$1 = state.selectedItem;
  if (match !== undefined && match$1 !== undefined) {
    var match$2 = match$1;
    var itemId = match$2[1];
    var system = Collection.get(match.systems, Id.firestoreId(match$2[0]));
    if (system !== undefined) {
      var s = system;
      var item = Belt_List.getByU(s.maintenance_items, (function (i) {
              return Caml_obj.caml_equal(i.id, itemId);
            }));
      return Belt_Option.mapU(item, (function (item) {
                    return /* tuple */[
                            s,
                            item
                          ];
                  }));
    } else {
      return ;
    }
  }
  
}

function getBoatById(rid, param) {
  if (rid !== undefined) {
    return Collection.get(param.boats, rid.firestore_id);
  }
  
}

function getPassageById(state, boatId, passageId) {
  var match = getBoatById(boatId, state);
  if (match !== undefined) {
    return Collection.get(match.passages, Id.firestoreId(passageId));
  }
  
}

function addPassageStats(state, passage) {
  var match = Passage.computePassageStats(passage);
  var stats = match[0];
  var passageUpdate = match[1] ? ({
        id: passage.id,
        name: passage.name,
        from: passage.from,
        to_: passage.to_,
        notes: passage.notes,
        crew: passage.crew,
        logs: passage.logs,
        fields: passage.fields,
        retroactive_data: passage.retroactive_data,
        logs_stats: stats
      }) : undefined;
  var firestoreId = Id.firestoreId(passage.id);
  var prevStats = Belt_MapString.get(state.passageStats, firestoreId);
  var passageStatsUpdate = prevStats !== undefined && Caml_obj.caml_equal(prevStats, stats) ? undefined : Caml_option.some(Belt_MapString.set(state.passageStats, firestoreId, stats));
  return /* tuple */[
          passageUpdate,
          passageStatsUpdate
        ];
}

function getPassageStats(state, passage) {
  var match = passage.id;
  if (match !== undefined) {
    return Belt_MapString.get(state.passageStats, match.firestore_id);
  }
  
}

function revChronoStats(l, r) {
  var l$1 = l.end_unix_timestamp;
  var r$1 = r.end_unix_timestamp;
  if (l$1 !== undefined) {
    if (r$1 !== undefined) {
      return r$1 - l$1 | 0;
    } else {
      return -1;
    }
  } else if (r$1 !== undefined) {
    return 1;
  } else {
    return 0;
  }
}

function getPassageStatsForBoat(state, boat) {
  if (boat !== undefined) {
    var stats = Collection.mapToList(boat.passages, (function (passage) {
            return getPassageStats(state, passage);
          }));
    var stats$1 = Belt_List.keep(stats, Belt_Option.isSome);
    var stats$2 = Belt_List.map(stats$1, Belt_Option.getExn);
    return Belt_List.sort(stats$2, revChronoStats);
  } else {
    return /* [] */0;
  }
}

function getBoatLatestEngineHours(state, boat) {
  var ret = Collection.reduceValues(boat.passages, undefined, (function (acc, passage) {
          var match = getPassageStats(state, passage);
          if (acc !== undefined) {
            if (match !== undefined) {
              var stats = match;
              var t = stats.end_unix_timestamp;
              var eH = stats.latest_engine_hours_counter;
              if (Belt_Option.isNone(eH) || Caml_obj.caml_greaterthan(acc[0], t)) {
                return acc;
              } else {
                return /* tuple */[
                        t,
                        eH
                      ];
              }
            } else {
              return acc;
            }
          } else if (match !== undefined) {
            var stats$1 = match;
            var t$1 = stats$1.end_unix_timestamp;
            var eH$1 = stats$1.latest_engine_hours_counter;
            if (Belt_Option.isNone(eH$1)) {
              return acc;
            } else {
              return /* tuple */[
                      t$1,
                      eH$1
                    ];
            }
          } else {
            return acc;
          }
        }));
  if (ret !== undefined) {
    return ret[1];
  }
  
}

function updateBoatWithId(state, id, f) {
  var boats = Collection.updateIfPresent(state.boats, id.firestore_id, Curry.__1(f));
  if (boats !== undefined) {
    return {
            selectedBoat: state.selectedBoat,
            selectedPassage: state.selectedPassage,
            selectedItem: state.selectedItem,
            boats: Caml_option.valFromOption(boats),
            incompleteData: state.incompleteData,
            passageStats: state.passageStats,
            alert: state.alert
          };
  } else {
    return state;
  }
}

function updatePassageWithId(boat, id, f) {
  var id$1 = id.firestore_id;
  var passages = Collection.updateIfPresent(boat.passages, id$1, Curry.__1(f));
  if (passages !== undefined) {
    return {
            data: boat.data,
            systems: boat.systems,
            passages: Caml_option.valFromOption(passages)
          };
  } else {
    return boat;
  }
}

function updateSystemWithId(boat, id, f) {
  var id$1 = id.firestore_id;
  var systems = Collection.updateIfPresent(boat.systems, id$1, Curry.__1(f));
  if (systems !== undefined) {
    return {
            data: boat.data,
            systems: Caml_option.valFromOption(systems),
            passages: boat.passages
          };
  } else {
    return boat;
  }
}

function updateMaintenanceItemWithId(system, id, f) {
  var maintenance_items = Belt_List.mapU(system.maintenance_items, (function (item) {
          var match = item.id;
          if (match !== undefined && Caml_obj.caml_equal(match, id)) {
            return Curry._1(f, item);
          } else {
            return item;
          }
        }));
  return {
          id: system.id,
          identifier: system.identifier,
          category: system.category,
          other_category: system.other_category,
          brand: system.brand,
          model: system.model,
          maintenance_items: maintenance_items
        };
}

function updateMaintenanceLogWithId(item, id, f) {
  var logs = Belt_List.mapU(item.logs, (function (log) {
          var match = log.id;
          if (match !== undefined && Caml_obj.caml_equal(match, id)) {
            return Curry._1(f, log);
          } else {
            return log;
          }
        }));
  return {
          id: item.id,
          short_description: item.short_description,
          notes: item.notes,
          frequency: item.frequency,
          type_: item.type_,
          logs: logs
        };
}

function addPassageStatsForPassageId(state, notifyHandler, boatId, passageId) {
  var passageStats = {
    contents: undefined
  };
  var state$1 = updateBoatWithId(state, boatId, (function (boat) {
          return updatePassageWithId(boat, passageId, (function (passage) {
                        var match = addPassageStats(state, passage);
                        var passage$prime = match[0];
                        passageStats.contents = match[1];
                        if (passage$prime !== undefined) {
                          var passage$1 = passage$prime;
                          Curry._1(notifyHandler, /* SyncPassageStats */[passage$1]);
                          return passage$1;
                        } else {
                          return passage;
                        }
                      }));
        }));
  var match = passageStats.contents;
  if (match !== undefined) {
    return {
            selectedBoat: state$1.selectedBoat,
            selectedPassage: state$1.selectedPassage,
            selectedItem: state$1.selectedItem,
            boats: state$1.boats,
            incompleteData: state$1.incompleteData,
            passageStats: Caml_option.valFromOption(match),
            alert: state$1.alert
          };
  } else {
    return state$1;
  }
}

function addIncompleteData(state, identifier) {
  var incompleteData = Belt_SetString.add(state.incompleteData, identifier);
  return {
          selectedBoat: state.selectedBoat,
          selectedPassage: state.selectedPassage,
          selectedItem: state.selectedItem,
          boats: state.boats,
          incompleteData: incompleteData,
          passageStats: state.passageStats,
          alert: state.alert
        };
}

function markAsComplete(state, identifier) {
  var incompleteData = Belt_SetString.remove(state.incompleteData, identifier);
  return {
          selectedBoat: state.selectedBoat,
          selectedPassage: state.selectedPassage,
          selectedItem: state.selectedItem,
          boats: state.boats,
          incompleteData: incompleteData,
          passageStats: state.passageStats,
          alert: state.alert
        };
}

function isComplete(state) {
  return Belt_SetString.size(state.incompleteData) === 0;
}

function reduce(notifyHandler, state, param) {
  if (typeof param === "number") {
    if (param !== -101336657) {
      if (param >= 612177942) {
        return {
                selectedBoat: undefined,
                selectedPassage: /* NoneSelected */0,
                selectedItem: undefined,
                boats: Collection.empty(/* () */0),
                incompleteData: Belt_SetString.empty,
                passageStats: Belt_MapString.empty,
                alert: state.alert
              };
      } else {
        return {
                selectedBoat: state.selectedBoat,
                selectedPassage: state.selectedPassage,
                selectedItem: state.selectedItem,
                boats: state.boats,
                incompleteData: state.incompleteData,
                passageStats: state.passageStats,
                alert: /* NoAlert */0
              };
      }
    } else {
      var init = makeDefault(/* () */0);
      return {
              selectedBoat: init.selectedBoat,
              selectedPassage: init.selectedPassage,
              selectedItem: init.selectedItem,
              boats: init.boats,
              incompleteData: init.incompleteData,
              passageStats: init.passageStats,
              alert: state.alert
            };
    }
  } else {
    var variant = param[0];
    if (variant >= 212346573) {
      if (variant >= 644346172) {
        if (variant >= 880431674) {
          if (variant >= 978680419) {
            if (variant >= 1010560668) {
              var match = param[1];
              var passageLogs = match[2];
              var passageId = match[1];
              return updateBoatWithId(state, match[0], (function (boat) {
                            return updatePassageWithId(boat, passageId, (function (passage) {
                                          var logs = Belt_List.concat(passageLogs, passage.logs);
                                          var logs$1 = Belt_List.sort(logs, Passage.revChronoPassageLogs);
                                          return {
                                                  id: passage.id,
                                                  name: passage.name,
                                                  from: passage.from,
                                                  to_: passage.to_,
                                                  notes: passage.notes,
                                                  crew: passage.crew,
                                                  logs: logs$1,
                                                  fields: passage.fields,
                                                  retroactive_data: passage.retroactive_data,
                                                  logs_stats: passage.logs_stats
                                                };
                                        }));
                          }));
            } else {
              var match$1 = param[1];
              var passageLog = match$1[2];
              var passageId$1 = match$1[1];
              return updateBoatWithId(state, match$1[0], (function (boat) {
                            return updatePassageWithId(boat, passageId$1, (function (passage) {
                                          var resort = {
                                            contents: false
                                          };
                                          var logs = Belt_List.mapU(passage.logs, (function (prev) {
                                                  var match = passageLog.id;
                                                  var match$1 = prev.id;
                                                  if (match !== undefined && match$1 !== undefined && Caml_obj.caml_equal(match, match$1)) {
                                                    resort.contents = prev.unix_timestamp !== passageLog.unix_timestamp;
                                                    return passageLog;
                                                  } else {
                                                    return prev;
                                                  }
                                                }));
                                          var logs$1 = resort.contents ? Belt_List.sort(logs, Passage.revChronoPassageLogs) : logs;
                                          return {
                                                  id: passage.id,
                                                  name: passage.name,
                                                  from: passage.from,
                                                  to_: passage.to_,
                                                  notes: passage.notes,
                                                  crew: passage.crew,
                                                  logs: logs$1,
                                                  fields: passage.fields,
                                                  retroactive_data: passage.retroactive_data,
                                                  logs_stats: passage.logs_stats
                                                };
                                        }));
                          }));
            }
          } else if (variant >= 887862916) {
            var boats = Collection.$$delete(state.boats, param[1].firestore_id);
            var match$2 = Collection.head(boats);
            var selectedBoat = match$2 !== undefined ? match$2.data.id : undefined;
            return {
                    selectedBoat: selectedBoat,
                    selectedPassage: /* NoneSelected */0,
                    selectedItem: undefined,
                    boats: boats,
                    incompleteData: state.incompleteData,
                    passageStats: state.passageStats,
                    alert: state.alert
                  };
          } else {
            var match$3 = param[1];
            return {
                    selectedBoat: state.selectedBoat,
                    selectedPassage: state.selectedPassage,
                    selectedItem: state.selectedItem,
                    boats: state.boats,
                    incompleteData: state.incompleteData,
                    passageStats: state.passageStats,
                    alert: /* Success */Block.__(0, [
                        match$3[0],
                        match$3[1]
                      ])
                  };
          }
        } else if (variant !== 706903667) {
          if (variant >= 773243074) {
            var match$4 = param[1];
            var id = match$4[2].id;
            if (id !== undefined) {
              var idToDelete = id;
              var systemId = match$4[1];
              var state$1 = updateBoatWithId(state, match$4[0], (function (boat) {
                      return updateSystemWithId(boat, systemId, (function (system) {
                                    var items = system.maintenance_items;
                                    var keepPred = function (param) {
                                      var match = param.id;
                                      if (match !== undefined && Caml_obj.caml_equal(match, idToDelete)) {
                                        return false;
                                      } else {
                                        return true;
                                      }
                                    };
                                    var items$1 = Belt_List.keep(items, keepPred);
                                    return {
                                            id: system.id,
                                            identifier: system.identifier,
                                            category: system.category,
                                            other_category: system.other_category,
                                            brand: system.brand,
                                            model: system.model,
                                            maintenance_items: items$1
                                          };
                                  }));
                    }));
              return {
                      selectedBoat: state$1.selectedBoat,
                      selectedPassage: state$1.selectedPassage,
                      selectedItem: undefined,
                      boats: state$1.boats,
                      incompleteData: state$1.incompleteData,
                      passageStats: state$1.passageStats,
                      alert: state$1.alert
                    };
            } else {
              return state;
            }
          } else {
            var id$1 = param[1];
            if (id$1 !== undefined) {
              var boat = Collection.get(state.boats, id$1.firestore_id);
              if (boat !== undefined) {
                return {
                        selectedBoat: id$1,
                        selectedPassage: /* NoneSelected */0,
                        selectedItem: undefined,
                        boats: state.boats,
                        incompleteData: state.incompleteData,
                        passageStats: state.passageStats,
                        alert: state.alert
                      };
              } else {
                return {
                        selectedBoat: state.selectedBoat,
                        selectedPassage: /* NoneSelected */0,
                        selectedItem: undefined,
                        boats: state.boats,
                        incompleteData: state.incompleteData,
                        passageStats: state.passageStats,
                        alert: state.alert
                      };
              }
            } else {
              return state;
            }
          }
        } else {
          var match$5 = param[1];
          var idToRemove = Id.firestoreId(match$5[1].id);
          var state$2 = updateBoatWithId(state, match$5[0], (function (boat) {
                  var systems = Collection.$$delete(boat.systems, idToRemove);
                  return {
                          data: boat.data,
                          systems: systems,
                          passages: boat.passages
                        };
                }));
          return {
                  selectedBoat: state$2.selectedBoat,
                  selectedPassage: state$2.selectedPassage,
                  selectedItem: undefined,
                  boats: state$2.boats,
                  incompleteData: state$2.incompleteData,
                  passageStats: state$2.passageStats,
                  alert: state$2.alert
                };
        }
      } else if (variant >= 303176914) {
        if (variant >= 459221530) {
          if (variant >= 504288394) {
            var match$6 = param[1];
            var firestoreId = Id.firestoreId(match$6[1].id);
            var state$3 = updateBoatWithId(state, match$6[0], (function (boat) {
                    var passages = Collection.$$delete(boat.passages, firestoreId);
                    return {
                            data: boat.data,
                            systems: boat.systems,
                            passages: passages
                          };
                  }));
            var passageStats = Belt_MapString.remove(state$3.passageStats, firestoreId);
            return {
                    selectedBoat: state$3.selectedBoat,
                    selectedPassage: /* NoneSelected */0,
                    selectedItem: state$3.selectedItem,
                    boats: state$3.boats,
                    incompleteData: state$3.incompleteData,
                    passageStats: passageStats,
                    alert: state$3.alert
                  };
          } else {
            var match$7 = param[1];
            var logToDelete = match$7[2];
            var passageId$2 = match$7[1];
            return updateBoatWithId(state, match$7[0], (function (boat) {
                          return updatePassageWithId(boat, passageId$2, (function (passage) {
                                        var logs = Belt_List.keepU(passage.logs, (function (log) {
                                                var match = log.id;
                                                var match$1 = logToDelete.id;
                                                if (match !== undefined && match$1 !== undefined && Caml_obj.caml_equal(match, match$1)) {
                                                  return false;
                                                } else {
                                                  return true;
                                                }
                                              }));
                                        return {
                                                id: passage.id,
                                                name: passage.name,
                                                from: passage.from,
                                                to_: passage.to_,
                                                notes: passage.notes,
                                                crew: passage.crew,
                                                logs: logs,
                                                fields: passage.fields,
                                                retroactive_data: passage.retroactive_data,
                                                logs_stats: passage.logs_stats
                                              };
                                      }));
                        }));
          }
        } else if (variant >= 377983242) {
          return {
                  selectedBoat: state.selectedBoat,
                  selectedPassage: state.selectedPassage,
                  selectedItem: param[1],
                  boats: state.boats,
                  incompleteData: state.incompleteData,
                  passageStats: state.passageStats,
                  alert: state.alert
                };
        } else {
          var boat$1 = getSelectedBoat(state);
          if (boat$1 !== undefined) {
            return {
                    selectedBoat: state.selectedBoat,
                    selectedPassage: param[1],
                    selectedItem: state.selectedItem,
                    boats: state.boats,
                    incompleteData: state.incompleteData,
                    passageStats: state.passageStats,
                    alert: state.alert
                  };
          } else {
            return state;
          }
        }
      } else if (variant >= 253468248) {
        return markAsComplete(state, param[1]);
      } else {
        var match$8 = param[1];
        var log = match$8[3];
        var maintenanceId = match$8[2];
        var systemId$1 = match$8[1];
        return updateBoatWithId(state, match$8[0], (function (boat) {
                      return updateSystemWithId(boat, systemId$1, (function (system) {
                                    return updateMaintenanceItemWithId(system, maintenanceId, (function (item) {
                                                  var logs = item.logs;
                                                  var logs$1;
                                                  if (logs) {
                                                    var latestTime = logs[0].unix_timestamp;
                                                    var logTime = log.unix_timestamp;
                                                    var logs$2 = /* :: */[
                                                      log,
                                                      logs
                                                    ];
                                                    logs$1 = logTime > latestTime ? logs$2 : Belt_List.sort(logs$2, revChronoMaintenanceLogs);
                                                  } else {
                                                    logs$1 = /* :: */[
                                                      log,
                                                      /* [] */0
                                                    ];
                                                  }
                                                  return {
                                                          id: item.id,
                                                          short_description: item.short_description,
                                                          notes: item.notes,
                                                          frequency: item.frequency,
                                                          type_: item.type_,
                                                          logs: logs$1
                                                        };
                                                }));
                                  }));
                    }));
      }
    } else if (variant >= -375268184) {
      if (variant >= -191390563) {
        if (variant >= 26332637) {
          if (variant >= 207288569) {
            var data = param[1];
            return updateBoatWithId(state, Belt_Option.getExn(data.id), (function (prevBoat) {
                          return {
                                  data: data,
                                  systems: prevBoat.systems,
                                  passages: prevBoat.passages
                                };
                        }));
          } else {
            var match$9 = param[1];
            var passage = match$9[1];
            var boatId = match$9[0];
            var state$4 = updateBoatWithId(state, boatId, (function (boat) {
                    var updatePassage = function (prevPassage) {
                      return {
                              id: passage.id,
                              name: passage.name,
                              from: passage.from,
                              to_: passage.to_,
                              notes: passage.notes,
                              crew: passage.crew,
                              logs: prevPassage.logs,
                              fields: passage.fields,
                              retroactive_data: passage.retroactive_data,
                              logs_stats: passage.logs_stats
                            };
                    };
                    var id = Belt_Option.getExn(passage.id);
                    return updatePassageWithId(boat, id, updatePassage);
                  }));
            var match$10 = Passage.passageType(passage);
            if (match$10) {
              return state$4;
            } else {
              return addPassageStatsForPassageId(state$4, notifyHandler, boatId, Belt_Option.getExn(passage.id));
            }
          }
        } else if (variant >= 20347072) {
          var match$11 = param[1];
          var item = match$11[2];
          var systemId$2 = match$11[1];
          return updateBoatWithId(state, match$11[0], (function (boat) {
                        return updateSystemWithId(boat, systemId$2, (function (system) {
                                      var items_001 = system.maintenance_items;
                                      var items = /* :: */[
                                        item,
                                        items_001
                                      ];
                                      return {
                                              id: system.id,
                                              identifier: system.identifier,
                                              category: system.category,
                                              other_category: system.other_category,
                                              brand: system.brand,
                                              model: system.model,
                                              maintenance_items: items
                                            };
                                    }));
                      }));
        } else {
          var match$12 = param[1];
          var item$1 = match$12[2];
          var systemId$3 = match$12[1];
          return updateBoatWithId(state, match$12[0], (function (boat) {
                        return updateSystemWithId(boat, systemId$3, (function (system) {
                                      var items = Belt_List.mapU(system.maintenance_items, (function (prevItem) {
                                              var match = prevItem.id;
                                              var match$1 = item$1.id;
                                              if (match !== undefined && match$1 !== undefined && Caml_obj.caml_equal(match, match$1)) {
                                                return {
                                                        id: item$1.id,
                                                        short_description: item$1.short_description,
                                                        notes: item$1.notes,
                                                        frequency: item$1.frequency,
                                                        type_: item$1.type_,
                                                        logs: prevItem.logs
                                                      };
                                              } else {
                                                return prevItem;
                                              }
                                            }));
                                      return {
                                              id: system.id,
                                              identifier: system.identifier,
                                              category: system.category,
                                              other_category: system.other_category,
                                              brand: system.brand,
                                              model: system.model,
                                              maintenance_items: items
                                            };
                                    }));
                      }));
        }
      } else if (variant !== -320788054) {
        if (variant >= -213167927) {
          return addIncompleteData(state, param[1]);
        } else {
          var match$13 = param[1];
          return addPassageStatsForPassageId(state, notifyHandler, match$13[0], match$13[1]);
        }
      } else {
        var match$14 = param[1];
        var system = match$14[1];
        return updateBoatWithId(state, match$14[0], (function (boat) {
                      var systemId = Belt_Option.getExn(system.id);
                      var updateSystem = function (prev) {
                        return {
                                id: system.id,
                                identifier: system.identifier,
                                category: system.category,
                                other_category: system.other_category,
                                brand: system.brand,
                                model: system.model,
                                maintenance_items: prev.maintenance_items
                              };
                      };
                      return updateSystemWithId(boat, systemId, updateSystem);
                    }));
      }
    } else if (variant >= -926162987) {
      if (variant >= -838517702) {
        if (variant >= -798156920) {
          var match$15 = param[1];
          var passage$1 = match$15[1];
          return updateBoatWithId(state, match$15[0], (function (boat) {
                        var passageId = Id.firestoreId(passage$1.id);
                        var passages = Collection.set(boat.passages, passageId, passage$1);
                        return {
                                data: boat.data,
                                systems: boat.systems,
                                passages: passages
                              };
                      }));
        } else {
          var match$16 = param[1];
          var log$1 = match$16[3];
          var maintenanceId$1 = match$16[2];
          var systemId$4 = match$16[1];
          return updateBoatWithId(state, match$16[0], (function (boat) {
                        return updateSystemWithId(boat, systemId$4, (function (system) {
                                      return updateMaintenanceItemWithId(system, maintenanceId$1, (function (item) {
                                                    var resort = {
                                                      contents: false
                                                    };
                                                    var logs = Belt_List.map(item.logs, (function (prevLog) {
                                                            var match = prevLog.id;
                                                            var match$1 = log$1.id;
                                                            if (match !== undefined && match$1 !== undefined && Caml_obj.caml_equal(match, match$1)) {
                                                              resort.contents = prevLog.unix_timestamp !== log$1.unix_timestamp;
                                                              return log$1;
                                                            } else {
                                                              return prevLog;
                                                            }
                                                          }));
                                                    var logs$1 = resort.contents ? Belt_List.sort(logs, revChronoMaintenanceLogs) : logs;
                                                    return {
                                                            id: item.id,
                                                            short_description: item.short_description,
                                                            notes: item.notes,
                                                            frequency: item.frequency,
                                                            type_: item.type_,
                                                            logs: logs$1
                                                          };
                                                  }));
                                    }));
                      }));
        }
      } else if (variant >= -894836063) {
        var data$1 = param[1];
        var id$2 = Id.firestoreId(data$1.id);
        var boat$2 = Boat.$$default(data$1, /* () */0);
        var boats$1 = Collection.set(state.boats, id$2, boat$2);
        var selectedBoat$1 = data$1.id;
        return {
                selectedBoat: selectedBoat$1,
                selectedPassage: /* NoneSelected */0,
                selectedItem: state.selectedItem,
                boats: boats$1,
                incompleteData: state.incompleteData,
                passageStats: state.passageStats,
                alert: state.alert
              };
      } else {
        return {
                selectedBoat: state.selectedBoat,
                selectedPassage: state.selectedPassage,
                selectedItem: state.selectedItem,
                boats: state.boats,
                incompleteData: state.incompleteData,
                passageStats: state.passageStats,
                alert: /* Error */Block.__(1, [param[1]])
              };
      }
    } else if (variant >= -1060782325) {
      var match$17 = param[1];
      var system$1 = match$17[1];
      var firestoreId$1 = Id.firestoreId(system$1.id);
      return updateBoatWithId(state, match$17[0], (function (boat) {
                    var systems = Collection.set(boat.systems, firestoreId$1, system$1);
                    return {
                            data: boat.data,
                            systems: systems,
                            passages: boat.passages
                          };
                  }));
    } else {
      var match$18 = param[1];
      var itemId = match$18[2];
      var systemId$5 = match$18[1];
      var idToDelete$1 = match$18[3].id;
      if (idToDelete$1 !== undefined) {
        return updateBoatWithId(state, match$18[0], (function (boat) {
                      return updateSystemWithId(boat, systemId$5, (function (system) {
                                    return updateMaintenanceItemWithId(system, itemId, (function (item) {
                                                  var logs = Belt_List.keep(item.logs, (function (log) {
                                                          return Caml_obj.caml_notequal(log.id, idToDelete$1);
                                                        }));
                                                  return {
                                                          id: item.id,
                                                          short_description: item.short_description,
                                                          notes: item.notes,
                                                          frequency: item.frequency,
                                                          type_: item.type_,
                                                          logs: logs
                                                        };
                                                }));
                                  }));
                    }));
      } else {
        return state;
      }
    }
  }
}

var B = /* alias */0;

var M = /* alias */0;

var S = /* alias */0;

var U = /* alias */0;

var Pa = /* alias */0;

var I = /* alias */0;

export {
  B ,
  M ,
  S ,
  U ,
  Pa ,
  I ,
  revChronoMaintenanceLogs ,
  makeDefault ,
  getSelectedBoat ,
  getSelectedPassage ,
  getSelectedItem ,
  getBoatById ,
  getPassageById ,
  addPassageStats ,
  getPassageStats ,
  revChronoStats ,
  getPassageStatsForBoat ,
  getBoatLatestEngineHours ,
  updateBoatWithId ,
  updatePassageWithId ,
  updateSystemWithId ,
  updateMaintenanceItemWithId ,
  updateMaintenanceLogWithId ,
  addPassageStatsForPassageId ,
  addIncompleteData ,
  markAsComplete ,
  isComplete ,
  reduce ,
  
}
/* Boat Not a pure module */
