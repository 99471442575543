// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Boat_types from "../types/boat_types.bs.js";
import * as Collection from "./Collection.bs.js";

var defaultData = Boat_types.default_boat(undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);

function $$default($staropt$star, param) {
  var data = $staropt$star !== undefined ? $staropt$star : defaultData;
  return {
          data: data,
          systems: Collection.empty(/* () */0),
          passages: Collection.empty(/* () */0)
        };
}

function name(param) {
  return param.name;
}

function stringOfYear(n) {
  if (n !== 0) {
    return String(n);
  } else {
    return "";
  }
}

var B = /* alias */0;

var S = /* alias */0;

var M = /* alias */0;

var F = /* alias */0;

var Pa = /* alias */0;

export {
  B ,
  S ,
  M ,
  F ,
  Pa ,
  defaultData ,
  $$default ,
  $$default as default,
  name ,
  stringOfYear ,
  
}
/* defaultData Not a pure module */
