// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Maintenance_bs from "../types/maintenance_bs.bs.js";
import * as Maintenance_types from "../types/maintenance_types.bs.js";

function make(param) {
  return Maintenance_types.default_maintenance_item(undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);
}

function id(p) {
  return Belt_Option.getExn(p.id);
}

function withId(p, id) {
  return {
          id: id,
          short_description: p.short_description,
          notes: p.notes,
          frequency: p.frequency,
          type_: p.type_,
          logs: p.logs
        };
}

function prepareForDb(p) {
  return {
          id: undefined,
          short_description: p.short_description,
          notes: p.notes,
          frequency: p.frequency,
          type_: p.type_,
          logs: /* [] */0
        };
}

var encode = Maintenance_bs.encode_maintenance_item;

var decode = Maintenance_bs.decode_maintenance_item;

export {
  make ,
  encode ,
  decode ,
  id ,
  withId ,
  prepareForDb ,
  
}
/* Maintenance_bs Not a pure module */
