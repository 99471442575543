// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as System from "../util/System.bs.js";
import * as MenuHook from "./util/MenuHook.bs.js";
import * as RowMenuButton from "./RowMenuButton.bs.js";
import * as StringBodyTableCell from "./core/StringBodyTableCell.bs.js";
import * as StringHeadTableCell from "./core/StringHeadTableCell.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as Menu from "@material-ui/core/Menu";
import * as MenuItem from "@material-ui/core/MenuItem";
import * as TableRow from "@material-ui/core/TableRow";
import * as TableCell from "@material-ui/core/TableCell";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function ignoreOnClick(param) {
  return /* () */0;
}

function BoatSystemsRow$Head(Props) {
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringHeadTableCell.make, {
                  value: "Part"
                }), React.createElement(StringHeadTableCell.make, {
                  value: "Brand"
                }), React.createElement(StringHeadTableCell.make, {
                  value: "Model"
                }), React.createElement(StringHeadTableCell.make, {
                  value: ""
                }));
}

var Head = {
  make: BoatSystemsRow$Head
};

var useStyles = MakeStyles.default({
      actionCell: {
        textAlign: "right"
      }
    });

function makeGridItem(s, size) {
  return React.createElement(Grid.default, {
              item: true,
              xs: size,
              children: s
            });
}

function ignoreOnAction(param) {
  return /* () */0;
}

function BoatSystemsRow$Body(Props) {
  var system = Props.system;
  var $staropt$star = Props.onAction;
  var onAction = $staropt$star !== undefined ? $staropt$star : ignoreOnAction;
  var classes = useStyles();
  var match = MenuHook.useMenu(/* () */0);
  var toggle = match[2];
  var handleOnAction = function (action, e) {
    Curry._2(toggle, /* Hide */803692770, e);
    return Curry._1(onAction, action);
  };
  var partial_arg = /* Edit */Block.__(0, [system]);
  var partial_arg$1 = /* Delete */Block.__(1, [system]);
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(StringBodyTableCell.make, {
                  value: System.displayName(system)
                }), React.createElement(StringBodyTableCell.make, {
                  value: system.brand
                }), React.createElement(StringBodyTableCell.make, {
                  value: system.model
                }), React.createElement(TableCell.default, {
                  className: classes.actionCell,
                  children: null
                }, React.createElement(RowMenuButton.make, {
                      onClick: Curry._1(toggle, /* Show */925630749),
                      color: /* secondary */-499495052
                    }), React.createElement(Menu.default, {
                      open: match[1],
                      anchorEl: match[0],
                      keepMounted: true,
                      onClose: Curry._1(toggle, /* Hide */803692770),
                      children: null
                    }, React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg, param);
                            }),
                          children: "Edit"
                        }), React.createElement(MenuItem.default, {
                          onClick: (function (param) {
                              return handleOnAction(partial_arg$1, param);
                            }),
                          children: "Delete"
                        }))));
}

var Body = {
  useStyles: useStyles,
  makeGridItem: makeGridItem,
  ignoreOnAction: ignoreOnAction,
  make: BoatSystemsRow$Body
};

var Mui = /* alias */0;

var S = /* alias */0;

export {
  Mui ,
  S ,
  concat ,
  ignoreOnClick ,
  Head ,
  Body ,
  
}
/* useStyles Not a pure module */
