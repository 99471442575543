// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function toString(param) {
  switch (param) {
    case /* Knots */0 :
        return "kn";
    case /* Meters_per_seconds */1 :
        return "m/s";
    case /* Kilometers_per_hours */2 :
        return "km/h";
    case /* Miles_per_hours */3 :
        return "mph";
    
  }
}

function convertFrom(value, param) {
  switch (param) {
    case /* Knots */0 :
        return value / 1.94384;
    case /* Meters_per_seconds */1 :
        return value;
    case /* Kilometers_per_hours */2 :
        return value / 3.6;
    case /* Miles_per_hours */3 :
        return value / 2.2369311202577;
    
  }
}

function digits(param) {
  return 2;
}

function convertTo(value, param) {
  switch (param) {
    case /* Knots */0 :
        return value * 1.94384;
    case /* Meters_per_seconds */1 :
        return value;
    case /* Kilometers_per_hours */2 :
        return value * 3.6;
    case /* Miles_per_hours */3 :
        return value * 2.2369311202577;
    
  }
}

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function toDisplayString(value, displayUnit) {
  var displayUnit$1 = Belt_Option.getWithDefault(displayUnit, /* Knots */0);
  var value$1 = convertTo(value, displayUnit$1);
  return value$1.toFixed(2).concat(" ").concat(toString(displayUnit$1));
}

var SU = /* alias */0;

var allSpeedUnit = /* :: */[
  /* Knots */0,
  /* :: */[
    /* Meters_per_seconds */1,
    /* :: */[
      /* Kilometers_per_hours */2,
      /* :: */[
        /* Miles_per_hours */3,
        /* [] */0
      ]
    ]
  ]
];

var $$default = /* Knots */0;

export {
  SU ,
  toString ,
  convertFrom ,
  digits ,
  convertTo ,
  allSpeedUnit ,
  $$default ,
  $$default as default,
  concat ,
  toDisplayString ,
  
}
/* No side effect */
