// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Id_bs from "./id_bs.bs.js";
import * as Pbrt_bs from "bs-ocaml-protoc-json/src/pbrt_bs.js";
import * as Passage_types from "./passage_types.bs.js";
import * as Cardinal_direction_bs from "./cardinal_direction_bs.bs.js";
import * as Cardinal_direction_types from "./cardinal_direction_types.bs.js";

function default_passage_crew_member_mutable(param) {
  return {
          id: 0,
          type_: Passage_types.default_crew_type(/* () */0),
          name: ""
        };
}

function default_log_position_data_mutable(param) {
  return {
          leg: 0,
          sog: undefined,
          cog: undefined,
          distance: undefined,
          chart_course: undefined,
          wind_speed: undefined,
          wind_direction: Cardinal_direction_types.default_cardinal_direction(/* () */0),
          sea_state_description: "",
          barometric_pressure: undefined,
          air_temperature: undefined,
          sea_temperature: undefined,
          sky: "",
          remarks: ""
        };
}

function default_passage_mutable(param) {
  return {
          id: undefined,
          name: "",
          from: "",
          to_: "",
          notes: "",
          crew: /* [] */0,
          logs: /* [] */0,
          fields: /* [] */0,
          retroactive_data: undefined,
          logs_stats: undefined
        };
}

function decode_crew_type(json) {
  var match = Pbrt_bs.string(json, "crew_type", "value");
  switch (match) {
    case "" :
    case "CAPTAIN" :
        return /* Captain */0;
    case "CREW" :
        return /* Crew */2;
    case "FIRST_MATE" :
        return /* First_mate */1;
    case "GUEST" :
        return /* Guest */3;
    default:
      return Pbrt_bs.E.malformed_variant("crew_type");
  }
}

function decode_passage_crew_member(json) {
  var v = default_passage_crew_member_mutable(/* () */0);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "id" :
          var json$1 = json["id"];
          v.id = Pbrt_bs.$$int(json$1, "passage_crew_member", "id");
          break;
      case "name" :
          var json$2 = json["name"];
          v.name = Pbrt_bs.string(json$2, "passage_crew_member", "name");
          break;
      case "type" :
          var json$3 = json["type"];
          v.type_ = decode_crew_type(json$3);
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          type_: v.type_,
          name: v.name
        };
}

function decode_log_field_type(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i !== -1) {
      var match = keys[i];
      switch (match) {
        case "airTemperature" :
            return /* Air_temperature */9;
        case "barometricPressure" :
            return /* Barometric_pressure */8;
        case "chartCourse" :
            return /* Chart_course */4;
        case "cog" :
            return /* Cog */2;
        case "distance" :
            return /* Distance */3;
        case "geoLocation" :
            return /* Geo_location */13;
        case "leg" :
            return /* Leg */0;
        case "locationName" :
            return /* Location_name */14;
        case "remarks" :
            return /* Remarks */12;
        case "seaStateDescription" :
            return /* Sea_state_description */7;
        case "seaTemperature" :
            return /* Sea_temperature */11;
        case "sky" :
            return /* Sky */10;
        case "sog" :
            return /* Sog */1;
        case "windDirection" :
            return /* Wind_direction */6;
        case "windSpeed" :
            return /* Wind_speed */5;
        default:
          _i = i - 1 | 0;
          continue ;
      }
    } else {
      return Pbrt_bs.E.malformed_variant("log_field_type");
    }
  };
}

function decode_log_position_data(json) {
  var v = default_log_position_data_mutable(/* () */0);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "airTemperature" :
          var json$1 = json["airTemperature"];
          v.air_temperature = Pbrt_bs.float_wrapped(json$1, "log_position_data", "air_temperature");
          break;
      case "barometricPressure" :
          var json$2 = json["barometricPressure"];
          v.barometric_pressure = Pbrt_bs.float_wrapped(json$2, "log_position_data", "barometric_pressure");
          break;
      case "chartCourse" :
          var json$3 = json["chartCourse"];
          v.chart_course = Pbrt_bs.float_wrapped(json$3, "log_position_data", "chart_course");
          break;
      case "cog" :
          var json$4 = json["cog"];
          v.cog = Pbrt_bs.float_wrapped(json$4, "log_position_data", "cog");
          break;
      case "distance" :
          var json$5 = json["distance"];
          v.distance = Pbrt_bs.float_wrapped(json$5, "log_position_data", "distance");
          break;
      case "leg" :
          var json$6 = json["leg"];
          v.leg = Pbrt_bs.$$int(json$6, "log_position_data", "leg");
          break;
      case "remarks" :
          var json$7 = json["remarks"];
          v.remarks = Pbrt_bs.string(json$7, "log_position_data", "remarks");
          break;
      case "seaStateDescription" :
          var json$8 = json["seaStateDescription"];
          v.sea_state_description = Pbrt_bs.string(json$8, "log_position_data", "sea_state_description");
          break;
      case "seaTemperature" :
          var json$9 = json["seaTemperature"];
          v.sea_temperature = Pbrt_bs.float_wrapped(json$9, "log_position_data", "sea_temperature");
          break;
      case "sky" :
          var json$10 = json["sky"];
          v.sky = Pbrt_bs.string(json$10, "log_position_data", "sky");
          break;
      case "sog" :
          var json$11 = json["sog"];
          v.sog = Pbrt_bs.float_wrapped(json$11, "log_position_data", "sog");
          break;
      case "windDirection" :
          var json$12 = json["windDirection"];
          v.wind_direction = Cardinal_direction_bs.decode_cardinal_direction(json$12);
          break;
      case "windSpeed" :
          var json$13 = json["windSpeed"];
          v.wind_speed = Pbrt_bs.float_wrapped(json$13, "log_position_data", "wind_speed");
          break;
      default:
        
    }
  }
  return {
          leg: v.leg,
          sog: v.sog,
          cog: v.cog,
          distance: v.distance,
          chart_course: v.chart_course,
          wind_speed: v.wind_speed,
          wind_direction: v.wind_direction,
          sea_state_description: v.sea_state_description,
          barometric_pressure: v.barometric_pressure,
          air_temperature: v.air_temperature,
          sea_temperature: v.sea_temperature,
          sky: v.sky,
          remarks: v.remarks
        };
}

function decode_log_motor_on(json) {
  var v = undefined;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "engineHoursCounter") {
      var json$1 = json["engineHoursCounter"];
      v = Pbrt_bs.float_wrapped(json$1, "log_motor_on", "engine_hours_counter");
    }
    
  }
  return {
          engine_hours_counter: v
        };
}

function decode_log_motor_off(json) {
  var v = undefined;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "engineHoursCounter") {
      var json$1 = json["engineHoursCounter"];
      v = Pbrt_bs.float_wrapped(json$1, "log_motor_off", "engine_hours_counter");
    }
    
  }
  return {
          engine_hours_counter: v
        };
}

function decode_log_sail_change(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "details") {
      var json$1 = json["details"];
      v = Pbrt_bs.string(json$1, "log_sail_change", "details");
    }
    
  }
  return {
          details: v
        };
}

function decode_passage_log_type(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i !== -1) {
      var match = keys[i];
      switch (match) {
        case "motorOff" :
            var json$1 = json["motorOff"];
            return /* Motor_off */Block.__(2, [decode_log_motor_off(Pbrt_bs.object_(json$1, "passage_log_type", "Motor_off"))]);
        case "motorOn" :
            var json$2 = json["motorOn"];
            return /* Motor_on */Block.__(1, [decode_log_motor_on(Pbrt_bs.object_(json$2, "passage_log_type", "Motor_on"))]);
        case "positionData" :
            var json$3 = json["positionData"];
            return /* Position_data */Block.__(0, [decode_log_position_data(Pbrt_bs.object_(json$3, "passage_log_type", "Position_data"))]);
        case "sailChange" :
            var json$4 = json["sailChange"];
            return /* Sail_change */Block.__(3, [decode_log_sail_change(Pbrt_bs.object_(json$4, "passage_log_type", "Sail_change"))]);
        default:
          _i = i - 1 | 0;
          continue ;
      }
    } else {
      return Pbrt_bs.E.malformed_variant("passage_log_type");
    }
  };
}

function decode_geo_location(json) {
  var v = {
    longitude: 0,
    latitude: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "latitude" :
          var json$1 = json["latitude"];
          v.latitude = Pbrt_bs.$$float(json$1, "geo_location", "latitude");
          break;
      case "longitude" :
          var json$2 = json["longitude"];
          v.longitude = Pbrt_bs.$$float(json$2, "geo_location", "longitude");
          break;
      default:
        
    }
  }
  return {
          longitude: v.longitude,
          latitude: v.latitude
        };
}

function decode_passage_log(json) {
  var v = {
    id: undefined,
    unix_timestamp: 0,
    geo_location: undefined,
    location_name: "",
    remarks: "",
    type_: undefined
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "geoLocation" :
          var json$1 = json["geoLocation"];
          v.geo_location = decode_geo_location(Pbrt_bs.object_(json$1, "passage_log", "geo_location"));
          break;
      case "id" :
          var json$2 = json["id"];
          v.id = Id_bs.decode_id(Pbrt_bs.object_(json$2, "passage_log", "id"));
          break;
      case "locationName" :
          var json$3 = json["locationName"];
          v.location_name = Pbrt_bs.string(json$3, "passage_log", "location_name");
          break;
      case "remarks" :
          var json$4 = json["remarks"];
          v.remarks = Pbrt_bs.string(json$4, "passage_log", "remarks");
          break;
      case "type" :
          var json$5 = json["type"];
          v.type_ = decode_passage_log_type(Pbrt_bs.object_(json$5, "passage_log", "type_"));
          break;
      case "unixTimestamp" :
          var json$6 = json["unixTimestamp"];
          v.unix_timestamp = Pbrt_bs.$$float(json$6, "passage_log", "unix_timestamp");
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          unix_timestamp: v.unix_timestamp,
          geo_location: v.geo_location,
          location_name: v.location_name,
          remarks: v.remarks,
          type_: v.type_
        };
}

function decode_retroactive_data(json) {
  var v = {
    start_unix_timestamp: 0,
    end_unix_timestamp: 0,
    total_engine_hours: 0,
    total_distance: 0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "endUnixTimestamp" :
          var json$1 = json["endUnixTimestamp"];
          v.end_unix_timestamp = Pbrt_bs.$$float(json$1, "retroactive_data", "end_unix_timestamp");
          break;
      case "startUnixTimestamp" :
          var json$2 = json["startUnixTimestamp"];
          v.start_unix_timestamp = Pbrt_bs.$$float(json$2, "retroactive_data", "start_unix_timestamp");
          break;
      case "totalDistance" :
          var json$3 = json["totalDistance"];
          v.total_distance = Pbrt_bs.$$float(json$3, "retroactive_data", "total_distance");
          break;
      case "totalEngineHours" :
          var json$4 = json["totalEngineHours"];
          v.total_engine_hours = Pbrt_bs.$$float(json$4, "retroactive_data", "total_engine_hours");
          break;
      default:
        
    }
  }
  return {
          start_unix_timestamp: v.start_unix_timestamp,
          end_unix_timestamp: v.end_unix_timestamp,
          total_engine_hours: v.total_engine_hours,
          total_distance: v.total_distance
        };
}

function decode_stats(json) {
  var v = {
    start_unix_timestamp: undefined,
    end_unix_timestamp: undefined,
    total_engine_hours: 0,
    latest_engine_hours_counter: undefined,
    latest_engine_on_unix_timestamp: undefined,
    current_leg: 0,
    total_distance: 0,
    latest_geo_location: undefined
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "currentLeg" :
          var json$1 = json["currentLeg"];
          v.current_leg = Pbrt_bs.$$int(json$1, "stats", "current_leg");
          break;
      case "endUnixTimestamp" :
          var json$2 = json["endUnixTimestamp"];
          v.end_unix_timestamp = Pbrt_bs.float_wrapped(json$2, "stats", "end_unix_timestamp");
          break;
      case "latestEngineHoursCounter" :
          var json$3 = json["latestEngineHoursCounter"];
          v.latest_engine_hours_counter = Pbrt_bs.float_wrapped(json$3, "stats", "latest_engine_hours_counter");
          break;
      case "latestEngineOnUnixTimestamp" :
          var json$4 = json["latestEngineOnUnixTimestamp"];
          v.latest_engine_on_unix_timestamp = Pbrt_bs.float_wrapped(json$4, "stats", "latest_engine_on_unix_timestamp");
          break;
      case "latestGeoLocation" :
          var json$5 = json["latestGeoLocation"];
          v.latest_geo_location = decode_geo_location(Pbrt_bs.object_(json$5, "stats", "latest_geo_location"));
          break;
      case "startUnixTimestamp" :
          var json$6 = json["startUnixTimestamp"];
          v.start_unix_timestamp = Pbrt_bs.float_wrapped(json$6, "stats", "start_unix_timestamp");
          break;
      case "totalDistance" :
          var json$7 = json["totalDistance"];
          v.total_distance = Pbrt_bs.$$float(json$7, "stats", "total_distance");
          break;
      case "totalEngineHours" :
          var json$8 = json["totalEngineHours"];
          v.total_engine_hours = Pbrt_bs.$$float(json$8, "stats", "total_engine_hours");
          break;
      default:
        
    }
  }
  return {
          start_unix_timestamp: v.start_unix_timestamp,
          end_unix_timestamp: v.end_unix_timestamp,
          total_engine_hours: v.total_engine_hours,
          latest_engine_hours_counter: v.latest_engine_hours_counter,
          latest_engine_on_unix_timestamp: v.latest_engine_on_unix_timestamp,
          current_leg: v.current_leg,
          total_distance: v.total_distance,
          latest_geo_location: v.latest_geo_location
        };
}

function decode_passage(json) {
  var v = default_passage_mutable(/* () */0);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "crew" :
          var a = json["crew"];
          var a$1 = Pbrt_bs.array_(a, "passage", "crew");
          v.crew = $$Array.to_list($$Array.map((function (json) {
                      return decode_passage_crew_member(Pbrt_bs.object_(json, "passage", "crew"));
                    }), a$1));
          break;
      case "fields" :
          var a$2 = json["fields"];
          var a$3 = Pbrt_bs.array_(a$2, "passage", "fields");
          v.fields = $$Array.to_list($$Array.map((function (json) {
                      return decode_log_field_type(Pbrt_bs.object_(json, "passage", "fields"));
                    }), a$3));
          break;
      case "from" :
          var json$1 = json["from"];
          v.from = Pbrt_bs.string(json$1, "passage", "from");
          break;
      case "id" :
          var json$2 = json["id"];
          v.id = Id_bs.decode_id(Pbrt_bs.object_(json$2, "passage", "id"));
          break;
      case "logs" :
          var a$4 = json["logs"];
          var a$5 = Pbrt_bs.array_(a$4, "passage", "logs");
          v.logs = $$Array.to_list($$Array.map((function (json) {
                      return decode_passage_log(Pbrt_bs.object_(json, "passage", "logs"));
                    }), a$5));
          break;
      case "logsStats" :
          var json$3 = json["logsStats"];
          v.logs_stats = decode_stats(Pbrt_bs.object_(json$3, "passage", "logs_stats"));
          break;
      case "name" :
          var json$4 = json["name"];
          v.name = Pbrt_bs.string(json$4, "passage", "name");
          break;
      case "notes" :
          var json$5 = json["notes"];
          v.notes = Pbrt_bs.string(json$5, "passage", "notes");
          break;
      case "retroactiveData" :
          var json$6 = json["retroactiveData"];
          v.retroactive_data = decode_retroactive_data(Pbrt_bs.object_(json$6, "passage", "retroactive_data"));
          break;
      case "to" :
          var json$7 = json["to"];
          v.to_ = Pbrt_bs.string(json$7, "passage", "to_");
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          name: v.name,
          from: v.from,
          to_: v.to_,
          notes: v.notes,
          crew: v.crew,
          logs: v.logs,
          fields: v.fields,
          retroactive_data: v.retroactive_data,
          logs_stats: v.logs_stats
        };
}

function encode_crew_type(v) {
  switch (v) {
    case /* Captain */0 :
        return "CAPTAIN";
    case /* First_mate */1 :
        return "FIRST_MATE";
    case /* Crew */2 :
        return "CREW";
    case /* Guest */3 :
        return "GUEST";
    
  }
}

function encode_passage_crew_member(v) {
  var json = { };
  json["id"] = String(v.id);
  json["type"] = encode_crew_type(v.type_);
  json["name"] = v.name;
  return json;
}

function encode_log_field_type(v) {
  var json = { };
  switch (v) {
    case /* Leg */0 :
        json["leg"] = null;
        break;
    case /* Sog */1 :
        json["sog"] = null;
        break;
    case /* Cog */2 :
        json["cog"] = null;
        break;
    case /* Distance */3 :
        json["distance"] = null;
        break;
    case /* Chart_course */4 :
        json["chartCourse"] = null;
        break;
    case /* Wind_speed */5 :
        json["windSpeed"] = null;
        break;
    case /* Wind_direction */6 :
        json["windDirection"] = null;
        break;
    case /* Sea_state_description */7 :
        json["seaStateDescription"] = null;
        break;
    case /* Barometric_pressure */8 :
        json["barometricPressure"] = null;
        break;
    case /* Air_temperature */9 :
        json["airTemperature"] = null;
        break;
    case /* Sky */10 :
        json["sky"] = null;
        break;
    case /* Sea_temperature */11 :
        json["seaTemperature"] = null;
        break;
    case /* Remarks */12 :
        json["remarks"] = null;
        break;
    case /* Geo_location */13 :
        json["geoLocation"] = null;
        break;
    case /* Location_name */14 :
        json["locationName"] = null;
        break;
    
  }
  return json;
}

function encode_log_position_data(v) {
  var json = { };
  json["leg"] = String(v.leg);
  var match = v.sog;
  if (match !== undefined) {
    json["sog"] = match;
  } else {
    json["sog"] = null;
  }
  var match$1 = v.cog;
  if (match$1 !== undefined) {
    json["cog"] = match$1;
  } else {
    json["cog"] = null;
  }
  var match$2 = v.distance;
  if (match$2 !== undefined) {
    json["distance"] = match$2;
  } else {
    json["distance"] = null;
  }
  var match$3 = v.chart_course;
  if (match$3 !== undefined) {
    json["chartCourse"] = match$3;
  } else {
    json["chartCourse"] = null;
  }
  var match$4 = v.wind_speed;
  if (match$4 !== undefined) {
    json["windSpeed"] = match$4;
  } else {
    json["windSpeed"] = null;
  }
  json["windDirection"] = Cardinal_direction_bs.encode_cardinal_direction(v.wind_direction);
  json["seaStateDescription"] = v.sea_state_description;
  var match$5 = v.barometric_pressure;
  if (match$5 !== undefined) {
    json["barometricPressure"] = match$5;
  } else {
    json["barometricPressure"] = null;
  }
  var match$6 = v.air_temperature;
  if (match$6 !== undefined) {
    json["airTemperature"] = match$6;
  } else {
    json["airTemperature"] = null;
  }
  var match$7 = v.sea_temperature;
  if (match$7 !== undefined) {
    json["seaTemperature"] = match$7;
  } else {
    json["seaTemperature"] = null;
  }
  json["sky"] = v.sky;
  json["remarks"] = v.remarks;
  return json;
}

function encode_log_motor_on(v) {
  var json = { };
  var match = v.engine_hours_counter;
  if (match !== undefined) {
    json["engineHoursCounter"] = match;
  } else {
    json["engineHoursCounter"] = null;
  }
  return json;
}

function encode_log_motor_off(v) {
  var json = { };
  var match = v.engine_hours_counter;
  if (match !== undefined) {
    json["engineHoursCounter"] = match;
  } else {
    json["engineHoursCounter"] = null;
  }
  return json;
}

function encode_log_sail_change(v) {
  var json = { };
  json["details"] = v.details;
  return json;
}

function encode_passage_log_type(v) {
  var json = { };
  switch (v.tag | 0) {
    case /* Position_data */0 :
        var json$prime = encode_log_position_data(v[0]);
        json["positionData"] = json$prime;
        break;
    case /* Motor_on */1 :
        var json$prime$1 = encode_log_motor_on(v[0]);
        json["motorOn"] = json$prime$1;
        break;
    case /* Motor_off */2 :
        var json$prime$2 = encode_log_motor_off(v[0]);
        json["motorOff"] = json$prime$2;
        break;
    case /* Sail_change */3 :
        var json$prime$3 = encode_log_sail_change(v[0]);
        json["sailChange"] = json$prime$3;
        break;
    
  }
  return json;
}

function encode_geo_location(v) {
  var json = { };
  json["longitude"] = v.longitude;
  json["latitude"] = v.latitude;
  return json;
}

function encode_passage_log(v) {
  var json = { };
  var match = v.id;
  if (match !== undefined) {
    var json$prime = Id_bs.encode_id(match);
    json["id"] = json$prime;
  }
  json["unixTimestamp"] = v.unix_timestamp;
  var match$1 = v.geo_location;
  if (match$1 !== undefined) {
    var json$prime$1 = encode_geo_location(match$1);
    json["geoLocation"] = json$prime$1;
  }
  json["locationName"] = v.location_name;
  json["remarks"] = v.remarks;
  var match$2 = v.type_;
  if (match$2 !== undefined) {
    var json$prime$2 = encode_passage_log_type(match$2);
    json["type"] = json$prime$2;
  }
  return json;
}

function encode_retroactive_data(v) {
  var json = { };
  json["startUnixTimestamp"] = v.start_unix_timestamp;
  json["endUnixTimestamp"] = v.end_unix_timestamp;
  json["totalEngineHours"] = v.total_engine_hours;
  json["totalDistance"] = v.total_distance;
  return json;
}

function encode_stats(v) {
  var json = { };
  var match = v.start_unix_timestamp;
  if (match !== undefined) {
    json["startUnixTimestamp"] = match;
  } else {
    json["startUnixTimestamp"] = null;
  }
  var match$1 = v.end_unix_timestamp;
  if (match$1 !== undefined) {
    json["endUnixTimestamp"] = match$1;
  } else {
    json["endUnixTimestamp"] = null;
  }
  json["totalEngineHours"] = v.total_engine_hours;
  var match$2 = v.latest_engine_hours_counter;
  if (match$2 !== undefined) {
    json["latestEngineHoursCounter"] = match$2;
  } else {
    json["latestEngineHoursCounter"] = null;
  }
  var match$3 = v.latest_engine_on_unix_timestamp;
  if (match$3 !== undefined) {
    json["latestEngineOnUnixTimestamp"] = match$3;
  } else {
    json["latestEngineOnUnixTimestamp"] = null;
  }
  json["currentLeg"] = String(v.current_leg);
  json["totalDistance"] = v.total_distance;
  var match$4 = v.latest_geo_location;
  if (match$4 !== undefined) {
    var json$prime = encode_geo_location(match$4);
    json["latestGeoLocation"] = json$prime;
  }
  return json;
}

function encode_passage(v) {
  var json = { };
  var match = v.id;
  if (match !== undefined) {
    var json$prime = Id_bs.encode_id(match);
    json["id"] = json$prime;
  }
  json["name"] = v.name;
  json["from"] = v.from;
  json["to"] = v.to_;
  json["notes"] = v.notes;
  var __x__ = v.crew;
  if (__x__) {
    var crew$prime = $$Array.map(encode_passage_crew_member, $$Array.of_list(__x__));
    json["crew"] = crew$prime;
  }
  var __x__$1 = v.logs;
  if (__x__$1) {
    var logs$prime = $$Array.map(encode_passage_log, $$Array.of_list(__x__$1));
    json["logs"] = logs$prime;
  }
  var __x__$2 = v.fields;
  if (__x__$2) {
    var fields$prime = $$Array.map(encode_log_field_type, $$Array.of_list(__x__$2));
    json["fields"] = fields$prime;
  }
  var match$1 = v.retroactive_data;
  if (match$1 !== undefined) {
    var json$prime$1 = encode_retroactive_data(match$1);
    json["retroactiveData"] = json$prime$1;
  }
  var match$2 = v.logs_stats;
  if (match$2 !== undefined) {
    var json$prime$2 = encode_stats(match$2);
    json["logsStats"] = json$prime$2;
  }
  return json;
}

export {
  encode_crew_type ,
  encode_passage_crew_member ,
  encode_log_field_type ,
  encode_log_position_data ,
  encode_log_motor_on ,
  encode_log_motor_off ,
  encode_log_sail_change ,
  encode_passage_log_type ,
  encode_geo_location ,
  encode_passage_log ,
  encode_retroactive_data ,
  encode_stats ,
  encode_passage ,
  decode_crew_type ,
  decode_passage_crew_member ,
  decode_log_field_type ,
  decode_log_position_data ,
  decode_log_motor_on ,
  decode_log_motor_off ,
  decode_log_sail_change ,
  decode_passage_log_type ,
  decode_geo_location ,
  decode_passage_log ,
  decode_retroactive_data ,
  decode_stats ,
  decode_passage ,
  
}
/* Id_bs Not a pure module */
