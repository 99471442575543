// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as ServiceWorker from "../serviceWorker";

function register_service_worker(prim) {
  ServiceWorker.register(prim);
  return /* () */0;
}

function unregister_service_worker(prim) {
  ServiceWorker.unregister();
  return /* () */0;
}

export {
  register_service_worker ,
  unregister_service_worker ,
  
}
/* ../serviceWorker Not a pure module */
