// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$Error from "./util/Error.bs.js";
import * as Input from "./core/Input.bs.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as NumberInput from "./core/NumberInput.bs.js";
import * as DistanceEntry from "./core/DistanceEntry.bs.js";
import * as DatetimePickers from "./core/DatetimePickers.bs.js";
import * as StringFormLabel from "./core/StringFormLabel.bs.js";
import * as Grid from "@material-ui/core/Grid";

function updateRd(setPassage, f) {
  return Curry._1(setPassage, (function (p) {
                var match = p.retroactive_data;
                if (match !== undefined) {
                  return {
                          id: p.id,
                          name: p.name,
                          from: p.from,
                          to_: p.to_,
                          notes: p.notes,
                          crew: p.crew,
                          logs: p.logs,
                          fields: p.fields,
                          retroactive_data: Curry._1(f, match),
                          logs_stats: p.logs_stats
                        };
                } else {
                  return p;
                }
              }));
}

function PassageInfoEntry$RetroactiveData(Props) {
  var rd = Props.rd;
  var setPassage = Props.setPassage;
  var updateStartUnixTimestamp = function (start_unix_timestamp) {
    return updateRd(setPassage, (function (rd) {
                  if (start_unix_timestamp > rd.end_unix_timestamp) {
                    return rd;
                  } else {
                    return {
                            start_unix_timestamp: start_unix_timestamp,
                            end_unix_timestamp: rd.end_unix_timestamp,
                            total_engine_hours: rd.total_engine_hours,
                            total_distance: rd.total_distance
                          };
                  }
                }));
  };
  var updateEndUnixTimestamp = function (end_unix_timestamp) {
    return updateRd(setPassage, (function (rd) {
                  if (end_unix_timestamp < rd.start_unix_timestamp) {
                    return rd;
                  } else {
                    return {
                            start_unix_timestamp: rd.start_unix_timestamp,
                            end_unix_timestamp: end_unix_timestamp,
                            total_engine_hours: rd.total_engine_hours,
                            total_distance: rd.total_distance
                          };
                  }
                }));
  };
  var updateTotalEngineHours = function (total_engine_hours) {
    return updateRd(setPassage, (function (rd) {
                  return {
                          start_unix_timestamp: rd.start_unix_timestamp,
                          end_unix_timestamp: rd.end_unix_timestamp,
                          total_engine_hours: total_engine_hours,
                          total_distance: rd.total_distance
                        };
                }));
  };
  var updateDistance = function (value) {
    var total_distance = Belt_Option.getWithDefault(value, 0.0);
    return updateRd(setPassage, (function (rd) {
                  return {
                          start_unix_timestamp: rd.start_unix_timestamp,
                          end_unix_timestamp: rd.end_unix_timestamp,
                          total_engine_hours: rd.total_engine_hours,
                          total_distance: total_distance
                        };
                }));
  };
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  xs: 2,
                  children: React.createElement(StringFormLabel.make, {
                        text: "Start"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 10,
                  children: React.createElement(DatetimePickers.make, {
                        unixTimestamp: rd.start_unix_timestamp,
                        onChange: updateStartUnixTimestamp
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 2,
                  children: React.createElement(StringFormLabel.make, {
                        text: "End"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 10,
                  children: React.createElement(DatetimePickers.make, {
                        unixTimestamp: rd.end_unix_timestamp,
                        onChange: updateEndUnixTimestamp
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(StringFormLabel.make, {
                        text: "Engine Hours"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(NumberInput.Float.make, {
                        value: rd.total_engine_hours,
                        onChange: updateTotalEngineHours
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(StringFormLabel.make, {
                        text: "Total Distance"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(DistanceEntry.make, {
                        value: rd.total_distance,
                        onChange: updateDistance
                      })
                }));
}

var RetroactiveData = {
  updateRd: updateRd,
  make: PassageInfoEntry$RetroactiveData
};

var noError = {
  name: $$Error.noError
};

function validate(passage) {
  var match = passage.name;
  if (match === "") {
    return {
            name: "Passage name cannot be empty"
          };
  } else {
    return noError;
  }
}

function PassageInfoEntry(Props) {
  var passage = Props.passage;
  var setPassage = Props.setPassage;
  var $staropt$star = Props.error;
  var type_ = Props.type_;
  var error = $staropt$star !== undefined ? $staropt$star : noError;
  var updatePassageName = React.useCallback((function (e) {
          var name = e.target.value;
          return Curry._1(setPassage, (function (p) {
                        return {
                                id: p.id,
                                name: name,
                                from: p.from,
                                to_: p.to_,
                                notes: p.notes,
                                crew: p.crew,
                                logs: p.logs,
                                fields: p.fields,
                                retroactive_data: p.retroactive_data,
                                logs_stats: p.logs_stats
                              };
                      }));
        }), []);
  var updateFrom = React.useCallback((function (e) {
          var from = e.target.value;
          return Curry._1(setPassage, (function (p) {
                        return {
                                id: p.id,
                                name: p.name,
                                from: from,
                                to_: p.to_,
                                notes: p.notes,
                                crew: p.crew,
                                logs: p.logs,
                                fields: p.fields,
                                retroactive_data: p.retroactive_data,
                                logs_stats: p.logs_stats
                              };
                      }));
        }), []);
  var updateTo = React.useCallback((function (e) {
          var to_ = e.target.value;
          return Curry._1(setPassage, (function (p) {
                        return {
                                id: p.id,
                                name: p.name,
                                from: p.from,
                                to_: to_,
                                notes: p.notes,
                                crew: p.crew,
                                logs: p.logs,
                                fields: p.fields,
                                retroactive_data: p.retroactive_data,
                                logs_stats: p.logs_stats
                              };
                      }));
        }), []);
  var match = passage.retroactive_data;
  return React.createElement(Grid.default, {
              container: true,
              alignItems: "center",
              spacing: 4,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  sm: 12,
                  xs: 12,
                  children: React.createElement(Input.make, {
                        error: error.name,
                        label: "Passage Name",
                        value: passage.name,
                        onChange: updatePassageName
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  sm: 6,
                  xs: 12,
                  children: React.createElement(Input.make, {
                        label: "From",
                        value: passage.from,
                        onChange: updateFrom
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  sm: 6,
                  xs: 12,
                  children: React.createElement(Input.make, {
                        label: "To",
                        value: passage.to_,
                        onChange: updateTo
                      })
                }), type_ || match === undefined ? null : React.createElement(PassageInfoEntry$RetroactiveData, {
                    rd: match,
                    setPassage: setPassage
                  }));
}

var Pa = /* alias */0;

var Mui = /* alias */0;

var make = PassageInfoEntry;

export {
  Pa ,
  Mui ,
  RetroactiveData ,
  noError ,
  validate ,
  make ,
  
}
/* Input Not a pure module */
