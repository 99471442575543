// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Maintenance_bs from "../types/maintenance_bs.bs.js";
import * as Maintenance_types from "../types/maintenance_types.bs.js";

function make(param) {
  return Maintenance_types.default_maintenance_item_log(undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);
}

function id(p) {
  return Belt_Option.getExn(p.id);
}

function withId(p, id) {
  return {
          id: id,
          unix_timestamp: p.unix_timestamp,
          notes: p.notes,
          time_taken: p.time_taken,
          cost: p.cost,
          value: p.value
        };
}

function prepareForDb(p) {
  return {
          id: undefined,
          unix_timestamp: p.unix_timestamp,
          notes: p.notes,
          time_taken: p.time_taken,
          cost: p.cost,
          value: p.value
        };
}

var encode = Maintenance_bs.encode_maintenance_item_log;

var decode = Maintenance_bs.decode_maintenance_item_log;

export {
  make ,
  encode ,
  decode ,
  id ,
  withId ,
  prepareForDb ,
  
}
/* Maintenance_bs Not a pure module */
