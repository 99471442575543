// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function default_category(param) {
  return /* None */0;
}

function default_system($staropt$star, $staropt$star$1, $staropt$star$2, $staropt$star$3, $staropt$star$4, $staropt$star$5, $staropt$star$6, param) {
  var id = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var identifier = $staropt$star$1 !== undefined ? $staropt$star$1 : "";
  var category = $staropt$star$2 !== undefined ? $staropt$star$2 : /* None */0;
  var other_category = $staropt$star$3 !== undefined ? $staropt$star$3 : "";
  var brand = $staropt$star$4 !== undefined ? $staropt$star$4 : "";
  var model = $staropt$star$5 !== undefined ? $staropt$star$5 : "";
  var maintenance_items = $staropt$star$6 !== undefined ? $staropt$star$6 : /* [] */0;
  return {
          id: id,
          identifier: identifier,
          category: category,
          other_category: other_category,
          brand: brand,
          model: model,
          maintenance_items: maintenance_items
        };
}

export {
  default_category ,
  default_system ,
  
}
/* No side effect */
