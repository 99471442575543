// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Input from "./core/Input.bs.js";
import * as React from "react";
import * as Passage from "../util/Passage.bs.js";
import * as NumberInput from "./core/NumberInput.bs.js";
import * as PassageFields from "./PassageFields.bs.js";
import * as DatetimePickers from "./core/DatetimePickers.bs.js";
import * as StringFormLabel from "./core/StringFormLabel.bs.js";
import * as PassageExtraInfo from "./PassageExtraInfo.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Grid from "@material-ui/core/Grid";
import * as IconButton from "@material-ui/core/IconButton";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";
import * as KeyboardBackspaceOutlined from "@material-ui/icons/KeyboardBackspaceOutlined";

function PassageInfoTab$RetroactiveData(Props) {
  var rd = Props.rd;
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  xs: 2,
                  children: React.createElement(StringFormLabel.make, {
                        text: "Start"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 10,
                  children: React.createElement(DatetimePickers.make, {
                        unixTimestamp: rd.start_unix_timestamp,
                        disabled: true
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 2,
                  children: React.createElement(StringFormLabel.make, {
                        text: "End"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 10,
                  children: React.createElement(DatetimePickers.make, {
                        unixTimestamp: rd.end_unix_timestamp,
                        disabled: true
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(StringFormLabel.make, {
                        text: "Engine Hours"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(NumberInput.Float.make, {
                        value: rd.total_engine_hours,
                        disabled: true
                      })
                }));
}

var RetroactiveData = {
  make: PassageInfoTab$RetroactiveData
};

var useStyles = MakeStyles.default({
      tabUpperSection: {
        marginTop: "10px",
        marginLeft: "20px",
        marginRight: "20px",
        marginBottom: "20px"
      },
      fields: {
        marginTop: "20px"
      },
      root: {
        marginTop: 10,
        marginLeft: "20px",
        marginRight: "20px"
      }
    });

function defaultOnBack(param) {
  return /* () */0;
}

function PassageInfoTab(Props) {
  var passage = Props.passage;
  var $staropt$star = Props.onBack;
  var onBack = $staropt$star !== undefined ? $staropt$star : defaultOnBack;
  var setPassage = function (param) {
    return /* () */0;
  };
  var classes = useStyles();
  var type_ = Passage.passageType(passage);
  var match = passage.retroactive_data;
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement(Box.default, {
                  className: classes.root,
                  children: React.createElement(Grid.default, {
                        container: true,
                        justify: "space-between",
                        alignItems: "center",
                        spacing: 1,
                        children: React.createElement(Grid.default, {
                              item: true,
                              xs: 2,
                              children: React.createElement(IconButton.default, {
                                    color: "secondary",
                                    size: "medium",
                                    edge: "start",
                                    onClick: (function (param) {
                                        return Curry._1(onBack, /* () */0);
                                      }),
                                    children: React.createElement(KeyboardBackspaceOutlined.default, {
                                          fontSize: "default"
                                        })
                                  })
                            })
                      })
                }), React.createElement(Box.default, {
                  className: classes.tabUpperSection,
                  children: React.createElement(Grid.default, {
                        container: true,
                        justify: "center",
                        alignItems: "center",
                        spacing: 2,
                        children: null
                      }, React.createElement(Grid.default, {
                            item: true,
                            xs: 6,
                            children: React.createElement(Input.make, {
                                  label: "From",
                                  value: passage.from,
                                  disabled: true
                                })
                          }), React.createElement(Grid.default, {
                            item: true,
                            xs: 6,
                            children: React.createElement(Input.make, {
                                  label: "To",
                                  value: passage.to_,
                                  disabled: true
                                })
                          }), match !== undefined ? React.createElement(PassageInfoTab$RetroactiveData, {
                              rd: match
                            }) : null)
                }), React.createElement(PassageExtraInfo.make, {
                  passage: passage,
                  setPassage: setPassage,
                  disabled: true
                }), type_ ? React.createElement(Box.default, {
                    className: classes.fields,
                    children: React.createElement(PassageFields.make, {
                          passage: passage,
                          setPassage: setPassage,
                          disabled: true
                        })
                  }) : null);
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var make = PassageInfoTab;

export {
  Mui ,
  Pa ,
  RetroactiveData ,
  useStyles ,
  defaultOnBack ,
  make ,
  
}
/* useStyles Not a pure module */
