// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Selector from "./core/Selector.bs.js";
import * as Maintenance from "../util/Maintenance.bs.js";

function ignoreOnChange(param) {
  return /* () */0;
}

function MaintenanceItemTypeSelector(Props) {
  var $staropt$star = Props.value;
  var $staropt$star$1 = Props.onChange;
  var value = $staropt$star !== undefined ? $staropt$star : /* Check */1;
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnChange;
  return React.createElement(Selector.make, {
              value: value,
              values: /* :: */[
                /* Check */1,
                /* :: */[
                  /* Replace */2,
                  /* :: */[
                    /* Record */3,
                    /* :: */[
                      /* Perform */4,
                      /* :: */[
                        /* Other */0,
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ],
              toString: Maintenance.typeToString,
              onChange: onChange
            });
}

var Mui = /* alias */0;

var M = /* alias */0;

var toString = Maintenance.typeToString;

var defaultValue = /* Check */1;

var make = MaintenanceItemTypeSelector;

export {
  Mui ,
  M ,
  toString ,
  defaultValue ,
  ignoreOnChange ,
  make ,
  
}
/* react Not a pure module */
