// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Error from "../util/Error.bs.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as TextField from "@material-ui/core/TextField";

function ignoreOnChange(param) {
  return /* () */0;
}

var noValue = "";

function Input(Props) {
  var $staropt$star = Props.error;
  var label = Props.label;
  var $staropt$star$1 = Props.value;
  var $staropt$star$2 = Props.disabled;
  var $staropt$star$3 = Props.onChange;
  var $staropt$star$4 = Props.autoFocus;
  var $staropt$star$5 = Props.helperText;
  var error = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : $$Error.noError;
  var value = $staropt$star$1 !== undefined ? $staropt$star$1 : noValue;
  var disabled = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var onChange = $staropt$star$3 !== undefined ? $staropt$star$3 : ignoreOnChange;
  var autoFocus = $staropt$star$4 !== undefined ? $staropt$star$4 : false;
  var helperText = $staropt$star$5 !== undefined ? $staropt$star$5 : "";
  var label$1 = React.useMemo((function () {
          if (label !== undefined) {
            return label;
          } else {
            return null;
          }
        }), [label]);
  var match = $$Error.isError(error);
  var helperText$1 = match ? $$Error.helperText(error) : (
      helperText === "" ? null : helperText
    );
  return React.createElement(TextField.default, {
              margin: "none",
              autoFocus: autoFocus,
              disabled: disabled,
              error: $$Error.isError(error),
              helperText: helperText$1,
              variant: "outlined",
              label: label$1,
              fullWidth: true,
              onChange: onChange,
              value: value
            });
}

var make = Input;

export {
  ignoreOnChange ,
  noValue ,
  make ,
  
}
/* react Not a pure module */
