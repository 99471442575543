// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Selector from "./Selector.bs.js";
import * as SpeedUnit from "../../util/SpeedUnit.bs.js";
import * as AppContext from "../util/AppContext.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as NumberInput from "./NumberInput.bs.js";
import * as Grid from "@material-ui/core/Grid";

function ignoreOnChange(param) {
  return /* () */0;
}

function SpeedEntry(Props) {
  var $staropt$star = Props.value;
  var $staropt$star$1 = Props.onChange;
  var $staropt$star$2 = Props.label;
  var value = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnChange;
  var label = $staropt$star$2 !== undefined ? $staropt$star$2 : "";
  var match = React.useContext(AppContext.ctx);
  var setUser = match.setUser;
  var user = match.user;
  var displayUnit = typeof user === "number" ? /* Knots */0 : user[0].speed_unit;
  var digits = SpeedUnit.digits(displayUnit);
  var value$1 = Belt_Option.map(value, (function (v) {
          return SpeedUnit.convertTo(v, displayUnit);
        }));
  var updateUserSpeedUnit = function (speed_unit) {
    if (setUser !== undefined) {
      return Curry._1(setUser, (function (user) {
                    return {
                            id: user.id,
                            display_name: user.display_name,
                            email: user.email,
                            provider_id: user.provider_id,
                            distance_unit: user.distance_unit,
                            speed_unit: speed_unit,
                            pressure_unit: user.pressure_unit,
                            temperature_unit: user.temperature_unit,
                            is_anonymous: user.is_anonymous,
                            unix_timestamp: user.unix_timestamp
                          };
                  }));
    } else {
      return /* () */0;
    }
  };
  var handleOnChange = function (v) {
    return Curry._1(onChange, Belt_Option.map(v, (function (v) {
                      return SpeedUnit.convertFrom(v, displayUnit);
                    })));
  };
  return React.createElement(Grid.default, {
              container: true,
              alignItems: "center",
              spacing: 2,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  xs: 8,
                  children: React.createElement(NumberInput.NullableFloat.make, {
                        value: value$1,
                        onChange: handleOnChange,
                        label: label,
                        digits: digits
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 4,
                  children: React.createElement(Selector.make, {
                        value: displayUnit,
                        values: SpeedUnit.allSpeedUnit,
                        toString: SpeedUnit.toString,
                        onChange: updateUserSpeedUnit
                      })
                }));
}

var Mui = /* alias */0;

var U = /* alias */0;

var defaultValue = undefined;

var make = SpeedEntry;

export {
  Mui ,
  U ,
  defaultValue ,
  ignoreOnChange ,
  make ,
  
}
/* react Not a pure module */
