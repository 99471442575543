// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Input from "./core/Input.bs.js";
import * as React from "react";
import * as System from "../util/System.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as EntryDialog from "./core/EntryDialog.bs.js";
import * as SearchInput from "./core/SearchInput.bs.js";
import * as System_types from "../types/system_types.bs.js";
import * as Grid from "@material-ui/core/Grid";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var categoryHelperText = "Select an existing category or enter a new one";

var categoryErrorText = "Required. ".concat(categoryHelperText);

function titleOfMode(param) {
  if (param.tag) {
    return EntryDialog.makeTitle(undefined, "Edit part: ".concat(param[0].identifier));
  } else {
    return EntryDialog.makeTitle(undefined, "Add a new part");
  }
}

function actionOfMode(onAction, param) {
  if (param.tag) {
    return EntryDialog.makeUpdate(onAction, "part");
  } else {
    return EntryDialog.makeAdd(onAction, "part");
  }
}

function ignoreOnClose(param) {
  return /* () */0;
}

function systemOfMode(param) {
  if (param.tag) {
    return param[0];
  } else {
    return System_types.default_system(undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);
  }
}

function allCategoriesOfMode(param) {
  if (param.tag) {
    var s = param[0];
    var match = s.category;
    if (match >= 119) {
      return Belt_Array.concat([s.other_category], System.allCategoriesAsString);
    } else {
      return System.allCategoriesAsString;
    }
  } else {
    return System.allCategoriesAsString;
  }
}

function SystemEntry(Props) {
  var mode = Props.mode;
  var $staropt$star = Props.onClose;
  var onClose = $staropt$star !== undefined ? $staropt$star : ignoreOnClose;
  var match = React.useState((function () {
          return systemOfMode(mode);
        }));
  var setSystem = match[1];
  var system = match[0];
  React.useEffect((function () {
          Curry._1(setSystem, (function (param) {
                  return systemOfMode(mode);
                }));
          return ;
        }), [mode]);
  var match$1 = React.useState((function () {
          return allCategoriesOfMode(mode);
        }));
  var setAllCategories = match$1[1];
  React.useEffect((function () {
          Curry._1(setAllCategories, (function (param) {
                  return allCategoriesOfMode(mode);
                }));
          return ;
        }), [mode]);
  var updateIdentifier = function (e) {
    var identifier = e.target.value;
    return Curry._1(setSystem, (function (system) {
                  return {
                          id: system.id,
                          identifier: identifier,
                          category: system.category,
                          other_category: system.other_category,
                          brand: system.brand,
                          model: system.model,
                          maintenance_items: system.maintenance_items
                        };
                }));
  };
  var updateCategory = function (value) {
    return Curry._1(setSystem, (function (param) {
                  var match = System.categoryOfString(value);
                  return {
                          id: system.id,
                          identifier: system.identifier,
                          category: match[0],
                          other_category: match[1],
                          brand: system.brand,
                          model: system.model,
                          maintenance_items: system.maintenance_items
                        };
                }));
  };
  var updateBrand = function (e) {
    var brand = e.target.value;
    return Curry._1(setSystem, (function (system) {
                  return {
                          id: system.id,
                          identifier: system.identifier,
                          category: system.category,
                          other_category: system.other_category,
                          brand: brand,
                          model: system.model,
                          maintenance_items: system.maintenance_items
                        };
                }));
  };
  var updateModel = function (e) {
    var model = e.target.value;
    return Curry._1(setSystem, (function (system) {
                  return {
                          id: system.id,
                          identifier: system.identifier,
                          category: system.category,
                          other_category: system.other_category,
                          brand: system.brand,
                          model: model,
                          maintenance_items: system.maintenance_items
                        };
                }));
  };
  var match$2 = React.useState((function () {
          return ;
        }));
  var other_category = system.other_category;
  var category = system.category;
  var setError = match$2[1];
  var match$3 = React.useContext(AppContext.ctx);
  var addErrorAlert = match$3.addErrorAlert;
  var firestore = match$3.firestore;
  var addSystem = function (param) {
    var exit = 0;
    exit = category !== 0 && !(category >= 119 && other_category === "") ? 1 : 2;
    switch (exit) {
      case 1 :
          if (mode.tag) {
            Db.updateSystem(firestore, system, (function (param) {
                    if (typeof param === "number") {
                      return /* () */0;
                    } else {
                      Analytics.except("DB Edit - System", param[1]);
                      return Curry._1(addErrorAlert, "Error updating part");
                    }
                  }));
          } else {
            Db.addSystem(mode[0], firestore, system, (function (param) {
                    if (param[0] >= 106380200) {
                      Analytics.except("DB Add - System", param[1]);
                      return Curry._1(addErrorAlert, "Internal error adding system. App will reset.");
                    } else {
                      return /* () */0;
                    }
                  }));
          }
          Curry._1(setSystem, (function (param) {
                  return System_types.default_system(undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* () */0);
                }));
          Curry._1(setError, (function (param) {
                  return ;
                }));
          return Curry._1(onClose, /* () */0);
      case 2 :
          return Curry._1(setError, (function (param) {
                        return categoryErrorText;
                      }));
      
    }
  };
  return React.createElement(EntryDialog.make, {
              title: titleOfMode(mode),
              action: actionOfMode(addSystem, mode),
              onClose: onClose,
              children: React.createElement(Grid.default, {
                    container: true,
                    justify: "space-between",
                    alignItems: "center",
                    spacing: 4,
                    children: null
                  }, React.createElement(Grid.default, {
                        item: true,
                        sm: 6,
                        xs: 12,
                        children: React.createElement(SearchInput.UnControlled.make, {
                              value: System.categoryToString(other_category, category),
                              options: match$1[0],
                              onChange: updateCategory,
                              label: "Category",
                              error: match$2[0],
                              allowArbitraryInput: true,
                              helperText: categoryHelperText
                            })
                      }), React.createElement(Grid.default, {
                        item: true,
                        sm: 6,
                        xs: 12,
                        children: React.createElement(Input.make, {
                              label: "Identifier",
                              value: system.identifier,
                              onChange: updateIdentifier,
                              helperText: "Optional. Use to differentiate parts"
                            })
                      }), React.createElement(Grid.default, {
                        item: true,
                        sm: 6,
                        xs: 12,
                        children: React.createElement(Input.make, {
                              label: "Brand",
                              value: system.brand,
                              onChange: updateBrand
                            })
                      }), React.createElement(Grid.default, {
                        item: true,
                        sm: 6,
                        xs: 12,
                        children: React.createElement(Input.make, {
                              label: "Model",
                              value: system.model,
                              onChange: updateModel
                            })
                      }))
            });
}

var Mui = /* alias */0;

var S = /* alias */0;

var I = /* alias */0;

var identifierHelperText = "Required.";

var identifierErrorText = "Unique identifier required";

var defaultBoatId = undefined;

var make = SystemEntry;

export {
  Mui ,
  S ,
  I ,
  concat ,
  categoryHelperText ,
  categoryErrorText ,
  identifierHelperText ,
  identifierErrorText ,
  titleOfMode ,
  actionOfMode ,
  defaultBoatId ,
  ignoreOnClose ,
  systemOfMode ,
  allCategoriesOfMode ,
  make ,
  
}
/* categoryErrorText Not a pure module */
