// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as TextField from "@material-ui/core/TextField";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      textField: {
        width: "100%"
      }
    });

function ignoreOnChange(param) {
  return /* () */0;
}

function Notes(Props) {
  var row = Props.row;
  var rowsMax = Props.rowsMax;
  var $staropt$star = Props.value;
  var $staropt$star$1 = Props.onChange;
  var $staropt$star$2 = Props.disabled;
  var $staropt$star$3 = Props.label;
  var $staropt$star$4 = Props.autoFocus;
  var value = $staropt$star !== undefined ? $staropt$star : "";
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnChange;
  var disabled = $staropt$star$2 !== undefined ? $staropt$star$2 : false;
  var label = $staropt$star$3 !== undefined ? $staropt$star$3 : "Notes";
  var autoFocus = $staropt$star$4 !== undefined ? $staropt$star$4 : false;
  var classes = useStyles();
  var onChange$1 = function (e) {
    return Curry._1(onChange, e.target.value);
  };
  return React.createElement(TextField.default, {
              className: classes.textField,
              margin: "none",
              autoFocus: autoFocus,
              disabled: disabled,
              variant: "outlined",
              label: label,
              onChange: onChange$1,
              value: value,
              multiline: true,
              rowsMax: rowsMax !== undefined ? rowsMax : 4,
              rows: row !== undefined ? row : 4
            });
}

var make = Notes;

export {
  useStyles ,
  ignoreOnChange ,
  make ,
  
}
/* useStyles Not a pure module */
