// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function then_(prim, prim$1) {
  return prim$1.then(Curry.__1(prim));
}

function $$catch(prim, prim$1) {
  return prim$1.catch(Curry.__1(prim));
}

function resolve(prim) {
  return Promise.resolve(prim);
}

function reject(prim) {
  return Promise.reject(prim);
}

var unit = Promise.resolve(/* () */0);

function all(prim) {
  return Promise.all(prim);
}

function mapThen(f, p) {
  return p.then((function (v) {
                return Promise.resolve(Curry._1(f, v));
              }));
}

function mapCatch(f, p) {
  return p.catch((function (e) {
                return Promise.resolve(Curry._1(f, e));
              }));
}

function arrayIter(a, f) {
  var aux = function (i) {
    var match = Belt_Array.get(a, i);
    if (match !== undefined) {
      var prim = Curry._1(f, Caml_option.valFromOption(match));
      return prim.then((function (param) {
                    return aux(i + 1 | 0);
                  }));
    } else {
      return unit;
    }
  };
  return aux(0);
}

export {
  then_ ,
  $$catch ,
  resolve ,
  reject ,
  unit ,
  all ,
  mapThen ,
  mapCatch ,
  arrayIter ,
  
}
/* unit Not a pure module */
