// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Alert from "@material-ui/lab/Alert";
import * as Hidden from "@material-ui/core/Hidden";
import * as Snackbar from "@material-ui/core/Snackbar";

var clickawayReason = "clickaway";

function AppAlert(Props) {
  var state = Props.state;
  var onClose = Props.onClose;
  var onClose$1 = function (param) {
    return Curry._1(onClose, /* () */0);
  };
  var snackClosed = function (param, reason) {
    if (reason !== clickawayReason) {
      return Curry._1(onClose, /* () */0);
    } else {
      return 0;
    }
  };
  var makeAlert = function ($staropt$star, severity, msg) {
    var autoHideDuration = $staropt$star !== undefined ? $staropt$star : Pervasives.max_int;
    var alert = React.createElement(Alert.default, {
          severity: (function () {
                switch (severity) {
                  case -215364664 :
                      return "error";
                  case 164354652 :
                      return "warning";
                  case -977586066 :
                      return "info";
                  case 944645571 :
                      return "success";
                  
                }
              })(),
          variant: "filled",
          onClose: onClose$1,
          children: msg
        });
    return React.createElement(React.Fragment, {
                children: null
              }, React.createElement(Hidden.default, {
                    smUp: true,
                    children: React.createElement(Snackbar.default, {
                          open: true,
                          autoHideDuration: autoHideDuration,
                          onClose: snackClosed,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          children: alert
                        })
                  }), React.createElement(Hidden.default, {
                    xsDown: true,
                    children: React.createElement(Snackbar.default, {
                          open: true,
                          autoHideDuration: autoHideDuration,
                          onClose: snackClosed,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                          },
                          children: alert
                        })
                  }));
  };
  var match = state.alert;
  if (typeof match === "number") {
    return null;
  } else if (match.tag) {
    return makeAlert(undefined, /* error */-215364664, match[0]);
  } else {
    return makeAlert(match[1], /* success */944645571, match[0]);
  }
}

var Mui = /* alias */0;

var make = AppAlert;

export {
  Mui ,
  clickawayReason ,
  make ,
  
}
/* react Not a pure module */
