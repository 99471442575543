// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Settings from "@material-ui/icons/Settings";
import * as IconButton from "@material-ui/core/IconButton";

function ignoreOnClick(param) {
  return /* () */0;
}

function SettingsMenuButton(Props) {
  var $staropt$star = Props.onClick;
  var $staropt$star$1 = Props.color;
  var onClick = $staropt$star !== undefined ? $staropt$star : ignoreOnClick;
  var color = $staropt$star$1 !== undefined ? $staropt$star$1 : /* inherit_ */-601204732;
  return React.createElement(IconButton.default, {
              color: (function () {
                    switch (color) {
                      case -601204732 :
                          return "inherit";
                      case 58474434 :
                          return "primary";
                      case -499495052 :
                          return "secondary";
                      case 465819841 :
                          return "default";
                      
                    }
                  })(),
              size: "medium",
              edge: "start",
              onClick: onClick,
              children: React.createElement(Settings.default, {
                    fontSize: "default"
                  })
            });
}

var Mui = /* alias */0;

var make = SettingsMenuButton;

export {
  Mui ,
  ignoreOnClick ,
  make ,
  
}
/* react Not a pure module */
