// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Id from "../util/Id.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Ignore from "../types/Ignore.bs.js";
import * as Passage from "../util/Passage.bs.js";
import * as Analytics from "./util/Analytics.bs.js";
import * as BackArrow from "./BackArrow.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Events_types from "../types/events_types.bs.js";
import * as UnixTimestamp from "../util/UnixTimestamp.bs.js";
import * as PassageActions from "./PassageActions.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as PassageLogsTable from "./PassageLogsTable.bs.js";
import * as DeleteConfirmation from "./DeleteConfirmation.bs.js";
import * as PassageMotorLogEntry from "./PassageMotorLogEntry.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Grid from "@material-ui/core/Grid";
import * as Alert from "@material-ui/lab/Alert";
import * as PassagePositionLogEntry from "./PassagePositionLogEntry.bs.js";
import * as Hidden from "@material-ui/core/Hidden";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

var useStyles = MakeStyles.default({
      root: {
        marginTop: 10,
        marginLeft: "20px",
        marginRight: "20px",
        paddingTop: "15px"
      },
      retroactiveInfo: {
        marginTop: 20
      }
    });

function noGeoLocationMotorOn(param) {
  return {
          id: undefined,
          unix_timestamp: UnixTimestamp.now(/* () */0),
          geo_location: undefined,
          location_name: "",
          remarks: "",
          type_: /* Motor_on */Block.__(1, [{
                engine_hours_counter: undefined
              }])
        };
}

function geoLocationMotorOn(geoRes) {
  var geo_location;
  if (geoRes.tag) {
    geo_location = undefined;
  } else {
    var position = geoRes[0];
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    geo_location = {
      longitude: longitude,
      latitude: latitude
    };
  }
  return {
          id: undefined,
          unix_timestamp: UnixTimestamp.now(/* () */0),
          geo_location: geo_location,
          location_name: "",
          remarks: "",
          type_: /* Motor_on */Block.__(1, [{
                engine_hours_counter: undefined
              }])
        };
}

function defaultOnBack(param) {
  return /* () */0;
}

function logAdd(passage_log_type) {
  return Analytics.data(Events_types.default_data(/* Passage_log */6, /* Open_dialog_entry_add */1, undefined, passage_log_type, undefined, /* () */0));
}

function logEdit(logEntry, passage_log_type) {
  return Analytics.data(Events_types.default_data(/* Passage_log */6, /* Open_dialog_entry_update */2, undefined, passage_log_type, Id.firestorePath(logEntry.id), /* () */0));
}

function PassageLogs(Props) {
  var passage = Props.passage;
  var $staropt$star = Props.isDataComplete;
  var $staropt$star$1 = Props.passageStats;
  var $staropt$star$2 = Props.boatLatestEngineHoursCounter;
  var $staropt$star$3 = Props.onBack;
  var isDataComplete = $staropt$star !== undefined ? $staropt$star : true;
  var passageStats = $staropt$star$1 !== undefined ? Caml_option.valFromOption($staropt$star$1) : undefined;
  var boatLatestEngineHoursCounter = $staropt$star$2 !== undefined ? Caml_option.valFromOption($staropt$star$2) : undefined;
  var onBack = $staropt$star$3 !== undefined ? $staropt$star$3 : Ignore.v0;
  var classes = useStyles();
  AnalyticsEffect.useCurrentScreen("PassageLogs");
  var match = React.useState((function () {
          return /* NoDialog */0;
        }));
  var setDialog = match[1];
  var dialog = match[0];
  var showAddLogDialog = function (param) {
    logAdd(/* Position_data */1);
    return Curry._1(setDialog, (function (param) {
                  return /* AddPositionLog */1;
                }));
  };
  var showAddMotorOffLogDialog = function (param) {
    logAdd(/* Motor_off */3);
    return Curry._1(setDialog, (function (param) {
                  return /* AddMotorLog */Block.__(1, [/* Off */1]);
                }));
  };
  var showAddMotorOnLogDialog = function (param) {
    logAdd(/* Motor_on */2);
    return Curry._1(setDialog, (function (param) {
                  return /* AddMotorLog */Block.__(1, [/* On */0]);
                }));
  };
  var showEditMotorLogDialog = function (logEntry, passage_log_type) {
    logEdit(logEntry, passage_log_type);
    return Curry._1(setDialog, (function (param) {
                  return /* EditMotorLog */Block.__(2, [logEntry]);
                }));
  };
  var hideDialogs = function (param) {
    return Curry._1(setDialog, (function (param) {
                  return /* NoDialog */0;
                }));
  };
  var isFieldEnabled = React.useMemo((function () {
          return Passage.makeIsFieldEnabledFun(passage.fields);
        }), [passage.fields]);
  var isAtLeastOneFieldEnabled = React.useMemo((function () {
          return Passage.makeIsAtLeastOneFieldEnabledFun(passage.fields);
        }), [passage.fields]);
  var match$1 = React.useContext(AppContext.ctx);
  var firestore = match$1.firestore;
  var handleRowAction = function (param) {
    if (param.tag) {
      var logEntry = param[0];
      Analytics.data(Events_types.default_data(/* Passage_log */6, /* Open_dialog_entry_delete */3, undefined, undefined, Id.firestorePath(logEntry.id), /* () */0));
      return Curry._1(setDialog, (function (param) {
                    return /* Delete */Block.__(3, [logEntry]);
                  }));
    } else {
      var logEntry$1 = param[0];
      var match = logEntry$1.type_;
      if (match !== undefined) {
        switch (match.tag | 0) {
          case /* Position_data */0 :
              var logEntry$2 = logEntry$1;
              logEdit(logEntry$2, /* Position_data */1);
              return Curry._1(setDialog, (function (param) {
                            return /* Edit */Block.__(0, [logEntry$2]);
                          }));
          case /* Motor_on */1 :
              return showEditMotorLogDialog(logEntry$1, /* Motor_on */2);
          case /* Motor_off */2 :
              return showEditMotorLogDialog(logEntry$1, /* Motor_off */3);
          case /* Sail_change */3 :
              return /* () */0;
          
        }
      } else {
        return /* () */0;
      }
    }
  };
  var type_ = Passage.passageType(passage);
  var tmp;
  if (typeof dialog === "number") {
    if (dialog === /* NoDialog */0) {
      tmp = null;
    } else {
      var mode = /* Add */Block.__(0, [passage]);
      tmp = React.createElement(PassagePositionLogEntry.make, {
            mode: mode,
            isFieldEnabled: isFieldEnabled,
            isAtLeastOneFieldEnabled: isAtLeastOneFieldEnabled,
            onClose: hideDialogs,
            passageStats: passageStats
          });
    }
  } else {
    switch (dialog.tag | 0) {
      case /* Edit */0 :
          var logEntry = dialog[0];
          var match$2 = logEntry.type_;
          if (match$2 !== undefined && !match$2.tag) {
            var mode$1 = /* Edit */Block.__(1, [
                passage,
                logEntry
              ]);
            tmp = React.createElement(PassagePositionLogEntry.make, {
                  mode: mode$1,
                  isFieldEnabled: isFieldEnabled,
                  isAtLeastOneFieldEnabled: isAtLeastOneFieldEnabled,
                  onClose: hideDialogs,
                  passageStats: passageStats
                });
          } else {
            tmp = null;
          }
          break;
      case /* AddMotorLog */1 :
          tmp = React.createElement(PassageMotorLogEntry.make, {
                mode: /* Add */Block.__(0, [
                    passage,
                    dialog[0]
                  ]),
                isFieldEnabled: isFieldEnabled,
                isAtLeastOneFieldEnabled: isAtLeastOneFieldEnabled,
                onClose: hideDialogs,
                boatLatestEngineHoursCounter: boatLatestEngineHoursCounter
              });
          break;
      case /* EditMotorLog */2 :
          tmp = React.createElement(PassageMotorLogEntry.make, {
                mode: /* Edit */Block.__(1, [
                    passage,
                    dialog[0]
                  ]),
                isFieldEnabled: isFieldEnabled,
                isAtLeastOneFieldEnabled: isAtLeastOneFieldEnabled,
                onClose: hideDialogs
              });
          break;
      case /* Delete */3 :
          var logEntry$1 = dialog[0];
          tmp = React.createElement(DeleteConfirmation.make, {
                what: "passage log",
                onClose: hideDialogs,
                onOk: (function (param) {
                    var logEntry$2 = logEntry$1;
                    Analytics.data(Events_types.default_data(/* Passage_log */6, /* Deleted */6, /* Success */1, undefined, Id.firestorePath(logEntry$2.id), /* () */0));
                    Db.deletePassageLog(firestore, logEntry$2);
                    return Curry._1(setDialog, (function (param) {
                                  return /* NoDialog */0;
                                }));
                  }),
                screenIdentifier: "PassageLog"
              });
          break;
      
    }
  }
  return React.createElement(Box.default, {
              children: null
            }, React.createElement(Box.default, {
                  className: classes.root,
                  children: React.createElement(Grid.default, {
                        container: true,
                        justify: "space-between",
                        alignItems: "center",
                        spacing: 1,
                        children: null
                      }, React.createElement(Grid.default, {
                            item: true,
                            xs: 1,
                            children: React.createElement(BackArrow.make, {
                                  onBack: onBack
                                })
                          }), React.createElement(Grid.default, {
                            item: true,
                            sm: 10,
                            xs: 11,
                            children: React.createElement(PassageActions.make, {
                                  passageStats: passageStats,
                                  onAddLog: showAddLogDialog,
                                  onMotorOn: showAddMotorOnLogDialog,
                                  onMotorOff: showAddMotorOffLogDialog,
                                  disabled: type_ === /* Retroactive */0
                                })
                          }), React.createElement(Hidden.default, {
                            xsDown: true,
                            children: React.createElement(Grid.default, {
                                  item: true,
                                  xs: 1
                                })
                          }))
                }), type_ ? React.createElement(PassageLogsTable.make, {
                    passage: passage,
                    isDataComplete: isDataComplete,
                    onAction: handleRowAction
                  }) : React.createElement(Alert.default, {
                    className: classes.retroactiveInfo,
                    severity: "info",
                    children: "No passage logs in retroactive passage"
                  }), tmp);
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var E = /* alias */0;

var defaultPassageStats = undefined;

var make = PassageLogs;

export {
  Mui ,
  Pa ,
  E ,
  concat ,
  useStyles ,
  noGeoLocationMotorOn ,
  geoLocationMotorOn ,
  defaultPassageStats ,
  defaultOnBack ,
  logAdd ,
  logEdit ,
  make ,
  
}
/* useStyles Not a pure module */
