// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as PassageLogRow from "./PassageLogRow.bs.js";
import * as NoDataInGridMessage from "./NoDataInGridMessage.bs.js";
import * as Table from "@material-ui/core/Table";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";
import * as TableBody from "@material-ui/core/TableBody";
import * as TableHead from "@material-ui/core/TableHead";
import * as TableContainer from "@material-ui/core/TableContainer";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      root: {
        marginTop: 20
      },
      table: {
        minWidth: 0
      }
    });

function PassageLogsTable(Props) {
  var passage = Props.passage;
  var $staropt$star = Props.isDataComplete;
  var $staropt$star$1 = Props.onAction;
  var isDataComplete = $staropt$star !== undefined ? $staropt$star : true;
  var onAction = $staropt$star$1 !== undefined ? $staropt$star$1 : PassageLogRow.Body.ignoreOnAction;
  var classes = useStyles();
  var logs = passage.logs;
  var keyOfPassageLog = function (passageLog) {
    var match = passageLog.id;
    if (match !== undefined) {
      return match.firestore_id;
    } else {
      var unix_timestamp = passageLog.unix_timestamp;
      if (unix_timestamp > 0.0) {
        return unix_timestamp.toString();
      } else {
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "PassageLogsTable.re",
                33,
                13
              ]
            ];
      }
    }
  };
  var tableConfig = PassageLogRow.useTableConfig(passage.fields);
  var match = passage.logs;
  if (isDataComplete) {
    if (!match) {
      return React.createElement(NoDataInGridMessage.make, {
                  msg: /* NoData */Block.__(0, ["No log entry, click `Add Log` or `Motor On`"])
                });
    }
    
  } else if (!match) {
    return React.createElement(NoDataInGridMessage.make, {
                msg: /* LoadingData */0
              });
  }
  return React.createElement(TableContainer.default, {
              className: classes.root,
              children: React.createElement(Table.default, {
                    className: classes.table,
                    size: "small",
                    children: null
                  }, React.createElement(TableHead.default, {
                        children: React.createElement(PassageLogRow.Head.make, {
                              tableConfig: tableConfig
                            })
                      }), React.createElement(TableBody.default, {
                        children: $$Array.of_list(Belt_List.reduceReverseU(logs, /* tuple */[
                                    /* [] */0,
                                    undefined,
                                    undefined,
                                    undefined
                                  ], (function (acc, passageLog) {
                                      var startTs = Belt_Option.getWithDefault(acc[1], passageLog.unix_timestamp);
                                      var e = React.createElement(PassageLogRow.Body.make, {
                                            passageLog: passageLog,
                                            startUnixTimestamp: startTs,
                                            prevUnixTimestamp: acc[2],
                                            prevGeoLocation: acc[3],
                                            onAction: onAction,
                                            tableConfig: tableConfig,
                                            key: keyOfPassageLog(passageLog)
                                          });
                                      return /* tuple */[
                                              /* :: */[
                                                e,
                                                acc[0]
                                              ],
                                              startTs,
                                              passageLog.unix_timestamp,
                                              passageLog.geo_location
                                            ];
                                    }))[0])
                      }))
            });
}

var Mui = /* alias */0;

var Pa = /* alias */0;

var make = PassageLogsTable;

export {
  Mui ,
  Pa ,
  useStyles ,
  make ,
  
}
/* useStyles Not a pure module */
