// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Db from "../db/Db.bs.js";
import * as Boat from "../util/Boat.bs.js";
import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Input from "./core/Input.bs.js";
import * as React from "react";
import * as BackArrow from "./BackArrow.bs.js";
import * as AppContext from "./util/AppContext.bs.js";
import * as Collection from "../util/Collection.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as SystemEntry from "./SystemEntry.bs.js";
import * as BoatSystemsRow from "./BoatSystemsRow.bs.js";
import * as AnalyticsEffect from "./util/AnalyticsEffect.bs.js";
import * as DeleteConfirmation from "./DeleteConfirmation.bs.js";
import * as Box from "@material-ui/core/Box";
import * as Grid from "@material-ui/core/Grid";
import * as Table from "@material-ui/core/Table";
import * as TableBody from "@material-ui/core/TableBody";
import * as TableHead from "@material-ui/core/TableHead";
import * as Typography from "@material-ui/core/Typography";
import * as TableContainer from "@material-ui/core/TableContainer";
import * as MakeStyles from "@material-ui/core/styles/makeStyles";

var useStyles = MakeStyles.default({
      root: {
        margin: "20px 10px 10px 10px",
        maxWidth: 600
      }
    });

function concat(prim, prim$1) {
  return prim$1.concat(prim);
}

function lengthToString(param) {
  if (param !== undefined) {
    var match = param;
    return String(match.length).concat(" ").concat(match.unit_ ? "ft" : "m");
  } else {
    return "Not set";
  }
}

function boatInfo(param) {
  return React.createElement(Grid.default, {
              container: true,
              spacing: 4,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(Input.make, {
                        label: "Builder",
                        value: param.builder,
                        disabled: true
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(Input.make, {
                        label: "Model",
                        value: param.model,
                        disabled: true
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(Input.make, {
                        label: "Model Year",
                        value: Boat.stringOfYear(param.year),
                        disabled: true
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(Input.make, {
                        label: "Length",
                        value: lengthToString(param.loa),
                        disabled: true
                      })
                }));
}

function ignoreOnBack(param) {
  return /* () */0;
}

function BoatInfo(Props) {
  var $staropt$star = Props.boat;
  var $staropt$star$1 = Props.onBack;
  var boat = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var onBack = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnBack;
  var classes = useStyles();
  AnalyticsEffect.useCurrentScreen("BoatInfo");
  var match = React.useState((function () {
          return ;
        }));
  var setMode = match[1];
  var mode = match[0];
  var closeDialogs = function (param) {
    return Curry._1(setMode, (function (param) {
                  return ;
                }));
  };
  var match$1 = React.useContext(AppContext.ctx);
  var firestore = match$1.firestore;
  var handleOnAction = function (param) {
    if (param.tag) {
      var system = param[0];
      return Curry._1(setMode, (function (param) {
                    return /* Delete */Block.__(1, [system]);
                  }));
    } else {
      var system$1 = param[0];
      return Curry._1(setMode, (function (param) {
                    return /* Edit */Block.__(0, [system$1]);
                  }));
    }
  };
  var systems = Belt_Option.mapWithDefaultU(boat, /* [] */0, (function (boat) {
          return Collection.valuesToList(boat.systems);
        }));
  var name = Belt_Option.mapWithDefaultU(boat, "", (function (boat) {
          return boat.data.name;
        }));
  var tmp;
  if (mode !== undefined) {
    var match$2 = mode;
    if (match$2.tag) {
      var system = match$2[0];
      var what = system.identifier;
      tmp = React.createElement(DeleteConfirmation.make, {
            what: what,
            onClose: closeDialogs,
            onOk: (function (param) {
                var system$1 = system;
                Db.deleteSystem(firestore, system$1);
                return Curry._1(setMode, (function (param) {
                              return ;
                            }));
              }),
            screenIdentifier: "System"
          });
    } else {
      var mode$1 = /* Edit */Block.__(1, [match$2[0]]);
      tmp = React.createElement(SystemEntry.make, {
            mode: mode$1,
            onClose: closeDialogs
          });
    }
  } else {
    tmp = null;
  }
  return React.createElement(Box.default, {
              className: classes.root,
              children: null
            }, React.createElement(Box.default, {
                  className: classes.root,
                  children: React.createElement(Grid.default, {
                        container: true,
                        justify: "space-between",
                        alignItems: "center",
                        spacing: 1,
                        children: null
                      }, React.createElement(Grid.default, {
                            item: true,
                            xs: 1,
                            children: React.createElement(BackArrow.make, {
                                  onBack: onBack
                                })
                          }), React.createElement(Grid.default, {
                            item: true,
                            xs: 11
                          }))
                }), React.createElement(Typography.default, {
                  variant: "h6",
                  children: null
                }, name, " information"), React.createElement("br", undefined), boat !== undefined ? boatInfo(boat.data) : null, React.createElement("br", undefined), React.createElement(Typography.default, {
                  variant: "h6",
                  children: "Part/System list"
                }), React.createElement("br", undefined), React.createElement(TableContainer.default, {
                  children: React.createElement(Table.default, {
                        children: null
                      }, React.createElement(TableHead.default, {
                            children: React.createElement(BoatSystemsRow.Head.make, { })
                          }), React.createElement(TableBody.default, {
                            children: $$Array.of_list(List.map((function (system) {
                                        return React.createElement(BoatSystemsRow.Body.make, {
                                                    system: system,
                                                    onAction: handleOnAction,
                                                    key: system.identifier
                                                  });
                                      }), systems))
                          }))
                }), tmp);
}

var Mui = /* alias */0;

var B = /* alias */0;

var S = /* alias */0;

var defaultBoat = undefined;

var noBoatSelected = undefined;

var make = BoatInfo;

export {
  Mui ,
  B ,
  S ,
  defaultBoat ,
  useStyles ,
  concat ,
  lengthToString ,
  boatInfo ,
  noBoatSelected ,
  ignoreOnBack ,
  make ,
  
}
/* useStyles Not a pure module */
