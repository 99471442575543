// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as StringFormLabel from "./core/StringFormLabel.bs.js";
import * as Grid from "@material-ui/core/Grid";
import * as CardinalDirectionSelector from "./core/CardinalDirectionSelector.bs.js";

function ignoreOnChange(param) {
  return /* () */0;
}

function WindDirectionEntry(Props) {
  var $staropt$star = Props.value;
  var $staropt$star$1 = Props.onChange;
  var value = $staropt$star !== undefined ? $staropt$star : /* None */0;
  var onChange = $staropt$star$1 !== undefined ? $staropt$star$1 : ignoreOnChange;
  return React.createElement(Grid.default, {
              container: true,
              justify: "center",
              alignItems: "center",
              spacing: 2,
              children: null
            }, React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(StringFormLabel.make, {
                        text: "Wind Direction"
                      })
                }), React.createElement(Grid.default, {
                  item: true,
                  xs: 6,
                  children: React.createElement(CardinalDirectionSelector.make, {
                        value: value,
                        onChange: onChange
                      })
                }));
}

var Mui = /* alias */0;

var CD = /* alias */0;

var make = WindDirectionEntry;

export {
  Mui ,
  CD ,
  ignoreOnChange ,
  make ,
  
}
/* react Not a pure module */
